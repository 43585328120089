:root {
    --text-primary: #fff;
    --bg: #0c0216;
    --bg-200: #1e1529;
    --grey-300: #d1d8e1;
    --grey-400: #94a0b1;
    --dark-blue: #0052e4;
    --success: #0dc268;
    --border-radius: 16px;
}

.grey-400 {
    color: #94a0b1;
}

* {
    outline: none;
}

ul,
ol,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

label {
    cursor: pointer;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#root {
    position: relative;
    overflow-x: hidden;
}

body,
html {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    font-family: "Object Sans", sans-serif;
    font-style: normal;
}

#__next {
    min-height: 99vh;
    display: flex;
    flex-direction: column;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

hr {
    height: 1px;
    width: 100%;
    opacity: 0.3;
    background-color: #ffffff;
}

.transition-list-enter {
    opacity: 0;
}

.transition-list-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.transition-list-exit {
    opacity: 1;
}

.transition-list-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.reset-style * {
    padding: 0;
    margin: 0;
}

.all-child-inline * {
    display: inline;
}

.display-block {
    display: block;
}
.display-none {
    display: none;
}

.circle {
    border-radius: 50%;
    overflow: hidden;
}

.transition-transform {
    transition: 0.3s transform ease-in-out;
}

.cursor-default {
    cursor: default;
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.border-grey {
    border: 1px solid #342c3e;
}
.border-top-grey {
    border-top: 1px solid #342c3e;
}

.link-underline {
    padding-bottom: 2px;
    border-bottom: 1px solid #fff;
}

.zIndex-1 {
    z-index: 1;
}

* {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
textarea,
ul,
li,
a,
input,
figure,
blockquote {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
}

textarea {
    border: none;
    resize: none;
}

picture,
img {
    display: block;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    display: inline-flex;
    width: max-content;
}

button {
    background: none;
}
button:disabled {
    cursor: default;
}

input,
button,
textarea {
    cursor: pointer;
    border: none;
}

input,
textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -webkit-box-shadow: inherit;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 998px) {
    select,
    textarea,
    input,
    input[type="tel"],
    input:focus {
        font-size: 15px;
    }
}
.T-center {
    text-align: center;
}

.T-nowrap {
    white-space: nowrap;
}

.T-white {
    color: #fff;
}

.T12 {
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
}

.T13 {
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    min-height: 21px;
}
.T13-list {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1px;
    font-weight: 400;
}
.T13-bold {
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
}

.T15,
.T15-bold {
    font-size: 15px;
    min-height: 24px;
    line-height: 24px;
    font-weight: 400;
}

.T15-list-bold {
    font-size: 15px;
    min-height: 24px;
    line-height: 24px;
    font-weight: 400;
    line-height: 21px;
}

.T14,
.T14-bold,
.T14-list-bold {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.T14-list {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    line-height: 20px;
}

.T16,
.T16-bold {
    font-size: 16px;
    line-height: 19px;
}

.T10 {
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
}
.T10-bold {
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
}

.T15-list,
.T15-list-bold {
    font-size: 15px;
    min-height: 24px;
    line-height: 24px;
    font-weight: 400;
    line-height: 21px;
}

.T15-bold,
.T15-list-bold,
.T16-bold,
.T14-bold,
.T15-list-bold,
.T14-list-bold {
    font-weight: 600;
}

.H5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    min-height: 24px;
}

.H4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    min-height: 32px;
}
@media screen and (max-width: 1024px) {
    .H4 {
        font-size: 20px;
        line-height: 26px;
        min-height: 26px;
    }
}

.H3 {
    font-size: 32px;
    line-height: 37px;
    font-weight: 600;
}
.H3.H--minHeight {
    min-height: 37px;
}
@media screen and (max-width: 1024px) {
    .H3 {
        font-size: 24px;
        line-height: 28px;
    }
    .H3.H--minHeight {
        min-height: 28px;
    }
}

.H2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 46px;
}
.H2.H--minHeight {
    min-height: 46px;
}
@media screen and (max-width: 1024px) {
    .H2 {
        font-size: 28px;
        line-height: 32px;
    }
    .H2.H--minHeight {
        min-height: 32px;
    }
}

.H1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
}
@media screen and (max-width: 1024px) {
    .H1 {
        font-size: 32px;
        line-height: 34px;
    }
    .H1.H--minHeight {
        min-height: 34px;
    }
}
.H1.H--minHeight {
    min-height: 55px;
}

.pt-2 {
    padding-top: 2px;
}

.pr-2 {
    padding-right: 2px;
}

.pb-2 {
    padding-bottom: 2px;
}

.pl-2 {
    padding-left: 2px;
}

.p-2 {
    padding: 2px;
}

.ph-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.pv-2 {
    padding-top: 2px;
    padding-bottom: 2px;
}

.pt-4 {
    padding-top: 4px;
}

.pr-4 {
    padding-right: 4px;
}

.pb-4 {
    padding-bottom: 4px;
}

.pl-4 {
    padding-left: 4px;
}

.p-4 {
    padding: 4px;
}

.ph-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.pv-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.pt-6 {
    padding-top: 6px;
}

.pr-6 {
    padding-right: 6px;
}

.pb-6 {
    padding-bottom: 6px;
}

.pl-6 {
    padding-left: 6px;
}

.p-6 {
    padding: 6px;
}

.ph-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.pv-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.pt-8 {
    padding-top: 8px;
}

.pr-8 {
    padding-right: 8px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pl-8 {
    padding-left: 8px;
}

.p-8 {
    padding: 8px;
}

.ph-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.pv-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pt-10 {
    padding-top: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.p-10 {
    padding: 10px;
}

.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pt-12 {
    padding-top: 12px;
}

.pr-12 {
    padding-right: 12px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pl-12 {
    padding-left: 12px;
}

.p-12 {
    padding: 12px;
}

.ph-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.pv-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pt-14 {
    padding-top: 14px;
}

.pr-14 {
    padding-right: 14px;
}

.pb-14 {
    padding-bottom: 14px;
}

.pl-14 {
    padding-left: 14px;
}

.p-14 {
    padding: 14px;
}

.ph-14 {
    padding-left: 14px;
    padding-right: 14px;
}

.pv-14 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.pt-16 {
    padding-top: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pl-16 {
    padding-left: 16px;
}

.p-16 {
    padding: 16px;
}

.ph-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.pv-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.pt-18 {
    padding-top: 18px;
}

.pr-18 {
    padding-right: 18px;
}

.pb-18 {
    padding-bottom: 18px;
}

.pl-18 {
    padding-left: 18px;
}

.p-18 {
    padding: 18px;
}

.ph-18 {
    padding-left: 18px;
    padding-right: 18px;
}

.pv-18 {
    padding-top: 18px;
    padding-bottom: 18px;
}

.pt-20 {
    padding-top: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.p-20 {
    padding: 20px;
}

.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pt-22 {
    padding-top: 22px;
}

.pr-22 {
    padding-right: 22px;
}

.pb-22 {
    padding-bottom: 22px;
}

.pl-22 {
    padding-left: 22px;
}

.p-22 {
    padding: 22px;
}

.ph-22 {
    padding-left: 22px;
    padding-right: 22px;
}

.pv-22 {
    padding-top: 22px;
    padding-bottom: 22px;
}

.pt-24 {
    padding-top: 24px;
}

.pr-24 {
    padding-right: 24px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pl-24 {
    padding-left: 24px;
}

.p-24 {
    padding: 24px;
}

.ph-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.pv-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.pt-26 {
    padding-top: 26px;
}

.pr-26 {
    padding-right: 26px;
}

.pb-26 {
    padding-bottom: 26px;
}

.pl-26 {
    padding-left: 26px;
}

.p-26 {
    padding: 26px;
}

.ph-26 {
    padding-left: 26px;
    padding-right: 26px;
}

.pv-26 {
    padding-top: 26px;
    padding-bottom: 26px;
}

.pt-28 {
    padding-top: 28px;
}

.pr-28 {
    padding-right: 28px;
}

.pb-28 {
    padding-bottom: 28px;
}

.pl-28 {
    padding-left: 28px;
}

.p-28 {
    padding: 28px;
}

.ph-28 {
    padding-left: 28px;
    padding-right: 28px;
}

.pv-28 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.pt-30 {
    padding-top: 30px;
}

.pr-30 {
    padding-right: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.p-30 {
    padding: 30px;
}

.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pv-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pt-32 {
    padding-top: 32px;
}

.pr-32 {
    padding-right: 32px;
}

.pb-32 {
    padding-bottom: 32px;
}

.pl-32 {
    padding-left: 32px;
}

.p-32 {
    padding: 32px;
}

.ph-32 {
    padding-left: 32px;
    padding-right: 32px;
}

.pv-32 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.pt-34 {
    padding-top: 34px;
}

.pr-34 {
    padding-right: 34px;
}

.pb-34 {
    padding-bottom: 34px;
}

.pl-34 {
    padding-left: 34px;
}

.p-34 {
    padding: 34px;
}

.ph-34 {
    padding-left: 34px;
    padding-right: 34px;
}

.pv-34 {
    padding-top: 34px;
    padding-bottom: 34px;
}

.pt-36 {
    padding-top: 36px;
}

.pr-36 {
    padding-right: 36px;
}

.pb-36 {
    padding-bottom: 36px;
}

.pl-36 {
    padding-left: 36px;
}

.p-36 {
    padding: 36px;
}

.ph-36 {
    padding-left: 36px;
    padding-right: 36px;
}

.pv-36 {
    padding-top: 36px;
    padding-bottom: 36px;
}

.pt-38 {
    padding-top: 38px;
}

.pr-38 {
    padding-right: 38px;
}

.pb-38 {
    padding-bottom: 38px;
}

.pl-38 {
    padding-left: 38px;
}

.p-38 {
    padding: 38px;
}

.ph-38 {
    padding-left: 38px;
    padding-right: 38px;
}

.pv-38 {
    padding-top: 38px;
    padding-bottom: 38px;
}

.pt-40 {
    padding-top: 40px;
}

.pr-40 {
    padding-right: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.p-40 {
    padding: 40px;
}

.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.pt-42 {
    padding-top: 42px;
}

.pr-42 {
    padding-right: 42px;
}

.pb-42 {
    padding-bottom: 42px;
}

.pl-42 {
    padding-left: 42px;
}

.p-42 {
    padding: 42px;
}

.ph-42 {
    padding-left: 42px;
    padding-right: 42px;
}

.pv-42 {
    padding-top: 42px;
    padding-bottom: 42px;
}

.pt-44 {
    padding-top: 44px;
}

.pr-44 {
    padding-right: 44px;
}

.pb-44 {
    padding-bottom: 44px;
}

.pl-44 {
    padding-left: 44px;
}

.p-44 {
    padding: 44px;
}

.ph-44 {
    padding-left: 44px;
    padding-right: 44px;
}

.pv-44 {
    padding-top: 44px;
    padding-bottom: 44px;
}

.pt-46 {
    padding-top: 46px;
}

.pr-46 {
    padding-right: 46px;
}

.pb-46 {
    padding-bottom: 46px;
}

.pl-46 {
    padding-left: 46px;
}

.p-46 {
    padding: 46px;
}

.ph-46 {
    padding-left: 46px;
    padding-right: 46px;
}

.pv-46 {
    padding-top: 46px;
    padding-bottom: 46px;
}

.pt-48 {
    padding-top: 48px;
}

.pr-48 {
    padding-right: 48px;
}

.pb-48 {
    padding-bottom: 48px;
}

.pl-48 {
    padding-left: 48px;
}

.p-48 {
    padding: 48px;
}

.ph-48 {
    padding-left: 48px;
    padding-right: 48px;
}

.pv-48 {
    padding-top: 48px;
    padding-bottom: 48px;
}

.pt-50 {
    padding-top: 50px;
}

.pr-50 {
    padding-right: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pl-50 {
    padding-left: 50px;
}

.p-50 {
    padding: 50px;
}

.ph-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.pv-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pt-52 {
    padding-top: 52px;
}

.pr-52 {
    padding-right: 52px;
}

.pb-52 {
    padding-bottom: 52px;
}

.pl-52 {
    padding-left: 52px;
}

.p-52 {
    padding: 52px;
}

.ph-52 {
    padding-left: 52px;
    padding-right: 52px;
}

.pv-52 {
    padding-top: 52px;
    padding-bottom: 52px;
}

.pt-54 {
    padding-top: 54px;
}

.pr-54 {
    padding-right: 54px;
}

.pb-54 {
    padding-bottom: 54px;
}

.pl-54 {
    padding-left: 54px;
}

.p-54 {
    padding: 54px;
}

.ph-54 {
    padding-left: 54px;
    padding-right: 54px;
}

.pv-54 {
    padding-top: 54px;
    padding-bottom: 54px;
}

.pt-56 {
    padding-top: 56px;
}

.pr-56 {
    padding-right: 56px;
}

.pb-56 {
    padding-bottom: 56px;
}

.pl-56 {
    padding-left: 56px;
}

.p-56 {
    padding: 56px;
}

.ph-56 {
    padding-left: 56px;
    padding-right: 56px;
}

.pv-56 {
    padding-top: 56px;
    padding-bottom: 56px;
}

.pt-58 {
    padding-top: 58px;
}

.pr-58 {
    padding-right: 58px;
}

.pb-58 {
    padding-bottom: 58px;
}

.pl-58 {
    padding-left: 58px;
}

.p-58 {
    padding: 58px;
}

.ph-58 {
    padding-left: 58px;
    padding-right: 58px;
}

.pv-58 {
    padding-top: 58px;
    padding-bottom: 58px;
}

.pt-60 {
    padding-top: 60px;
}

.pr-60 {
    padding-right: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pl-60 {
    padding-left: 60px;
}

.p-60 {
    padding: 60px;
}

.ph-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.pv-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pt-62 {
    padding-top: 62px;
}

.pr-62 {
    padding-right: 62px;
}

.pb-62 {
    padding-bottom: 62px;
}

.pl-62 {
    padding-left: 62px;
}

.p-62 {
    padding: 62px;
}

.ph-62 {
    padding-left: 62px;
    padding-right: 62px;
}

.pv-62 {
    padding-top: 62px;
    padding-bottom: 62px;
}

.pt-64 {
    padding-top: 64px;
}

.pr-64 {
    padding-right: 64px;
}

.pb-64 {
    padding-bottom: 64px;
}

.pl-64 {
    padding-left: 64px;
}

.p-64 {
    padding: 64px;
}

.ph-64 {
    padding-left: 64px;
    padding-right: 64px;
}

.pv-64 {
    padding-top: 64px;
    padding-bottom: 64px;
}

.pt-66 {
    padding-top: 66px;
}

.pr-66 {
    padding-right: 66px;
}

.pb-66 {
    padding-bottom: 66px;
}

.pl-66 {
    padding-left: 66px;
}

.p-66 {
    padding: 66px;
}

.ph-66 {
    padding-left: 66px;
    padding-right: 66px;
}

.pv-66 {
    padding-top: 66px;
    padding-bottom: 66px;
}

.pt-68 {
    padding-top: 68px;
}

.pr-68 {
    padding-right: 68px;
}

.pb-68 {
    padding-bottom: 68px;
}

.pl-68 {
    padding-left: 68px;
}

.p-68 {
    padding: 68px;
}

.ph-68 {
    padding-left: 68px;
    padding-right: 68px;
}

.pv-68 {
    padding-top: 68px;
    padding-bottom: 68px;
}

.pt-70 {
    padding-top: 70px;
}

.pr-70 {
    padding-right: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pl-70 {
    padding-left: 70px;
}

.p-70 {
    padding: 70px;
}

.ph-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.pv-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pt-72 {
    padding-top: 72px;
}

.pr-72 {
    padding-right: 72px;
}

.pb-72 {
    padding-bottom: 72px;
}

.pl-72 {
    padding-left: 72px;
}

.p-72 {
    padding: 72px;
}

.ph-72 {
    padding-left: 72px;
    padding-right: 72px;
}

.pv-72 {
    padding-top: 72px;
    padding-bottom: 72px;
}

.pt-74 {
    padding-top: 74px;
}

.pr-74 {
    padding-right: 74px;
}

.pb-74 {
    padding-bottom: 74px;
}

.pl-74 {
    padding-left: 74px;
}

.p-74 {
    padding: 74px;
}

.ph-74 {
    padding-left: 74px;
    padding-right: 74px;
}

.pv-74 {
    padding-top: 74px;
    padding-bottom: 74px;
}

.pt-76 {
    padding-top: 76px;
}

.pr-76 {
    padding-right: 76px;
}

.pb-76 {
    padding-bottom: 76px;
}

.pl-76 {
    padding-left: 76px;
}

.p-76 {
    padding: 76px;
}

.ph-76 {
    padding-left: 76px;
    padding-right: 76px;
}

.pv-76 {
    padding-top: 76px;
    padding-bottom: 76px;
}

.pt-78 {
    padding-top: 78px;
}

.pr-78 {
    padding-right: 78px;
}

.pb-78 {
    padding-bottom: 78px;
}

.pl-78 {
    padding-left: 78px;
}

.p-78 {
    padding: 78px;
}

.ph-78 {
    padding-left: 78px;
    padding-right: 78px;
}

.pv-78 {
    padding-top: 78px;
    padding-bottom: 78px;
}

.pt-80 {
    padding-top: 80px;
}

.pr-80 {
    padding-right: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pl-80 {
    padding-left: 80px;
}

.p-80 {
    padding: 80px;
}

.ph-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.pv-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pt-82 {
    padding-top: 82px;
}

.pr-82 {
    padding-right: 82px;
}

.pb-82 {
    padding-bottom: 82px;
}

.pl-82 {
    padding-left: 82px;
}

.p-82 {
    padding: 82px;
}

.ph-82 {
    padding-left: 82px;
    padding-right: 82px;
}

.pv-82 {
    padding-top: 82px;
    padding-bottom: 82px;
}

.pt-84 {
    padding-top: 84px;
}

.pr-84 {
    padding-right: 84px;
}

.pb-84 {
    padding-bottom: 84px;
}

.pl-84 {
    padding-left: 84px;
}

.p-84 {
    padding: 84px;
}

.ph-84 {
    padding-left: 84px;
    padding-right: 84px;
}

.pv-84 {
    padding-top: 84px;
    padding-bottom: 84px;
}

.pt-86 {
    padding-top: 86px;
}

.pr-86 {
    padding-right: 86px;
}

.pb-86 {
    padding-bottom: 86px;
}

.pl-86 {
    padding-left: 86px;
}

.p-86 {
    padding: 86px;
}

.ph-86 {
    padding-left: 86px;
    padding-right: 86px;
}

.pv-86 {
    padding-top: 86px;
    padding-bottom: 86px;
}

.pt-88 {
    padding-top: 88px;
}

.pr-88 {
    padding-right: 88px;
}

.pb-88 {
    padding-bottom: 88px;
}

.pl-88 {
    padding-left: 88px;
}

.p-88 {
    padding: 88px;
}

.ph-88 {
    padding-left: 88px;
    padding-right: 88px;
}

.pv-88 {
    padding-top: 88px;
    padding-bottom: 88px;
}

.pt-90 {
    padding-top: 90px;
}

.pr-90 {
    padding-right: 90px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pl-90 {
    padding-left: 90px;
}

.p-90 {
    padding: 90px;
}

.ph-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.pv-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.pt-92 {
    padding-top: 92px;
}

.pr-92 {
    padding-right: 92px;
}

.pb-92 {
    padding-bottom: 92px;
}

.pl-92 {
    padding-left: 92px;
}

.p-92 {
    padding: 92px;
}

.ph-92 {
    padding-left: 92px;
    padding-right: 92px;
}

.pv-92 {
    padding-top: 92px;
    padding-bottom: 92px;
}

.pt-94 {
    padding-top: 94px;
}

.pr-94 {
    padding-right: 94px;
}

.pb-94 {
    padding-bottom: 94px;
}

.pl-94 {
    padding-left: 94px;
}

.p-94 {
    padding: 94px;
}

.ph-94 {
    padding-left: 94px;
    padding-right: 94px;
}

.pv-94 {
    padding-top: 94px;
    padding-bottom: 94px;
}

.pt-96 {
    padding-top: 96px;
}

.pr-96 {
    padding-right: 96px;
}

.pb-96 {
    padding-bottom: 96px;
}

.pl-96 {
    padding-left: 96px;
}

.p-96 {
    padding: 96px;
}

.ph-96 {
    padding-left: 96px;
    padding-right: 96px;
}

.pv-96 {
    padding-top: 96px;
    padding-bottom: 96px;
}

.pt-98 {
    padding-top: 98px;
}

.pr-98 {
    padding-right: 98px;
}

.pb-98 {
    padding-bottom: 98px;
}

.pl-98 {
    padding-left: 98px;
}

.p-98 {
    padding: 98px;
}

.ph-98 {
    padding-left: 98px;
    padding-right: 98px;
}

.pv-98 {
    padding-top: 98px;
    padding-bottom: 98px;
}

.pt-100 {
    padding-top: 100px;
}

.pr-100 {
    padding-right: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pl-100 {
    padding-left: 100px;
}

.p-100 {
    padding: 100px;
}

.ph-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.pv-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-102 {
    padding-top: 102px;
}

.pr-102 {
    padding-right: 102px;
}

.pb-102 {
    padding-bottom: 102px;
}

.pl-102 {
    padding-left: 102px;
}

.p-102 {
    padding: 102px;
}

.ph-102 {
    padding-left: 102px;
    padding-right: 102px;
}

.pv-102 {
    padding-top: 102px;
    padding-bottom: 102px;
}

.pt-104 {
    padding-top: 104px;
}

.pr-104 {
    padding-right: 104px;
}

.pb-104 {
    padding-bottom: 104px;
}

.pl-104 {
    padding-left: 104px;
}

.p-104 {
    padding: 104px;
}

.ph-104 {
    padding-left: 104px;
    padding-right: 104px;
}

.pv-104 {
    padding-top: 104px;
    padding-bottom: 104px;
}

.pt-106 {
    padding-top: 106px;
}

.pr-106 {
    padding-right: 106px;
}

.pb-106 {
    padding-bottom: 106px;
}

.pl-106 {
    padding-left: 106px;
}

.p-106 {
    padding: 106px;
}

.ph-106 {
    padding-left: 106px;
    padding-right: 106px;
}

.pv-106 {
    padding-top: 106px;
    padding-bottom: 106px;
}

.pt-108 {
    padding-top: 108px;
}

.pr-108 {
    padding-right: 108px;
}

.pb-108 {
    padding-bottom: 108px;
}

.pl-108 {
    padding-left: 108px;
}

.p-108 {
    padding: 108px;
}

.ph-108 {
    padding-left: 108px;
    padding-right: 108px;
}

.pv-108 {
    padding-top: 108px;
    padding-bottom: 108px;
}

.pt-110 {
    padding-top: 110px;
}

.pr-110 {
    padding-right: 110px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pl-110 {
    padding-left: 110px;
}

.p-110 {
    padding: 110px;
}

.ph-110 {
    padding-left: 110px;
    padding-right: 110px;
}

.pv-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.pt-112 {
    padding-top: 112px;
}

.pr-112 {
    padding-right: 112px;
}

.pb-112 {
    padding-bottom: 112px;
}

.pl-112 {
    padding-left: 112px;
}

.p-112 {
    padding: 112px;
}

.ph-112 {
    padding-left: 112px;
    padding-right: 112px;
}

.pv-112 {
    padding-top: 112px;
    padding-bottom: 112px;
}

.pt-114 {
    padding-top: 114px;
}

.pr-114 {
    padding-right: 114px;
}

.pb-114 {
    padding-bottom: 114px;
}

.pl-114 {
    padding-left: 114px;
}

.p-114 {
    padding: 114px;
}

.ph-114 {
    padding-left: 114px;
    padding-right: 114px;
}

.pv-114 {
    padding-top: 114px;
    padding-bottom: 114px;
}

.pt-116 {
    padding-top: 116px;
}

.pr-116 {
    padding-right: 116px;
}

.pb-116 {
    padding-bottom: 116px;
}

.pl-116 {
    padding-left: 116px;
}

.p-116 {
    padding: 116px;
}

.ph-116 {
    padding-left: 116px;
    padding-right: 116px;
}

.pv-116 {
    padding-top: 116px;
    padding-bottom: 116px;
}

.pt-118 {
    padding-top: 118px;
}

.pr-118 {
    padding-right: 118px;
}

.pb-118 {
    padding-bottom: 118px;
}

.pl-118 {
    padding-left: 118px;
}

.p-118 {
    padding: 118px;
}

.ph-118 {
    padding-left: 118px;
    padding-right: 118px;
}

.pv-118 {
    padding-top: 118px;
    padding-bottom: 118px;
}

.pt-120 {
    padding-top: 120px;
}

.pr-120 {
    padding-right: 120px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pl-120 {
    padding-left: 120px;
}

.p-120 {
    padding: 120px;
}

.ph-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.pv-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pt-122 {
    padding-top: 122px;
}

.pr-122 {
    padding-right: 122px;
}

.pb-122 {
    padding-bottom: 122px;
}

.pl-122 {
    padding-left: 122px;
}

.p-122 {
    padding: 122px;
}

.ph-122 {
    padding-left: 122px;
    padding-right: 122px;
}

.pv-122 {
    padding-top: 122px;
    padding-bottom: 122px;
}

.pt-124 {
    padding-top: 124px;
}

.pr-124 {
    padding-right: 124px;
}

.pb-124 {
    padding-bottom: 124px;
}

.pl-124 {
    padding-left: 124px;
}

.p-124 {
    padding: 124px;
}

.ph-124 {
    padding-left: 124px;
    padding-right: 124px;
}

.pv-124 {
    padding-top: 124px;
    padding-bottom: 124px;
}

.pt-126 {
    padding-top: 126px;
}

.pr-126 {
    padding-right: 126px;
}

.pb-126 {
    padding-bottom: 126px;
}

.pl-126 {
    padding-left: 126px;
}

.p-126 {
    padding: 126px;
}

.ph-126 {
    padding-left: 126px;
    padding-right: 126px;
}

.pv-126 {
    padding-top: 126px;
    padding-bottom: 126px;
}

.pt-128 {
    padding-top: 128px;
}

.pr-128 {
    padding-right: 128px;
}

.pb-128 {
    padding-bottom: 128px;
}

.pl-128 {
    padding-left: 128px;
}

.p-128 {
    padding: 128px;
}

.ph-128 {
    padding-left: 128px;
    padding-right: 128px;
}

.pv-128 {
    padding-top: 128px;
    padding-bottom: 128px;
}

.pt-130 {
    padding-top: 130px;
}

.pr-130 {
    padding-right: 130px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pl-130 {
    padding-left: 130px;
}

.p-130 {
    padding: 130px;
}

.ph-130 {
    padding-left: 130px;
    padding-right: 130px;
}

.pv-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.pt-132 {
    padding-top: 132px;
}

.pr-132 {
    padding-right: 132px;
}

.pb-132 {
    padding-bottom: 132px;
}

.pl-132 {
    padding-left: 132px;
}

.p-132 {
    padding: 132px;
}

.ph-132 {
    padding-left: 132px;
    padding-right: 132px;
}

.pv-132 {
    padding-top: 132px;
    padding-bottom: 132px;
}

.pt-134 {
    padding-top: 134px;
}

.pr-134 {
    padding-right: 134px;
}

.pb-134 {
    padding-bottom: 134px;
}

.pl-134 {
    padding-left: 134px;
}

.p-134 {
    padding: 134px;
}

.ph-134 {
    padding-left: 134px;
    padding-right: 134px;
}

.pv-134 {
    padding-top: 134px;
    padding-bottom: 134px;
}

.pt-136 {
    padding-top: 136px;
}

.pr-136 {
    padding-right: 136px;
}

.pb-136 {
    padding-bottom: 136px;
}

.pl-136 {
    padding-left: 136px;
}

.p-136 {
    padding: 136px;
}

.ph-136 {
    padding-left: 136px;
    padding-right: 136px;
}

.pv-136 {
    padding-top: 136px;
    padding-bottom: 136px;
}

.pt-138 {
    padding-top: 138px;
}

.pr-138 {
    padding-right: 138px;
}

.pb-138 {
    padding-bottom: 138px;
}

.pl-138 {
    padding-left: 138px;
}

.p-138 {
    padding: 138px;
}

.ph-138 {
    padding-left: 138px;
    padding-right: 138px;
}

.pv-138 {
    padding-top: 138px;
    padding-bottom: 138px;
}

.pt-140 {
    padding-top: 140px;
}

.pr-140 {
    padding-right: 140px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pl-140 {
    padding-left: 140px;
}

.p-140 {
    padding: 140px;
}

.ph-140 {
    padding-left: 140px;
    padding-right: 140px;
}

.pv-140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

.pt-142 {
    padding-top: 142px;
}

.pr-142 {
    padding-right: 142px;
}

.pb-142 {
    padding-bottom: 142px;
}

.pl-142 {
    padding-left: 142px;
}

.p-142 {
    padding: 142px;
}

.ph-142 {
    padding-left: 142px;
    padding-right: 142px;
}

.pv-142 {
    padding-top: 142px;
    padding-bottom: 142px;
}

.pt-144 {
    padding-top: 144px;
}

.pr-144 {
    padding-right: 144px;
}

.pb-144 {
    padding-bottom: 144px;
}

.pl-144 {
    padding-left: 144px;
}

.p-144 {
    padding: 144px;
}

.ph-144 {
    padding-left: 144px;
    padding-right: 144px;
}

.pv-144 {
    padding-top: 144px;
    padding-bottom: 144px;
}

.pt-146 {
    padding-top: 146px;
}

.pr-146 {
    padding-right: 146px;
}

.pb-146 {
    padding-bottom: 146px;
}

.pl-146 {
    padding-left: 146px;
}

.p-146 {
    padding: 146px;
}

.ph-146 {
    padding-left: 146px;
    padding-right: 146px;
}

.pv-146 {
    padding-top: 146px;
    padding-bottom: 146px;
}

.pt-148 {
    padding-top: 148px;
}

.pr-148 {
    padding-right: 148px;
}

.pb-148 {
    padding-bottom: 148px;
}

.pl-148 {
    padding-left: 148px;
}

.p-148 {
    padding: 148px;
}

.ph-148 {
    padding-left: 148px;
    padding-right: 148px;
}

.pv-148 {
    padding-top: 148px;
    padding-bottom: 148px;
}

.pt-150 {
    padding-top: 150px;
}

.pr-150 {
    padding-right: 150px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pl-150 {
    padding-left: 150px;
}

.p-150 {
    padding: 150px;
}

.ph-150 {
    padding-left: 150px;
    padding-right: 150px;
}

.pv-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.pt-152 {
    padding-top: 152px;
}

.pr-152 {
    padding-right: 152px;
}

.pb-152 {
    padding-bottom: 152px;
}

.pl-152 {
    padding-left: 152px;
}

.p-152 {
    padding: 152px;
}

.ph-152 {
    padding-left: 152px;
    padding-right: 152px;
}

.pv-152 {
    padding-top: 152px;
    padding-bottom: 152px;
}

.pt-154 {
    padding-top: 154px;
}

.pr-154 {
    padding-right: 154px;
}

.pb-154 {
    padding-bottom: 154px;
}

.pl-154 {
    padding-left: 154px;
}

.p-154 {
    padding: 154px;
}

.ph-154 {
    padding-left: 154px;
    padding-right: 154px;
}

.pv-154 {
    padding-top: 154px;
    padding-bottom: 154px;
}

.pt-156 {
    padding-top: 156px;
}

.pr-156 {
    padding-right: 156px;
}

.pb-156 {
    padding-bottom: 156px;
}

.pl-156 {
    padding-left: 156px;
}

.p-156 {
    padding: 156px;
}

.ph-156 {
    padding-left: 156px;
    padding-right: 156px;
}

.pv-156 {
    padding-top: 156px;
    padding-bottom: 156px;
}

.pt-158 {
    padding-top: 158px;
}

.pr-158 {
    padding-right: 158px;
}

.pb-158 {
    padding-bottom: 158px;
}

.pl-158 {
    padding-left: 158px;
}

.p-158 {
    padding: 158px;
}

.ph-158 {
    padding-left: 158px;
    padding-right: 158px;
}

.pv-158 {
    padding-top: 158px;
    padding-bottom: 158px;
}

.pt-160 {
    padding-top: 160px;
}

.pr-160 {
    padding-right: 160px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pl-160 {
    padding-left: 160px;
}

.p-160 {
    padding: 160px;
}

.ph-160 {
    padding-left: 160px;
    padding-right: 160px;
}

.pv-160 {
    padding-top: 160px;
    padding-bottom: 160px;
}

.pt-162 {
    padding-top: 162px;
}

.pr-162 {
    padding-right: 162px;
}

.pb-162 {
    padding-bottom: 162px;
}

.pl-162 {
    padding-left: 162px;
}

.p-162 {
    padding: 162px;
}

.ph-162 {
    padding-left: 162px;
    padding-right: 162px;
}

.pv-162 {
    padding-top: 162px;
    padding-bottom: 162px;
}

.pt-164 {
    padding-top: 164px;
}

.pr-164 {
    padding-right: 164px;
}

.pb-164 {
    padding-bottom: 164px;
}

.pl-164 {
    padding-left: 164px;
}

.p-164 {
    padding: 164px;
}

.ph-164 {
    padding-left: 164px;
    padding-right: 164px;
}

.pv-164 {
    padding-top: 164px;
    padding-bottom: 164px;
}

.pt-166 {
    padding-top: 166px;
}

.pr-166 {
    padding-right: 166px;
}

.pb-166 {
    padding-bottom: 166px;
}

.pl-166 {
    padding-left: 166px;
}

.p-166 {
    padding: 166px;
}

.ph-166 {
    padding-left: 166px;
    padding-right: 166px;
}

.pv-166 {
    padding-top: 166px;
    padding-bottom: 166px;
}

.pt-168 {
    padding-top: 168px;
}

.pr-168 {
    padding-right: 168px;
}

.pb-168 {
    padding-bottom: 168px;
}

.pl-168 {
    padding-left: 168px;
}

.p-168 {
    padding: 168px;
}

.ph-168 {
    padding-left: 168px;
    padding-right: 168px;
}

.pv-168 {
    padding-top: 168px;
    padding-bottom: 168px;
}

.pt-170 {
    padding-top: 170px;
}

.pr-170 {
    padding-right: 170px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pl-170 {
    padding-left: 170px;
}

.p-170 {
    padding: 170px;
}

.ph-170 {
    padding-left: 170px;
    padding-right: 170px;
}

.pv-170 {
    padding-top: 170px;
    padding-bottom: 170px;
}

.pt-172 {
    padding-top: 172px;
}

.pr-172 {
    padding-right: 172px;
}

.pb-172 {
    padding-bottom: 172px;
}

.pl-172 {
    padding-left: 172px;
}

.p-172 {
    padding: 172px;
}

.ph-172 {
    padding-left: 172px;
    padding-right: 172px;
}

.pv-172 {
    padding-top: 172px;
    padding-bottom: 172px;
}

.pt-174 {
    padding-top: 174px;
}

.pr-174 {
    padding-right: 174px;
}

.pb-174 {
    padding-bottom: 174px;
}

.pl-174 {
    padding-left: 174px;
}

.p-174 {
    padding: 174px;
}

.ph-174 {
    padding-left: 174px;
    padding-right: 174px;
}

.pv-174 {
    padding-top: 174px;
    padding-bottom: 174px;
}

.pt-176 {
    padding-top: 176px;
}

.pr-176 {
    padding-right: 176px;
}

.pb-176 {
    padding-bottom: 176px;
}

.pl-176 {
    padding-left: 176px;
}

.p-176 {
    padding: 176px;
}

.ph-176 {
    padding-left: 176px;
    padding-right: 176px;
}

.pv-176 {
    padding-top: 176px;
    padding-bottom: 176px;
}

.pt-178 {
    padding-top: 178px;
}

.pr-178 {
    padding-right: 178px;
}

.pb-178 {
    padding-bottom: 178px;
}

.pl-178 {
    padding-left: 178px;
}

.p-178 {
    padding: 178px;
}

.ph-178 {
    padding-left: 178px;
    padding-right: 178px;
}

.pv-178 {
    padding-top: 178px;
    padding-bottom: 178px;
}

.pt-180 {
    padding-top: 180px;
}

.pr-180 {
    padding-right: 180px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pl-180 {
    padding-left: 180px;
}

.p-180 {
    padding: 180px;
}

.ph-180 {
    padding-left: 180px;
    padding-right: 180px;
}

.pv-180 {
    padding-top: 180px;
    padding-bottom: 180px;
}

.pt-182 {
    padding-top: 182px;
}

.pr-182 {
    padding-right: 182px;
}

.pb-182 {
    padding-bottom: 182px;
}

.pl-182 {
    padding-left: 182px;
}

.p-182 {
    padding: 182px;
}

.ph-182 {
    padding-left: 182px;
    padding-right: 182px;
}

.pv-182 {
    padding-top: 182px;
    padding-bottom: 182px;
}

.pt-184 {
    padding-top: 184px;
}

.pr-184 {
    padding-right: 184px;
}

.pb-184 {
    padding-bottom: 184px;
}

.pl-184 {
    padding-left: 184px;
}

.p-184 {
    padding: 184px;
}

.ph-184 {
    padding-left: 184px;
    padding-right: 184px;
}

.pv-184 {
    padding-top: 184px;
    padding-bottom: 184px;
}

.pt-186 {
    padding-top: 186px;
}

.pr-186 {
    padding-right: 186px;
}

.pb-186 {
    padding-bottom: 186px;
}

.pl-186 {
    padding-left: 186px;
}

.p-186 {
    padding: 186px;
}

.ph-186 {
    padding-left: 186px;
    padding-right: 186px;
}

.pv-186 {
    padding-top: 186px;
    padding-bottom: 186px;
}

.pt-188 {
    padding-top: 188px;
}

.pr-188 {
    padding-right: 188px;
}

.pb-188 {
    padding-bottom: 188px;
}

.pl-188 {
    padding-left: 188px;
}

.p-188 {
    padding: 188px;
}

.ph-188 {
    padding-left: 188px;
    padding-right: 188px;
}

.pv-188 {
    padding-top: 188px;
    padding-bottom: 188px;
}

.pt-190 {
    padding-top: 190px;
}

.pr-190 {
    padding-right: 190px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pl-190 {
    padding-left: 190px;
}

.p-190 {
    padding: 190px;
}

.ph-190 {
    padding-left: 190px;
    padding-right: 190px;
}

.pv-190 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.pt-192 {
    padding-top: 192px;
}

.pr-192 {
    padding-right: 192px;
}

.pb-192 {
    padding-bottom: 192px;
}

.pl-192 {
    padding-left: 192px;
}

.p-192 {
    padding: 192px;
}

.ph-192 {
    padding-left: 192px;
    padding-right: 192px;
}

.pv-192 {
    padding-top: 192px;
    padding-bottom: 192px;
}

.pt-194 {
    padding-top: 194px;
}

.pr-194 {
    padding-right: 194px;
}

.pb-194 {
    padding-bottom: 194px;
}

.pl-194 {
    padding-left: 194px;
}

.p-194 {
    padding: 194px;
}

.ph-194 {
    padding-left: 194px;
    padding-right: 194px;
}

.pv-194 {
    padding-top: 194px;
    padding-bottom: 194px;
}

.pt-196 {
    padding-top: 196px;
}

.pr-196 {
    padding-right: 196px;
}

.pb-196 {
    padding-bottom: 196px;
}

.pl-196 {
    padding-left: 196px;
}

.p-196 {
    padding: 196px;
}

.ph-196 {
    padding-left: 196px;
    padding-right: 196px;
}

.pv-196 {
    padding-top: 196px;
    padding-bottom: 196px;
}

.pt-198 {
    padding-top: 198px;
}

.pr-198 {
    padding-right: 198px;
}

.pb-198 {
    padding-bottom: 198px;
}

.pl-198 {
    padding-left: 198px;
}

.p-198 {
    padding: 198px;
}

.ph-198 {
    padding-left: 198px;
    padding-right: 198px;
}

.pv-198 {
    padding-top: 198px;
    padding-bottom: 198px;
}

.pt-200 {
    padding-top: 200px;
}

.pr-200 {
    padding-right: 200px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pl-200 {
    padding-left: 200px;
}

.p-200 {
    padding: 200px;
}

.ph-200 {
    padding-left: 200px;
    padding-right: 200px;
}

.pv-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.p-0 {
    padding: 0;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pv-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.mt-2 {
    margin-top: 2px;
}

.mr-2 {
    margin-right: 2px;
}

.mb-2 {
    margin-bottom: 2px;
}

.ml-2 {
    margin-left: 2px;
}

.m-2 {
    margin: 2px;
}

.mh-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.mv-2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mt-4 {
    margin-top: 4px;
}

.mr-4 {
    margin-right: 4px;
}

.mb-4 {
    margin-bottom: 4px;
}

.ml-4 {
    margin-left: 4px;
}

.m-4 {
    margin: 4px;
}

.mh-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mv-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mt-6 {
    margin-top: 6px;
}

.mr-6 {
    margin-right: 6px;
}

.mb-6 {
    margin-bottom: 6px;
}

.ml-6 {
    margin-left: 6px;
}

.m-6 {
    margin: 6px;
}

.mh-6 {
    margin-left: 6px;
    margin-right: 6px;
}

.mv-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.mt-8 {
    margin-top: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mb-8 {
    margin-bottom: 8px;
}

.ml-8 {
    margin-left: 8px;
}

.m-8 {
    margin: 8px;
}

.mh-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mv-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mt-10 {
    margin-top: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.m-10 {
    margin: 10px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mt-12 {
    margin-top: 12px;
}

.mr-12 {
    margin-right: 12px;
}

.mb-12 {
    margin-bottom: 12px;
}

.ml-12 {
    margin-left: 12px;
}

.m-12 {
    margin: 12px;
}

.mh-12 {
    margin-left: 12px;
    margin-right: 12px;
}

.mv-12 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.mt-14 {
    margin-top: 14px;
}

.mr-14 {
    margin-right: 14px;
}

.mb-14 {
    margin-bottom: 14px;
}

.ml-14 {
    margin-left: 14px;
}

.m-14 {
    margin: 14px;
}

.mh-14 {
    margin-left: 14px;
    margin-right: 14px;
}

.mv-14 {
    margin-top: 14px;
    margin-bottom: 14px;
}

.mt-16 {
    margin-top: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.m-16 {
    margin: 16px;
}

.mh-16 {
    margin-left: 16px;
    margin-right: 16px;
}

.mv-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.mt-18 {
    margin-top: 18px;
}

.mr-18 {
    margin-right: 18px;
}

.mb-18 {
    margin-bottom: 18px;
}

.ml-18 {
    margin-left: 18px;
}

.m-18 {
    margin: 18px;
}

.mh-18 {
    margin-left: 18px;
    margin-right: 18px;
}

.mv-18 {
    margin-top: 18px;
    margin-bottom: 18px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.m-20 {
    margin: 20px;
}

.mh-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mv-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mt-22 {
    margin-top: 22px;
}

.mr-22 {
    margin-right: 22px;
}

.mb-22 {
    margin-bottom: 22px;
}

.ml-22 {
    margin-left: 22px;
}

.m-22 {
    margin: 22px;
}

.mh-22 {
    margin-left: 22px;
    margin-right: 22px;
}

.mv-22 {
    margin-top: 22px;
    margin-bottom: 22px;
}

.mt-24 {
    margin-top: 24px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-24 {
    margin-bottom: 24px;
}

.ml-24 {
    margin-left: 24px;
}

.m-24 {
    margin: 24px;
}

.mh-24 {
    margin-left: 24px;
    margin-right: 24px;
}

.mv-24 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.mt-26 {
    margin-top: 26px;
}

.mr-26 {
    margin-right: 26px;
}

.mb-26 {
    margin-bottom: 26px;
}

.ml-26 {
    margin-left: 26px;
}

.m-26 {
    margin: 26px;
}

.mh-26 {
    margin-left: 26px;
    margin-right: 26px;
}

.mv-26 {
    margin-top: 26px;
    margin-bottom: 26px;
}

.mt-28 {
    margin-top: 28px;
}

.mr-28 {
    margin-right: 28px;
}

.mb-28 {
    margin-bottom: 28px;
}

.ml-28 {
    margin-left: 28px;
}

.m-28 {
    margin: 28px;
}

.mh-28 {
    margin-left: 28px;
    margin-right: 28px;
}

.mv-28 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.m-30 {
    margin: 30px;
}

.mh-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mv-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mt-32 {
    margin-top: 32px;
}

.mr-32 {
    margin-right: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.ml-32 {
    margin-left: 32px;
}

.m-32 {
    margin: 32px;
}

.mh-32 {
    margin-left: 32px;
    margin-right: 32px;
}

.mv-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.mt-34 {
    margin-top: 34px;
}

.mr-34 {
    margin-right: 34px;
}

.mb-34 {
    margin-bottom: 34px;
}

.ml-34 {
    margin-left: 34px;
}

.m-34 {
    margin: 34px;
}

.mh-34 {
    margin-left: 34px;
    margin-right: 34px;
}

.mv-34 {
    margin-top: 34px;
    margin-bottom: 34px;
}

.mt-36 {
    margin-top: 36px;
}

.mr-36 {
    margin-right: 36px;
}

.mb-36 {
    margin-bottom: 36px;
}

.ml-36 {
    margin-left: 36px;
}

.m-36 {
    margin: 36px;
}

.mh-36 {
    margin-left: 36px;
    margin-right: 36px;
}

.mv-36 {
    margin-top: 36px;
    margin-bottom: 36px;
}

.mt-38 {
    margin-top: 38px;
}

.mr-38 {
    margin-right: 38px;
}

.mb-38 {
    margin-bottom: 38px;
}

.ml-38 {
    margin-left: 38px;
}

.m-38 {
    margin: 38px;
}

.mh-38 {
    margin-left: 38px;
    margin-right: 38px;
}

.mv-38 {
    margin-top: 38px;
    margin-bottom: 38px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.m-40 {
    margin: 40px;
}

.mh-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mt-42 {
    margin-top: 42px;
}

.mr-42 {
    margin-right: 42px;
}

.mb-42 {
    margin-bottom: 42px;
}

.ml-42 {
    margin-left: 42px;
}

.m-42 {
    margin: 42px;
}

.mh-42 {
    margin-left: 42px;
    margin-right: 42px;
}

.mv-42 {
    margin-top: 42px;
    margin-bottom: 42px;
}

.mt-44 {
    margin-top: 44px;
}

.mr-44 {
    margin-right: 44px;
}

.mb-44 {
    margin-bottom: 44px;
}

.ml-44 {
    margin-left: 44px;
}

.m-44 {
    margin: 44px;
}

.mh-44 {
    margin-left: 44px;
    margin-right: 44px;
}

.mv-44 {
    margin-top: 44px;
    margin-bottom: 44px;
}

.mt-46 {
    margin-top: 46px;
}

.mr-46 {
    margin-right: 46px;
}

.mb-46 {
    margin-bottom: 46px;
}

.ml-46 {
    margin-left: 46px;
}

.m-46 {
    margin: 46px;
}

.mh-46 {
    margin-left: 46px;
    margin-right: 46px;
}

.mv-46 {
    margin-top: 46px;
    margin-bottom: 46px;
}

.mt-48 {
    margin-top: 48px;
}

.mr-48 {
    margin-right: 48px;
}

.mb-48 {
    margin-bottom: 48px;
}

.ml-48 {
    margin-left: 48px;
}

.m-48 {
    margin: 48px;
}

.mh-48 {
    margin-left: 48px;
    margin-right: 48px;
}

.mv-48 {
    margin-top: 48px;
    margin-bottom: 48px;
}

.mt-50 {
    margin-top: 50px;
}

.mr-50 {
    margin-right: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-50 {
    margin-left: 50px;
}

.m-50 {
    margin: 50px;
}

.mh-50 {
    margin-left: 50px;
    margin-right: 50px;
}

.mv-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.mt-52 {
    margin-top: 52px;
}

.mr-52 {
    margin-right: 52px;
}

.mb-52 {
    margin-bottom: 52px;
}

.ml-52 {
    margin-left: 52px;
}

.m-52 {
    margin: 52px;
}

.mh-52 {
    margin-left: 52px;
    margin-right: 52px;
}

.mv-52 {
    margin-top: 52px;
    margin-bottom: 52px;
}

.mt-54 {
    margin-top: 54px;
}

.mr-54 {
    margin-right: 54px;
}

.mb-54 {
    margin-bottom: 54px;
}

.ml-54 {
    margin-left: 54px;
}

.m-54 {
    margin: 54px;
}

.mh-54 {
    margin-left: 54px;
    margin-right: 54px;
}

.mv-54 {
    margin-top: 54px;
    margin-bottom: 54px;
}

.mt-56 {
    margin-top: 56px;
}

.mr-56 {
    margin-right: 56px;
}

.mb-56 {
    margin-bottom: 56px;
}

.ml-56 {
    margin-left: 56px;
}

.m-56 {
    margin: 56px;
}

.mh-56 {
    margin-left: 56px;
    margin-right: 56px;
}

.mv-56 {
    margin-top: 56px;
    margin-bottom: 56px;
}

.mt-58 {
    margin-top: 58px;
}

.mr-58 {
    margin-right: 58px;
}

.mb-58 {
    margin-bottom: 58px;
}

.ml-58 {
    margin-left: 58px;
}

.m-58 {
    margin: 58px;
}

.mh-58 {
    margin-left: 58px;
    margin-right: 58px;
}

.mv-58 {
    margin-top: 58px;
    margin-bottom: 58px;
}

.mt-60 {
    margin-top: 60px;
}

.mr-60 {
    margin-right: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.ml-60 {
    margin-left: 60px;
}

.m-60 {
    margin: 60px;
}

.mh-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.mv-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mt-62 {
    margin-top: 62px;
}

.mr-62 {
    margin-right: 62px;
}

.mb-62 {
    margin-bottom: 62px;
}

.ml-62 {
    margin-left: 62px;
}

.m-62 {
    margin: 62px;
}

.mh-62 {
    margin-left: 62px;
    margin-right: 62px;
}

.mv-62 {
    margin-top: 62px;
    margin-bottom: 62px;
}

.mt-64 {
    margin-top: 64px;
}

.mr-64 {
    margin-right: 64px;
}

.mb-64 {
    margin-bottom: 64px;
}

.ml-64 {
    margin-left: 64px;
}

.m-64 {
    margin: 64px;
}

.mh-64 {
    margin-left: 64px;
    margin-right: 64px;
}

.mv-64 {
    margin-top: 64px;
    margin-bottom: 64px;
}

.mt-66 {
    margin-top: 66px;
}

.mr-66 {
    margin-right: 66px;
}

.mb-66 {
    margin-bottom: 66px;
}

.ml-66 {
    margin-left: 66px;
}

.m-66 {
    margin: 66px;
}

.mh-66 {
    margin-left: 66px;
    margin-right: 66px;
}

.mv-66 {
    margin-top: 66px;
    margin-bottom: 66px;
}

.mt-68 {
    margin-top: 68px;
}

.mr-68 {
    margin-right: 68px;
}

.mb-68 {
    margin-bottom: 68px;
}

.ml-68 {
    margin-left: 68px;
}

.m-68 {
    margin: 68px;
}

.mh-68 {
    margin-left: 68px;
    margin-right: 68px;
}

.mv-68 {
    margin-top: 68px;
    margin-bottom: 68px;
}

.mt-70 {
    margin-top: 70px;
}

.mr-70 {
    margin-right: 70px;
}

.mb-70 {
    margin-bottom: 70px;
}

.ml-70 {
    margin-left: 70px;
}

.m-70 {
    margin: 70px;
}

.mh-70 {
    margin-left: 70px;
    margin-right: 70px;
}

.mv-70 {
    margin-top: 70px;
    margin-bottom: 70px;
}

.mt-72 {
    margin-top: 72px;
}

.mr-72 {
    margin-right: 72px;
}

.mb-72 {
    margin-bottom: 72px;
}

.ml-72 {
    margin-left: 72px;
}

.m-72 {
    margin: 72px;
}

.mh-72 {
    margin-left: 72px;
    margin-right: 72px;
}

.mv-72 {
    margin-top: 72px;
    margin-bottom: 72px;
}

.mt-74 {
    margin-top: 74px;
}

.mr-74 {
    margin-right: 74px;
}

.mb-74 {
    margin-bottom: 74px;
}

.ml-74 {
    margin-left: 74px;
}

.m-74 {
    margin: 74px;
}

.mh-74 {
    margin-left: 74px;
    margin-right: 74px;
}

.mv-74 {
    margin-top: 74px;
    margin-bottom: 74px;
}

.mt-76 {
    margin-top: 76px;
}

.mr-76 {
    margin-right: 76px;
}

.mb-76 {
    margin-bottom: 76px;
}

.ml-76 {
    margin-left: 76px;
}

.m-76 {
    margin: 76px;
}

.mh-76 {
    margin-left: 76px;
    margin-right: 76px;
}

.mv-76 {
    margin-top: 76px;
    margin-bottom: 76px;
}

.mt-78 {
    margin-top: 78px;
}

.mr-78 {
    margin-right: 78px;
}

.mb-78 {
    margin-bottom: 78px;
}

.ml-78 {
    margin-left: 78px;
}

.m-78 {
    margin: 78px;
}

.mh-78 {
    margin-left: 78px;
    margin-right: 78px;
}

.mv-78 {
    margin-top: 78px;
    margin-bottom: 78px;
}

.mt-80 {
    margin-top: 80px;
}

.mr-80 {
    margin-right: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

.ml-80 {
    margin-left: 80px;
}

.m-80 {
    margin: 80px;
}

.mh-80 {
    margin-left: 80px;
    margin-right: 80px;
}

.mv-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mt-82 {
    margin-top: 82px;
}

.mr-82 {
    margin-right: 82px;
}

.mb-82 {
    margin-bottom: 82px;
}

.ml-82 {
    margin-left: 82px;
}

.m-82 {
    margin: 82px;
}

.mh-82 {
    margin-left: 82px;
    margin-right: 82px;
}

.mv-82 {
    margin-top: 82px;
    margin-bottom: 82px;
}

.mt-84 {
    margin-top: 84px;
}

.mr-84 {
    margin-right: 84px;
}

.mb-84 {
    margin-bottom: 84px;
}

.ml-84 {
    margin-left: 84px;
}

.m-84 {
    margin: 84px;
}

.mh-84 {
    margin-left: 84px;
    margin-right: 84px;
}

.mv-84 {
    margin-top: 84px;
    margin-bottom: 84px;
}

.mt-86 {
    margin-top: 86px;
}

.mr-86 {
    margin-right: 86px;
}

.mb-86 {
    margin-bottom: 86px;
}

.ml-86 {
    margin-left: 86px;
}

.m-86 {
    margin: 86px;
}

.mh-86 {
    margin-left: 86px;
    margin-right: 86px;
}

.mv-86 {
    margin-top: 86px;
    margin-bottom: 86px;
}

.mt-88 {
    margin-top: 88px;
}

.mr-88 {
    margin-right: 88px;
}

.mb-88 {
    margin-bottom: 88px;
}

.ml-88 {
    margin-left: 88px;
}

.m-88 {
    margin: 88px;
}

.mh-88 {
    margin-left: 88px;
    margin-right: 88px;
}

.mv-88 {
    margin-top: 88px;
    margin-bottom: 88px;
}

.mt-90 {
    margin-top: 90px;
}

.mr-90 {
    margin-right: 90px;
}

.mb-90 {
    margin-bottom: 90px;
}

.ml-90 {
    margin-left: 90px;
}

.m-90 {
    margin: 90px;
}

.mh-90 {
    margin-left: 90px;
    margin-right: 90px;
}

.mv-90 {
    margin-top: 90px;
    margin-bottom: 90px;
}

.mt-92 {
    margin-top: 92px;
}

.mr-92 {
    margin-right: 92px;
}

.mb-92 {
    margin-bottom: 92px;
}

.ml-92 {
    margin-left: 92px;
}

.m-92 {
    margin: 92px;
}

.mh-92 {
    margin-left: 92px;
    margin-right: 92px;
}

.mv-92 {
    margin-top: 92px;
    margin-bottom: 92px;
}

.mt-94 {
    margin-top: 94px;
}

.mr-94 {
    margin-right: 94px;
}

.mb-94 {
    margin-bottom: 94px;
}

.ml-94 {
    margin-left: 94px;
}

.m-94 {
    margin: 94px;
}

.mh-94 {
    margin-left: 94px;
    margin-right: 94px;
}

.mv-94 {
    margin-top: 94px;
    margin-bottom: 94px;
}

.mt-96 {
    margin-top: 96px;
}

.mr-96 {
    margin-right: 96px;
}

.mb-96 {
    margin-bottom: 96px;
}

.ml-96 {
    margin-left: 96px;
}

.m-96 {
    margin: 96px;
}

.mh-96 {
    margin-left: 96px;
    margin-right: 96px;
}

.mv-96 {
    margin-top: 96px;
    margin-bottom: 96px;
}

.mt-98 {
    margin-top: 98px;
}

.mr-98 {
    margin-right: 98px;
}

.mb-98 {
    margin-bottom: 98px;
}

.ml-98 {
    margin-left: 98px;
}

.m-98 {
    margin: 98px;
}

.mh-98 {
    margin-left: 98px;
    margin-right: 98px;
}

.mv-98 {
    margin-top: 98px;
    margin-bottom: 98px;
}

.mt-100 {
    margin-top: 100px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-100 {
    margin-left: 100px;
}

.m-100 {
    margin: 100px;
}

.mh-100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mv-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.mt-102 {
    margin-top: 102px;
}

.mr-102 {
    margin-right: 102px;
}

.mb-102 {
    margin-bottom: 102px;
}

.ml-102 {
    margin-left: 102px;
}

.m-102 {
    margin: 102px;
}

.mh-102 {
    margin-left: 102px;
    margin-right: 102px;
}

.mv-102 {
    margin-top: 102px;
    margin-bottom: 102px;
}

.mt-104 {
    margin-top: 104px;
}

.mr-104 {
    margin-right: 104px;
}

.mb-104 {
    margin-bottom: 104px;
}

.ml-104 {
    margin-left: 104px;
}

.m-104 {
    margin: 104px;
}

.mh-104 {
    margin-left: 104px;
    margin-right: 104px;
}

.mv-104 {
    margin-top: 104px;
    margin-bottom: 104px;
}

.mt-106 {
    margin-top: 106px;
}

.mr-106 {
    margin-right: 106px;
}

.mb-106 {
    margin-bottom: 106px;
}

.ml-106 {
    margin-left: 106px;
}

.m-106 {
    margin: 106px;
}

.mh-106 {
    margin-left: 106px;
    margin-right: 106px;
}

.mv-106 {
    margin-top: 106px;
    margin-bottom: 106px;
}

.mt-108 {
    margin-top: 108px;
}

.mr-108 {
    margin-right: 108px;
}

.mb-108 {
    margin-bottom: 108px;
}

.ml-108 {
    margin-left: 108px;
}

.m-108 {
    margin: 108px;
}

.mh-108 {
    margin-left: 108px;
    margin-right: 108px;
}

.mv-108 {
    margin-top: 108px;
    margin-bottom: 108px;
}

.mt-110 {
    margin-top: 110px;
}

.mr-110 {
    margin-right: 110px;
}

.mb-110 {
    margin-bottom: 110px;
}

.ml-110 {
    margin-left: 110px;
}

.m-110 {
    margin: 110px;
}

.mh-110 {
    margin-left: 110px;
    margin-right: 110px;
}

.mv-110 {
    margin-top: 110px;
    margin-bottom: 110px;
}

.mt-112 {
    margin-top: 112px;
}

.mr-112 {
    margin-right: 112px;
}

.mb-112 {
    margin-bottom: 112px;
}

.ml-112 {
    margin-left: 112px;
}

.m-112 {
    margin: 112px;
}

.mh-112 {
    margin-left: 112px;
    margin-right: 112px;
}

.mv-112 {
    margin-top: 112px;
    margin-bottom: 112px;
}

.mt-114 {
    margin-top: 114px;
}

.mr-114 {
    margin-right: 114px;
}

.mb-114 {
    margin-bottom: 114px;
}

.ml-114 {
    margin-left: 114px;
}

.m-114 {
    margin: 114px;
}

.mh-114 {
    margin-left: 114px;
    margin-right: 114px;
}

.mv-114 {
    margin-top: 114px;
    margin-bottom: 114px;
}

.mt-116 {
    margin-top: 116px;
}

.mr-116 {
    margin-right: 116px;
}

.mb-116 {
    margin-bottom: 116px;
}

.ml-116 {
    margin-left: 116px;
}

.m-116 {
    margin: 116px;
}

.mh-116 {
    margin-left: 116px;
    margin-right: 116px;
}

.mv-116 {
    margin-top: 116px;
    margin-bottom: 116px;
}

.mt-118 {
    margin-top: 118px;
}

.mr-118 {
    margin-right: 118px;
}

.mb-118 {
    margin-bottom: 118px;
}

.ml-118 {
    margin-left: 118px;
}

.m-118 {
    margin: 118px;
}

.mh-118 {
    margin-left: 118px;
    margin-right: 118px;
}

.mv-118 {
    margin-top: 118px;
    margin-bottom: 118px;
}

.mt-120 {
    margin-top: 120px;
}

.mr-120 {
    margin-right: 120px;
}

.mb-120 {
    margin-bottom: 120px;
}

.ml-120 {
    margin-left: 120px;
}

.m-120 {
    margin: 120px;
}

.mh-120 {
    margin-left: 120px;
    margin-right: 120px;
}

.mv-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.mt-122 {
    margin-top: 122px;
}

.mr-122 {
    margin-right: 122px;
}

.mb-122 {
    margin-bottom: 122px;
}

.ml-122 {
    margin-left: 122px;
}

.m-122 {
    margin: 122px;
}

.mh-122 {
    margin-left: 122px;
    margin-right: 122px;
}

.mv-122 {
    margin-top: 122px;
    margin-bottom: 122px;
}

.mt-124 {
    margin-top: 124px;
}

.mr-124 {
    margin-right: 124px;
}

.mb-124 {
    margin-bottom: 124px;
}

.ml-124 {
    margin-left: 124px;
}

.m-124 {
    margin: 124px;
}

.mh-124 {
    margin-left: 124px;
    margin-right: 124px;
}

.mv-124 {
    margin-top: 124px;
    margin-bottom: 124px;
}

.mt-126 {
    margin-top: 126px;
}

.mr-126 {
    margin-right: 126px;
}

.mb-126 {
    margin-bottom: 126px;
}

.ml-126 {
    margin-left: 126px;
}

.m-126 {
    margin: 126px;
}

.mh-126 {
    margin-left: 126px;
    margin-right: 126px;
}

.mv-126 {
    margin-top: 126px;
    margin-bottom: 126px;
}

.mt-128 {
    margin-top: 128px;
}

.mr-128 {
    margin-right: 128px;
}

.mb-128 {
    margin-bottom: 128px;
}

.ml-128 {
    margin-left: 128px;
}

.m-128 {
    margin: 128px;
}

.mh-128 {
    margin-left: 128px;
    margin-right: 128px;
}

.mv-128 {
    margin-top: 128px;
    margin-bottom: 128px;
}

.mt-130 {
    margin-top: 130px;
}

.mr-130 {
    margin-right: 130px;
}

.mb-130 {
    margin-bottom: 130px;
}

.ml-130 {
    margin-left: 130px;
}

.m-130 {
    margin: 130px;
}

.mh-130 {
    margin-left: 130px;
    margin-right: 130px;
}

.mv-130 {
    margin-top: 130px;
    margin-bottom: 130px;
}

.mt-132 {
    margin-top: 132px;
}

.mr-132 {
    margin-right: 132px;
}

.mb-132 {
    margin-bottom: 132px;
}

.ml-132 {
    margin-left: 132px;
}

.m-132 {
    margin: 132px;
}

.mh-132 {
    margin-left: 132px;
    margin-right: 132px;
}

.mv-132 {
    margin-top: 132px;
    margin-bottom: 132px;
}

.mt-134 {
    margin-top: 134px;
}

.mr-134 {
    margin-right: 134px;
}

.mb-134 {
    margin-bottom: 134px;
}

.ml-134 {
    margin-left: 134px;
}

.m-134 {
    margin: 134px;
}

.mh-134 {
    margin-left: 134px;
    margin-right: 134px;
}

.mv-134 {
    margin-top: 134px;
    margin-bottom: 134px;
}

.mt-136 {
    margin-top: 136px;
}

.mr-136 {
    margin-right: 136px;
}

.mb-136 {
    margin-bottom: 136px;
}

.ml-136 {
    margin-left: 136px;
}

.m-136 {
    margin: 136px;
}

.mh-136 {
    margin-left: 136px;
    margin-right: 136px;
}

.mv-136 {
    margin-top: 136px;
    margin-bottom: 136px;
}

.mt-138 {
    margin-top: 138px;
}

.mr-138 {
    margin-right: 138px;
}

.mb-138 {
    margin-bottom: 138px;
}

.ml-138 {
    margin-left: 138px;
}

.m-138 {
    margin: 138px;
}

.mh-138 {
    margin-left: 138px;
    margin-right: 138px;
}

.mv-138 {
    margin-top: 138px;
    margin-bottom: 138px;
}

.mt-140 {
    margin-top: 140px;
}

.mr-140 {
    margin-right: 140px;
}

.mb-140 {
    margin-bottom: 140px;
}

.ml-140 {
    margin-left: 140px;
}

.m-140 {
    margin: 140px;
}

.mh-140 {
    margin-left: 140px;
    margin-right: 140px;
}

.mv-140 {
    margin-top: 140px;
    margin-bottom: 140px;
}

.mt-142 {
    margin-top: 142px;
}

.mr-142 {
    margin-right: 142px;
}

.mb-142 {
    margin-bottom: 142px;
}

.ml-142 {
    margin-left: 142px;
}

.m-142 {
    margin: 142px;
}

.mh-142 {
    margin-left: 142px;
    margin-right: 142px;
}

.mv-142 {
    margin-top: 142px;
    margin-bottom: 142px;
}

.mt-144 {
    margin-top: 144px;
}

.mr-144 {
    margin-right: 144px;
}

.mb-144 {
    margin-bottom: 144px;
}

.ml-144 {
    margin-left: 144px;
}

.m-144 {
    margin: 144px;
}

.mh-144 {
    margin-left: 144px;
    margin-right: 144px;
}

.mv-144 {
    margin-top: 144px;
    margin-bottom: 144px;
}

.mt-146 {
    margin-top: 146px;
}

.mr-146 {
    margin-right: 146px;
}

.mb-146 {
    margin-bottom: 146px;
}

.ml-146 {
    margin-left: 146px;
}

.m-146 {
    margin: 146px;
}

.mh-146 {
    margin-left: 146px;
    margin-right: 146px;
}

.mv-146 {
    margin-top: 146px;
    margin-bottom: 146px;
}

.mt-148 {
    margin-top: 148px;
}

.mr-148 {
    margin-right: 148px;
}

.mb-148 {
    margin-bottom: 148px;
}

.ml-148 {
    margin-left: 148px;
}

.m-148 {
    margin: 148px;
}

.mh-148 {
    margin-left: 148px;
    margin-right: 148px;
}

.mv-148 {
    margin-top: 148px;
    margin-bottom: 148px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-150 {
    margin-right: 150px;
}

.mb-150 {
    margin-bottom: 150px;
}

.ml-150 {
    margin-left: 150px;
}

.m-150 {
    margin: 150px;
}

.mh-150 {
    margin-left: 150px;
    margin-right: 150px;
}

.mv-150 {
    margin-top: 150px;
    margin-bottom: 150px;
}

.mt-152 {
    margin-top: 152px;
}

.mr-152 {
    margin-right: 152px;
}

.mb-152 {
    margin-bottom: 152px;
}

.ml-152 {
    margin-left: 152px;
}

.m-152 {
    margin: 152px;
}

.mh-152 {
    margin-left: 152px;
    margin-right: 152px;
}

.mv-152 {
    margin-top: 152px;
    margin-bottom: 152px;
}

.mt-154 {
    margin-top: 154px;
}

.mr-154 {
    margin-right: 154px;
}

.mb-154 {
    margin-bottom: 154px;
}

.ml-154 {
    margin-left: 154px;
}

.m-154 {
    margin: 154px;
}

.mh-154 {
    margin-left: 154px;
    margin-right: 154px;
}

.mv-154 {
    margin-top: 154px;
    margin-bottom: 154px;
}

.mt-156 {
    margin-top: 156px;
}

.mr-156 {
    margin-right: 156px;
}

.mb-156 {
    margin-bottom: 156px;
}

.ml-156 {
    margin-left: 156px;
}

.m-156 {
    margin: 156px;
}

.mh-156 {
    margin-left: 156px;
    margin-right: 156px;
}

.mv-156 {
    margin-top: 156px;
    margin-bottom: 156px;
}

.mt-158 {
    margin-top: 158px;
}

.mr-158 {
    margin-right: 158px;
}

.mb-158 {
    margin-bottom: 158px;
}

.ml-158 {
    margin-left: 158px;
}

.m-158 {
    margin: 158px;
}

.mh-158 {
    margin-left: 158px;
    margin-right: 158px;
}

.mv-158 {
    margin-top: 158px;
    margin-bottom: 158px;
}

.mt-160 {
    margin-top: 160px;
}

.mr-160 {
    margin-right: 160px;
}

.mb-160 {
    margin-bottom: 160px;
}

.ml-160 {
    margin-left: 160px;
}

.m-160 {
    margin: 160px;
}

.mh-160 {
    margin-left: 160px;
    margin-right: 160px;
}

.mv-160 {
    margin-top: 160px;
    margin-bottom: 160px;
}

.mt-162 {
    margin-top: 162px;
}

.mr-162 {
    margin-right: 162px;
}

.mb-162 {
    margin-bottom: 162px;
}

.ml-162 {
    margin-left: 162px;
}

.m-162 {
    margin: 162px;
}

.mh-162 {
    margin-left: 162px;
    margin-right: 162px;
}

.mv-162 {
    margin-top: 162px;
    margin-bottom: 162px;
}

.mt-164 {
    margin-top: 164px;
}

.mr-164 {
    margin-right: 164px;
}

.mb-164 {
    margin-bottom: 164px;
}

.ml-164 {
    margin-left: 164px;
}

.m-164 {
    margin: 164px;
}

.mh-164 {
    margin-left: 164px;
    margin-right: 164px;
}

.mv-164 {
    margin-top: 164px;
    margin-bottom: 164px;
}

.mt-166 {
    margin-top: 166px;
}

.mr-166 {
    margin-right: 166px;
}

.mb-166 {
    margin-bottom: 166px;
}

.ml-166 {
    margin-left: 166px;
}

.m-166 {
    margin: 166px;
}

.mh-166 {
    margin-left: 166px;
    margin-right: 166px;
}

.mv-166 {
    margin-top: 166px;
    margin-bottom: 166px;
}

.mt-168 {
    margin-top: 168px;
}

.mr-168 {
    margin-right: 168px;
}

.mb-168 {
    margin-bottom: 168px;
}

.ml-168 {
    margin-left: 168px;
}

.m-168 {
    margin: 168px;
}

.mh-168 {
    margin-left: 168px;
    margin-right: 168px;
}

.mv-168 {
    margin-top: 168px;
    margin-bottom: 168px;
}

.mt-170 {
    margin-top: 170px;
}

.mr-170 {
    margin-right: 170px;
}

.mb-170 {
    margin-bottom: 170px;
}

.ml-170 {
    margin-left: 170px;
}

.m-170 {
    margin: 170px;
}

.mh-170 {
    margin-left: 170px;
    margin-right: 170px;
}

.mv-170 {
    margin-top: 170px;
    margin-bottom: 170px;
}

.mt-172 {
    margin-top: 172px;
}

.mr-172 {
    margin-right: 172px;
}

.mb-172 {
    margin-bottom: 172px;
}

.ml-172 {
    margin-left: 172px;
}

.m-172 {
    margin: 172px;
}

.mh-172 {
    margin-left: 172px;
    margin-right: 172px;
}

.mv-172 {
    margin-top: 172px;
    margin-bottom: 172px;
}

.mt-174 {
    margin-top: 174px;
}

.mr-174 {
    margin-right: 174px;
}

.mb-174 {
    margin-bottom: 174px;
}

.ml-174 {
    margin-left: 174px;
}

.m-174 {
    margin: 174px;
}

.mh-174 {
    margin-left: 174px;
    margin-right: 174px;
}

.mv-174 {
    margin-top: 174px;
    margin-bottom: 174px;
}

.mt-176 {
    margin-top: 176px;
}

.mr-176 {
    margin-right: 176px;
}

.mb-176 {
    margin-bottom: 176px;
}

.ml-176 {
    margin-left: 176px;
}

.m-176 {
    margin: 176px;
}

.mh-176 {
    margin-left: 176px;
    margin-right: 176px;
}

.mv-176 {
    margin-top: 176px;
    margin-bottom: 176px;
}

.mt-178 {
    margin-top: 178px;
}

.mr-178 {
    margin-right: 178px;
}

.mb-178 {
    margin-bottom: 178px;
}

.ml-178 {
    margin-left: 178px;
}

.m-178 {
    margin: 178px;
}

.mh-178 {
    margin-left: 178px;
    margin-right: 178px;
}

.mv-178 {
    margin-top: 178px;
    margin-bottom: 178px;
}

.mt-180 {
    margin-top: 180px;
}

.mr-180 {
    margin-right: 180px;
}

.mb-180 {
    margin-bottom: 180px;
}

.ml-180 {
    margin-left: 180px;
}

.m-180 {
    margin: 180px;
}

.mh-180 {
    margin-left: 180px;
    margin-right: 180px;
}

.mv-180 {
    margin-top: 180px;
    margin-bottom: 180px;
}

.mt-182 {
    margin-top: 182px;
}

.mr-182 {
    margin-right: 182px;
}

.mb-182 {
    margin-bottom: 182px;
}

.ml-182 {
    margin-left: 182px;
}

.m-182 {
    margin: 182px;
}

.mh-182 {
    margin-left: 182px;
    margin-right: 182px;
}

.mv-182 {
    margin-top: 182px;
    margin-bottom: 182px;
}

.mt-184 {
    margin-top: 184px;
}

.mr-184 {
    margin-right: 184px;
}

.mb-184 {
    margin-bottom: 184px;
}

.ml-184 {
    margin-left: 184px;
}

.m-184 {
    margin: 184px;
}

.mh-184 {
    margin-left: 184px;
    margin-right: 184px;
}

.mv-184 {
    margin-top: 184px;
    margin-bottom: 184px;
}

.mt-186 {
    margin-top: 186px;
}

.mr-186 {
    margin-right: 186px;
}

.mb-186 {
    margin-bottom: 186px;
}

.ml-186 {
    margin-left: 186px;
}

.m-186 {
    margin: 186px;
}

.mh-186 {
    margin-left: 186px;
    margin-right: 186px;
}

.mv-186 {
    margin-top: 186px;
    margin-bottom: 186px;
}

.mt-188 {
    margin-top: 188px;
}

.mr-188 {
    margin-right: 188px;
}

.mb-188 {
    margin-bottom: 188px;
}

.ml-188 {
    margin-left: 188px;
}

.m-188 {
    margin: 188px;
}

.mh-188 {
    margin-left: 188px;
    margin-right: 188px;
}

.mv-188 {
    margin-top: 188px;
    margin-bottom: 188px;
}

.mt-190 {
    margin-top: 190px;
}

.mr-190 {
    margin-right: 190px;
}

.mb-190 {
    margin-bottom: 190px;
}

.ml-190 {
    margin-left: 190px;
}

.m-190 {
    margin: 190px;
}

.mh-190 {
    margin-left: 190px;
    margin-right: 190px;
}

.mv-190 {
    margin-top: 190px;
    margin-bottom: 190px;
}

.mt-192 {
    margin-top: 192px;
}

.mr-192 {
    margin-right: 192px;
}

.mb-192 {
    margin-bottom: 192px;
}

.ml-192 {
    margin-left: 192px;
}

.m-192 {
    margin: 192px;
}

.mh-192 {
    margin-left: 192px;
    margin-right: 192px;
}

.mv-192 {
    margin-top: 192px;
    margin-bottom: 192px;
}

.mt-194 {
    margin-top: 194px;
}

.mr-194 {
    margin-right: 194px;
}

.mb-194 {
    margin-bottom: 194px;
}

.ml-194 {
    margin-left: 194px;
}

.m-194 {
    margin: 194px;
}

.mh-194 {
    margin-left: 194px;
    margin-right: 194px;
}

.mv-194 {
    margin-top: 194px;
    margin-bottom: 194px;
}

.mt-196 {
    margin-top: 196px;
}

.mr-196 {
    margin-right: 196px;
}

.mb-196 {
    margin-bottom: 196px;
}

.ml-196 {
    margin-left: 196px;
}

.m-196 {
    margin: 196px;
}

.mh-196 {
    margin-left: 196px;
    margin-right: 196px;
}

.mv-196 {
    margin-top: 196px;
    margin-bottom: 196px;
}

.mt-198 {
    margin-top: 198px;
}

.mr-198 {
    margin-right: 198px;
}

.mb-198 {
    margin-bottom: 198px;
}

.ml-198 {
    margin-left: 198px;
}

.m-198 {
    margin: 198px;
}

.mh-198 {
    margin-left: 198px;
    margin-right: 198px;
}

.mv-198 {
    margin-top: 198px;
    margin-bottom: 198px;
}

.mt-200 {
    margin-top: 200px;
}

.mr-200 {
    margin-right: 200px;
}

.mb-200 {
    margin-bottom: 200px;
}

.ml-200 {
    margin-left: 200px;
}

.m-200 {
    margin: 200px;
}

.mh-200 {
    margin-left: 200px;
    margin-right: 200px;
}

.mv-200 {
    margin-top: 200px;
    margin-bottom: 200px;
}

.mt-202 {
    margin-top: 202px;
}

.mr-202 {
    margin-right: 202px;
}

.mb-202 {
    margin-bottom: 202px;
}

.ml-202 {
    margin-left: 202px;
}

.m-202 {
    margin: 202px;
}

.mh-202 {
    margin-left: 202px;
    margin-right: 202px;
}

.mv-202 {
    margin-top: 202px;
    margin-bottom: 202px;
}

.mt-204 {
    margin-top: 204px;
}

.mr-204 {
    margin-right: 204px;
}

.mb-204 {
    margin-bottom: 204px;
}

.ml-204 {
    margin-left: 204px;
}

.m-204 {
    margin: 204px;
}

.mh-204 {
    margin-left: 204px;
    margin-right: 204px;
}

.mv-204 {
    margin-top: 204px;
    margin-bottom: 204px;
}

.mt-206 {
    margin-top: 206px;
}

.mr-206 {
    margin-right: 206px;
}

.mb-206 {
    margin-bottom: 206px;
}

.ml-206 {
    margin-left: 206px;
}

.m-206 {
    margin: 206px;
}

.mh-206 {
    margin-left: 206px;
    margin-right: 206px;
}

.mv-206 {
    margin-top: 206px;
    margin-bottom: 206px;
}

.mt-208 {
    margin-top: 208px;
}

.mr-208 {
    margin-right: 208px;
}

.mb-208 {
    margin-bottom: 208px;
}

.ml-208 {
    margin-left: 208px;
}

.m-208 {
    margin: 208px;
}

.mh-208 {
    margin-left: 208px;
    margin-right: 208px;
}

.mv-208 {
    margin-top: 208px;
    margin-bottom: 208px;
}

.mt-210 {
    margin-top: 210px;
}

.mr-210 {
    margin-right: 210px;
}

.mb-210 {
    margin-bottom: 210px;
}

.ml-210 {
    margin-left: 210px;
}

.m-210 {
    margin: 210px;
}

.mh-210 {
    margin-left: 210px;
    margin-right: 210px;
}

.mv-210 {
    margin-top: 210px;
    margin-bottom: 210px;
}

.mt-212 {
    margin-top: 212px;
}

.mr-212 {
    margin-right: 212px;
}

.mb-212 {
    margin-bottom: 212px;
}

.ml-212 {
    margin-left: 212px;
}

.m-212 {
    margin: 212px;
}

.mh-212 {
    margin-left: 212px;
    margin-right: 212px;
}

.mv-212 {
    margin-top: 212px;
    margin-bottom: 212px;
}

.mt-214 {
    margin-top: 214px;
}

.mr-214 {
    margin-right: 214px;
}

.mb-214 {
    margin-bottom: 214px;
}

.ml-214 {
    margin-left: 214px;
}

.m-214 {
    margin: 214px;
}

.mh-214 {
    margin-left: 214px;
    margin-right: 214px;
}

.mv-214 {
    margin-top: 214px;
    margin-bottom: 214px;
}

.mt-216 {
    margin-top: 216px;
}

.mr-216 {
    margin-right: 216px;
}

.mb-216 {
    margin-bottom: 216px;
}

.ml-216 {
    margin-left: 216px;
}

.m-216 {
    margin: 216px;
}

.mh-216 {
    margin-left: 216px;
    margin-right: 216px;
}

.mv-216 {
    margin-top: 216px;
    margin-bottom: 216px;
}

.mt-218 {
    margin-top: 218px;
}

.mr-218 {
    margin-right: 218px;
}

.mb-218 {
    margin-bottom: 218px;
}

.ml-218 {
    margin-left: 218px;
}

.m-218 {
    margin: 218px;
}

.mh-218 {
    margin-left: 218px;
    margin-right: 218px;
}

.mv-218 {
    margin-top: 218px;
    margin-bottom: 218px;
}

.mt-220 {
    margin-top: 220px;
}

.mr-220 {
    margin-right: 220px;
}

.mb-220 {
    margin-bottom: 220px;
}

.ml-220 {
    margin-left: 220px;
}

.m-220 {
    margin: 220px;
}

.mh-220 {
    margin-left: 220px;
    margin-right: 220px;
}

.mv-220 {
    margin-top: 220px;
    margin-bottom: 220px;
}

.mt-222 {
    margin-top: 222px;
}

.mr-222 {
    margin-right: 222px;
}

.mb-222 {
    margin-bottom: 222px;
}

.ml-222 {
    margin-left: 222px;
}

.m-222 {
    margin: 222px;
}

.mh-222 {
    margin-left: 222px;
    margin-right: 222px;
}

.mv-222 {
    margin-top: 222px;
    margin-bottom: 222px;
}

.mt-224 {
    margin-top: 224px;
}

.mr-224 {
    margin-right: 224px;
}

.mb-224 {
    margin-bottom: 224px;
}

.ml-224 {
    margin-left: 224px;
}

.m-224 {
    margin: 224px;
}

.mh-224 {
    margin-left: 224px;
    margin-right: 224px;
}

.mv-224 {
    margin-top: 224px;
    margin-bottom: 224px;
}

.mt-226 {
    margin-top: 226px;
}

.mr-226 {
    margin-right: 226px;
}

.mb-226 {
    margin-bottom: 226px;
}

.ml-226 {
    margin-left: 226px;
}

.m-226 {
    margin: 226px;
}

.mh-226 {
    margin-left: 226px;
    margin-right: 226px;
}

.mv-226 {
    margin-top: 226px;
    margin-bottom: 226px;
}

.mt-228 {
    margin-top: 228px;
}

.mr-228 {
    margin-right: 228px;
}

.mb-228 {
    margin-bottom: 228px;
}

.ml-228 {
    margin-left: 228px;
}

.m-228 {
    margin: 228px;
}

.mh-228 {
    margin-left: 228px;
    margin-right: 228px;
}

.mv-228 {
    margin-top: 228px;
    margin-bottom: 228px;
}

.mt-230 {
    margin-top: 230px;
}

.mr-230 {
    margin-right: 230px;
}

.mb-230 {
    margin-bottom: 230px;
}

.ml-230 {
    margin-left: 230px;
}

.m-230 {
    margin: 230px;
}

.mh-230 {
    margin-left: 230px;
    margin-right: 230px;
}

.mv-230 {
    margin-top: 230px;
    margin-bottom: 230px;
}

.mt-232 {
    margin-top: 232px;
}

.mr-232 {
    margin-right: 232px;
}

.mb-232 {
    margin-bottom: 232px;
}

.ml-232 {
    margin-left: 232px;
}

.m-232 {
    margin: 232px;
}

.mh-232 {
    margin-left: 232px;
    margin-right: 232px;
}

.mv-232 {
    margin-top: 232px;
    margin-bottom: 232px;
}

.mt-234 {
    margin-top: 234px;
}

.mr-234 {
    margin-right: 234px;
}

.mb-234 {
    margin-bottom: 234px;
}

.ml-234 {
    margin-left: 234px;
}

.m-234 {
    margin: 234px;
}

.mh-234 {
    margin-left: 234px;
    margin-right: 234px;
}

.mv-234 {
    margin-top: 234px;
    margin-bottom: 234px;
}

.mt-236 {
    margin-top: 236px;
}

.mr-236 {
    margin-right: 236px;
}

.mb-236 {
    margin-bottom: 236px;
}

.ml-236 {
    margin-left: 236px;
}

.m-236 {
    margin: 236px;
}

.mh-236 {
    margin-left: 236px;
    margin-right: 236px;
}

.mv-236 {
    margin-top: 236px;
    margin-bottom: 236px;
}

.mt-238 {
    margin-top: 238px;
}

.mr-238 {
    margin-right: 238px;
}

.mb-238 {
    margin-bottom: 238px;
}

.ml-238 {
    margin-left: 238px;
}

.m-238 {
    margin: 238px;
}

.mh-238 {
    margin-left: 238px;
    margin-right: 238px;
}

.mv-238 {
    margin-top: 238px;
    margin-bottom: 238px;
}

.mt-240 {
    margin-top: 240px;
}

.mr-240 {
    margin-right: 240px;
}

.mb-240 {
    margin-bottom: 240px;
}

.ml-240 {
    margin-left: 240px;
}

.m-240 {
    margin: 240px;
}

.mh-240 {
    margin-left: 240px;
    margin-right: 240px;
}

.mv-240 {
    margin-top: 240px;
    margin-bottom: 240px;
}

.mt-242 {
    margin-top: 242px;
}

.mr-242 {
    margin-right: 242px;
}

.mb-242 {
    margin-bottom: 242px;
}

.ml-242 {
    margin-left: 242px;
}

.m-242 {
    margin: 242px;
}

.mh-242 {
    margin-left: 242px;
    margin-right: 242px;
}

.mv-242 {
    margin-top: 242px;
    margin-bottom: 242px;
}

.mt-244 {
    margin-top: 244px;
}

.mr-244 {
    margin-right: 244px;
}

.mb-244 {
    margin-bottom: 244px;
}

.ml-244 {
    margin-left: 244px;
}

.m-244 {
    margin: 244px;
}

.mh-244 {
    margin-left: 244px;
    margin-right: 244px;
}

.mv-244 {
    margin-top: 244px;
    margin-bottom: 244px;
}

.mt-246 {
    margin-top: 246px;
}

.mr-246 {
    margin-right: 246px;
}

.mb-246 {
    margin-bottom: 246px;
}

.ml-246 {
    margin-left: 246px;
}

.m-246 {
    margin: 246px;
}

.mh-246 {
    margin-left: 246px;
    margin-right: 246px;
}

.mv-246 {
    margin-top: 246px;
    margin-bottom: 246px;
}

.mt-248 {
    margin-top: 248px;
}

.mr-248 {
    margin-right: 248px;
}

.mb-248 {
    margin-bottom: 248px;
}

.ml-248 {
    margin-left: 248px;
}

.m-248 {
    margin: 248px;
}

.mh-248 {
    margin-left: 248px;
    margin-right: 248px;
}

.mv-248 {
    margin-top: 248px;
    margin-bottom: 248px;
}

.mt-250 {
    margin-top: 250px;
}

.mr-250 {
    margin-right: 250px;
}

.mb-250 {
    margin-bottom: 250px;
}

.ml-250 {
    margin-left: 250px;
}

.m-250 {
    margin: 250px;
}

.mh-250 {
    margin-left: 250px;
    margin-right: 250px;
}

.mv-250 {
    margin-top: 250px;
    margin-bottom: 250px;
}

.mt-252 {
    margin-top: 252px;
}

.mr-252 {
    margin-right: 252px;
}

.mb-252 {
    margin-bottom: 252px;
}

.ml-252 {
    margin-left: 252px;
}

.m-252 {
    margin: 252px;
}

.mh-252 {
    margin-left: 252px;
    margin-right: 252px;
}

.mv-252 {
    margin-top: 252px;
    margin-bottom: 252px;
}

.mt-254 {
    margin-top: 254px;
}

.mr-254 {
    margin-right: 254px;
}

.mb-254 {
    margin-bottom: 254px;
}

.ml-254 {
    margin-left: 254px;
}

.m-254 {
    margin: 254px;
}

.mh-254 {
    margin-left: 254px;
    margin-right: 254px;
}

.mv-254 {
    margin-top: 254px;
    margin-bottom: 254px;
}

.mt-256 {
    margin-top: 256px;
}

.mr-256 {
    margin-right: 256px;
}

.mb-256 {
    margin-bottom: 256px;
}

.ml-256 {
    margin-left: 256px;
}

.m-256 {
    margin: 256px;
}

.mh-256 {
    margin-left: 256px;
    margin-right: 256px;
}

.mv-256 {
    margin-top: 256px;
    margin-bottom: 256px;
}

.mt-258 {
    margin-top: 258px;
}

.mr-258 {
    margin-right: 258px;
}

.mb-258 {
    margin-bottom: 258px;
}

.ml-258 {
    margin-left: 258px;
}

.m-258 {
    margin: 258px;
}

.mh-258 {
    margin-left: 258px;
    margin-right: 258px;
}

.mv-258 {
    margin-top: 258px;
    margin-bottom: 258px;
}

.mt-260 {
    margin-top: 260px;
}

.mr-260 {
    margin-right: 260px;
}

.mb-260 {
    margin-bottom: 260px;
}

.ml-260 {
    margin-left: 260px;
}

.m-260 {
    margin: 260px;
}

.mh-260 {
    margin-left: 260px;
    margin-right: 260px;
}

.mv-260 {
    margin-top: 260px;
    margin-bottom: 260px;
}

.mt-262 {
    margin-top: 262px;
}

.mr-262 {
    margin-right: 262px;
}

.mb-262 {
    margin-bottom: 262px;
}

.ml-262 {
    margin-left: 262px;
}

.m-262 {
    margin: 262px;
}

.mh-262 {
    margin-left: 262px;
    margin-right: 262px;
}

.mv-262 {
    margin-top: 262px;
    margin-bottom: 262px;
}

.mt-264 {
    margin-top: 264px;
}

.mr-264 {
    margin-right: 264px;
}

.mb-264 {
    margin-bottom: 264px;
}

.ml-264 {
    margin-left: 264px;
}

.m-264 {
    margin: 264px;
}

.mh-264 {
    margin-left: 264px;
    margin-right: 264px;
}

.mv-264 {
    margin-top: 264px;
    margin-bottom: 264px;
}

.mt-266 {
    margin-top: 266px;
}

.mr-266 {
    margin-right: 266px;
}

.mb-266 {
    margin-bottom: 266px;
}

.ml-266 {
    margin-left: 266px;
}

.m-266 {
    margin: 266px;
}

.mh-266 {
    margin-left: 266px;
    margin-right: 266px;
}

.mv-266 {
    margin-top: 266px;
    margin-bottom: 266px;
}

.mt-268 {
    margin-top: 268px;
}

.mr-268 {
    margin-right: 268px;
}

.mb-268 {
    margin-bottom: 268px;
}

.ml-268 {
    margin-left: 268px;
}

.m-268 {
    margin: 268px;
}

.mh-268 {
    margin-left: 268px;
    margin-right: 268px;
}

.mv-268 {
    margin-top: 268px;
    margin-bottom: 268px;
}

.mt-270 {
    margin-top: 270px;
}

.mr-270 {
    margin-right: 270px;
}

.mb-270 {
    margin-bottom: 270px;
}

.ml-270 {
    margin-left: 270px;
}

.m-270 {
    margin: 270px;
}

.mh-270 {
    margin-left: 270px;
    margin-right: 270px;
}

.mv-270 {
    margin-top: 270px;
    margin-bottom: 270px;
}

.mt-272 {
    margin-top: 272px;
}

.mr-272 {
    margin-right: 272px;
}

.mb-272 {
    margin-bottom: 272px;
}

.ml-272 {
    margin-left: 272px;
}

.m-272 {
    margin: 272px;
}

.mh-272 {
    margin-left: 272px;
    margin-right: 272px;
}

.mv-272 {
    margin-top: 272px;
    margin-bottom: 272px;
}

.mt-274 {
    margin-top: 274px;
}

.mr-274 {
    margin-right: 274px;
}

.mb-274 {
    margin-bottom: 274px;
}

.ml-274 {
    margin-left: 274px;
}

.m-274 {
    margin: 274px;
}

.mh-274 {
    margin-left: 274px;
    margin-right: 274px;
}

.mv-274 {
    margin-top: 274px;
    margin-bottom: 274px;
}

.mt-276 {
    margin-top: 276px;
}

.mr-276 {
    margin-right: 276px;
}

.mb-276 {
    margin-bottom: 276px;
}

.ml-276 {
    margin-left: 276px;
}

.m-276 {
    margin: 276px;
}

.mh-276 {
    margin-left: 276px;
    margin-right: 276px;
}

.mv-276 {
    margin-top: 276px;
    margin-bottom: 276px;
}

.mt-278 {
    margin-top: 278px;
}

.mr-278 {
    margin-right: 278px;
}

.mb-278 {
    margin-bottom: 278px;
}

.ml-278 {
    margin-left: 278px;
}

.m-278 {
    margin: 278px;
}

.mh-278 {
    margin-left: 278px;
    margin-right: 278px;
}

.mv-278 {
    margin-top: 278px;
    margin-bottom: 278px;
}

.mt-280 {
    margin-top: 280px;
}

.mr-280 {
    margin-right: 280px;
}

.mb-280 {
    margin-bottom: 280px;
}

.ml-280 {
    margin-left: 280px;
}

.m-280 {
    margin: 280px;
}

.mh-280 {
    margin-left: 280px;
    margin-right: 280px;
}

.mv-280 {
    margin-top: 280px;
    margin-bottom: 280px;
}

.mt-282 {
    margin-top: 282px;
}

.mr-282 {
    margin-right: 282px;
}

.mb-282 {
    margin-bottom: 282px;
}

.ml-282 {
    margin-left: 282px;
}

.m-282 {
    margin: 282px;
}

.mh-282 {
    margin-left: 282px;
    margin-right: 282px;
}

.mv-282 {
    margin-top: 282px;
    margin-bottom: 282px;
}

.mt-284 {
    margin-top: 284px;
}

.mr-284 {
    margin-right: 284px;
}

.mb-284 {
    margin-bottom: 284px;
}

.ml-284 {
    margin-left: 284px;
}

.m-284 {
    margin: 284px;
}

.mh-284 {
    margin-left: 284px;
    margin-right: 284px;
}

.mv-284 {
    margin-top: 284px;
    margin-bottom: 284px;
}

.mt-286 {
    margin-top: 286px;
}

.mr-286 {
    margin-right: 286px;
}

.mb-286 {
    margin-bottom: 286px;
}

.ml-286 {
    margin-left: 286px;
}

.m-286 {
    margin: 286px;
}

.mh-286 {
    margin-left: 286px;
    margin-right: 286px;
}

.mv-286 {
    margin-top: 286px;
    margin-bottom: 286px;
}

.mt-288 {
    margin-top: 288px;
}

.mr-288 {
    margin-right: 288px;
}

.mb-288 {
    margin-bottom: 288px;
}

.ml-288 {
    margin-left: 288px;
}

.m-288 {
    margin: 288px;
}

.mh-288 {
    margin-left: 288px;
    margin-right: 288px;
}

.mv-288 {
    margin-top: 288px;
    margin-bottom: 288px;
}

.mt-290 {
    margin-top: 290px;
}

.mr-290 {
    margin-right: 290px;
}

.mb-290 {
    margin-bottom: 290px;
}

.ml-290 {
    margin-left: 290px;
}

.m-290 {
    margin: 290px;
}

.mh-290 {
    margin-left: 290px;
    margin-right: 290px;
}

.mv-290 {
    margin-top: 290px;
    margin-bottom: 290px;
}

.mt-292 {
    margin-top: 292px;
}

.mr-292 {
    margin-right: 292px;
}

.mb-292 {
    margin-bottom: 292px;
}

.ml-292 {
    margin-left: 292px;
}

.m-292 {
    margin: 292px;
}

.mh-292 {
    margin-left: 292px;
    margin-right: 292px;
}

.mv-292 {
    margin-top: 292px;
    margin-bottom: 292px;
}

.mt-294 {
    margin-top: 294px;
}

.mr-294 {
    margin-right: 294px;
}

.mb-294 {
    margin-bottom: 294px;
}

.ml-294 {
    margin-left: 294px;
}

.m-294 {
    margin: 294px;
}

.mh-294 {
    margin-left: 294px;
    margin-right: 294px;
}

.mv-294 {
    margin-top: 294px;
    margin-bottom: 294px;
}

.mt-296 {
    margin-top: 296px;
}

.mr-296 {
    margin-right: 296px;
}

.mb-296 {
    margin-bottom: 296px;
}

.ml-296 {
    margin-left: 296px;
}

.m-296 {
    margin: 296px;
}

.mh-296 {
    margin-left: 296px;
    margin-right: 296px;
}

.mv-296 {
    margin-top: 296px;
    margin-bottom: 296px;
}

.mt-298 {
    margin-top: 298px;
}

.mr-298 {
    margin-right: 298px;
}

.mb-298 {
    margin-bottom: 298px;
}

.ml-298 {
    margin-left: 298px;
}

.m-298 {
    margin: 298px;
}

.mh-298 {
    margin-left: 298px;
    margin-right: 298px;
}

.mv-298 {
    margin-top: 298px;
    margin-bottom: 298px;
}

.mt-300 {
    margin-top: 300px;
}

.mr-300 {
    margin-right: 300px;
}

.mb-300 {
    margin-bottom: 300px;
}

.ml-300 {
    margin-left: 300px;
}

.m-300 {
    margin: 300px;
}

.mh-300 {
    margin-left: 300px;
    margin-right: 300px;
}

.mv-300 {
    margin-top: 300px;
    margin-bottom: 300px;
}

.m-0 {
    margin: 0;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.mv-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.rGap-2 {
    row-gap: 2px;
}

.rGap-4 {
    row-gap: 4px;
}

.rGap-6 {
    row-gap: 6px;
}

.rGap-8 {
    row-gap: 8px;
}

.rGap-10 {
    row-gap: 10px;
}

.rGap-12 {
    row-gap: 12px;
}

.rGap-14 {
    row-gap: 14px;
}

.rGap-16 {
    row-gap: 16px;
}

.rGap-18 {
    row-gap: 18px;
}

.rGap-20 {
    row-gap: 20px;
}

.rGap-22 {
    row-gap: 22px;
}

.rGap-24 {
    row-gap: 24px;
}

.rGap-26 {
    row-gap: 26px;
}

.rGap-28 {
    row-gap: 28px;
}

.rGap-30 {
    row-gap: 30px;
}

.rGap-32 {
    row-gap: 32px;
}

.rGap-34 {
    row-gap: 34px;
}

.rGap-36 {
    row-gap: 36px;
}

.rGap-38 {
    row-gap: 38px;
}

.rGap-40 {
    row-gap: 40px;
}

.rGap-42 {
    row-gap: 42px;
}

.rGap-44 {
    row-gap: 44px;
}

.rGap-46 {
    row-gap: 46px;
}

.rGap-48 {
    row-gap: 48px;
}

.rGap-50 {
    row-gap: 50px;
}

.rGap-52 {
    row-gap: 52px;
}

.rGap-54 {
    row-gap: 54px;
}

.rGap-56 {
    row-gap: 56px;
}

.rGap-58 {
    row-gap: 58px;
}

.rGap-60 {
    row-gap: 60px;
}

.rGap-62 {
    row-gap: 62px;
}

.rGap-64 {
    row-gap: 64px;
}

.rGap-66 {
    row-gap: 66px;
}

.rGap-68 {
    row-gap: 68px;
}

.rGap-70 {
    row-gap: 70px;
}

.rGap-72 {
    row-gap: 72px;
}

.rGap-74 {
    row-gap: 74px;
}

.rGap-76 {
    row-gap: 76px;
}

.rGap-78 {
    row-gap: 78px;
}

.rGap-80 {
    row-gap: 80px;
}

.rGap-82 {
    row-gap: 82px;
}

.rGap-84 {
    row-gap: 84px;
}

.rGap-86 {
    row-gap: 86px;
}

.rGap-88 {
    row-gap: 88px;
}

.rGap-90 {
    row-gap: 90px;
}

.rGap-92 {
    row-gap: 92px;
}

.rGap-94 {
    row-gap: 94px;
}

.rGap-96 {
    row-gap: 96px;
}

.rGap-98 {
    row-gap: 98px;
}

.rGap-100 {
    row-gap: 100px;
}

.rGap-102 {
    row-gap: 102px;
}

.rGap-104 {
    row-gap: 104px;
}

.rGap-106 {
    row-gap: 106px;
}

.rGap-108 {
    row-gap: 108px;
}

.rGap-110 {
    row-gap: 110px;
}

.rGap-112 {
    row-gap: 112px;
}

.rGap-114 {
    row-gap: 114px;
}

.rGap-116 {
    row-gap: 116px;
}

.rGap-118 {
    row-gap: 118px;
}

.rGap-120 {
    row-gap: 120px;
}

.rGap-122 {
    row-gap: 122px;
}

.rGap-124 {
    row-gap: 124px;
}

.rGap-126 {
    row-gap: 126px;
}

.rGap-128 {
    row-gap: 128px;
}

.rGap-130 {
    row-gap: 130px;
}

.rGap-132 {
    row-gap: 132px;
}

.rGap-134 {
    row-gap: 134px;
}

.rGap-136 {
    row-gap: 136px;
}

.rGap-138 {
    row-gap: 138px;
}

.rGap-140 {
    row-gap: 140px;
}

.rGap-0 {
    row-gap: 0;
}

.cGap-2 {
    column-gap: 2px;
}

.cGap-4 {
    column-gap: 4px;
}

.cGap-6 {
    column-gap: 6px;
}

.cGap-8 {
    column-gap: 8px;
}

.cGap-10 {
    column-gap: 10px;
}

.cGap-12 {
    column-gap: 12px;
}

.cGap-14 {
    column-gap: 14px;
}

.cGap-16 {
    column-gap: 16px;
}

.cGap-18 {
    column-gap: 18px;
}

.cGap-20 {
    column-gap: 20px;
}

.cGap-22 {
    column-gap: 22px;
}

.cGap-24 {
    column-gap: 24px;
}

.cGap-26 {
    column-gap: 26px;
}

.cGap-28 {
    column-gap: 28px;
}

.cGap-30 {
    column-gap: 30px;
}

.cGap-32 {
    column-gap: 32px;
}

.cGap-34 {
    column-gap: 34px;
}

.cGap-36 {
    column-gap: 36px;
}

.cGap-38 {
    column-gap: 38px;
}

.cGap-40 {
    column-gap: 40px;
}

.cGap-42 {
    column-gap: 42px;
}

.cGap-44 {
    column-gap: 44px;
}

.cGap-46 {
    column-gap: 46px;
}

.cGap-48 {
    column-gap: 48px;
}

.cGap-50 {
    column-gap: 50px;
}

.cGap-52 {
    column-gap: 52px;
}

.cGap-54 {
    column-gap: 54px;
}

.cGap-56 {
    column-gap: 56px;
}

.cGap-58 {
    column-gap: 58px;
}

.cGap-60 {
    column-gap: 60px;
}

.cGap-62 {
    column-gap: 62px;
}

.cGap-64 {
    column-gap: 64px;
}

.cGap-66 {
    column-gap: 66px;
}

.cGap-68 {
    column-gap: 68px;
}

.cGap-70 {
    column-gap: 70px;
}

.cGap-72 {
    column-gap: 72px;
}

.cGap-74 {
    column-gap: 74px;
}

.cGap-76 {
    column-gap: 76px;
}

.cGap-78 {
    column-gap: 78px;
}

.cGap-80 {
    column-gap: 80px;
}

.cGap-82 {
    column-gap: 82px;
}

.cGap-84 {
    column-gap: 84px;
}

.cGap-86 {
    column-gap: 86px;
}

.cGap-88 {
    column-gap: 88px;
}

.cGap-90 {
    column-gap: 90px;
}

.cGap-92 {
    column-gap: 92px;
}

.cGap-94 {
    column-gap: 94px;
}

.cGap-96 {
    column-gap: 96px;
}

.cGap-98 {
    column-gap: 98px;
}

.cGap-100 {
    column-gap: 100px;
}

.cGap-102 {
    column-gap: 102px;
}

.cGap-104 {
    column-gap: 104px;
}

.cGap-106 {
    column-gap: 106px;
}

.cGap-108 {
    column-gap: 108px;
}

.cGap-110 {
    column-gap: 110px;
}

.cGap-112 {
    column-gap: 112px;
}

.cGap-114 {
    column-gap: 114px;
}

.cGap-116 {
    column-gap: 116px;
}

.cGap-118 {
    column-gap: 118px;
}

.cGap-120 {
    column-gap: 120px;
}

.cGap-122 {
    column-gap: 122px;
}

.cGap-124 {
    column-gap: 124px;
}

.cGap-126 {
    column-gap: 126px;
}

.cGap-128 {
    column-gap: 128px;
}

.cGap-130 {
    column-gap: 130px;
}

.cGap-132 {
    column-gap: 132px;
}

.cGap-134 {
    column-gap: 134px;
}

.cGap-136 {
    column-gap: 136px;
}

.cGap-138 {
    column-gap: 138px;
}

.cGap-140 {
    column-gap: 140px;
}

.cGap-0 {
    column-gap: 0;
}

@media screen and (max-width: 1440px) {
    .mt-l-2 {
        margin-top: 2px;
    }
    .mr-l-2 {
        margin-right: 2px;
    }
    .mb-l-2 {
        margin-bottom: 2px;
    }
    .ml-l-2 {
        margin-left: 2px;
    }
    .m-l-2 {
        margin: 2px;
    }
    .mh-l-2 {
        margin-left: 2px;
        margin-right: 2px;
    }
    .mv-l-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .mt-l-4 {
        margin-top: 4px;
    }
    .mr-l-4 {
        margin-right: 4px;
    }
    .mb-l-4 {
        margin-bottom: 4px;
    }
    .ml-l-4 {
        margin-left: 4px;
    }
    .m-l-4 {
        margin: 4px;
    }
    .mh-l-4 {
        margin-left: 4px;
        margin-right: 4px;
    }
    .mv-l-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .mt-l-6 {
        margin-top: 6px;
    }
    .mr-l-6 {
        margin-right: 6px;
    }
    .mb-l-6 {
        margin-bottom: 6px;
    }
    .ml-l-6 {
        margin-left: 6px;
    }
    .m-l-6 {
        margin: 6px;
    }
    .mh-l-6 {
        margin-left: 6px;
        margin-right: 6px;
    }
    .mv-l-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .mt-l-8 {
        margin-top: 8px;
    }
    .mr-l-8 {
        margin-right: 8px;
    }
    .mb-l-8 {
        margin-bottom: 8px;
    }
    .ml-l-8 {
        margin-left: 8px;
    }
    .m-l-8 {
        margin: 8px;
    }
    .mh-l-8 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .mv-l-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .mt-l-10 {
        margin-top: 10px;
    }
    .mr-l-10 {
        margin-right: 10px;
    }
    .mb-l-10 {
        margin-bottom: 10px;
    }
    .ml-l-10 {
        margin-left: 10px;
    }
    .m-l-10 {
        margin: 10px;
    }
    .mh-l-10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    .mv-l-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mt-l-12 {
        margin-top: 12px;
    }
    .mr-l-12 {
        margin-right: 12px;
    }
    .mb-l-12 {
        margin-bottom: 12px;
    }
    .ml-l-12 {
        margin-left: 12px;
    }
    .m-l-12 {
        margin: 12px;
    }
    .mh-l-12 {
        margin-left: 12px;
        margin-right: 12px;
    }
    .mv-l-12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .mt-l-14 {
        margin-top: 14px;
    }
    .mr-l-14 {
        margin-right: 14px;
    }
    .mb-l-14 {
        margin-bottom: 14px;
    }
    .ml-l-14 {
        margin-left: 14px;
    }
    .m-l-14 {
        margin: 14px;
    }
    .mh-l-14 {
        margin-left: 14px;
        margin-right: 14px;
    }
    .mv-l-14 {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .mt-l-16 {
        margin-top: 16px;
    }
    .mr-l-16 {
        margin-right: 16px;
    }
    .mb-l-16 {
        margin-bottom: 16px;
    }
    .ml-l-16 {
        margin-left: 16px;
    }
    .m-l-16 {
        margin: 16px;
    }
    .mh-l-16 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .mv-l-16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .mt-l-18 {
        margin-top: 18px;
    }
    .mr-l-18 {
        margin-right: 18px;
    }
    .mb-l-18 {
        margin-bottom: 18px;
    }
    .ml-l-18 {
        margin-left: 18px;
    }
    .m-l-18 {
        margin: 18px;
    }
    .mh-l-18 {
        margin-left: 18px;
        margin-right: 18px;
    }
    .mv-l-18 {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .mt-l-20 {
        margin-top: 20px;
    }
    .mr-l-20 {
        margin-right: 20px;
    }
    .mb-l-20 {
        margin-bottom: 20px;
    }
    .ml-l-20 {
        margin-left: 20px;
    }
    .m-l-20 {
        margin: 20px;
    }
    .mh-l-20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    .mv-l-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .mt-l-22 {
        margin-top: 22px;
    }
    .mr-l-22 {
        margin-right: 22px;
    }
    .mb-l-22 {
        margin-bottom: 22px;
    }
    .ml-l-22 {
        margin-left: 22px;
    }
    .m-l-22 {
        margin: 22px;
    }
    .mh-l-22 {
        margin-left: 22px;
        margin-right: 22px;
    }
    .mv-l-22 {
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .mt-l-24 {
        margin-top: 24px;
    }
    .mr-l-24 {
        margin-right: 24px;
    }
    .mb-l-24 {
        margin-bottom: 24px;
    }
    .ml-l-24 {
        margin-left: 24px;
    }
    .m-l-24 {
        margin: 24px;
    }
    .mh-l-24 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .mv-l-24 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .mt-l-26 {
        margin-top: 26px;
    }
    .mr-l-26 {
        margin-right: 26px;
    }
    .mb-l-26 {
        margin-bottom: 26px;
    }
    .ml-l-26 {
        margin-left: 26px;
    }
    .m-l-26 {
        margin: 26px;
    }
    .mh-l-26 {
        margin-left: 26px;
        margin-right: 26px;
    }
    .mv-l-26 {
        margin-top: 26px;
        margin-bottom: 26px;
    }
    .mt-l-28 {
        margin-top: 28px;
    }
    .mr-l-28 {
        margin-right: 28px;
    }
    .mb-l-28 {
        margin-bottom: 28px;
    }
    .ml-l-28 {
        margin-left: 28px;
    }
    .m-l-28 {
        margin: 28px;
    }
    .mh-l-28 {
        margin-left: 28px;
        margin-right: 28px;
    }
    .mv-l-28 {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .mt-l-30 {
        margin-top: 30px;
    }
    .mr-l-30 {
        margin-right: 30px;
    }
    .mb-l-30 {
        margin-bottom: 30px;
    }
    .ml-l-30 {
        margin-left: 30px;
    }
    .m-l-30 {
        margin: 30px;
    }
    .mh-l-30 {
        margin-left: 30px;
        margin-right: 30px;
    }
    .mv-l-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .mt-l-32 {
        margin-top: 32px;
    }
    .mr-l-32 {
        margin-right: 32px;
    }
    .mb-l-32 {
        margin-bottom: 32px;
    }
    .ml-l-32 {
        margin-left: 32px;
    }
    .m-l-32 {
        margin: 32px;
    }
    .mh-l-32 {
        margin-left: 32px;
        margin-right: 32px;
    }
    .mv-l-32 {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .mt-l-34 {
        margin-top: 34px;
    }
    .mr-l-34 {
        margin-right: 34px;
    }
    .mb-l-34 {
        margin-bottom: 34px;
    }
    .ml-l-34 {
        margin-left: 34px;
    }
    .m-l-34 {
        margin: 34px;
    }
    .mh-l-34 {
        margin-left: 34px;
        margin-right: 34px;
    }
    .mv-l-34 {
        margin-top: 34px;
        margin-bottom: 34px;
    }
    .mt-l-36 {
        margin-top: 36px;
    }
    .mr-l-36 {
        margin-right: 36px;
    }
    .mb-l-36 {
        margin-bottom: 36px;
    }
    .ml-l-36 {
        margin-left: 36px;
    }
    .m-l-36 {
        margin: 36px;
    }
    .mh-l-36 {
        margin-left: 36px;
        margin-right: 36px;
    }
    .mv-l-36 {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .mt-l-38 {
        margin-top: 38px;
    }
    .mr-l-38 {
        margin-right: 38px;
    }
    .mb-l-38 {
        margin-bottom: 38px;
    }
    .ml-l-38 {
        margin-left: 38px;
    }
    .m-l-38 {
        margin: 38px;
    }
    .mh-l-38 {
        margin-left: 38px;
        margin-right: 38px;
    }
    .mv-l-38 {
        margin-top: 38px;
        margin-bottom: 38px;
    }
    .mt-l-40 {
        margin-top: 40px;
    }
    .mr-l-40 {
        margin-right: 40px;
    }
    .mb-l-40 {
        margin-bottom: 40px;
    }
    .ml-l-40 {
        margin-left: 40px;
    }
    .m-l-40 {
        margin: 40px;
    }
    .mh-l-40 {
        margin-left: 40px;
        margin-right: 40px;
    }
    .mv-l-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .mt-l-42 {
        margin-top: 42px;
    }
    .mr-l-42 {
        margin-right: 42px;
    }
    .mb-l-42 {
        margin-bottom: 42px;
    }
    .ml-l-42 {
        margin-left: 42px;
    }
    .m-l-42 {
        margin: 42px;
    }
    .mh-l-42 {
        margin-left: 42px;
        margin-right: 42px;
    }
    .mv-l-42 {
        margin-top: 42px;
        margin-bottom: 42px;
    }
    .mt-l-44 {
        margin-top: 44px;
    }
    .mr-l-44 {
        margin-right: 44px;
    }
    .mb-l-44 {
        margin-bottom: 44px;
    }
    .ml-l-44 {
        margin-left: 44px;
    }
    .m-l-44 {
        margin: 44px;
    }
    .mh-l-44 {
        margin-left: 44px;
        margin-right: 44px;
    }
    .mv-l-44 {
        margin-top: 44px;
        margin-bottom: 44px;
    }
    .mt-l-46 {
        margin-top: 46px;
    }
    .mr-l-46 {
        margin-right: 46px;
    }
    .mb-l-46 {
        margin-bottom: 46px;
    }
    .ml-l-46 {
        margin-left: 46px;
    }
    .m-l-46 {
        margin: 46px;
    }
    .mh-l-46 {
        margin-left: 46px;
        margin-right: 46px;
    }
    .mv-l-46 {
        margin-top: 46px;
        margin-bottom: 46px;
    }
    .mt-l-48 {
        margin-top: 48px;
    }
    .mr-l-48 {
        margin-right: 48px;
    }
    .mb-l-48 {
        margin-bottom: 48px;
    }
    .ml-l-48 {
        margin-left: 48px;
    }
    .m-l-48 {
        margin: 48px;
    }
    .mh-l-48 {
        margin-left: 48px;
        margin-right: 48px;
    }
    .mv-l-48 {
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .mt-l-50 {
        margin-top: 50px;
    }
    .mr-l-50 {
        margin-right: 50px;
    }
    .mb-l-50 {
        margin-bottom: 50px;
    }
    .ml-l-50 {
        margin-left: 50px;
    }
    .m-l-50 {
        margin: 50px;
    }
    .mh-l-50 {
        margin-left: 50px;
        margin-right: 50px;
    }
    .mv-l-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .mt-l-52 {
        margin-top: 52px;
    }
    .mr-l-52 {
        margin-right: 52px;
    }
    .mb-l-52 {
        margin-bottom: 52px;
    }
    .ml-l-52 {
        margin-left: 52px;
    }
    .m-l-52 {
        margin: 52px;
    }
    .mh-l-52 {
        margin-left: 52px;
        margin-right: 52px;
    }
    .mv-l-52 {
        margin-top: 52px;
        margin-bottom: 52px;
    }
    .mt-l-54 {
        margin-top: 54px;
    }
    .mr-l-54 {
        margin-right: 54px;
    }
    .mb-l-54 {
        margin-bottom: 54px;
    }
    .ml-l-54 {
        margin-left: 54px;
    }
    .m-l-54 {
        margin: 54px;
    }
    .mh-l-54 {
        margin-left: 54px;
        margin-right: 54px;
    }
    .mv-l-54 {
        margin-top: 54px;
        margin-bottom: 54px;
    }
    .mt-l-56 {
        margin-top: 56px;
    }
    .mr-l-56 {
        margin-right: 56px;
    }
    .mb-l-56 {
        margin-bottom: 56px;
    }
    .ml-l-56 {
        margin-left: 56px;
    }
    .m-l-56 {
        margin: 56px;
    }
    .mh-l-56 {
        margin-left: 56px;
        margin-right: 56px;
    }
    .mv-l-56 {
        margin-top: 56px;
        margin-bottom: 56px;
    }
    .mt-l-58 {
        margin-top: 58px;
    }
    .mr-l-58 {
        margin-right: 58px;
    }
    .mb-l-58 {
        margin-bottom: 58px;
    }
    .ml-l-58 {
        margin-left: 58px;
    }
    .m-l-58 {
        margin: 58px;
    }
    .mh-l-58 {
        margin-left: 58px;
        margin-right: 58px;
    }
    .mv-l-58 {
        margin-top: 58px;
        margin-bottom: 58px;
    }
    .mt-l-60 {
        margin-top: 60px;
    }
    .mr-l-60 {
        margin-right: 60px;
    }
    .mb-l-60 {
        margin-bottom: 60px;
    }
    .ml-l-60 {
        margin-left: 60px;
    }
    .m-l-60 {
        margin: 60px;
    }
    .mh-l-60 {
        margin-left: 60px;
        margin-right: 60px;
    }
    .mv-l-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .mt-l-62 {
        margin-top: 62px;
    }
    .mr-l-62 {
        margin-right: 62px;
    }
    .mb-l-62 {
        margin-bottom: 62px;
    }
    .ml-l-62 {
        margin-left: 62px;
    }
    .m-l-62 {
        margin: 62px;
    }
    .mh-l-62 {
        margin-left: 62px;
        margin-right: 62px;
    }
    .mv-l-62 {
        margin-top: 62px;
        margin-bottom: 62px;
    }
    .mt-l-64 {
        margin-top: 64px;
    }
    .mr-l-64 {
        margin-right: 64px;
    }
    .mb-l-64 {
        margin-bottom: 64px;
    }
    .ml-l-64 {
        margin-left: 64px;
    }
    .m-l-64 {
        margin: 64px;
    }
    .mh-l-64 {
        margin-left: 64px;
        margin-right: 64px;
    }
    .mv-l-64 {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    .mt-l-66 {
        margin-top: 66px;
    }
    .mr-l-66 {
        margin-right: 66px;
    }
    .mb-l-66 {
        margin-bottom: 66px;
    }
    .ml-l-66 {
        margin-left: 66px;
    }
    .m-l-66 {
        margin: 66px;
    }
    .mh-l-66 {
        margin-left: 66px;
        margin-right: 66px;
    }
    .mv-l-66 {
        margin-top: 66px;
        margin-bottom: 66px;
    }
    .mt-l-68 {
        margin-top: 68px;
    }
    .mr-l-68 {
        margin-right: 68px;
    }
    .mb-l-68 {
        margin-bottom: 68px;
    }
    .ml-l-68 {
        margin-left: 68px;
    }
    .m-l-68 {
        margin: 68px;
    }
    .mh-l-68 {
        margin-left: 68px;
        margin-right: 68px;
    }
    .mv-l-68 {
        margin-top: 68px;
        margin-bottom: 68px;
    }
    .mt-l-70 {
        margin-top: 70px;
    }
    .mr-l-70 {
        margin-right: 70px;
    }
    .mb-l-70 {
        margin-bottom: 70px;
    }
    .ml-l-70 {
        margin-left: 70px;
    }
    .m-l-70 {
        margin: 70px;
    }
    .mh-l-70 {
        margin-left: 70px;
        margin-right: 70px;
    }
    .mv-l-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .mt-l-72 {
        margin-top: 72px;
    }
    .mr-l-72 {
        margin-right: 72px;
    }
    .mb-l-72 {
        margin-bottom: 72px;
    }
    .ml-l-72 {
        margin-left: 72px;
    }
    .m-l-72 {
        margin: 72px;
    }
    .mh-l-72 {
        margin-left: 72px;
        margin-right: 72px;
    }
    .mv-l-72 {
        margin-top: 72px;
        margin-bottom: 72px;
    }
    .mt-l-74 {
        margin-top: 74px;
    }
    .mr-l-74 {
        margin-right: 74px;
    }
    .mb-l-74 {
        margin-bottom: 74px;
    }
    .ml-l-74 {
        margin-left: 74px;
    }
    .m-l-74 {
        margin: 74px;
    }
    .mh-l-74 {
        margin-left: 74px;
        margin-right: 74px;
    }
    .mv-l-74 {
        margin-top: 74px;
        margin-bottom: 74px;
    }
    .mt-l-76 {
        margin-top: 76px;
    }
    .mr-l-76 {
        margin-right: 76px;
    }
    .mb-l-76 {
        margin-bottom: 76px;
    }
    .ml-l-76 {
        margin-left: 76px;
    }
    .m-l-76 {
        margin: 76px;
    }
    .mh-l-76 {
        margin-left: 76px;
        margin-right: 76px;
    }
    .mv-l-76 {
        margin-top: 76px;
        margin-bottom: 76px;
    }
    .mt-l-78 {
        margin-top: 78px;
    }
    .mr-l-78 {
        margin-right: 78px;
    }
    .mb-l-78 {
        margin-bottom: 78px;
    }
    .ml-l-78 {
        margin-left: 78px;
    }
    .m-l-78 {
        margin: 78px;
    }
    .mh-l-78 {
        margin-left: 78px;
        margin-right: 78px;
    }
    .mv-l-78 {
        margin-top: 78px;
        margin-bottom: 78px;
    }
    .mt-l-80 {
        margin-top: 80px;
    }
    .mr-l-80 {
        margin-right: 80px;
    }
    .mb-l-80 {
        margin-bottom: 80px;
    }
    .ml-l-80 {
        margin-left: 80px;
    }
    .m-l-80 {
        margin: 80px;
    }
    .mh-l-80 {
        margin-left: 80px;
        margin-right: 80px;
    }
    .mv-l-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .mt-l-82 {
        margin-top: 82px;
    }
    .mr-l-82 {
        margin-right: 82px;
    }
    .mb-l-82 {
        margin-bottom: 82px;
    }
    .ml-l-82 {
        margin-left: 82px;
    }
    .m-l-82 {
        margin: 82px;
    }
    .mh-l-82 {
        margin-left: 82px;
        margin-right: 82px;
    }
    .mv-l-82 {
        margin-top: 82px;
        margin-bottom: 82px;
    }
    .mt-l-84 {
        margin-top: 84px;
    }
    .mr-l-84 {
        margin-right: 84px;
    }
    .mb-l-84 {
        margin-bottom: 84px;
    }
    .ml-l-84 {
        margin-left: 84px;
    }
    .m-l-84 {
        margin: 84px;
    }
    .mh-l-84 {
        margin-left: 84px;
        margin-right: 84px;
    }
    .mv-l-84 {
        margin-top: 84px;
        margin-bottom: 84px;
    }
    .mt-l-86 {
        margin-top: 86px;
    }
    .mr-l-86 {
        margin-right: 86px;
    }
    .mb-l-86 {
        margin-bottom: 86px;
    }
    .ml-l-86 {
        margin-left: 86px;
    }
    .m-l-86 {
        margin: 86px;
    }
    .mh-l-86 {
        margin-left: 86px;
        margin-right: 86px;
    }
    .mv-l-86 {
        margin-top: 86px;
        margin-bottom: 86px;
    }
    .mt-l-88 {
        margin-top: 88px;
    }
    .mr-l-88 {
        margin-right: 88px;
    }
    .mb-l-88 {
        margin-bottom: 88px;
    }
    .ml-l-88 {
        margin-left: 88px;
    }
    .m-l-88 {
        margin: 88px;
    }
    .mh-l-88 {
        margin-left: 88px;
        margin-right: 88px;
    }
    .mv-l-88 {
        margin-top: 88px;
        margin-bottom: 88px;
    }
    .mt-l-90 {
        margin-top: 90px;
    }
    .mr-l-90 {
        margin-right: 90px;
    }
    .mb-l-90 {
        margin-bottom: 90px;
    }
    .ml-l-90 {
        margin-left: 90px;
    }
    .m-l-90 {
        margin: 90px;
    }
    .mh-l-90 {
        margin-left: 90px;
        margin-right: 90px;
    }
    .mv-l-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }
    .mt-l-92 {
        margin-top: 92px;
    }
    .mr-l-92 {
        margin-right: 92px;
    }
    .mb-l-92 {
        margin-bottom: 92px;
    }
    .ml-l-92 {
        margin-left: 92px;
    }
    .m-l-92 {
        margin: 92px;
    }
    .mh-l-92 {
        margin-left: 92px;
        margin-right: 92px;
    }
    .mv-l-92 {
        margin-top: 92px;
        margin-bottom: 92px;
    }
    .mt-l-94 {
        margin-top: 94px;
    }
    .mr-l-94 {
        margin-right: 94px;
    }
    .mb-l-94 {
        margin-bottom: 94px;
    }
    .ml-l-94 {
        margin-left: 94px;
    }
    .m-l-94 {
        margin: 94px;
    }
    .mh-l-94 {
        margin-left: 94px;
        margin-right: 94px;
    }
    .mv-l-94 {
        margin-top: 94px;
        margin-bottom: 94px;
    }
    .mt-l-96 {
        margin-top: 96px;
    }
    .mr-l-96 {
        margin-right: 96px;
    }
    .mb-l-96 {
        margin-bottom: 96px;
    }
    .ml-l-96 {
        margin-left: 96px;
    }
    .m-l-96 {
        margin: 96px;
    }
    .mh-l-96 {
        margin-left: 96px;
        margin-right: 96px;
    }
    .mv-l-96 {
        margin-top: 96px;
        margin-bottom: 96px;
    }
    .mt-l-98 {
        margin-top: 98px;
    }
    .mr-l-98 {
        margin-right: 98px;
    }
    .mb-l-98 {
        margin-bottom: 98px;
    }
    .ml-l-98 {
        margin-left: 98px;
    }
    .m-l-98 {
        margin: 98px;
    }
    .mh-l-98 {
        margin-left: 98px;
        margin-right: 98px;
    }
    .mv-l-98 {
        margin-top: 98px;
        margin-bottom: 98px;
    }
    .mt-l-100 {
        margin-top: 100px;
    }
    .mr-l-100 {
        margin-right: 100px;
    }
    .mb-l-100 {
        margin-bottom: 100px;
    }
    .ml-l-100 {
        margin-left: 100px;
    }
    .m-l-100 {
        margin: 100px;
    }
    .mh-l-100 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .mv-l-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .mt-l-102 {
        margin-top: 102px;
    }
    .mr-l-102 {
        margin-right: 102px;
    }
    .mb-l-102 {
        margin-bottom: 102px;
    }
    .ml-l-102 {
        margin-left: 102px;
    }
    .m-l-102 {
        margin: 102px;
    }
    .mh-l-102 {
        margin-left: 102px;
        margin-right: 102px;
    }
    .mv-l-102 {
        margin-top: 102px;
        margin-bottom: 102px;
    }
    .mt-l-104 {
        margin-top: 104px;
    }
    .mr-l-104 {
        margin-right: 104px;
    }
    .mb-l-104 {
        margin-bottom: 104px;
    }
    .ml-l-104 {
        margin-left: 104px;
    }
    .m-l-104 {
        margin: 104px;
    }
    .mh-l-104 {
        margin-left: 104px;
        margin-right: 104px;
    }
    .mv-l-104 {
        margin-top: 104px;
        margin-bottom: 104px;
    }
    .mt-l-106 {
        margin-top: 106px;
    }
    .mr-l-106 {
        margin-right: 106px;
    }
    .mb-l-106 {
        margin-bottom: 106px;
    }
    .ml-l-106 {
        margin-left: 106px;
    }
    .m-l-106 {
        margin: 106px;
    }
    .mh-l-106 {
        margin-left: 106px;
        margin-right: 106px;
    }
    .mv-l-106 {
        margin-top: 106px;
        margin-bottom: 106px;
    }
    .mt-l-108 {
        margin-top: 108px;
    }
    .mr-l-108 {
        margin-right: 108px;
    }
    .mb-l-108 {
        margin-bottom: 108px;
    }
    .ml-l-108 {
        margin-left: 108px;
    }
    .m-l-108 {
        margin: 108px;
    }
    .mh-l-108 {
        margin-left: 108px;
        margin-right: 108px;
    }
    .mv-l-108 {
        margin-top: 108px;
        margin-bottom: 108px;
    }
    .mt-l-110 {
        margin-top: 110px;
    }
    .mr-l-110 {
        margin-right: 110px;
    }
    .mb-l-110 {
        margin-bottom: 110px;
    }
    .ml-l-110 {
        margin-left: 110px;
    }
    .m-l-110 {
        margin: 110px;
    }
    .mh-l-110 {
        margin-left: 110px;
        margin-right: 110px;
    }
    .mv-l-110 {
        margin-top: 110px;
        margin-bottom: 110px;
    }
    .mt-l-112 {
        margin-top: 112px;
    }
    .mr-l-112 {
        margin-right: 112px;
    }
    .mb-l-112 {
        margin-bottom: 112px;
    }
    .ml-l-112 {
        margin-left: 112px;
    }
    .m-l-112 {
        margin: 112px;
    }
    .mh-l-112 {
        margin-left: 112px;
        margin-right: 112px;
    }
    .mv-l-112 {
        margin-top: 112px;
        margin-bottom: 112px;
    }
    .mt-l-114 {
        margin-top: 114px;
    }
    .mr-l-114 {
        margin-right: 114px;
    }
    .mb-l-114 {
        margin-bottom: 114px;
    }
    .ml-l-114 {
        margin-left: 114px;
    }
    .m-l-114 {
        margin: 114px;
    }
    .mh-l-114 {
        margin-left: 114px;
        margin-right: 114px;
    }
    .mv-l-114 {
        margin-top: 114px;
        margin-bottom: 114px;
    }
    .mt-l-116 {
        margin-top: 116px;
    }
    .mr-l-116 {
        margin-right: 116px;
    }
    .mb-l-116 {
        margin-bottom: 116px;
    }
    .ml-l-116 {
        margin-left: 116px;
    }
    .m-l-116 {
        margin: 116px;
    }
    .mh-l-116 {
        margin-left: 116px;
        margin-right: 116px;
    }
    .mv-l-116 {
        margin-top: 116px;
        margin-bottom: 116px;
    }
    .mt-l-118 {
        margin-top: 118px;
    }
    .mr-l-118 {
        margin-right: 118px;
    }
    .mb-l-118 {
        margin-bottom: 118px;
    }
    .ml-l-118 {
        margin-left: 118px;
    }
    .m-l-118 {
        margin: 118px;
    }
    .mh-l-118 {
        margin-left: 118px;
        margin-right: 118px;
    }
    .mv-l-118 {
        margin-top: 118px;
        margin-bottom: 118px;
    }
    .mt-l-120 {
        margin-top: 120px;
    }
    .mr-l-120 {
        margin-right: 120px;
    }
    .mb-l-120 {
        margin-bottom: 120px;
    }
    .ml-l-120 {
        margin-left: 120px;
    }
    .m-l-120 {
        margin: 120px;
    }
    .mh-l-120 {
        margin-left: 120px;
        margin-right: 120px;
    }
    .mv-l-120 {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .mt-l-122 {
        margin-top: 122px;
    }
    .mr-l-122 {
        margin-right: 122px;
    }
    .mb-l-122 {
        margin-bottom: 122px;
    }
    .ml-l-122 {
        margin-left: 122px;
    }
    .m-l-122 {
        margin: 122px;
    }
    .mh-l-122 {
        margin-left: 122px;
        margin-right: 122px;
    }
    .mv-l-122 {
        margin-top: 122px;
        margin-bottom: 122px;
    }
    .mt-l-124 {
        margin-top: 124px;
    }
    .mr-l-124 {
        margin-right: 124px;
    }
    .mb-l-124 {
        margin-bottom: 124px;
    }
    .ml-l-124 {
        margin-left: 124px;
    }
    .m-l-124 {
        margin: 124px;
    }
    .mh-l-124 {
        margin-left: 124px;
        margin-right: 124px;
    }
    .mv-l-124 {
        margin-top: 124px;
        margin-bottom: 124px;
    }
    .mt-l-126 {
        margin-top: 126px;
    }
    .mr-l-126 {
        margin-right: 126px;
    }
    .mb-l-126 {
        margin-bottom: 126px;
    }
    .ml-l-126 {
        margin-left: 126px;
    }
    .m-l-126 {
        margin: 126px;
    }
    .mh-l-126 {
        margin-left: 126px;
        margin-right: 126px;
    }
    .mv-l-126 {
        margin-top: 126px;
        margin-bottom: 126px;
    }
    .mt-l-128 {
        margin-top: 128px;
    }
    .mr-l-128 {
        margin-right: 128px;
    }
    .mb-l-128 {
        margin-bottom: 128px;
    }
    .ml-l-128 {
        margin-left: 128px;
    }
    .m-l-128 {
        margin: 128px;
    }
    .mh-l-128 {
        margin-left: 128px;
        margin-right: 128px;
    }
    .mv-l-128 {
        margin-top: 128px;
        margin-bottom: 128px;
    }
    .mt-l-130 {
        margin-top: 130px;
    }
    .mr-l-130 {
        margin-right: 130px;
    }
    .mb-l-130 {
        margin-bottom: 130px;
    }
    .ml-l-130 {
        margin-left: 130px;
    }
    .m-l-130 {
        margin: 130px;
    }
    .mh-l-130 {
        margin-left: 130px;
        margin-right: 130px;
    }
    .mv-l-130 {
        margin-top: 130px;
        margin-bottom: 130px;
    }
    .mt-l-132 {
        margin-top: 132px;
    }
    .mr-l-132 {
        margin-right: 132px;
    }
    .mb-l-132 {
        margin-bottom: 132px;
    }
    .ml-l-132 {
        margin-left: 132px;
    }
    .m-l-132 {
        margin: 132px;
    }
    .mh-l-132 {
        margin-left: 132px;
        margin-right: 132px;
    }
    .mv-l-132 {
        margin-top: 132px;
        margin-bottom: 132px;
    }
    .mt-l-134 {
        margin-top: 134px;
    }
    .mr-l-134 {
        margin-right: 134px;
    }
    .mb-l-134 {
        margin-bottom: 134px;
    }
    .ml-l-134 {
        margin-left: 134px;
    }
    .m-l-134 {
        margin: 134px;
    }
    .mh-l-134 {
        margin-left: 134px;
        margin-right: 134px;
    }
    .mv-l-134 {
        margin-top: 134px;
        margin-bottom: 134px;
    }
    .mt-l-136 {
        margin-top: 136px;
    }
    .mr-l-136 {
        margin-right: 136px;
    }
    .mb-l-136 {
        margin-bottom: 136px;
    }
    .ml-l-136 {
        margin-left: 136px;
    }
    .m-l-136 {
        margin: 136px;
    }
    .mh-l-136 {
        margin-left: 136px;
        margin-right: 136px;
    }
    .mv-l-136 {
        margin-top: 136px;
        margin-bottom: 136px;
    }
    .mt-l-138 {
        margin-top: 138px;
    }
    .mr-l-138 {
        margin-right: 138px;
    }
    .mb-l-138 {
        margin-bottom: 138px;
    }
    .ml-l-138 {
        margin-left: 138px;
    }
    .m-l-138 {
        margin: 138px;
    }
    .mh-l-138 {
        margin-left: 138px;
        margin-right: 138px;
    }
    .mv-l-138 {
        margin-top: 138px;
        margin-bottom: 138px;
    }
    .mt-l-140 {
        margin-top: 140px;
    }
    .mr-l-140 {
        margin-right: 140px;
    }
    .mb-l-140 {
        margin-bottom: 140px;
    }
    .ml-l-140 {
        margin-left: 140px;
    }
    .m-l-140 {
        margin: 140px;
    }
    .mh-l-140 {
        margin-left: 140px;
        margin-right: 140px;
    }
    .mv-l-140 {
        margin-top: 140px;
        margin-bottom: 140px;
    }
    .m-l-0 {
        margin: 0;
    }
    .mt-l-0 {
        margin-top: 0;
    }
    .mr-l-0 {
        margin-right: 0;
    }
    .mb-l-0 {
        margin-bottom: 0;
    }
    .ml-l-0 {
        margin-left: 0;
    }
    .mv-l-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pt-l-2 {
        padding-top: 2px;
    }
    .pr-l-2 {
        padding-right: 2px;
    }
    .pb-l-2 {
        padding-bottom: 2px;
    }
    .pl-l-2 {
        padding-left: 2px;
    }
    .p-l-2 {
        padding: 2px;
    }
    .ph-l-2 {
        padding-left: 2px;
        padding-right: 2px;
    }
    .pv-l-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .pt-l-4 {
        padding-top: 4px;
    }
    .pr-l-4 {
        padding-right: 4px;
    }
    .pb-l-4 {
        padding-bottom: 4px;
    }
    .pl-l-4 {
        padding-left: 4px;
    }
    .p-l-4 {
        padding: 4px;
    }
    .ph-l-4 {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pv-l-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .pt-l-6 {
        padding-top: 6px;
    }
    .pr-l-6 {
        padding-right: 6px;
    }
    .pb-l-6 {
        padding-bottom: 6px;
    }
    .pl-l-6 {
        padding-left: 6px;
    }
    .p-l-6 {
        padding: 6px;
    }
    .ph-l-6 {
        padding-left: 6px;
        padding-right: 6px;
    }
    .pv-l-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .pt-l-8 {
        padding-top: 8px;
    }
    .pr-l-8 {
        padding-right: 8px;
    }
    .pb-l-8 {
        padding-bottom: 8px;
    }
    .pl-l-8 {
        padding-left: 8px;
    }
    .p-l-8 {
        padding: 8px;
    }
    .ph-l-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .pv-l-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .pt-l-10 {
        padding-top: 10px;
    }
    .pr-l-10 {
        padding-right: 10px;
    }
    .pb-l-10 {
        padding-bottom: 10px;
    }
    .pl-l-10 {
        padding-left: 10px;
    }
    .p-l-10 {
        padding: 10px;
    }
    .ph-l-10 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pv-l-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .pt-l-12 {
        padding-top: 12px;
    }
    .pr-l-12 {
        padding-right: 12px;
    }
    .pb-l-12 {
        padding-bottom: 12px;
    }
    .pl-l-12 {
        padding-left: 12px;
    }
    .p-l-12 {
        padding: 12px;
    }
    .ph-l-12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .pv-l-12 {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .pt-l-14 {
        padding-top: 14px;
    }
    .pr-l-14 {
        padding-right: 14px;
    }
    .pb-l-14 {
        padding-bottom: 14px;
    }
    .pl-l-14 {
        padding-left: 14px;
    }
    .p-l-14 {
        padding: 14px;
    }
    .ph-l-14 {
        padding-left: 14px;
        padding-right: 14px;
    }
    .pv-l-14 {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .pt-l-16 {
        padding-top: 16px;
    }
    .pr-l-16 {
        padding-right: 16px;
    }
    .pb-l-16 {
        padding-bottom: 16px;
    }
    .pl-l-16 {
        padding-left: 16px;
    }
    .p-l-16 {
        padding: 16px;
    }
    .ph-l-16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .pv-l-16 {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .pt-l-18 {
        padding-top: 18px;
    }
    .pr-l-18 {
        padding-right: 18px;
    }
    .pb-l-18 {
        padding-bottom: 18px;
    }
    .pl-l-18 {
        padding-left: 18px;
    }
    .p-l-18 {
        padding: 18px;
    }
    .ph-l-18 {
        padding-left: 18px;
        padding-right: 18px;
    }
    .pv-l-18 {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .pt-l-20 {
        padding-top: 20px;
    }
    .pr-l-20 {
        padding-right: 20px;
    }
    .pb-l-20 {
        padding-bottom: 20px;
    }
    .pl-l-20 {
        padding-left: 20px;
    }
    .p-l-20 {
        padding: 20px;
    }
    .ph-l-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pv-l-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .pt-l-22 {
        padding-top: 22px;
    }
    .pr-l-22 {
        padding-right: 22px;
    }
    .pb-l-22 {
        padding-bottom: 22px;
    }
    .pl-l-22 {
        padding-left: 22px;
    }
    .p-l-22 {
        padding: 22px;
    }
    .ph-l-22 {
        padding-left: 22px;
        padding-right: 22px;
    }
    .pv-l-22 {
        padding-top: 22px;
        padding-bottom: 22px;
    }
    .pt-l-24 {
        padding-top: 24px;
    }
    .pr-l-24 {
        padding-right: 24px;
    }
    .pb-l-24 {
        padding-bottom: 24px;
    }
    .pl-l-24 {
        padding-left: 24px;
    }
    .p-l-24 {
        padding: 24px;
    }
    .ph-l-24 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pv-l-24 {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .pt-l-26 {
        padding-top: 26px;
    }
    .pr-l-26 {
        padding-right: 26px;
    }
    .pb-l-26 {
        padding-bottom: 26px;
    }
    .pl-l-26 {
        padding-left: 26px;
    }
    .p-l-26 {
        padding: 26px;
    }
    .ph-l-26 {
        padding-left: 26px;
        padding-right: 26px;
    }
    .pv-l-26 {
        padding-top: 26px;
        padding-bottom: 26px;
    }
    .pt-l-28 {
        padding-top: 28px;
    }
    .pr-l-28 {
        padding-right: 28px;
    }
    .pb-l-28 {
        padding-bottom: 28px;
    }
    .pl-l-28 {
        padding-left: 28px;
    }
    .p-l-28 {
        padding: 28px;
    }
    .ph-l-28 {
        padding-left: 28px;
        padding-right: 28px;
    }
    .pv-l-28 {
        padding-top: 28px;
        padding-bottom: 28px;
    }
    .pt-l-30 {
        padding-top: 30px;
    }
    .pr-l-30 {
        padding-right: 30px;
    }
    .pb-l-30 {
        padding-bottom: 30px;
    }
    .pl-l-30 {
        padding-left: 30px;
    }
    .p-l-30 {
        padding: 30px;
    }
    .ph-l-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pv-l-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pt-l-32 {
        padding-top: 32px;
    }
    .pr-l-32 {
        padding-right: 32px;
    }
    .pb-l-32 {
        padding-bottom: 32px;
    }
    .pl-l-32 {
        padding-left: 32px;
    }
    .p-l-32 {
        padding: 32px;
    }
    .ph-l-32 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .pv-l-32 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .pt-l-34 {
        padding-top: 34px;
    }
    .pr-l-34 {
        padding-right: 34px;
    }
    .pb-l-34 {
        padding-bottom: 34px;
    }
    .pl-l-34 {
        padding-left: 34px;
    }
    .p-l-34 {
        padding: 34px;
    }
    .ph-l-34 {
        padding-left: 34px;
        padding-right: 34px;
    }
    .pv-l-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .pt-l-36 {
        padding-top: 36px;
    }
    .pr-l-36 {
        padding-right: 36px;
    }
    .pb-l-36 {
        padding-bottom: 36px;
    }
    .pl-l-36 {
        padding-left: 36px;
    }
    .p-l-36 {
        padding: 36px;
    }
    .ph-l-36 {
        padding-left: 36px;
        padding-right: 36px;
    }
    .pv-l-36 {
        padding-top: 36px;
        padding-bottom: 36px;
    }
    .pt-l-38 {
        padding-top: 38px;
    }
    .pr-l-38 {
        padding-right: 38px;
    }
    .pb-l-38 {
        padding-bottom: 38px;
    }
    .pl-l-38 {
        padding-left: 38px;
    }
    .p-l-38 {
        padding: 38px;
    }
    .ph-l-38 {
        padding-left: 38px;
        padding-right: 38px;
    }
    .pv-l-38 {
        padding-top: 38px;
        padding-bottom: 38px;
    }
    .pt-l-40 {
        padding-top: 40px;
    }
    .pr-l-40 {
        padding-right: 40px;
    }
    .pb-l-40 {
        padding-bottom: 40px;
    }
    .pl-l-40 {
        padding-left: 40px;
    }
    .p-l-40 {
        padding: 40px;
    }
    .ph-l-40 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pv-l-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .pt-l-42 {
        padding-top: 42px;
    }
    .pr-l-42 {
        padding-right: 42px;
    }
    .pb-l-42 {
        padding-bottom: 42px;
    }
    .pl-l-42 {
        padding-left: 42px;
    }
    .p-l-42 {
        padding: 42px;
    }
    .ph-l-42 {
        padding-left: 42px;
        padding-right: 42px;
    }
    .pv-l-42 {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    .pt-l-44 {
        padding-top: 44px;
    }
    .pr-l-44 {
        padding-right: 44px;
    }
    .pb-l-44 {
        padding-bottom: 44px;
    }
    .pl-l-44 {
        padding-left: 44px;
    }
    .p-l-44 {
        padding: 44px;
    }
    .ph-l-44 {
        padding-left: 44px;
        padding-right: 44px;
    }
    .pv-l-44 {
        padding-top: 44px;
        padding-bottom: 44px;
    }
    .pt-l-46 {
        padding-top: 46px;
    }
    .pr-l-46 {
        padding-right: 46px;
    }
    .pb-l-46 {
        padding-bottom: 46px;
    }
    .pl-l-46 {
        padding-left: 46px;
    }
    .p-l-46 {
        padding: 46px;
    }
    .ph-l-46 {
        padding-left: 46px;
        padding-right: 46px;
    }
    .pv-l-46 {
        padding-top: 46px;
        padding-bottom: 46px;
    }
    .pt-l-48 {
        padding-top: 48px;
    }
    .pr-l-48 {
        padding-right: 48px;
    }
    .pb-l-48 {
        padding-bottom: 48px;
    }
    .pl-l-48 {
        padding-left: 48px;
    }
    .p-l-48 {
        padding: 48px;
    }
    .ph-l-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .pv-l-48 {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .pt-l-50 {
        padding-top: 50px;
    }
    .pr-l-50 {
        padding-right: 50px;
    }
    .pb-l-50 {
        padding-bottom: 50px;
    }
    .pl-l-50 {
        padding-left: 50px;
    }
    .p-l-50 {
        padding: 50px;
    }
    .ph-l-50 {
        padding-left: 50px;
        padding-right: 50px;
    }
    .pv-l-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-l-52 {
        padding-top: 52px;
    }
    .pr-l-52 {
        padding-right: 52px;
    }
    .pb-l-52 {
        padding-bottom: 52px;
    }
    .pl-l-52 {
        padding-left: 52px;
    }
    .p-l-52 {
        padding: 52px;
    }
    .ph-l-52 {
        padding-left: 52px;
        padding-right: 52px;
    }
    .pv-l-52 {
        padding-top: 52px;
        padding-bottom: 52px;
    }
    .pt-l-54 {
        padding-top: 54px;
    }
    .pr-l-54 {
        padding-right: 54px;
    }
    .pb-l-54 {
        padding-bottom: 54px;
    }
    .pl-l-54 {
        padding-left: 54px;
    }
    .p-l-54 {
        padding: 54px;
    }
    .ph-l-54 {
        padding-left: 54px;
        padding-right: 54px;
    }
    .pv-l-54 {
        padding-top: 54px;
        padding-bottom: 54px;
    }
    .pt-l-56 {
        padding-top: 56px;
    }
    .pr-l-56 {
        padding-right: 56px;
    }
    .pb-l-56 {
        padding-bottom: 56px;
    }
    .pl-l-56 {
        padding-left: 56px;
    }
    .p-l-56 {
        padding: 56px;
    }
    .ph-l-56 {
        padding-left: 56px;
        padding-right: 56px;
    }
    .pv-l-56 {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .pt-l-58 {
        padding-top: 58px;
    }
    .pr-l-58 {
        padding-right: 58px;
    }
    .pb-l-58 {
        padding-bottom: 58px;
    }
    .pl-l-58 {
        padding-left: 58px;
    }
    .p-l-58 {
        padding: 58px;
    }
    .ph-l-58 {
        padding-left: 58px;
        padding-right: 58px;
    }
    .pv-l-58 {
        padding-top: 58px;
        padding-bottom: 58px;
    }
    .pt-l-60 {
        padding-top: 60px;
    }
    .pr-l-60 {
        padding-right: 60px;
    }
    .pb-l-60 {
        padding-bottom: 60px;
    }
    .pl-l-60 {
        padding-left: 60px;
    }
    .p-l-60 {
        padding: 60px;
    }
    .ph-l-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .pv-l-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .pt-l-62 {
        padding-top: 62px;
    }
    .pr-l-62 {
        padding-right: 62px;
    }
    .pb-l-62 {
        padding-bottom: 62px;
    }
    .pl-l-62 {
        padding-left: 62px;
    }
    .p-l-62 {
        padding: 62px;
    }
    .ph-l-62 {
        padding-left: 62px;
        padding-right: 62px;
    }
    .pv-l-62 {
        padding-top: 62px;
        padding-bottom: 62px;
    }
    .pt-l-64 {
        padding-top: 64px;
    }
    .pr-l-64 {
        padding-right: 64px;
    }
    .pb-l-64 {
        padding-bottom: 64px;
    }
    .pl-l-64 {
        padding-left: 64px;
    }
    .p-l-64 {
        padding: 64px;
    }
    .ph-l-64 {
        padding-left: 64px;
        padding-right: 64px;
    }
    .pv-l-64 {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .pt-l-66 {
        padding-top: 66px;
    }
    .pr-l-66 {
        padding-right: 66px;
    }
    .pb-l-66 {
        padding-bottom: 66px;
    }
    .pl-l-66 {
        padding-left: 66px;
    }
    .p-l-66 {
        padding: 66px;
    }
    .ph-l-66 {
        padding-left: 66px;
        padding-right: 66px;
    }
    .pv-l-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .pt-l-68 {
        padding-top: 68px;
    }
    .pr-l-68 {
        padding-right: 68px;
    }
    .pb-l-68 {
        padding-bottom: 68px;
    }
    .pl-l-68 {
        padding-left: 68px;
    }
    .p-l-68 {
        padding: 68px;
    }
    .ph-l-68 {
        padding-left: 68px;
        padding-right: 68px;
    }
    .pv-l-68 {
        padding-top: 68px;
        padding-bottom: 68px;
    }
    .pt-l-70 {
        padding-top: 70px;
    }
    .pr-l-70 {
        padding-right: 70px;
    }
    .pb-l-70 {
        padding-bottom: 70px;
    }
    .pl-l-70 {
        padding-left: 70px;
    }
    .p-l-70 {
        padding: 70px;
    }
    .ph-l-70 {
        padding-left: 70px;
        padding-right: 70px;
    }
    .pv-l-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-l-72 {
        padding-top: 72px;
    }
    .pr-l-72 {
        padding-right: 72px;
    }
    .pb-l-72 {
        padding-bottom: 72px;
    }
    .pl-l-72 {
        padding-left: 72px;
    }
    .p-l-72 {
        padding: 72px;
    }
    .ph-l-72 {
        padding-left: 72px;
        padding-right: 72px;
    }
    .pv-l-72 {
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .pt-l-74 {
        padding-top: 74px;
    }
    .pr-l-74 {
        padding-right: 74px;
    }
    .pb-l-74 {
        padding-bottom: 74px;
    }
    .pl-l-74 {
        padding-left: 74px;
    }
    .p-l-74 {
        padding: 74px;
    }
    .ph-l-74 {
        padding-left: 74px;
        padding-right: 74px;
    }
    .pv-l-74 {
        padding-top: 74px;
        padding-bottom: 74px;
    }
    .pt-l-76 {
        padding-top: 76px;
    }
    .pr-l-76 {
        padding-right: 76px;
    }
    .pb-l-76 {
        padding-bottom: 76px;
    }
    .pl-l-76 {
        padding-left: 76px;
    }
    .p-l-76 {
        padding: 76px;
    }
    .ph-l-76 {
        padding-left: 76px;
        padding-right: 76px;
    }
    .pv-l-76 {
        padding-top: 76px;
        padding-bottom: 76px;
    }
    .pt-l-78 {
        padding-top: 78px;
    }
    .pr-l-78 {
        padding-right: 78px;
    }
    .pb-l-78 {
        padding-bottom: 78px;
    }
    .pl-l-78 {
        padding-left: 78px;
    }
    .p-l-78 {
        padding: 78px;
    }
    .ph-l-78 {
        padding-left: 78px;
        padding-right: 78px;
    }
    .pv-l-78 {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    .pt-l-80 {
        padding-top: 80px;
    }
    .pr-l-80 {
        padding-right: 80px;
    }
    .pb-l-80 {
        padding-bottom: 80px;
    }
    .pl-l-80 {
        padding-left: 80px;
    }
    .p-l-80 {
        padding: 80px;
    }
    .ph-l-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .pv-l-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-l-82 {
        padding-top: 82px;
    }
    .pr-l-82 {
        padding-right: 82px;
    }
    .pb-l-82 {
        padding-bottom: 82px;
    }
    .pl-l-82 {
        padding-left: 82px;
    }
    .p-l-82 {
        padding: 82px;
    }
    .ph-l-82 {
        padding-left: 82px;
        padding-right: 82px;
    }
    .pv-l-82 {
        padding-top: 82px;
        padding-bottom: 82px;
    }
    .pt-l-84 {
        padding-top: 84px;
    }
    .pr-l-84 {
        padding-right: 84px;
    }
    .pb-l-84 {
        padding-bottom: 84px;
    }
    .pl-l-84 {
        padding-left: 84px;
    }
    .p-l-84 {
        padding: 84px;
    }
    .ph-l-84 {
        padding-left: 84px;
        padding-right: 84px;
    }
    .pv-l-84 {
        padding-top: 84px;
        padding-bottom: 84px;
    }
    .pt-l-86 {
        padding-top: 86px;
    }
    .pr-l-86 {
        padding-right: 86px;
    }
    .pb-l-86 {
        padding-bottom: 86px;
    }
    .pl-l-86 {
        padding-left: 86px;
    }
    .p-l-86 {
        padding: 86px;
    }
    .ph-l-86 {
        padding-left: 86px;
        padding-right: 86px;
    }
    .pv-l-86 {
        padding-top: 86px;
        padding-bottom: 86px;
    }
    .pt-l-88 {
        padding-top: 88px;
    }
    .pr-l-88 {
        padding-right: 88px;
    }
    .pb-l-88 {
        padding-bottom: 88px;
    }
    .pl-l-88 {
        padding-left: 88px;
    }
    .p-l-88 {
        padding: 88px;
    }
    .ph-l-88 {
        padding-left: 88px;
        padding-right: 88px;
    }
    .pv-l-88 {
        padding-top: 88px;
        padding-bottom: 88px;
    }
    .pt-l-90 {
        padding-top: 90px;
    }
    .pr-l-90 {
        padding-right: 90px;
    }
    .pb-l-90 {
        padding-bottom: 90px;
    }
    .pl-l-90 {
        padding-left: 90px;
    }
    .p-l-90 {
        padding: 90px;
    }
    .ph-l-90 {
        padding-left: 90px;
        padding-right: 90px;
    }
    .pv-l-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .pt-l-92 {
        padding-top: 92px;
    }
    .pr-l-92 {
        padding-right: 92px;
    }
    .pb-l-92 {
        padding-bottom: 92px;
    }
    .pl-l-92 {
        padding-left: 92px;
    }
    .p-l-92 {
        padding: 92px;
    }
    .ph-l-92 {
        padding-left: 92px;
        padding-right: 92px;
    }
    .pv-l-92 {
        padding-top: 92px;
        padding-bottom: 92px;
    }
    .pt-l-94 {
        padding-top: 94px;
    }
    .pr-l-94 {
        padding-right: 94px;
    }
    .pb-l-94 {
        padding-bottom: 94px;
    }
    .pl-l-94 {
        padding-left: 94px;
    }
    .p-l-94 {
        padding: 94px;
    }
    .ph-l-94 {
        padding-left: 94px;
        padding-right: 94px;
    }
    .pv-l-94 {
        padding-top: 94px;
        padding-bottom: 94px;
    }
    .pt-l-96 {
        padding-top: 96px;
    }
    .pr-l-96 {
        padding-right: 96px;
    }
    .pb-l-96 {
        padding-bottom: 96px;
    }
    .pl-l-96 {
        padding-left: 96px;
    }
    .p-l-96 {
        padding: 96px;
    }
    .ph-l-96 {
        padding-left: 96px;
        padding-right: 96px;
    }
    .pv-l-96 {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    .pt-l-98 {
        padding-top: 98px;
    }
    .pr-l-98 {
        padding-right: 98px;
    }
    .pb-l-98 {
        padding-bottom: 98px;
    }
    .pl-l-98 {
        padding-left: 98px;
    }
    .p-l-98 {
        padding: 98px;
    }
    .ph-l-98 {
        padding-left: 98px;
        padding-right: 98px;
    }
    .pv-l-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .pt-l-100 {
        padding-top: 100px;
    }
    .pr-l-100 {
        padding-right: 100px;
    }
    .pb-l-100 {
        padding-bottom: 100px;
    }
    .pl-l-100 {
        padding-left: 100px;
    }
    .p-l-100 {
        padding: 100px;
    }
    .ph-l-100 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .pv-l-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .pt-l-102 {
        padding-top: 102px;
    }
    .pr-l-102 {
        padding-right: 102px;
    }
    .pb-l-102 {
        padding-bottom: 102px;
    }
    .pl-l-102 {
        padding-left: 102px;
    }
    .p-l-102 {
        padding: 102px;
    }
    .ph-l-102 {
        padding-left: 102px;
        padding-right: 102px;
    }
    .pv-l-102 {
        padding-top: 102px;
        padding-bottom: 102px;
    }
    .pt-l-104 {
        padding-top: 104px;
    }
    .pr-l-104 {
        padding-right: 104px;
    }
    .pb-l-104 {
        padding-bottom: 104px;
    }
    .pl-l-104 {
        padding-left: 104px;
    }
    .p-l-104 {
        padding: 104px;
    }
    .ph-l-104 {
        padding-left: 104px;
        padding-right: 104px;
    }
    .pv-l-104 {
        padding-top: 104px;
        padding-bottom: 104px;
    }
    .pt-l-106 {
        padding-top: 106px;
    }
    .pr-l-106 {
        padding-right: 106px;
    }
    .pb-l-106 {
        padding-bottom: 106px;
    }
    .pl-l-106 {
        padding-left: 106px;
    }
    .p-l-106 {
        padding: 106px;
    }
    .ph-l-106 {
        padding-left: 106px;
        padding-right: 106px;
    }
    .pv-l-106 {
        padding-top: 106px;
        padding-bottom: 106px;
    }
    .pt-l-108 {
        padding-top: 108px;
    }
    .pr-l-108 {
        padding-right: 108px;
    }
    .pb-l-108 {
        padding-bottom: 108px;
    }
    .pl-l-108 {
        padding-left: 108px;
    }
    .p-l-108 {
        padding: 108px;
    }
    .ph-l-108 {
        padding-left: 108px;
        padding-right: 108px;
    }
    .pv-l-108 {
        padding-top: 108px;
        padding-bottom: 108px;
    }
    .pt-l-110 {
        padding-top: 110px;
    }
    .pr-l-110 {
        padding-right: 110px;
    }
    .pb-l-110 {
        padding-bottom: 110px;
    }
    .pl-l-110 {
        padding-left: 110px;
    }
    .p-l-110 {
        padding: 110px;
    }
    .ph-l-110 {
        padding-left: 110px;
        padding-right: 110px;
    }
    .pv-l-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .pt-l-112 {
        padding-top: 112px;
    }
    .pr-l-112 {
        padding-right: 112px;
    }
    .pb-l-112 {
        padding-bottom: 112px;
    }
    .pl-l-112 {
        padding-left: 112px;
    }
    .p-l-112 {
        padding: 112px;
    }
    .ph-l-112 {
        padding-left: 112px;
        padding-right: 112px;
    }
    .pv-l-112 {
        padding-top: 112px;
        padding-bottom: 112px;
    }
    .pt-l-114 {
        padding-top: 114px;
    }
    .pr-l-114 {
        padding-right: 114px;
    }
    .pb-l-114 {
        padding-bottom: 114px;
    }
    .pl-l-114 {
        padding-left: 114px;
    }
    .p-l-114 {
        padding: 114px;
    }
    .ph-l-114 {
        padding-left: 114px;
        padding-right: 114px;
    }
    .pv-l-114 {
        padding-top: 114px;
        padding-bottom: 114px;
    }
    .pt-l-116 {
        padding-top: 116px;
    }
    .pr-l-116 {
        padding-right: 116px;
    }
    .pb-l-116 {
        padding-bottom: 116px;
    }
    .pl-l-116 {
        padding-left: 116px;
    }
    .p-l-116 {
        padding: 116px;
    }
    .ph-l-116 {
        padding-left: 116px;
        padding-right: 116px;
    }
    .pv-l-116 {
        padding-top: 116px;
        padding-bottom: 116px;
    }
    .pt-l-118 {
        padding-top: 118px;
    }
    .pr-l-118 {
        padding-right: 118px;
    }
    .pb-l-118 {
        padding-bottom: 118px;
    }
    .pl-l-118 {
        padding-left: 118px;
    }
    .p-l-118 {
        padding: 118px;
    }
    .ph-l-118 {
        padding-left: 118px;
        padding-right: 118px;
    }
    .pv-l-118 {
        padding-top: 118px;
        padding-bottom: 118px;
    }
    .pt-l-120 {
        padding-top: 120px;
    }
    .pr-l-120 {
        padding-right: 120px;
    }
    .pb-l-120 {
        padding-bottom: 120px;
    }
    .pl-l-120 {
        padding-left: 120px;
    }
    .p-l-120 {
        padding: 120px;
    }
    .ph-l-120 {
        padding-left: 120px;
        padding-right: 120px;
    }
    .pv-l-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .pt-l-122 {
        padding-top: 122px;
    }
    .pr-l-122 {
        padding-right: 122px;
    }
    .pb-l-122 {
        padding-bottom: 122px;
    }
    .pl-l-122 {
        padding-left: 122px;
    }
    .p-l-122 {
        padding: 122px;
    }
    .ph-l-122 {
        padding-left: 122px;
        padding-right: 122px;
    }
    .pv-l-122 {
        padding-top: 122px;
        padding-bottom: 122px;
    }
    .pt-l-124 {
        padding-top: 124px;
    }
    .pr-l-124 {
        padding-right: 124px;
    }
    .pb-l-124 {
        padding-bottom: 124px;
    }
    .pl-l-124 {
        padding-left: 124px;
    }
    .p-l-124 {
        padding: 124px;
    }
    .ph-l-124 {
        padding-left: 124px;
        padding-right: 124px;
    }
    .pv-l-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .pt-l-126 {
        padding-top: 126px;
    }
    .pr-l-126 {
        padding-right: 126px;
    }
    .pb-l-126 {
        padding-bottom: 126px;
    }
    .pl-l-126 {
        padding-left: 126px;
    }
    .p-l-126 {
        padding: 126px;
    }
    .ph-l-126 {
        padding-left: 126px;
        padding-right: 126px;
    }
    .pv-l-126 {
        padding-top: 126px;
        padding-bottom: 126px;
    }
    .pt-l-128 {
        padding-top: 128px;
    }
    .pr-l-128 {
        padding-right: 128px;
    }
    .pb-l-128 {
        padding-bottom: 128px;
    }
    .pl-l-128 {
        padding-left: 128px;
    }
    .p-l-128 {
        padding: 128px;
    }
    .ph-l-128 {
        padding-left: 128px;
        padding-right: 128px;
    }
    .pv-l-128 {
        padding-top: 128px;
        padding-bottom: 128px;
    }
    .pt-l-130 {
        padding-top: 130px;
    }
    .pr-l-130 {
        padding-right: 130px;
    }
    .pb-l-130 {
        padding-bottom: 130px;
    }
    .pl-l-130 {
        padding-left: 130px;
    }
    .p-l-130 {
        padding: 130px;
    }
    .ph-l-130 {
        padding-left: 130px;
        padding-right: 130px;
    }
    .pv-l-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .pt-l-132 {
        padding-top: 132px;
    }
    .pr-l-132 {
        padding-right: 132px;
    }
    .pb-l-132 {
        padding-bottom: 132px;
    }
    .pl-l-132 {
        padding-left: 132px;
    }
    .p-l-132 {
        padding: 132px;
    }
    .ph-l-132 {
        padding-left: 132px;
        padding-right: 132px;
    }
    .pv-l-132 {
        padding-top: 132px;
        padding-bottom: 132px;
    }
    .pt-l-134 {
        padding-top: 134px;
    }
    .pr-l-134 {
        padding-right: 134px;
    }
    .pb-l-134 {
        padding-bottom: 134px;
    }
    .pl-l-134 {
        padding-left: 134px;
    }
    .p-l-134 {
        padding: 134px;
    }
    .ph-l-134 {
        padding-left: 134px;
        padding-right: 134px;
    }
    .pv-l-134 {
        padding-top: 134px;
        padding-bottom: 134px;
    }
    .pt-l-136 {
        padding-top: 136px;
    }
    .pr-l-136 {
        padding-right: 136px;
    }
    .pb-l-136 {
        padding-bottom: 136px;
    }
    .pl-l-136 {
        padding-left: 136px;
    }
    .p-l-136 {
        padding: 136px;
    }
    .ph-l-136 {
        padding-left: 136px;
        padding-right: 136px;
    }
    .pv-l-136 {
        padding-top: 136px;
        padding-bottom: 136px;
    }
    .pt-l-138 {
        padding-top: 138px;
    }
    .pr-l-138 {
        padding-right: 138px;
    }
    .pb-l-138 {
        padding-bottom: 138px;
    }
    .pl-l-138 {
        padding-left: 138px;
    }
    .p-l-138 {
        padding: 138px;
    }
    .ph-l-138 {
        padding-left: 138px;
        padding-right: 138px;
    }
    .pv-l-138 {
        padding-top: 138px;
        padding-bottom: 138px;
    }
    .pt-l-140 {
        padding-top: 140px;
    }
    .pr-l-140 {
        padding-right: 140px;
    }
    .pb-l-140 {
        padding-bottom: 140px;
    }
    .pl-l-140 {
        padding-left: 140px;
    }
    .p-l-140 {
        padding: 140px;
    }
    .ph-l-140 {
        padding-left: 140px;
        padding-right: 140px;
    }
    .pv-l-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    .p-l-0 {
        padding: 0;
    }
    .pt-l-0 {
        padding-top: 0;
    }
    .pr-l-0 {
        padding-right: 0;
    }
    .pb-l-0 {
        padding-bottom: 0;
    }
    .pl-l-0 {
        padding-left: 0;
    }
    .pv-l-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .rGap-l-2 {
        row-gap: 2px;
    }
    .rGap-l-4 {
        row-gap: 4px;
    }
    .rGap-l-6 {
        row-gap: 6px;
    }
    .rGap-l-8 {
        row-gap: 8px;
    }
    .rGap-l-10 {
        row-gap: 10px;
    }
    .rGap-l-12 {
        row-gap: 12px;
    }
    .rGap-l-14 {
        row-gap: 14px;
    }
    .rGap-l-16 {
        row-gap: 16px;
    }
    .rGap-l-18 {
        row-gap: 18px;
    }
    .rGap-l-20 {
        row-gap: 20px;
    }
    .rGap-l-22 {
        row-gap: 22px;
    }
    .rGap-l-24 {
        row-gap: 24px;
    }
    .rGap-l-26 {
        row-gap: 26px;
    }
    .rGap-l-28 {
        row-gap: 28px;
    }
    .rGap-l-30 {
        row-gap: 30px;
    }
    .rGap-l-32 {
        row-gap: 32px;
    }
    .rGap-l-34 {
        row-gap: 34px;
    }
    .rGap-l-36 {
        row-gap: 36px;
    }
    .rGap-l-38 {
        row-gap: 38px;
    }
    .rGap-l-40 {
        row-gap: 40px;
    }
    .rGap-l-42 {
        row-gap: 42px;
    }
    .rGap-l-44 {
        row-gap: 44px;
    }
    .rGap-l-46 {
        row-gap: 46px;
    }
    .rGap-l-48 {
        row-gap: 48px;
    }
    .rGap-l-50 {
        row-gap: 50px;
    }
    .rGap-l-52 {
        row-gap: 52px;
    }
    .rGap-l-54 {
        row-gap: 54px;
    }
    .rGap-l-56 {
        row-gap: 56px;
    }
    .rGap-l-58 {
        row-gap: 58px;
    }
    .rGap-l-60 {
        row-gap: 60px;
    }
    .rGap-l-62 {
        row-gap: 62px;
    }
    .rGap-l-64 {
        row-gap: 64px;
    }
    .rGap-l-66 {
        row-gap: 66px;
    }
    .rGap-l-68 {
        row-gap: 68px;
    }
    .rGap-l-70 {
        row-gap: 70px;
    }
    .rGap-l-72 {
        row-gap: 72px;
    }
    .rGap-l-74 {
        row-gap: 74px;
    }
    .rGap-l-76 {
        row-gap: 76px;
    }
    .rGap-l-78 {
        row-gap: 78px;
    }
    .rGap-l-80 {
        row-gap: 80px;
    }
    .rGap-l-82 {
        row-gap: 82px;
    }
    .rGap-l-84 {
        row-gap: 84px;
    }
    .rGap-l-86 {
        row-gap: 86px;
    }
    .rGap-l-88 {
        row-gap: 88px;
    }
    .rGap-l-90 {
        row-gap: 90px;
    }
    .rGap-l-92 {
        row-gap: 92px;
    }
    .rGap-l-94 {
        row-gap: 94px;
    }
    .rGap-l-96 {
        row-gap: 96px;
    }
    .rGap-l-98 {
        row-gap: 98px;
    }
    .rGap-l-100 {
        row-gap: 100px;
    }
    .rGap-l-102 {
        row-gap: 102px;
    }
    .rGap-l-104 {
        row-gap: 104px;
    }
    .rGap-l-106 {
        row-gap: 106px;
    }
    .rGap-l-108 {
        row-gap: 108px;
    }
    .rGap-l-110 {
        row-gap: 110px;
    }
    .rGap-l-112 {
        row-gap: 112px;
    }
    .rGap-l-114 {
        row-gap: 114px;
    }
    .rGap-l-116 {
        row-gap: 116px;
    }
    .rGap-l-118 {
        row-gap: 118px;
    }
    .rGap-l-120 {
        row-gap: 120px;
    }
    .rGap-l-122 {
        row-gap: 122px;
    }
    .rGap-l-124 {
        row-gap: 124px;
    }
    .rGap-l-126 {
        row-gap: 126px;
    }
    .rGap-l-128 {
        row-gap: 128px;
    }
    .rGap-l-130 {
        row-gap: 130px;
    }
    .rGap-l-132 {
        row-gap: 132px;
    }
    .rGap-l-134 {
        row-gap: 134px;
    }
    .rGap-l-136 {
        row-gap: 136px;
    }
    .rGap-l-138 {
        row-gap: 138px;
    }
    .rGap-l-140 {
        row-gap: 140px;
    }
    .rGap-l-0 {
        row-gap: 0;
    }
    .cGap-l-2 {
        column-gap: 2px;
    }
    .cGap-l-4 {
        column-gap: 4px;
    }
    .cGap-l-6 {
        column-gap: 6px;
    }
    .cGap-l-8 {
        column-gap: 8px;
    }
    .cGap-l-10 {
        column-gap: 10px;
    }
    .cGap-l-12 {
        column-gap: 12px;
    }
    .cGap-l-14 {
        column-gap: 14px;
    }
    .cGap-l-16 {
        column-gap: 16px;
    }
    .cGap-l-18 {
        column-gap: 18px;
    }
    .cGap-l-20 {
        column-gap: 20px;
    }
    .cGap-l-22 {
        column-gap: 22px;
    }
    .cGap-l-24 {
        column-gap: 24px;
    }
    .cGap-l-26 {
        column-gap: 26px;
    }
    .cGap-l-28 {
        column-gap: 28px;
    }
    .cGap-l-30 {
        column-gap: 30px;
    }
    .cGap-l-32 {
        column-gap: 32px;
    }
    .cGap-l-34 {
        column-gap: 34px;
    }
    .cGap-l-36 {
        column-gap: 36px;
    }
    .cGap-l-38 {
        column-gap: 38px;
    }
    .cGap-l-40 {
        column-gap: 40px;
    }
    .cGap-l-42 {
        column-gap: 42px;
    }
    .cGap-l-44 {
        column-gap: 44px;
    }
    .cGap-l-46 {
        column-gap: 46px;
    }
    .cGap-l-48 {
        column-gap: 48px;
    }
    .cGap-l-50 {
        column-gap: 50px;
    }
    .cGap-l-52 {
        column-gap: 52px;
    }
    .cGap-l-54 {
        column-gap: 54px;
    }
    .cGap-l-56 {
        column-gap: 56px;
    }
    .cGap-l-58 {
        column-gap: 58px;
    }
    .cGap-l-60 {
        column-gap: 60px;
    }
    .cGap-l-62 {
        column-gap: 62px;
    }
    .cGap-l-64 {
        column-gap: 64px;
    }
    .cGap-l-66 {
        column-gap: 66px;
    }
    .cGap-l-68 {
        column-gap: 68px;
    }
    .cGap-l-70 {
        column-gap: 70px;
    }
    .cGap-l-72 {
        column-gap: 72px;
    }
    .cGap-l-74 {
        column-gap: 74px;
    }
    .cGap-l-76 {
        column-gap: 76px;
    }
    .cGap-l-78 {
        column-gap: 78px;
    }
    .cGap-l-80 {
        column-gap: 80px;
    }
    .cGap-l-82 {
        column-gap: 82px;
    }
    .cGap-l-84 {
        column-gap: 84px;
    }
    .cGap-l-86 {
        column-gap: 86px;
    }
    .cGap-l-88 {
        column-gap: 88px;
    }
    .cGap-l-90 {
        column-gap: 90px;
    }
    .cGap-l-92 {
        column-gap: 92px;
    }
    .cGap-l-94 {
        column-gap: 94px;
    }
    .cGap-l-96 {
        column-gap: 96px;
    }
    .cGap-l-98 {
        column-gap: 98px;
    }
    .cGap-l-100 {
        column-gap: 100px;
    }
    .cGap-l-102 {
        column-gap: 102px;
    }
    .cGap-l-104 {
        column-gap: 104px;
    }
    .cGap-l-106 {
        column-gap: 106px;
    }
    .cGap-l-108 {
        column-gap: 108px;
    }
    .cGap-l-110 {
        column-gap: 110px;
    }
    .cGap-l-112 {
        column-gap: 112px;
    }
    .cGap-l-114 {
        column-gap: 114px;
    }
    .cGap-l-116 {
        column-gap: 116px;
    }
    .cGap-l-118 {
        column-gap: 118px;
    }
    .cGap-l-120 {
        column-gap: 120px;
    }
    .cGap-l-122 {
        column-gap: 122px;
    }
    .cGap-l-124 {
        column-gap: 124px;
    }
    .cGap-l-126 {
        column-gap: 126px;
    }
    .cGap-l-128 {
        column-gap: 128px;
    }
    .cGap-l-130 {
        column-gap: 130px;
    }
    .cGap-l-132 {
        column-gap: 132px;
    }
    .cGap-l-134 {
        column-gap: 134px;
    }
    .cGap-l-136 {
        column-gap: 136px;
    }
    .cGap-l-138 {
        column-gap: 138px;
    }
    .cGap-l-140 {
        column-gap: 140px;
    }
    .cGap-l-0 {
        column-gap: 0;
    }
}
@media screen and (max-width: 1024px) {
    .mt-m-2 {
        margin-top: 2px;
    }
    .mr-m-2 {
        margin-right: 2px;
    }
    .mb-m-2 {
        margin-bottom: 2px;
    }
    .ml-m-2 {
        margin-left: 2px;
    }
    .m-m-2 {
        margin: 2px;
    }
    .mh-m-2 {
        margin-left: 2px;
        margin-right: 2px;
    }
    .mv-m-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .mt-m-4 {
        margin-top: 4px;
    }
    .mr-m-4 {
        margin-right: 4px;
    }
    .mb-m-4 {
        margin-bottom: 4px;
    }
    .ml-m-4 {
        margin-left: 4px;
    }
    .m-m-4 {
        margin: 4px;
    }
    .mh-m-4 {
        margin-left: 4px;
        margin-right: 4px;
    }
    .mv-m-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .mt-m-6 {
        margin-top: 6px;
    }
    .mr-m-6 {
        margin-right: 6px;
    }
    .mb-m-6 {
        margin-bottom: 6px;
    }
    .ml-m-6 {
        margin-left: 6px;
    }
    .m-m-6 {
        margin: 6px;
    }
    .mh-m-6 {
        margin-left: 6px;
        margin-right: 6px;
    }
    .mv-m-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .mt-m-8 {
        margin-top: 8px;
    }
    .mr-m-8 {
        margin-right: 8px;
    }
    .mb-m-8 {
        margin-bottom: 8px;
    }
    .ml-m-8 {
        margin-left: 8px;
    }
    .m-m-8 {
        margin: 8px;
    }
    .mh-m-8 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .mv-m-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .mt-m-10 {
        margin-top: 10px;
    }
    .mr-m-10 {
        margin-right: 10px;
    }
    .mb-m-10 {
        margin-bottom: 10px;
    }
    .ml-m-10 {
        margin-left: 10px;
    }
    .m-m-10 {
        margin: 10px;
    }
    .mh-m-10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    .mv-m-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mt-m-12 {
        margin-top: 12px;
    }
    .mr-m-12 {
        margin-right: 12px;
    }
    .mb-m-12 {
        margin-bottom: 12px;
    }
    .ml-m-12 {
        margin-left: 12px;
    }
    .m-m-12 {
        margin: 12px;
    }
    .mh-m-12 {
        margin-left: 12px;
        margin-right: 12px;
    }
    .mv-m-12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .mt-m-14 {
        margin-top: 14px;
    }
    .mr-m-14 {
        margin-right: 14px;
    }
    .mb-m-14 {
        margin-bottom: 14px;
    }
    .ml-m-14 {
        margin-left: 14px;
    }
    .m-m-14 {
        margin: 14px;
    }
    .mh-m-14 {
        margin-left: 14px;
        margin-right: 14px;
    }
    .mv-m-14 {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .mt-m-16 {
        margin-top: 16px;
    }
    .mr-m-16 {
        margin-right: 16px;
    }
    .mb-m-16 {
        margin-bottom: 16px;
    }
    .ml-m-16 {
        margin-left: 16px;
    }
    .m-m-16 {
        margin: 16px;
    }
    .mh-m-16 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .mv-m-16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .mt-m-18 {
        margin-top: 18px;
    }
    .mr-m-18 {
        margin-right: 18px;
    }
    .mb-m-18 {
        margin-bottom: 18px;
    }
    .ml-m-18 {
        margin-left: 18px;
    }
    .m-m-18 {
        margin: 18px;
    }
    .mh-m-18 {
        margin-left: 18px;
        margin-right: 18px;
    }
    .mv-m-18 {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .mt-m-20 {
        margin-top: 20px;
    }
    .mr-m-20 {
        margin-right: 20px;
    }
    .mb-m-20 {
        margin-bottom: 20px;
    }
    .ml-m-20 {
        margin-left: 20px;
    }
    .m-m-20 {
        margin: 20px;
    }
    .mh-m-20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    .mv-m-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .mt-m-22 {
        margin-top: 22px;
    }
    .mr-m-22 {
        margin-right: 22px;
    }
    .mb-m-22 {
        margin-bottom: 22px;
    }
    .ml-m-22 {
        margin-left: 22px;
    }
    .m-m-22 {
        margin: 22px;
    }
    .mh-m-22 {
        margin-left: 22px;
        margin-right: 22px;
    }
    .mv-m-22 {
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .mt-m-24 {
        margin-top: 24px;
    }
    .mr-m-24 {
        margin-right: 24px;
    }
    .mb-m-24 {
        margin-bottom: 24px;
    }
    .ml-m-24 {
        margin-left: 24px;
    }
    .m-m-24 {
        margin: 24px;
    }
    .mh-m-24 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .mv-m-24 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .mt-m-26 {
        margin-top: 26px;
    }
    .mr-m-26 {
        margin-right: 26px;
    }
    .mb-m-26 {
        margin-bottom: 26px;
    }
    .ml-m-26 {
        margin-left: 26px;
    }
    .m-m-26 {
        margin: 26px;
    }
    .mh-m-26 {
        margin-left: 26px;
        margin-right: 26px;
    }
    .mv-m-26 {
        margin-top: 26px;
        margin-bottom: 26px;
    }
    .mt-m-28 {
        margin-top: 28px;
    }
    .mr-m-28 {
        margin-right: 28px;
    }
    .mb-m-28 {
        margin-bottom: 28px;
    }
    .ml-m-28 {
        margin-left: 28px;
    }
    .m-m-28 {
        margin: 28px;
    }
    .mh-m-28 {
        margin-left: 28px;
        margin-right: 28px;
    }
    .mv-m-28 {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .mt-m-30 {
        margin-top: 30px;
    }
    .mr-m-30 {
        margin-right: 30px;
    }
    .mb-m-30 {
        margin-bottom: 30px;
    }
    .ml-m-30 {
        margin-left: 30px;
    }
    .m-m-30 {
        margin: 30px;
    }
    .mh-m-30 {
        margin-left: 30px;
        margin-right: 30px;
    }
    .mv-m-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .mt-m-32 {
        margin-top: 32px;
    }
    .mr-m-32 {
        margin-right: 32px;
    }
    .mb-m-32 {
        margin-bottom: 32px;
    }
    .ml-m-32 {
        margin-left: 32px;
    }
    .m-m-32 {
        margin: 32px;
    }
    .mh-m-32 {
        margin-left: 32px;
        margin-right: 32px;
    }
    .mv-m-32 {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .mt-m-34 {
        margin-top: 34px;
    }
    .mr-m-34 {
        margin-right: 34px;
    }
    .mb-m-34 {
        margin-bottom: 34px;
    }
    .ml-m-34 {
        margin-left: 34px;
    }
    .m-m-34 {
        margin: 34px;
    }
    .mh-m-34 {
        margin-left: 34px;
        margin-right: 34px;
    }
    .mv-m-34 {
        margin-top: 34px;
        margin-bottom: 34px;
    }
    .mt-m-36 {
        margin-top: 36px;
    }
    .mr-m-36 {
        margin-right: 36px;
    }
    .mb-m-36 {
        margin-bottom: 36px;
    }
    .ml-m-36 {
        margin-left: 36px;
    }
    .m-m-36 {
        margin: 36px;
    }
    .mh-m-36 {
        margin-left: 36px;
        margin-right: 36px;
    }
    .mv-m-36 {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .mt-m-38 {
        margin-top: 38px;
    }
    .mr-m-38 {
        margin-right: 38px;
    }
    .mb-m-38 {
        margin-bottom: 38px;
    }
    .ml-m-38 {
        margin-left: 38px;
    }
    .m-m-38 {
        margin: 38px;
    }
    .mh-m-38 {
        margin-left: 38px;
        margin-right: 38px;
    }
    .mv-m-38 {
        margin-top: 38px;
        margin-bottom: 38px;
    }
    .mt-m-40 {
        margin-top: 40px;
    }
    .mr-m-40 {
        margin-right: 40px;
    }
    .mb-m-40 {
        margin-bottom: 40px;
    }
    .ml-m-40 {
        margin-left: 40px;
    }
    .m-m-40 {
        margin: 40px;
    }
    .mh-m-40 {
        margin-left: 40px;
        margin-right: 40px;
    }
    .mv-m-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .mt-m-42 {
        margin-top: 42px;
    }
    .mr-m-42 {
        margin-right: 42px;
    }
    .mb-m-42 {
        margin-bottom: 42px;
    }
    .ml-m-42 {
        margin-left: 42px;
    }
    .m-m-42 {
        margin: 42px;
    }
    .mh-m-42 {
        margin-left: 42px;
        margin-right: 42px;
    }
    .mv-m-42 {
        margin-top: 42px;
        margin-bottom: 42px;
    }
    .mt-m-44 {
        margin-top: 44px;
    }
    .mr-m-44 {
        margin-right: 44px;
    }
    .mb-m-44 {
        margin-bottom: 44px;
    }
    .ml-m-44 {
        margin-left: 44px;
    }
    .m-m-44 {
        margin: 44px;
    }
    .mh-m-44 {
        margin-left: 44px;
        margin-right: 44px;
    }
    .mv-m-44 {
        margin-top: 44px;
        margin-bottom: 44px;
    }
    .mt-m-46 {
        margin-top: 46px;
    }
    .mr-m-46 {
        margin-right: 46px;
    }
    .mb-m-46 {
        margin-bottom: 46px;
    }
    .ml-m-46 {
        margin-left: 46px;
    }
    .m-m-46 {
        margin: 46px;
    }
    .mh-m-46 {
        margin-left: 46px;
        margin-right: 46px;
    }
    .mv-m-46 {
        margin-top: 46px;
        margin-bottom: 46px;
    }
    .mt-m-48 {
        margin-top: 48px;
    }
    .mr-m-48 {
        margin-right: 48px;
    }
    .mb-m-48 {
        margin-bottom: 48px;
    }
    .ml-m-48 {
        margin-left: 48px;
    }
    .m-m-48 {
        margin: 48px;
    }
    .mh-m-48 {
        margin-left: 48px;
        margin-right: 48px;
    }
    .mv-m-48 {
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .mt-m-50 {
        margin-top: 50px;
    }
    .mr-m-50 {
        margin-right: 50px;
    }
    .mb-m-50 {
        margin-bottom: 50px;
    }
    .ml-m-50 {
        margin-left: 50px;
    }
    .m-m-50 {
        margin: 50px;
    }
    .mh-m-50 {
        margin-left: 50px;
        margin-right: 50px;
    }
    .mv-m-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .mt-m-52 {
        margin-top: 52px;
    }
    .mr-m-52 {
        margin-right: 52px;
    }
    .mb-m-52 {
        margin-bottom: 52px;
    }
    .ml-m-52 {
        margin-left: 52px;
    }
    .m-m-52 {
        margin: 52px;
    }
    .mh-m-52 {
        margin-left: 52px;
        margin-right: 52px;
    }
    .mv-m-52 {
        margin-top: 52px;
        margin-bottom: 52px;
    }
    .mt-m-54 {
        margin-top: 54px;
    }
    .mr-m-54 {
        margin-right: 54px;
    }
    .mb-m-54 {
        margin-bottom: 54px;
    }
    .ml-m-54 {
        margin-left: 54px;
    }
    .m-m-54 {
        margin: 54px;
    }
    .mh-m-54 {
        margin-left: 54px;
        margin-right: 54px;
    }
    .mv-m-54 {
        margin-top: 54px;
        margin-bottom: 54px;
    }
    .mt-m-56 {
        margin-top: 56px;
    }
    .mr-m-56 {
        margin-right: 56px;
    }
    .mb-m-56 {
        margin-bottom: 56px;
    }
    .ml-m-56 {
        margin-left: 56px;
    }
    .m-m-56 {
        margin: 56px;
    }
    .mh-m-56 {
        margin-left: 56px;
        margin-right: 56px;
    }
    .mv-m-56 {
        margin-top: 56px;
        margin-bottom: 56px;
    }
    .mt-m-58 {
        margin-top: 58px;
    }
    .mr-m-58 {
        margin-right: 58px;
    }
    .mb-m-58 {
        margin-bottom: 58px;
    }
    .ml-m-58 {
        margin-left: 58px;
    }
    .m-m-58 {
        margin: 58px;
    }
    .mh-m-58 {
        margin-left: 58px;
        margin-right: 58px;
    }
    .mv-m-58 {
        margin-top: 58px;
        margin-bottom: 58px;
    }
    .mt-m-60 {
        margin-top: 60px;
    }
    .mr-m-60 {
        margin-right: 60px;
    }
    .mb-m-60 {
        margin-bottom: 60px;
    }
    .ml-m-60 {
        margin-left: 60px;
    }
    .m-m-60 {
        margin: 60px;
    }
    .mh-m-60 {
        margin-left: 60px;
        margin-right: 60px;
    }
    .mv-m-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .mt-m-62 {
        margin-top: 62px;
    }
    .mr-m-62 {
        margin-right: 62px;
    }
    .mb-m-62 {
        margin-bottom: 62px;
    }
    .ml-m-62 {
        margin-left: 62px;
    }
    .m-m-62 {
        margin: 62px;
    }
    .mh-m-62 {
        margin-left: 62px;
        margin-right: 62px;
    }
    .mv-m-62 {
        margin-top: 62px;
        margin-bottom: 62px;
    }
    .mt-m-64 {
        margin-top: 64px;
    }
    .mr-m-64 {
        margin-right: 64px;
    }
    .mb-m-64 {
        margin-bottom: 64px;
    }
    .ml-m-64 {
        margin-left: 64px;
    }
    .m-m-64 {
        margin: 64px;
    }
    .mh-m-64 {
        margin-left: 64px;
        margin-right: 64px;
    }
    .mv-m-64 {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    .mt-m-66 {
        margin-top: 66px;
    }
    .mr-m-66 {
        margin-right: 66px;
    }
    .mb-m-66 {
        margin-bottom: 66px;
    }
    .ml-m-66 {
        margin-left: 66px;
    }
    .m-m-66 {
        margin: 66px;
    }
    .mh-m-66 {
        margin-left: 66px;
        margin-right: 66px;
    }
    .mv-m-66 {
        margin-top: 66px;
        margin-bottom: 66px;
    }
    .mt-m-68 {
        margin-top: 68px;
    }
    .mr-m-68 {
        margin-right: 68px;
    }
    .mb-m-68 {
        margin-bottom: 68px;
    }
    .ml-m-68 {
        margin-left: 68px;
    }
    .m-m-68 {
        margin: 68px;
    }
    .mh-m-68 {
        margin-left: 68px;
        margin-right: 68px;
    }
    .mv-m-68 {
        margin-top: 68px;
        margin-bottom: 68px;
    }
    .mt-m-70 {
        margin-top: 70px;
    }
    .mr-m-70 {
        margin-right: 70px;
    }
    .mb-m-70 {
        margin-bottom: 70px;
    }
    .ml-m-70 {
        margin-left: 70px;
    }
    .m-m-70 {
        margin: 70px;
    }
    .mh-m-70 {
        margin-left: 70px;
        margin-right: 70px;
    }
    .mv-m-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .mt-m-72 {
        margin-top: 72px;
    }
    .mr-m-72 {
        margin-right: 72px;
    }
    .mb-m-72 {
        margin-bottom: 72px;
    }
    .ml-m-72 {
        margin-left: 72px;
    }
    .m-m-72 {
        margin: 72px;
    }
    .mh-m-72 {
        margin-left: 72px;
        margin-right: 72px;
    }
    .mv-m-72 {
        margin-top: 72px;
        margin-bottom: 72px;
    }
    .mt-m-74 {
        margin-top: 74px;
    }
    .mr-m-74 {
        margin-right: 74px;
    }
    .mb-m-74 {
        margin-bottom: 74px;
    }
    .ml-m-74 {
        margin-left: 74px;
    }
    .m-m-74 {
        margin: 74px;
    }
    .mh-m-74 {
        margin-left: 74px;
        margin-right: 74px;
    }
    .mv-m-74 {
        margin-top: 74px;
        margin-bottom: 74px;
    }
    .mt-m-76 {
        margin-top: 76px;
    }
    .mr-m-76 {
        margin-right: 76px;
    }
    .mb-m-76 {
        margin-bottom: 76px;
    }
    .ml-m-76 {
        margin-left: 76px;
    }
    .m-m-76 {
        margin: 76px;
    }
    .mh-m-76 {
        margin-left: 76px;
        margin-right: 76px;
    }
    .mv-m-76 {
        margin-top: 76px;
        margin-bottom: 76px;
    }
    .mt-m-78 {
        margin-top: 78px;
    }
    .mr-m-78 {
        margin-right: 78px;
    }
    .mb-m-78 {
        margin-bottom: 78px;
    }
    .ml-m-78 {
        margin-left: 78px;
    }
    .m-m-78 {
        margin: 78px;
    }
    .mh-m-78 {
        margin-left: 78px;
        margin-right: 78px;
    }
    .mv-m-78 {
        margin-top: 78px;
        margin-bottom: 78px;
    }
    .mt-m-80 {
        margin-top: 80px;
    }
    .mr-m-80 {
        margin-right: 80px;
    }
    .mb-m-80 {
        margin-bottom: 80px;
    }
    .ml-m-80 {
        margin-left: 80px;
    }
    .m-m-80 {
        margin: 80px;
    }
    .mh-m-80 {
        margin-left: 80px;
        margin-right: 80px;
    }
    .mv-m-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .mt-m-82 {
        margin-top: 82px;
    }
    .mr-m-82 {
        margin-right: 82px;
    }
    .mb-m-82 {
        margin-bottom: 82px;
    }
    .ml-m-82 {
        margin-left: 82px;
    }
    .m-m-82 {
        margin: 82px;
    }
    .mh-m-82 {
        margin-left: 82px;
        margin-right: 82px;
    }
    .mv-m-82 {
        margin-top: 82px;
        margin-bottom: 82px;
    }
    .mt-m-84 {
        margin-top: 84px;
    }
    .mr-m-84 {
        margin-right: 84px;
    }
    .mb-m-84 {
        margin-bottom: 84px;
    }
    .ml-m-84 {
        margin-left: 84px;
    }
    .m-m-84 {
        margin: 84px;
    }
    .mh-m-84 {
        margin-left: 84px;
        margin-right: 84px;
    }
    .mv-m-84 {
        margin-top: 84px;
        margin-bottom: 84px;
    }
    .mt-m-86 {
        margin-top: 86px;
    }
    .mr-m-86 {
        margin-right: 86px;
    }
    .mb-m-86 {
        margin-bottom: 86px;
    }
    .ml-m-86 {
        margin-left: 86px;
    }
    .m-m-86 {
        margin: 86px;
    }
    .mh-m-86 {
        margin-left: 86px;
        margin-right: 86px;
    }
    .mv-m-86 {
        margin-top: 86px;
        margin-bottom: 86px;
    }
    .mt-m-88 {
        margin-top: 88px;
    }
    .mr-m-88 {
        margin-right: 88px;
    }
    .mb-m-88 {
        margin-bottom: 88px;
    }
    .ml-m-88 {
        margin-left: 88px;
    }
    .m-m-88 {
        margin: 88px;
    }
    .mh-m-88 {
        margin-left: 88px;
        margin-right: 88px;
    }
    .mv-m-88 {
        margin-top: 88px;
        margin-bottom: 88px;
    }
    .mt-m-90 {
        margin-top: 90px;
    }
    .mr-m-90 {
        margin-right: 90px;
    }
    .mb-m-90 {
        margin-bottom: 90px;
    }
    .ml-m-90 {
        margin-left: 90px;
    }
    .m-m-90 {
        margin: 90px;
    }
    .mh-m-90 {
        margin-left: 90px;
        margin-right: 90px;
    }
    .mv-m-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }
    .mt-m-92 {
        margin-top: 92px;
    }
    .mr-m-92 {
        margin-right: 92px;
    }
    .mb-m-92 {
        margin-bottom: 92px;
    }
    .ml-m-92 {
        margin-left: 92px;
    }
    .m-m-92 {
        margin: 92px;
    }
    .mh-m-92 {
        margin-left: 92px;
        margin-right: 92px;
    }
    .mv-m-92 {
        margin-top: 92px;
        margin-bottom: 92px;
    }
    .mt-m-94 {
        margin-top: 94px;
    }
    .mr-m-94 {
        margin-right: 94px;
    }
    .mb-m-94 {
        margin-bottom: 94px;
    }
    .ml-m-94 {
        margin-left: 94px;
    }
    .m-m-94 {
        margin: 94px;
    }
    .mh-m-94 {
        margin-left: 94px;
        margin-right: 94px;
    }
    .mv-m-94 {
        margin-top: 94px;
        margin-bottom: 94px;
    }
    .mt-m-96 {
        margin-top: 96px;
    }
    .mr-m-96 {
        margin-right: 96px;
    }
    .mb-m-96 {
        margin-bottom: 96px;
    }
    .ml-m-96 {
        margin-left: 96px;
    }
    .m-m-96 {
        margin: 96px;
    }
    .mh-m-96 {
        margin-left: 96px;
        margin-right: 96px;
    }
    .mv-m-96 {
        margin-top: 96px;
        margin-bottom: 96px;
    }
    .mt-m-98 {
        margin-top: 98px;
    }
    .mr-m-98 {
        margin-right: 98px;
    }
    .mb-m-98 {
        margin-bottom: 98px;
    }
    .ml-m-98 {
        margin-left: 98px;
    }
    .m-m-98 {
        margin: 98px;
    }
    .mh-m-98 {
        margin-left: 98px;
        margin-right: 98px;
    }
    .mv-m-98 {
        margin-top: 98px;
        margin-bottom: 98px;
    }
    .mt-m-100 {
        margin-top: 100px;
    }
    .mr-m-100 {
        margin-right: 100px;
    }
    .mb-m-100 {
        margin-bottom: 100px;
    }
    .ml-m-100 {
        margin-left: 100px;
    }
    .m-m-100 {
        margin: 100px;
    }
    .mh-m-100 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .mv-m-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .mt-m-102 {
        margin-top: 102px;
    }
    .mr-m-102 {
        margin-right: 102px;
    }
    .mb-m-102 {
        margin-bottom: 102px;
    }
    .ml-m-102 {
        margin-left: 102px;
    }
    .m-m-102 {
        margin: 102px;
    }
    .mh-m-102 {
        margin-left: 102px;
        margin-right: 102px;
    }
    .mv-m-102 {
        margin-top: 102px;
        margin-bottom: 102px;
    }
    .mt-m-104 {
        margin-top: 104px;
    }
    .mr-m-104 {
        margin-right: 104px;
    }
    .mb-m-104 {
        margin-bottom: 104px;
    }
    .ml-m-104 {
        margin-left: 104px;
    }
    .m-m-104 {
        margin: 104px;
    }
    .mh-m-104 {
        margin-left: 104px;
        margin-right: 104px;
    }
    .mv-m-104 {
        margin-top: 104px;
        margin-bottom: 104px;
    }
    .mt-m-106 {
        margin-top: 106px;
    }
    .mr-m-106 {
        margin-right: 106px;
    }
    .mb-m-106 {
        margin-bottom: 106px;
    }
    .ml-m-106 {
        margin-left: 106px;
    }
    .m-m-106 {
        margin: 106px;
    }
    .mh-m-106 {
        margin-left: 106px;
        margin-right: 106px;
    }
    .mv-m-106 {
        margin-top: 106px;
        margin-bottom: 106px;
    }
    .mt-m-108 {
        margin-top: 108px;
    }
    .mr-m-108 {
        margin-right: 108px;
    }
    .mb-m-108 {
        margin-bottom: 108px;
    }
    .ml-m-108 {
        margin-left: 108px;
    }
    .m-m-108 {
        margin: 108px;
    }
    .mh-m-108 {
        margin-left: 108px;
        margin-right: 108px;
    }
    .mv-m-108 {
        margin-top: 108px;
        margin-bottom: 108px;
    }
    .mt-m-110 {
        margin-top: 110px;
    }
    .mr-m-110 {
        margin-right: 110px;
    }
    .mb-m-110 {
        margin-bottom: 110px;
    }
    .ml-m-110 {
        margin-left: 110px;
    }
    .m-m-110 {
        margin: 110px;
    }
    .mh-m-110 {
        margin-left: 110px;
        margin-right: 110px;
    }
    .mv-m-110 {
        margin-top: 110px;
        margin-bottom: 110px;
    }
    .mt-m-112 {
        margin-top: 112px;
    }
    .mr-m-112 {
        margin-right: 112px;
    }
    .mb-m-112 {
        margin-bottom: 112px;
    }
    .ml-m-112 {
        margin-left: 112px;
    }
    .m-m-112 {
        margin: 112px;
    }
    .mh-m-112 {
        margin-left: 112px;
        margin-right: 112px;
    }
    .mv-m-112 {
        margin-top: 112px;
        margin-bottom: 112px;
    }
    .mt-m-114 {
        margin-top: 114px;
    }
    .mr-m-114 {
        margin-right: 114px;
    }
    .mb-m-114 {
        margin-bottom: 114px;
    }
    .ml-m-114 {
        margin-left: 114px;
    }
    .m-m-114 {
        margin: 114px;
    }
    .mh-m-114 {
        margin-left: 114px;
        margin-right: 114px;
    }
    .mv-m-114 {
        margin-top: 114px;
        margin-bottom: 114px;
    }
    .mt-m-116 {
        margin-top: 116px;
    }
    .mr-m-116 {
        margin-right: 116px;
    }
    .mb-m-116 {
        margin-bottom: 116px;
    }
    .ml-m-116 {
        margin-left: 116px;
    }
    .m-m-116 {
        margin: 116px;
    }
    .mh-m-116 {
        margin-left: 116px;
        margin-right: 116px;
    }
    .mv-m-116 {
        margin-top: 116px;
        margin-bottom: 116px;
    }
    .mt-m-118 {
        margin-top: 118px;
    }
    .mr-m-118 {
        margin-right: 118px;
    }
    .mb-m-118 {
        margin-bottom: 118px;
    }
    .ml-m-118 {
        margin-left: 118px;
    }
    .m-m-118 {
        margin: 118px;
    }
    .mh-m-118 {
        margin-left: 118px;
        margin-right: 118px;
    }
    .mv-m-118 {
        margin-top: 118px;
        margin-bottom: 118px;
    }
    .mt-m-120 {
        margin-top: 120px;
    }
    .mr-m-120 {
        margin-right: 120px;
    }
    .mb-m-120 {
        margin-bottom: 120px;
    }
    .ml-m-120 {
        margin-left: 120px;
    }
    .m-m-120 {
        margin: 120px;
    }
    .mh-m-120 {
        margin-left: 120px;
        margin-right: 120px;
    }
    .mv-m-120 {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .mt-m-122 {
        margin-top: 122px;
    }
    .mr-m-122 {
        margin-right: 122px;
    }
    .mb-m-122 {
        margin-bottom: 122px;
    }
    .ml-m-122 {
        margin-left: 122px;
    }
    .m-m-122 {
        margin: 122px;
    }
    .mh-m-122 {
        margin-left: 122px;
        margin-right: 122px;
    }
    .mv-m-122 {
        margin-top: 122px;
        margin-bottom: 122px;
    }
    .mt-m-124 {
        margin-top: 124px;
    }
    .mr-m-124 {
        margin-right: 124px;
    }
    .mb-m-124 {
        margin-bottom: 124px;
    }
    .ml-m-124 {
        margin-left: 124px;
    }
    .m-m-124 {
        margin: 124px;
    }
    .mh-m-124 {
        margin-left: 124px;
        margin-right: 124px;
    }
    .mv-m-124 {
        margin-top: 124px;
        margin-bottom: 124px;
    }
    .mt-m-126 {
        margin-top: 126px;
    }
    .mr-m-126 {
        margin-right: 126px;
    }
    .mb-m-126 {
        margin-bottom: 126px;
    }
    .ml-m-126 {
        margin-left: 126px;
    }
    .m-m-126 {
        margin: 126px;
    }
    .mh-m-126 {
        margin-left: 126px;
        margin-right: 126px;
    }
    .mv-m-126 {
        margin-top: 126px;
        margin-bottom: 126px;
    }
    .mt-m-128 {
        margin-top: 128px;
    }
    .mr-m-128 {
        margin-right: 128px;
    }
    .mb-m-128 {
        margin-bottom: 128px;
    }
    .ml-m-128 {
        margin-left: 128px;
    }
    .m-m-128 {
        margin: 128px;
    }
    .mh-m-128 {
        margin-left: 128px;
        margin-right: 128px;
    }
    .mv-m-128 {
        margin-top: 128px;
        margin-bottom: 128px;
    }
    .mt-m-130 {
        margin-top: 130px;
    }
    .mr-m-130 {
        margin-right: 130px;
    }
    .mb-m-130 {
        margin-bottom: 130px;
    }
    .ml-m-130 {
        margin-left: 130px;
    }
    .m-m-130 {
        margin: 130px;
    }
    .mh-m-130 {
        margin-left: 130px;
        margin-right: 130px;
    }
    .mv-m-130 {
        margin-top: 130px;
        margin-bottom: 130px;
    }
    .mt-m-132 {
        margin-top: 132px;
    }
    .mr-m-132 {
        margin-right: 132px;
    }
    .mb-m-132 {
        margin-bottom: 132px;
    }
    .ml-m-132 {
        margin-left: 132px;
    }
    .m-m-132 {
        margin: 132px;
    }
    .mh-m-132 {
        margin-left: 132px;
        margin-right: 132px;
    }
    .mv-m-132 {
        margin-top: 132px;
        margin-bottom: 132px;
    }
    .mt-m-134 {
        margin-top: 134px;
    }
    .mr-m-134 {
        margin-right: 134px;
    }
    .mb-m-134 {
        margin-bottom: 134px;
    }
    .ml-m-134 {
        margin-left: 134px;
    }
    .m-m-134 {
        margin: 134px;
    }
    .mh-m-134 {
        margin-left: 134px;
        margin-right: 134px;
    }
    .mv-m-134 {
        margin-top: 134px;
        margin-bottom: 134px;
    }
    .mt-m-136 {
        margin-top: 136px;
    }
    .mr-m-136 {
        margin-right: 136px;
    }
    .mb-m-136 {
        margin-bottom: 136px;
    }
    .ml-m-136 {
        margin-left: 136px;
    }
    .m-m-136 {
        margin: 136px;
    }
    .mh-m-136 {
        margin-left: 136px;
        margin-right: 136px;
    }
    .mv-m-136 {
        margin-top: 136px;
        margin-bottom: 136px;
    }
    .mt-m-138 {
        margin-top: 138px;
    }
    .mr-m-138 {
        margin-right: 138px;
    }
    .mb-m-138 {
        margin-bottom: 138px;
    }
    .ml-m-138 {
        margin-left: 138px;
    }
    .m-m-138 {
        margin: 138px;
    }
    .mh-m-138 {
        margin-left: 138px;
        margin-right: 138px;
    }
    .mv-m-138 {
        margin-top: 138px;
        margin-bottom: 138px;
    }
    .mt-m-140 {
        margin-top: 140px;
    }
    .mr-m-140 {
        margin-right: 140px;
    }
    .mb-m-140 {
        margin-bottom: 140px;
    }
    .ml-m-140 {
        margin-left: 140px;
    }
    .m-m-140 {
        margin: 140px;
    }
    .mh-m-140 {
        margin-left: 140px;
        margin-right: 140px;
    }
    .mv-m-140 {
        margin-top: 140px;
        margin-bottom: 140px;
    }
    .m-m-0 {
        margin: 0;
    }
    .mt-m-0 {
        margin-top: 0;
    }
    .mr-m-0 {
        margin-right: 0;
    }
    .mb-m-0 {
        margin-bottom: 0;
    }
    .ml-m-0 {
        margin-left: 0;
    }
    .mv-m-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pt-m-2 {
        padding-top: 2px;
    }
    .pr-m-2 {
        padding-right: 2px;
    }
    .pb-m-2 {
        padding-bottom: 2px;
    }
    .pl-m-2 {
        padding-left: 2px;
    }
    .p-m-2 {
        padding: 2px;
    }
    .ph-m-2 {
        padding-left: 2px;
        padding-right: 2px;
    }
    .pv-m-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .pt-m-4 {
        padding-top: 4px;
    }
    .pr-m-4 {
        padding-right: 4px;
    }
    .pb-m-4 {
        padding-bottom: 4px;
    }
    .pl-m-4 {
        padding-left: 4px;
    }
    .p-m-4 {
        padding: 4px;
    }
    .ph-m-4 {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pv-m-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .pt-m-6 {
        padding-top: 6px;
    }
    .pr-m-6 {
        padding-right: 6px;
    }
    .pb-m-6 {
        padding-bottom: 6px;
    }
    .pl-m-6 {
        padding-left: 6px;
    }
    .p-m-6 {
        padding: 6px;
    }
    .ph-m-6 {
        padding-left: 6px;
        padding-right: 6px;
    }
    .pv-m-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .pt-m-8 {
        padding-top: 8px;
    }
    .pr-m-8 {
        padding-right: 8px;
    }
    .pb-m-8 {
        padding-bottom: 8px;
    }
    .pl-m-8 {
        padding-left: 8px;
    }
    .p-m-8 {
        padding: 8px;
    }
    .ph-m-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .pv-m-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .pt-m-10 {
        padding-top: 10px;
    }
    .pr-m-10 {
        padding-right: 10px;
    }
    .pb-m-10 {
        padding-bottom: 10px;
    }
    .pl-m-10 {
        padding-left: 10px;
    }
    .p-m-10 {
        padding: 10px;
    }
    .ph-m-10 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pv-m-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .pt-m-12 {
        padding-top: 12px;
    }
    .pr-m-12 {
        padding-right: 12px;
    }
    .pb-m-12 {
        padding-bottom: 12px;
    }
    .pl-m-12 {
        padding-left: 12px;
    }
    .p-m-12 {
        padding: 12px;
    }
    .ph-m-12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .pv-m-12 {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .pt-m-14 {
        padding-top: 14px;
    }
    .pr-m-14 {
        padding-right: 14px;
    }
    .pb-m-14 {
        padding-bottom: 14px;
    }
    .pl-m-14 {
        padding-left: 14px;
    }
    .p-m-14 {
        padding: 14px;
    }
    .ph-m-14 {
        padding-left: 14px;
        padding-right: 14px;
    }
    .pv-m-14 {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .pt-m-16 {
        padding-top: 16px;
    }
    .pr-m-16 {
        padding-right: 16px;
    }
    .pb-m-16 {
        padding-bottom: 16px;
    }
    .pl-m-16 {
        padding-left: 16px;
    }
    .p-m-16 {
        padding: 16px;
    }
    .ph-m-16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .pv-m-16 {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .pt-m-18 {
        padding-top: 18px;
    }
    .pr-m-18 {
        padding-right: 18px;
    }
    .pb-m-18 {
        padding-bottom: 18px;
    }
    .pl-m-18 {
        padding-left: 18px;
    }
    .p-m-18 {
        padding: 18px;
    }
    .ph-m-18 {
        padding-left: 18px;
        padding-right: 18px;
    }
    .pv-m-18 {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .pt-m-20 {
        padding-top: 20px;
    }
    .pr-m-20 {
        padding-right: 20px;
    }
    .pb-m-20 {
        padding-bottom: 20px;
    }
    .pl-m-20 {
        padding-left: 20px;
    }
    .p-m-20 {
        padding: 20px;
    }
    .ph-m-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pv-m-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .pt-m-22 {
        padding-top: 22px;
    }
    .pr-m-22 {
        padding-right: 22px;
    }
    .pb-m-22 {
        padding-bottom: 22px;
    }
    .pl-m-22 {
        padding-left: 22px;
    }
    .p-m-22 {
        padding: 22px;
    }
    .ph-m-22 {
        padding-left: 22px;
        padding-right: 22px;
    }
    .pv-m-22 {
        padding-top: 22px;
        padding-bottom: 22px;
    }
    .pt-m-24 {
        padding-top: 24px;
    }
    .pr-m-24 {
        padding-right: 24px;
    }
    .pb-m-24 {
        padding-bottom: 24px;
    }
    .pl-m-24 {
        padding-left: 24px;
    }
    .p-m-24 {
        padding: 24px;
    }
    .ph-m-24 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pv-m-24 {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .pt-m-26 {
        padding-top: 26px;
    }
    .pr-m-26 {
        padding-right: 26px;
    }
    .pb-m-26 {
        padding-bottom: 26px;
    }
    .pl-m-26 {
        padding-left: 26px;
    }
    .p-m-26 {
        padding: 26px;
    }
    .ph-m-26 {
        padding-left: 26px;
        padding-right: 26px;
    }
    .pv-m-26 {
        padding-top: 26px;
        padding-bottom: 26px;
    }
    .pt-m-28 {
        padding-top: 28px;
    }
    .pr-m-28 {
        padding-right: 28px;
    }
    .pb-m-28 {
        padding-bottom: 28px;
    }
    .pl-m-28 {
        padding-left: 28px;
    }
    .p-m-28 {
        padding: 28px;
    }
    .ph-m-28 {
        padding-left: 28px;
        padding-right: 28px;
    }
    .pv-m-28 {
        padding-top: 28px;
        padding-bottom: 28px;
    }
    .pt-m-30 {
        padding-top: 30px;
    }
    .pr-m-30 {
        padding-right: 30px;
    }
    .pb-m-30 {
        padding-bottom: 30px;
    }
    .pl-m-30 {
        padding-left: 30px;
    }
    .p-m-30 {
        padding: 30px;
    }
    .ph-m-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pv-m-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pt-m-32 {
        padding-top: 32px;
    }
    .pr-m-32 {
        padding-right: 32px;
    }
    .pb-m-32 {
        padding-bottom: 32px;
    }
    .pl-m-32 {
        padding-left: 32px;
    }
    .p-m-32 {
        padding: 32px;
    }
    .ph-m-32 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .pv-m-32 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .pt-m-34 {
        padding-top: 34px;
    }
    .pr-m-34 {
        padding-right: 34px;
    }
    .pb-m-34 {
        padding-bottom: 34px;
    }
    .pl-m-34 {
        padding-left: 34px;
    }
    .p-m-34 {
        padding: 34px;
    }
    .ph-m-34 {
        padding-left: 34px;
        padding-right: 34px;
    }
    .pv-m-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .pt-m-36 {
        padding-top: 36px;
    }
    .pr-m-36 {
        padding-right: 36px;
    }
    .pb-m-36 {
        padding-bottom: 36px;
    }
    .pl-m-36 {
        padding-left: 36px;
    }
    .p-m-36 {
        padding: 36px;
    }
    .ph-m-36 {
        padding-left: 36px;
        padding-right: 36px;
    }
    .pv-m-36 {
        padding-top: 36px;
        padding-bottom: 36px;
    }
    .pt-m-38 {
        padding-top: 38px;
    }
    .pr-m-38 {
        padding-right: 38px;
    }
    .pb-m-38 {
        padding-bottom: 38px;
    }
    .pl-m-38 {
        padding-left: 38px;
    }
    .p-m-38 {
        padding: 38px;
    }
    .ph-m-38 {
        padding-left: 38px;
        padding-right: 38px;
    }
    .pv-m-38 {
        padding-top: 38px;
        padding-bottom: 38px;
    }
    .pt-m-40 {
        padding-top: 40px;
    }
    .pr-m-40 {
        padding-right: 40px;
    }
    .pb-m-40 {
        padding-bottom: 40px;
    }
    .pl-m-40 {
        padding-left: 40px;
    }
    .p-m-40 {
        padding: 40px;
    }
    .ph-m-40 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pv-m-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .pt-m-42 {
        padding-top: 42px;
    }
    .pr-m-42 {
        padding-right: 42px;
    }
    .pb-m-42 {
        padding-bottom: 42px;
    }
    .pl-m-42 {
        padding-left: 42px;
    }
    .p-m-42 {
        padding: 42px;
    }
    .ph-m-42 {
        padding-left: 42px;
        padding-right: 42px;
    }
    .pv-m-42 {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    .pt-m-44 {
        padding-top: 44px;
    }
    .pr-m-44 {
        padding-right: 44px;
    }
    .pb-m-44 {
        padding-bottom: 44px;
    }
    .pl-m-44 {
        padding-left: 44px;
    }
    .p-m-44 {
        padding: 44px;
    }
    .ph-m-44 {
        padding-left: 44px;
        padding-right: 44px;
    }
    .pv-m-44 {
        padding-top: 44px;
        padding-bottom: 44px;
    }
    .pt-m-46 {
        padding-top: 46px;
    }
    .pr-m-46 {
        padding-right: 46px;
    }
    .pb-m-46 {
        padding-bottom: 46px;
    }
    .pl-m-46 {
        padding-left: 46px;
    }
    .p-m-46 {
        padding: 46px;
    }
    .ph-m-46 {
        padding-left: 46px;
        padding-right: 46px;
    }
    .pv-m-46 {
        padding-top: 46px;
        padding-bottom: 46px;
    }
    .pt-m-48 {
        padding-top: 48px;
    }
    .pr-m-48 {
        padding-right: 48px;
    }
    .pb-m-48 {
        padding-bottom: 48px;
    }
    .pl-m-48 {
        padding-left: 48px;
    }
    .p-m-48 {
        padding: 48px;
    }
    .ph-m-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .pv-m-48 {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .pt-m-50 {
        padding-top: 50px;
    }
    .pr-m-50 {
        padding-right: 50px;
    }
    .pb-m-50 {
        padding-bottom: 50px;
    }
    .pl-m-50 {
        padding-left: 50px;
    }
    .p-m-50 {
        padding: 50px;
    }
    .ph-m-50 {
        padding-left: 50px;
        padding-right: 50px;
    }
    .pv-m-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-m-52 {
        padding-top: 52px;
    }
    .pr-m-52 {
        padding-right: 52px;
    }
    .pb-m-52 {
        padding-bottom: 52px;
    }
    .pl-m-52 {
        padding-left: 52px;
    }
    .p-m-52 {
        padding: 52px;
    }
    .ph-m-52 {
        padding-left: 52px;
        padding-right: 52px;
    }
    .pv-m-52 {
        padding-top: 52px;
        padding-bottom: 52px;
    }
    .pt-m-54 {
        padding-top: 54px;
    }
    .pr-m-54 {
        padding-right: 54px;
    }
    .pb-m-54 {
        padding-bottom: 54px;
    }
    .pl-m-54 {
        padding-left: 54px;
    }
    .p-m-54 {
        padding: 54px;
    }
    .ph-m-54 {
        padding-left: 54px;
        padding-right: 54px;
    }
    .pv-m-54 {
        padding-top: 54px;
        padding-bottom: 54px;
    }
    .pt-m-56 {
        padding-top: 56px;
    }
    .pr-m-56 {
        padding-right: 56px;
    }
    .pb-m-56 {
        padding-bottom: 56px;
    }
    .pl-m-56 {
        padding-left: 56px;
    }
    .p-m-56 {
        padding: 56px;
    }
    .ph-m-56 {
        padding-left: 56px;
        padding-right: 56px;
    }
    .pv-m-56 {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .pt-m-58 {
        padding-top: 58px;
    }
    .pr-m-58 {
        padding-right: 58px;
    }
    .pb-m-58 {
        padding-bottom: 58px;
    }
    .pl-m-58 {
        padding-left: 58px;
    }
    .p-m-58 {
        padding: 58px;
    }
    .ph-m-58 {
        padding-left: 58px;
        padding-right: 58px;
    }
    .pv-m-58 {
        padding-top: 58px;
        padding-bottom: 58px;
    }
    .pt-m-60 {
        padding-top: 60px;
    }
    .pr-m-60 {
        padding-right: 60px;
    }
    .pb-m-60 {
        padding-bottom: 60px;
    }
    .pl-m-60 {
        padding-left: 60px;
    }
    .p-m-60 {
        padding: 60px;
    }
    .ph-m-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .pv-m-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .pt-m-62 {
        padding-top: 62px;
    }
    .pr-m-62 {
        padding-right: 62px;
    }
    .pb-m-62 {
        padding-bottom: 62px;
    }
    .pl-m-62 {
        padding-left: 62px;
    }
    .p-m-62 {
        padding: 62px;
    }
    .ph-m-62 {
        padding-left: 62px;
        padding-right: 62px;
    }
    .pv-m-62 {
        padding-top: 62px;
        padding-bottom: 62px;
    }
    .pt-m-64 {
        padding-top: 64px;
    }
    .pr-m-64 {
        padding-right: 64px;
    }
    .pb-m-64 {
        padding-bottom: 64px;
    }
    .pl-m-64 {
        padding-left: 64px;
    }
    .p-m-64 {
        padding: 64px;
    }
    .ph-m-64 {
        padding-left: 64px;
        padding-right: 64px;
    }
    .pv-m-64 {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .pt-m-66 {
        padding-top: 66px;
    }
    .pr-m-66 {
        padding-right: 66px;
    }
    .pb-m-66 {
        padding-bottom: 66px;
    }
    .pl-m-66 {
        padding-left: 66px;
    }
    .p-m-66 {
        padding: 66px;
    }
    .ph-m-66 {
        padding-left: 66px;
        padding-right: 66px;
    }
    .pv-m-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .pt-m-68 {
        padding-top: 68px;
    }
    .pr-m-68 {
        padding-right: 68px;
    }
    .pb-m-68 {
        padding-bottom: 68px;
    }
    .pl-m-68 {
        padding-left: 68px;
    }
    .p-m-68 {
        padding: 68px;
    }
    .ph-m-68 {
        padding-left: 68px;
        padding-right: 68px;
    }
    .pv-m-68 {
        padding-top: 68px;
        padding-bottom: 68px;
    }
    .pt-m-70 {
        padding-top: 70px;
    }
    .pr-m-70 {
        padding-right: 70px;
    }
    .pb-m-70 {
        padding-bottom: 70px;
    }
    .pl-m-70 {
        padding-left: 70px;
    }
    .p-m-70 {
        padding: 70px;
    }
    .ph-m-70 {
        padding-left: 70px;
        padding-right: 70px;
    }
    .pv-m-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-m-72 {
        padding-top: 72px;
    }
    .pr-m-72 {
        padding-right: 72px;
    }
    .pb-m-72 {
        padding-bottom: 72px;
    }
    .pl-m-72 {
        padding-left: 72px;
    }
    .p-m-72 {
        padding: 72px;
    }
    .ph-m-72 {
        padding-left: 72px;
        padding-right: 72px;
    }
    .pv-m-72 {
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .pt-m-74 {
        padding-top: 74px;
    }
    .pr-m-74 {
        padding-right: 74px;
    }
    .pb-m-74 {
        padding-bottom: 74px;
    }
    .pl-m-74 {
        padding-left: 74px;
    }
    .p-m-74 {
        padding: 74px;
    }
    .ph-m-74 {
        padding-left: 74px;
        padding-right: 74px;
    }
    .pv-m-74 {
        padding-top: 74px;
        padding-bottom: 74px;
    }
    .pt-m-76 {
        padding-top: 76px;
    }
    .pr-m-76 {
        padding-right: 76px;
    }
    .pb-m-76 {
        padding-bottom: 76px;
    }
    .pl-m-76 {
        padding-left: 76px;
    }
    .p-m-76 {
        padding: 76px;
    }
    .ph-m-76 {
        padding-left: 76px;
        padding-right: 76px;
    }
    .pv-m-76 {
        padding-top: 76px;
        padding-bottom: 76px;
    }
    .pt-m-78 {
        padding-top: 78px;
    }
    .pr-m-78 {
        padding-right: 78px;
    }
    .pb-m-78 {
        padding-bottom: 78px;
    }
    .pl-m-78 {
        padding-left: 78px;
    }
    .p-m-78 {
        padding: 78px;
    }
    .ph-m-78 {
        padding-left: 78px;
        padding-right: 78px;
    }
    .pv-m-78 {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    .pt-m-80 {
        padding-top: 80px;
    }
    .pr-m-80 {
        padding-right: 80px;
    }
    .pb-m-80 {
        padding-bottom: 80px;
    }
    .pl-m-80 {
        padding-left: 80px;
    }
    .p-m-80 {
        padding: 80px;
    }
    .ph-m-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .pv-m-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-m-82 {
        padding-top: 82px;
    }
    .pr-m-82 {
        padding-right: 82px;
    }
    .pb-m-82 {
        padding-bottom: 82px;
    }
    .pl-m-82 {
        padding-left: 82px;
    }
    .p-m-82 {
        padding: 82px;
    }
    .ph-m-82 {
        padding-left: 82px;
        padding-right: 82px;
    }
    .pv-m-82 {
        padding-top: 82px;
        padding-bottom: 82px;
    }
    .pt-m-84 {
        padding-top: 84px;
    }
    .pr-m-84 {
        padding-right: 84px;
    }
    .pb-m-84 {
        padding-bottom: 84px;
    }
    .pl-m-84 {
        padding-left: 84px;
    }
    .p-m-84 {
        padding: 84px;
    }
    .ph-m-84 {
        padding-left: 84px;
        padding-right: 84px;
    }
    .pv-m-84 {
        padding-top: 84px;
        padding-bottom: 84px;
    }
    .pt-m-86 {
        padding-top: 86px;
    }
    .pr-m-86 {
        padding-right: 86px;
    }
    .pb-m-86 {
        padding-bottom: 86px;
    }
    .pl-m-86 {
        padding-left: 86px;
    }
    .p-m-86 {
        padding: 86px;
    }
    .ph-m-86 {
        padding-left: 86px;
        padding-right: 86px;
    }
    .pv-m-86 {
        padding-top: 86px;
        padding-bottom: 86px;
    }
    .pt-m-88 {
        padding-top: 88px;
    }
    .pr-m-88 {
        padding-right: 88px;
    }
    .pb-m-88 {
        padding-bottom: 88px;
    }
    .pl-m-88 {
        padding-left: 88px;
    }
    .p-m-88 {
        padding: 88px;
    }
    .ph-m-88 {
        padding-left: 88px;
        padding-right: 88px;
    }
    .pv-m-88 {
        padding-top: 88px;
        padding-bottom: 88px;
    }
    .pt-m-90 {
        padding-top: 90px;
    }
    .pr-m-90 {
        padding-right: 90px;
    }
    .pb-m-90 {
        padding-bottom: 90px;
    }
    .pl-m-90 {
        padding-left: 90px;
    }
    .p-m-90 {
        padding: 90px;
    }
    .ph-m-90 {
        padding-left: 90px;
        padding-right: 90px;
    }
    .pv-m-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .pt-m-92 {
        padding-top: 92px;
    }
    .pr-m-92 {
        padding-right: 92px;
    }
    .pb-m-92 {
        padding-bottom: 92px;
    }
    .pl-m-92 {
        padding-left: 92px;
    }
    .p-m-92 {
        padding: 92px;
    }
    .ph-m-92 {
        padding-left: 92px;
        padding-right: 92px;
    }
    .pv-m-92 {
        padding-top: 92px;
        padding-bottom: 92px;
    }
    .pt-m-94 {
        padding-top: 94px;
    }
    .pr-m-94 {
        padding-right: 94px;
    }
    .pb-m-94 {
        padding-bottom: 94px;
    }
    .pl-m-94 {
        padding-left: 94px;
    }
    .p-m-94 {
        padding: 94px;
    }
    .ph-m-94 {
        padding-left: 94px;
        padding-right: 94px;
    }
    .pv-m-94 {
        padding-top: 94px;
        padding-bottom: 94px;
    }
    .pt-m-96 {
        padding-top: 96px;
    }
    .pr-m-96 {
        padding-right: 96px;
    }
    .pb-m-96 {
        padding-bottom: 96px;
    }
    .pl-m-96 {
        padding-left: 96px;
    }
    .p-m-96 {
        padding: 96px;
    }
    .ph-m-96 {
        padding-left: 96px;
        padding-right: 96px;
    }
    .pv-m-96 {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    .pt-m-98 {
        padding-top: 98px;
    }
    .pr-m-98 {
        padding-right: 98px;
    }
    .pb-m-98 {
        padding-bottom: 98px;
    }
    .pl-m-98 {
        padding-left: 98px;
    }
    .p-m-98 {
        padding: 98px;
    }
    .ph-m-98 {
        padding-left: 98px;
        padding-right: 98px;
    }
    .pv-m-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .pt-m-100 {
        padding-top: 100px;
    }
    .pr-m-100 {
        padding-right: 100px;
    }
    .pb-m-100 {
        padding-bottom: 100px;
    }
    .pl-m-100 {
        padding-left: 100px;
    }
    .p-m-100 {
        padding: 100px;
    }
    .ph-m-100 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .pv-m-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .pt-m-102 {
        padding-top: 102px;
    }
    .pr-m-102 {
        padding-right: 102px;
    }
    .pb-m-102 {
        padding-bottom: 102px;
    }
    .pl-m-102 {
        padding-left: 102px;
    }
    .p-m-102 {
        padding: 102px;
    }
    .ph-m-102 {
        padding-left: 102px;
        padding-right: 102px;
    }
    .pv-m-102 {
        padding-top: 102px;
        padding-bottom: 102px;
    }
    .pt-m-104 {
        padding-top: 104px;
    }
    .pr-m-104 {
        padding-right: 104px;
    }
    .pb-m-104 {
        padding-bottom: 104px;
    }
    .pl-m-104 {
        padding-left: 104px;
    }
    .p-m-104 {
        padding: 104px;
    }
    .ph-m-104 {
        padding-left: 104px;
        padding-right: 104px;
    }
    .pv-m-104 {
        padding-top: 104px;
        padding-bottom: 104px;
    }
    .pt-m-106 {
        padding-top: 106px;
    }
    .pr-m-106 {
        padding-right: 106px;
    }
    .pb-m-106 {
        padding-bottom: 106px;
    }
    .pl-m-106 {
        padding-left: 106px;
    }
    .p-m-106 {
        padding: 106px;
    }
    .ph-m-106 {
        padding-left: 106px;
        padding-right: 106px;
    }
    .pv-m-106 {
        padding-top: 106px;
        padding-bottom: 106px;
    }
    .pt-m-108 {
        padding-top: 108px;
    }
    .pr-m-108 {
        padding-right: 108px;
    }
    .pb-m-108 {
        padding-bottom: 108px;
    }
    .pl-m-108 {
        padding-left: 108px;
    }
    .p-m-108 {
        padding: 108px;
    }
    .ph-m-108 {
        padding-left: 108px;
        padding-right: 108px;
    }
    .pv-m-108 {
        padding-top: 108px;
        padding-bottom: 108px;
    }
    .pt-m-110 {
        padding-top: 110px;
    }
    .pr-m-110 {
        padding-right: 110px;
    }
    .pb-m-110 {
        padding-bottom: 110px;
    }
    .pl-m-110 {
        padding-left: 110px;
    }
    .p-m-110 {
        padding: 110px;
    }
    .ph-m-110 {
        padding-left: 110px;
        padding-right: 110px;
    }
    .pv-m-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .pt-m-112 {
        padding-top: 112px;
    }
    .pr-m-112 {
        padding-right: 112px;
    }
    .pb-m-112 {
        padding-bottom: 112px;
    }
    .pl-m-112 {
        padding-left: 112px;
    }
    .p-m-112 {
        padding: 112px;
    }
    .ph-m-112 {
        padding-left: 112px;
        padding-right: 112px;
    }
    .pv-m-112 {
        padding-top: 112px;
        padding-bottom: 112px;
    }
    .pt-m-114 {
        padding-top: 114px;
    }
    .pr-m-114 {
        padding-right: 114px;
    }
    .pb-m-114 {
        padding-bottom: 114px;
    }
    .pl-m-114 {
        padding-left: 114px;
    }
    .p-m-114 {
        padding: 114px;
    }
    .ph-m-114 {
        padding-left: 114px;
        padding-right: 114px;
    }
    .pv-m-114 {
        padding-top: 114px;
        padding-bottom: 114px;
    }
    .pt-m-116 {
        padding-top: 116px;
    }
    .pr-m-116 {
        padding-right: 116px;
    }
    .pb-m-116 {
        padding-bottom: 116px;
    }
    .pl-m-116 {
        padding-left: 116px;
    }
    .p-m-116 {
        padding: 116px;
    }
    .ph-m-116 {
        padding-left: 116px;
        padding-right: 116px;
    }
    .pv-m-116 {
        padding-top: 116px;
        padding-bottom: 116px;
    }
    .pt-m-118 {
        padding-top: 118px;
    }
    .pr-m-118 {
        padding-right: 118px;
    }
    .pb-m-118 {
        padding-bottom: 118px;
    }
    .pl-m-118 {
        padding-left: 118px;
    }
    .p-m-118 {
        padding: 118px;
    }
    .ph-m-118 {
        padding-left: 118px;
        padding-right: 118px;
    }
    .pv-m-118 {
        padding-top: 118px;
        padding-bottom: 118px;
    }
    .pt-m-120 {
        padding-top: 120px;
    }
    .pr-m-120 {
        padding-right: 120px;
    }
    .pb-m-120 {
        padding-bottom: 120px;
    }
    .pl-m-120 {
        padding-left: 120px;
    }
    .p-m-120 {
        padding: 120px;
    }
    .ph-m-120 {
        padding-left: 120px;
        padding-right: 120px;
    }
    .pv-m-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .pt-m-122 {
        padding-top: 122px;
    }
    .pr-m-122 {
        padding-right: 122px;
    }
    .pb-m-122 {
        padding-bottom: 122px;
    }
    .pl-m-122 {
        padding-left: 122px;
    }
    .p-m-122 {
        padding: 122px;
    }
    .ph-m-122 {
        padding-left: 122px;
        padding-right: 122px;
    }
    .pv-m-122 {
        padding-top: 122px;
        padding-bottom: 122px;
    }
    .pt-m-124 {
        padding-top: 124px;
    }
    .pr-m-124 {
        padding-right: 124px;
    }
    .pb-m-124 {
        padding-bottom: 124px;
    }
    .pl-m-124 {
        padding-left: 124px;
    }
    .p-m-124 {
        padding: 124px;
    }
    .ph-m-124 {
        padding-left: 124px;
        padding-right: 124px;
    }
    .pv-m-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .pt-m-126 {
        padding-top: 126px;
    }
    .pr-m-126 {
        padding-right: 126px;
    }
    .pb-m-126 {
        padding-bottom: 126px;
    }
    .pl-m-126 {
        padding-left: 126px;
    }
    .p-m-126 {
        padding: 126px;
    }
    .ph-m-126 {
        padding-left: 126px;
        padding-right: 126px;
    }
    .pv-m-126 {
        padding-top: 126px;
        padding-bottom: 126px;
    }
    .pt-m-128 {
        padding-top: 128px;
    }
    .pr-m-128 {
        padding-right: 128px;
    }
    .pb-m-128 {
        padding-bottom: 128px;
    }
    .pl-m-128 {
        padding-left: 128px;
    }
    .p-m-128 {
        padding: 128px;
    }
    .ph-m-128 {
        padding-left: 128px;
        padding-right: 128px;
    }
    .pv-m-128 {
        padding-top: 128px;
        padding-bottom: 128px;
    }
    .pt-m-130 {
        padding-top: 130px;
    }
    .pr-m-130 {
        padding-right: 130px;
    }
    .pb-m-130 {
        padding-bottom: 130px;
    }
    .pl-m-130 {
        padding-left: 130px;
    }
    .p-m-130 {
        padding: 130px;
    }
    .ph-m-130 {
        padding-left: 130px;
        padding-right: 130px;
    }
    .pv-m-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .pt-m-132 {
        padding-top: 132px;
    }
    .pr-m-132 {
        padding-right: 132px;
    }
    .pb-m-132 {
        padding-bottom: 132px;
    }
    .pl-m-132 {
        padding-left: 132px;
    }
    .p-m-132 {
        padding: 132px;
    }
    .ph-m-132 {
        padding-left: 132px;
        padding-right: 132px;
    }
    .pv-m-132 {
        padding-top: 132px;
        padding-bottom: 132px;
    }
    .pt-m-134 {
        padding-top: 134px;
    }
    .pr-m-134 {
        padding-right: 134px;
    }
    .pb-m-134 {
        padding-bottom: 134px;
    }
    .pl-m-134 {
        padding-left: 134px;
    }
    .p-m-134 {
        padding: 134px;
    }
    .ph-m-134 {
        padding-left: 134px;
        padding-right: 134px;
    }
    .pv-m-134 {
        padding-top: 134px;
        padding-bottom: 134px;
    }
    .pt-m-136 {
        padding-top: 136px;
    }
    .pr-m-136 {
        padding-right: 136px;
    }
    .pb-m-136 {
        padding-bottom: 136px;
    }
    .pl-m-136 {
        padding-left: 136px;
    }
    .p-m-136 {
        padding: 136px;
    }
    .ph-m-136 {
        padding-left: 136px;
        padding-right: 136px;
    }
    .pv-m-136 {
        padding-top: 136px;
        padding-bottom: 136px;
    }
    .pt-m-138 {
        padding-top: 138px;
    }
    .pr-m-138 {
        padding-right: 138px;
    }
    .pb-m-138 {
        padding-bottom: 138px;
    }
    .pl-m-138 {
        padding-left: 138px;
    }
    .p-m-138 {
        padding: 138px;
    }
    .ph-m-138 {
        padding-left: 138px;
        padding-right: 138px;
    }
    .pv-m-138 {
        padding-top: 138px;
        padding-bottom: 138px;
    }
    .pt-m-140 {
        padding-top: 140px;
    }
    .pr-m-140 {
        padding-right: 140px;
    }
    .pb-m-140 {
        padding-bottom: 140px;
    }
    .pl-m-140 {
        padding-left: 140px;
    }
    .p-m-140 {
        padding: 140px;
    }
    .ph-m-140 {
        padding-left: 140px;
        padding-right: 140px;
    }
    .pv-m-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    .p-m-0 {
        padding: 0;
    }
    .pt-m-0 {
        padding-top: 0;
    }
    .pr-m-0 {
        padding-right: 0;
    }
    .pb-m-0 {
        padding-bottom: 0;
    }
    .pl-m-0 {
        padding-left: 0;
    }
    .pv-m-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .rGap-m-2 {
        row-gap: 2px;
    }
    .rGap-m-4 {
        row-gap: 4px;
    }
    .rGap-m-6 {
        row-gap: 6px;
    }
    .rGap-m-8 {
        row-gap: 8px;
    }
    .rGap-m-10 {
        row-gap: 10px;
    }
    .rGap-m-12 {
        row-gap: 12px;
    }
    .rGap-m-14 {
        row-gap: 14px;
    }
    .rGap-m-16 {
        row-gap: 16px;
    }
    .rGap-m-18 {
        row-gap: 18px;
    }
    .rGap-m-20 {
        row-gap: 20px;
    }
    .rGap-m-22 {
        row-gap: 22px;
    }
    .rGap-m-24 {
        row-gap: 24px;
    }
    .rGap-m-26 {
        row-gap: 26px;
    }
    .rGap-m-28 {
        row-gap: 28px;
    }
    .rGap-m-30 {
        row-gap: 30px;
    }
    .rGap-m-32 {
        row-gap: 32px;
    }
    .rGap-m-34 {
        row-gap: 34px;
    }
    .rGap-m-36 {
        row-gap: 36px;
    }
    .rGap-m-38 {
        row-gap: 38px;
    }
    .rGap-m-40 {
        row-gap: 40px;
    }
    .rGap-m-42 {
        row-gap: 42px;
    }
    .rGap-m-44 {
        row-gap: 44px;
    }
    .rGap-m-46 {
        row-gap: 46px;
    }
    .rGap-m-48 {
        row-gap: 48px;
    }
    .rGap-m-50 {
        row-gap: 50px;
    }
    .rGap-m-52 {
        row-gap: 52px;
    }
    .rGap-m-54 {
        row-gap: 54px;
    }
    .rGap-m-56 {
        row-gap: 56px;
    }
    .rGap-m-58 {
        row-gap: 58px;
    }
    .rGap-m-60 {
        row-gap: 60px;
    }
    .rGap-m-62 {
        row-gap: 62px;
    }
    .rGap-m-64 {
        row-gap: 64px;
    }
    .rGap-m-66 {
        row-gap: 66px;
    }
    .rGap-m-68 {
        row-gap: 68px;
    }
    .rGap-m-70 {
        row-gap: 70px;
    }
    .rGap-m-72 {
        row-gap: 72px;
    }
    .rGap-m-74 {
        row-gap: 74px;
    }
    .rGap-m-76 {
        row-gap: 76px;
    }
    .rGap-m-78 {
        row-gap: 78px;
    }
    .rGap-m-80 {
        row-gap: 80px;
    }
    .rGap-m-82 {
        row-gap: 82px;
    }
    .rGap-m-84 {
        row-gap: 84px;
    }
    .rGap-m-86 {
        row-gap: 86px;
    }
    .rGap-m-88 {
        row-gap: 88px;
    }
    .rGap-m-90 {
        row-gap: 90px;
    }
    .rGap-m-92 {
        row-gap: 92px;
    }
    .rGap-m-94 {
        row-gap: 94px;
    }
    .rGap-m-96 {
        row-gap: 96px;
    }
    .rGap-m-98 {
        row-gap: 98px;
    }
    .rGap-m-100 {
        row-gap: 100px;
    }
    .rGap-m-102 {
        row-gap: 102px;
    }
    .rGap-m-104 {
        row-gap: 104px;
    }
    .rGap-m-106 {
        row-gap: 106px;
    }
    .rGap-m-108 {
        row-gap: 108px;
    }
    .rGap-m-110 {
        row-gap: 110px;
    }
    .rGap-m-112 {
        row-gap: 112px;
    }
    .rGap-m-114 {
        row-gap: 114px;
    }
    .rGap-m-116 {
        row-gap: 116px;
    }
    .rGap-m-118 {
        row-gap: 118px;
    }
    .rGap-m-120 {
        row-gap: 120px;
    }
    .rGap-m-122 {
        row-gap: 122px;
    }
    .rGap-m-124 {
        row-gap: 124px;
    }
    .rGap-m-126 {
        row-gap: 126px;
    }
    .rGap-m-128 {
        row-gap: 128px;
    }
    .rGap-m-130 {
        row-gap: 130px;
    }
    .rGap-m-132 {
        row-gap: 132px;
    }
    .rGap-m-134 {
        row-gap: 134px;
    }
    .rGap-m-136 {
        row-gap: 136px;
    }
    .rGap-m-138 {
        row-gap: 138px;
    }
    .rGap-m-140 {
        row-gap: 140px;
    }
    .rGap-m-0 {
        row-gap: 0;
    }
    .cGap-m-2 {
        column-gap: 2px;
    }
    .cGap-m-4 {
        column-gap: 4px;
    }
    .cGap-m-6 {
        column-gap: 6px;
    }
    .cGap-m-8 {
        column-gap: 8px;
    }
    .cGap-m-10 {
        column-gap: 10px;
    }
    .cGap-m-12 {
        column-gap: 12px;
    }
    .cGap-m-14 {
        column-gap: 14px;
    }
    .cGap-m-16 {
        column-gap: 16px;
    }
    .cGap-m-18 {
        column-gap: 18px;
    }
    .cGap-m-20 {
        column-gap: 20px;
    }
    .cGap-m-22 {
        column-gap: 22px;
    }
    .cGap-m-24 {
        column-gap: 24px;
    }
    .cGap-m-26 {
        column-gap: 26px;
    }
    .cGap-m-28 {
        column-gap: 28px;
    }
    .cGap-m-30 {
        column-gap: 30px;
    }
    .cGap-m-32 {
        column-gap: 32px;
    }
    .cGap-m-34 {
        column-gap: 34px;
    }
    .cGap-m-36 {
        column-gap: 36px;
    }
    .cGap-m-38 {
        column-gap: 38px;
    }
    .cGap-m-40 {
        column-gap: 40px;
    }
    .cGap-m-42 {
        column-gap: 42px;
    }
    .cGap-m-44 {
        column-gap: 44px;
    }
    .cGap-m-46 {
        column-gap: 46px;
    }
    .cGap-m-48 {
        column-gap: 48px;
    }
    .cGap-m-50 {
        column-gap: 50px;
    }
    .cGap-m-52 {
        column-gap: 52px;
    }
    .cGap-m-54 {
        column-gap: 54px;
    }
    .cGap-m-56 {
        column-gap: 56px;
    }
    .cGap-m-58 {
        column-gap: 58px;
    }
    .cGap-m-60 {
        column-gap: 60px;
    }
    .cGap-m-62 {
        column-gap: 62px;
    }
    .cGap-m-64 {
        column-gap: 64px;
    }
    .cGap-m-66 {
        column-gap: 66px;
    }
    .cGap-m-68 {
        column-gap: 68px;
    }
    .cGap-m-70 {
        column-gap: 70px;
    }
    .cGap-m-72 {
        column-gap: 72px;
    }
    .cGap-m-74 {
        column-gap: 74px;
    }
    .cGap-m-76 {
        column-gap: 76px;
    }
    .cGap-m-78 {
        column-gap: 78px;
    }
    .cGap-m-80 {
        column-gap: 80px;
    }
    .cGap-m-82 {
        column-gap: 82px;
    }
    .cGap-m-84 {
        column-gap: 84px;
    }
    .cGap-m-86 {
        column-gap: 86px;
    }
    .cGap-m-88 {
        column-gap: 88px;
    }
    .cGap-m-90 {
        column-gap: 90px;
    }
    .cGap-m-92 {
        column-gap: 92px;
    }
    .cGap-m-94 {
        column-gap: 94px;
    }
    .cGap-m-96 {
        column-gap: 96px;
    }
    .cGap-m-98 {
        column-gap: 98px;
    }
    .cGap-m-100 {
        column-gap: 100px;
    }
    .cGap-m-102 {
        column-gap: 102px;
    }
    .cGap-m-104 {
        column-gap: 104px;
    }
    .cGap-m-106 {
        column-gap: 106px;
    }
    .cGap-m-108 {
        column-gap: 108px;
    }
    .cGap-m-110 {
        column-gap: 110px;
    }
    .cGap-m-112 {
        column-gap: 112px;
    }
    .cGap-m-114 {
        column-gap: 114px;
    }
    .cGap-m-116 {
        column-gap: 116px;
    }
    .cGap-m-118 {
        column-gap: 118px;
    }
    .cGap-m-120 {
        column-gap: 120px;
    }
    .cGap-m-122 {
        column-gap: 122px;
    }
    .cGap-m-124 {
        column-gap: 124px;
    }
    .cGap-m-126 {
        column-gap: 126px;
    }
    .cGap-m-128 {
        column-gap: 128px;
    }
    .cGap-m-130 {
        column-gap: 130px;
    }
    .cGap-m-132 {
        column-gap: 132px;
    }
    .cGap-m-134 {
        column-gap: 134px;
    }
    .cGap-m-136 {
        column-gap: 136px;
    }
    .cGap-m-138 {
        column-gap: 138px;
    }
    .cGap-m-140 {
        column-gap: 140px;
    }
    .cGap-m-0 {
        column-gap: 0;
    }
}
@media screen and (max-width: 768px) {
    .mt-s-2 {
        margin-top: 2px;
    }
    .mr-s-2 {
        margin-right: 2px;
    }
    .mb-s-2 {
        margin-bottom: 2px;
    }
    .ml-s-2 {
        margin-left: 2px;
    }
    .m-s-2 {
        margin: 2px;
    }
    .mh-s-2 {
        margin-left: 2px;
        margin-right: 2px;
    }
    .mv-s-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .mt-s-4 {
        margin-top: 4px;
    }
    .mr-s-4 {
        margin-right: 4px;
    }
    .mb-s-4 {
        margin-bottom: 4px;
    }
    .ml-s-4 {
        margin-left: 4px;
    }
    .m-s-4 {
        margin: 4px;
    }
    .mh-s-4 {
        margin-left: 4px;
        margin-right: 4px;
    }
    .mv-s-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .mt-s-6 {
        margin-top: 6px;
    }
    .mr-s-6 {
        margin-right: 6px;
    }
    .mb-s-6 {
        margin-bottom: 6px;
    }
    .ml-s-6 {
        margin-left: 6px;
    }
    .m-s-6 {
        margin: 6px;
    }
    .mh-s-6 {
        margin-left: 6px;
        margin-right: 6px;
    }
    .mv-s-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .mt-s-8 {
        margin-top: 8px;
    }
    .mr-s-8 {
        margin-right: 8px;
    }
    .mb-s-8 {
        margin-bottom: 8px;
    }
    .ml-s-8 {
        margin-left: 8px;
    }
    .m-s-8 {
        margin: 8px;
    }
    .mh-s-8 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .mv-s-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .mt-s-10 {
        margin-top: 10px;
    }
    .mr-s-10 {
        margin-right: 10px;
    }
    .mb-s-10 {
        margin-bottom: 10px;
    }
    .ml-s-10 {
        margin-left: 10px;
    }
    .m-s-10 {
        margin: 10px;
    }
    .mh-s-10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    .mv-s-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mt-s-12 {
        margin-top: 12px;
    }
    .mr-s-12 {
        margin-right: 12px;
    }
    .mb-s-12 {
        margin-bottom: 12px;
    }
    .ml-s-12 {
        margin-left: 12px;
    }
    .m-s-12 {
        margin: 12px;
    }
    .mh-s-12 {
        margin-left: 12px;
        margin-right: 12px;
    }
    .mv-s-12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .mt-s-14 {
        margin-top: 14px;
    }
    .mr-s-14 {
        margin-right: 14px;
    }
    .mb-s-14 {
        margin-bottom: 14px;
    }
    .ml-s-14 {
        margin-left: 14px;
    }
    .m-s-14 {
        margin: 14px;
    }
    .mh-s-14 {
        margin-left: 14px;
        margin-right: 14px;
    }
    .mv-s-14 {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .mt-s-16 {
        margin-top: 16px;
    }
    .mr-s-16 {
        margin-right: 16px;
    }
    .mb-s-16 {
        margin-bottom: 16px;
    }
    .ml-s-16 {
        margin-left: 16px;
    }
    .m-s-16 {
        margin: 16px;
    }
    .mh-s-16 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .mv-s-16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .mt-s-18 {
        margin-top: 18px;
    }
    .mr-s-18 {
        margin-right: 18px;
    }
    .mb-s-18 {
        margin-bottom: 18px;
    }
    .ml-s-18 {
        margin-left: 18px;
    }
    .m-s-18 {
        margin: 18px;
    }
    .mh-s-18 {
        margin-left: 18px;
        margin-right: 18px;
    }
    .mv-s-18 {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .mt-s-20 {
        margin-top: 20px;
    }
    .mr-s-20 {
        margin-right: 20px;
    }
    .mb-s-20 {
        margin-bottom: 20px;
    }
    .ml-s-20 {
        margin-left: 20px;
    }
    .m-s-20 {
        margin: 20px;
    }
    .mh-s-20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    .mv-s-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .mt-s-22 {
        margin-top: 22px;
    }
    .mr-s-22 {
        margin-right: 22px;
    }
    .mb-s-22 {
        margin-bottom: 22px;
    }
    .ml-s-22 {
        margin-left: 22px;
    }
    .m-s-22 {
        margin: 22px;
    }
    .mh-s-22 {
        margin-left: 22px;
        margin-right: 22px;
    }
    .mv-s-22 {
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .mt-s-24 {
        margin-top: 24px;
    }
    .mr-s-24 {
        margin-right: 24px;
    }
    .mb-s-24 {
        margin-bottom: 24px;
    }
    .ml-s-24 {
        margin-left: 24px;
    }
    .m-s-24 {
        margin: 24px;
    }
    .mh-s-24 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .mv-s-24 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .mt-s-26 {
        margin-top: 26px;
    }
    .mr-s-26 {
        margin-right: 26px;
    }
    .mb-s-26 {
        margin-bottom: 26px;
    }
    .ml-s-26 {
        margin-left: 26px;
    }
    .m-s-26 {
        margin: 26px;
    }
    .mh-s-26 {
        margin-left: 26px;
        margin-right: 26px;
    }
    .mv-s-26 {
        margin-top: 26px;
        margin-bottom: 26px;
    }
    .mt-s-28 {
        margin-top: 28px;
    }
    .mr-s-28 {
        margin-right: 28px;
    }
    .mb-s-28 {
        margin-bottom: 28px;
    }
    .ml-s-28 {
        margin-left: 28px;
    }
    .m-s-28 {
        margin: 28px;
    }
    .mh-s-28 {
        margin-left: 28px;
        margin-right: 28px;
    }
    .mv-s-28 {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .mt-s-30 {
        margin-top: 30px;
    }
    .mr-s-30 {
        margin-right: 30px;
    }
    .mb-s-30 {
        margin-bottom: 30px;
    }
    .ml-s-30 {
        margin-left: 30px;
    }
    .m-s-30 {
        margin: 30px;
    }
    .mh-s-30 {
        margin-left: 30px;
        margin-right: 30px;
    }
    .mv-s-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .mt-s-32 {
        margin-top: 32px;
    }
    .mr-s-32 {
        margin-right: 32px;
    }
    .mb-s-32 {
        margin-bottom: 32px;
    }
    .ml-s-32 {
        margin-left: 32px;
    }
    .m-s-32 {
        margin: 32px;
    }
    .mh-s-32 {
        margin-left: 32px;
        margin-right: 32px;
    }
    .mv-s-32 {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .mt-s-34 {
        margin-top: 34px;
    }
    .mr-s-34 {
        margin-right: 34px;
    }
    .mb-s-34 {
        margin-bottom: 34px;
    }
    .ml-s-34 {
        margin-left: 34px;
    }
    .m-s-34 {
        margin: 34px;
    }
    .mh-s-34 {
        margin-left: 34px;
        margin-right: 34px;
    }
    .mv-s-34 {
        margin-top: 34px;
        margin-bottom: 34px;
    }
    .mt-s-36 {
        margin-top: 36px;
    }
    .mr-s-36 {
        margin-right: 36px;
    }
    .mb-s-36 {
        margin-bottom: 36px;
    }
    .ml-s-36 {
        margin-left: 36px;
    }
    .m-s-36 {
        margin: 36px;
    }
    .mh-s-36 {
        margin-left: 36px;
        margin-right: 36px;
    }
    .mv-s-36 {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .mt-s-38 {
        margin-top: 38px;
    }
    .mr-s-38 {
        margin-right: 38px;
    }
    .mb-s-38 {
        margin-bottom: 38px;
    }
    .ml-s-38 {
        margin-left: 38px;
    }
    .m-s-38 {
        margin: 38px;
    }
    .mh-s-38 {
        margin-left: 38px;
        margin-right: 38px;
    }
    .mv-s-38 {
        margin-top: 38px;
        margin-bottom: 38px;
    }
    .mt-s-40 {
        margin-top: 40px;
    }
    .mr-s-40 {
        margin-right: 40px;
    }
    .mb-s-40 {
        margin-bottom: 40px;
    }
    .ml-s-40 {
        margin-left: 40px;
    }
    .m-s-40 {
        margin: 40px;
    }
    .mh-s-40 {
        margin-left: 40px;
        margin-right: 40px;
    }
    .mv-s-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .mt-s-42 {
        margin-top: 42px;
    }
    .mr-s-42 {
        margin-right: 42px;
    }
    .mb-s-42 {
        margin-bottom: 42px;
    }
    .ml-s-42 {
        margin-left: 42px;
    }
    .m-s-42 {
        margin: 42px;
    }
    .mh-s-42 {
        margin-left: 42px;
        margin-right: 42px;
    }
    .mv-s-42 {
        margin-top: 42px;
        margin-bottom: 42px;
    }
    .mt-s-44 {
        margin-top: 44px;
    }
    .mr-s-44 {
        margin-right: 44px;
    }
    .mb-s-44 {
        margin-bottom: 44px;
    }
    .ml-s-44 {
        margin-left: 44px;
    }
    .m-s-44 {
        margin: 44px;
    }
    .mh-s-44 {
        margin-left: 44px;
        margin-right: 44px;
    }
    .mv-s-44 {
        margin-top: 44px;
        margin-bottom: 44px;
    }
    .mt-s-46 {
        margin-top: 46px;
    }
    .mr-s-46 {
        margin-right: 46px;
    }
    .mb-s-46 {
        margin-bottom: 46px;
    }
    .ml-s-46 {
        margin-left: 46px;
    }
    .m-s-46 {
        margin: 46px;
    }
    .mh-s-46 {
        margin-left: 46px;
        margin-right: 46px;
    }
    .mv-s-46 {
        margin-top: 46px;
        margin-bottom: 46px;
    }
    .mt-s-48 {
        margin-top: 48px;
    }
    .mr-s-48 {
        margin-right: 48px;
    }
    .mb-s-48 {
        margin-bottom: 48px;
    }
    .ml-s-48 {
        margin-left: 48px;
    }
    .m-s-48 {
        margin: 48px;
    }
    .mh-s-48 {
        margin-left: 48px;
        margin-right: 48px;
    }
    .mv-s-48 {
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .mt-s-50 {
        margin-top: 50px;
    }
    .mr-s-50 {
        margin-right: 50px;
    }
    .mb-s-50 {
        margin-bottom: 50px;
    }
    .ml-s-50 {
        margin-left: 50px;
    }
    .m-s-50 {
        margin: 50px;
    }
    .mh-s-50 {
        margin-left: 50px;
        margin-right: 50px;
    }
    .mv-s-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .mt-s-52 {
        margin-top: 52px;
    }
    .mr-s-52 {
        margin-right: 52px;
    }
    .mb-s-52 {
        margin-bottom: 52px;
    }
    .ml-s-52 {
        margin-left: 52px;
    }
    .m-s-52 {
        margin: 52px;
    }
    .mh-s-52 {
        margin-left: 52px;
        margin-right: 52px;
    }
    .mv-s-52 {
        margin-top: 52px;
        margin-bottom: 52px;
    }
    .mt-s-54 {
        margin-top: 54px;
    }
    .mr-s-54 {
        margin-right: 54px;
    }
    .mb-s-54 {
        margin-bottom: 54px;
    }
    .ml-s-54 {
        margin-left: 54px;
    }
    .m-s-54 {
        margin: 54px;
    }
    .mh-s-54 {
        margin-left: 54px;
        margin-right: 54px;
    }
    .mv-s-54 {
        margin-top: 54px;
        margin-bottom: 54px;
    }
    .mt-s-56 {
        margin-top: 56px;
    }
    .mr-s-56 {
        margin-right: 56px;
    }
    .mb-s-56 {
        margin-bottom: 56px;
    }
    .ml-s-56 {
        margin-left: 56px;
    }
    .m-s-56 {
        margin: 56px;
    }
    .mh-s-56 {
        margin-left: 56px;
        margin-right: 56px;
    }
    .mv-s-56 {
        margin-top: 56px;
        margin-bottom: 56px;
    }
    .mt-s-58 {
        margin-top: 58px;
    }
    .mr-s-58 {
        margin-right: 58px;
    }
    .mb-s-58 {
        margin-bottom: 58px;
    }
    .ml-s-58 {
        margin-left: 58px;
    }
    .m-s-58 {
        margin: 58px;
    }
    .mh-s-58 {
        margin-left: 58px;
        margin-right: 58px;
    }
    .mv-s-58 {
        margin-top: 58px;
        margin-bottom: 58px;
    }
    .mt-s-60 {
        margin-top: 60px;
    }
    .mr-s-60 {
        margin-right: 60px;
    }
    .mb-s-60 {
        margin-bottom: 60px;
    }
    .ml-s-60 {
        margin-left: 60px;
    }
    .m-s-60 {
        margin: 60px;
    }
    .mh-s-60 {
        margin-left: 60px;
        margin-right: 60px;
    }
    .mv-s-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .mt-s-62 {
        margin-top: 62px;
    }
    .mr-s-62 {
        margin-right: 62px;
    }
    .mb-s-62 {
        margin-bottom: 62px;
    }
    .ml-s-62 {
        margin-left: 62px;
    }
    .m-s-62 {
        margin: 62px;
    }
    .mh-s-62 {
        margin-left: 62px;
        margin-right: 62px;
    }
    .mv-s-62 {
        margin-top: 62px;
        margin-bottom: 62px;
    }
    .mt-s-64 {
        margin-top: 64px;
    }
    .mr-s-64 {
        margin-right: 64px;
    }
    .mb-s-64 {
        margin-bottom: 64px;
    }
    .ml-s-64 {
        margin-left: 64px;
    }
    .m-s-64 {
        margin: 64px;
    }
    .mh-s-64 {
        margin-left: 64px;
        margin-right: 64px;
    }
    .mv-s-64 {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    .mt-s-66 {
        margin-top: 66px;
    }
    .mr-s-66 {
        margin-right: 66px;
    }
    .mb-s-66 {
        margin-bottom: 66px;
    }
    .ml-s-66 {
        margin-left: 66px;
    }
    .m-s-66 {
        margin: 66px;
    }
    .mh-s-66 {
        margin-left: 66px;
        margin-right: 66px;
    }
    .mv-s-66 {
        margin-top: 66px;
        margin-bottom: 66px;
    }
    .mt-s-68 {
        margin-top: 68px;
    }
    .mr-s-68 {
        margin-right: 68px;
    }
    .mb-s-68 {
        margin-bottom: 68px;
    }
    .ml-s-68 {
        margin-left: 68px;
    }
    .m-s-68 {
        margin: 68px;
    }
    .mh-s-68 {
        margin-left: 68px;
        margin-right: 68px;
    }
    .mv-s-68 {
        margin-top: 68px;
        margin-bottom: 68px;
    }
    .mt-s-70 {
        margin-top: 70px;
    }
    .mr-s-70 {
        margin-right: 70px;
    }
    .mb-s-70 {
        margin-bottom: 70px;
    }
    .ml-s-70 {
        margin-left: 70px;
    }
    .m-s-70 {
        margin: 70px;
    }
    .mh-s-70 {
        margin-left: 70px;
        margin-right: 70px;
    }
    .mv-s-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .mt-s-72 {
        margin-top: 72px;
    }
    .mr-s-72 {
        margin-right: 72px;
    }
    .mb-s-72 {
        margin-bottom: 72px;
    }
    .ml-s-72 {
        margin-left: 72px;
    }
    .m-s-72 {
        margin: 72px;
    }
    .mh-s-72 {
        margin-left: 72px;
        margin-right: 72px;
    }
    .mv-s-72 {
        margin-top: 72px;
        margin-bottom: 72px;
    }
    .mt-s-74 {
        margin-top: 74px;
    }
    .mr-s-74 {
        margin-right: 74px;
    }
    .mb-s-74 {
        margin-bottom: 74px;
    }
    .ml-s-74 {
        margin-left: 74px;
    }
    .m-s-74 {
        margin: 74px;
    }
    .mh-s-74 {
        margin-left: 74px;
        margin-right: 74px;
    }
    .mv-s-74 {
        margin-top: 74px;
        margin-bottom: 74px;
    }
    .mt-s-76 {
        margin-top: 76px;
    }
    .mr-s-76 {
        margin-right: 76px;
    }
    .mb-s-76 {
        margin-bottom: 76px;
    }
    .ml-s-76 {
        margin-left: 76px;
    }
    .m-s-76 {
        margin: 76px;
    }
    .mh-s-76 {
        margin-left: 76px;
        margin-right: 76px;
    }
    .mv-s-76 {
        margin-top: 76px;
        margin-bottom: 76px;
    }
    .mt-s-78 {
        margin-top: 78px;
    }
    .mr-s-78 {
        margin-right: 78px;
    }
    .mb-s-78 {
        margin-bottom: 78px;
    }
    .ml-s-78 {
        margin-left: 78px;
    }
    .m-s-78 {
        margin: 78px;
    }
    .mh-s-78 {
        margin-left: 78px;
        margin-right: 78px;
    }
    .mv-s-78 {
        margin-top: 78px;
        margin-bottom: 78px;
    }
    .mt-s-80 {
        margin-top: 80px;
    }
    .mr-s-80 {
        margin-right: 80px;
    }
    .mb-s-80 {
        margin-bottom: 80px;
    }
    .ml-s-80 {
        margin-left: 80px;
    }
    .m-s-80 {
        margin: 80px;
    }
    .mh-s-80 {
        margin-left: 80px;
        margin-right: 80px;
    }
    .mv-s-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .mt-s-82 {
        margin-top: 82px;
    }
    .mr-s-82 {
        margin-right: 82px;
    }
    .mb-s-82 {
        margin-bottom: 82px;
    }
    .ml-s-82 {
        margin-left: 82px;
    }
    .m-s-82 {
        margin: 82px;
    }
    .mh-s-82 {
        margin-left: 82px;
        margin-right: 82px;
    }
    .mv-s-82 {
        margin-top: 82px;
        margin-bottom: 82px;
    }
    .mt-s-84 {
        margin-top: 84px;
    }
    .mr-s-84 {
        margin-right: 84px;
    }
    .mb-s-84 {
        margin-bottom: 84px;
    }
    .ml-s-84 {
        margin-left: 84px;
    }
    .m-s-84 {
        margin: 84px;
    }
    .mh-s-84 {
        margin-left: 84px;
        margin-right: 84px;
    }
    .mv-s-84 {
        margin-top: 84px;
        margin-bottom: 84px;
    }
    .mt-s-86 {
        margin-top: 86px;
    }
    .mr-s-86 {
        margin-right: 86px;
    }
    .mb-s-86 {
        margin-bottom: 86px;
    }
    .ml-s-86 {
        margin-left: 86px;
    }
    .m-s-86 {
        margin: 86px;
    }
    .mh-s-86 {
        margin-left: 86px;
        margin-right: 86px;
    }
    .mv-s-86 {
        margin-top: 86px;
        margin-bottom: 86px;
    }
    .mt-s-88 {
        margin-top: 88px;
    }
    .mr-s-88 {
        margin-right: 88px;
    }
    .mb-s-88 {
        margin-bottom: 88px;
    }
    .ml-s-88 {
        margin-left: 88px;
    }
    .m-s-88 {
        margin: 88px;
    }
    .mh-s-88 {
        margin-left: 88px;
        margin-right: 88px;
    }
    .mv-s-88 {
        margin-top: 88px;
        margin-bottom: 88px;
    }
    .mt-s-90 {
        margin-top: 90px;
    }
    .mr-s-90 {
        margin-right: 90px;
    }
    .mb-s-90 {
        margin-bottom: 90px;
    }
    .ml-s-90 {
        margin-left: 90px;
    }
    .m-s-90 {
        margin: 90px;
    }
    .mh-s-90 {
        margin-left: 90px;
        margin-right: 90px;
    }
    .mv-s-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }
    .mt-s-92 {
        margin-top: 92px;
    }
    .mr-s-92 {
        margin-right: 92px;
    }
    .mb-s-92 {
        margin-bottom: 92px;
    }
    .ml-s-92 {
        margin-left: 92px;
    }
    .m-s-92 {
        margin: 92px;
    }
    .mh-s-92 {
        margin-left: 92px;
        margin-right: 92px;
    }
    .mv-s-92 {
        margin-top: 92px;
        margin-bottom: 92px;
    }
    .mt-s-94 {
        margin-top: 94px;
    }
    .mr-s-94 {
        margin-right: 94px;
    }
    .mb-s-94 {
        margin-bottom: 94px;
    }
    .ml-s-94 {
        margin-left: 94px;
    }
    .m-s-94 {
        margin: 94px;
    }
    .mh-s-94 {
        margin-left: 94px;
        margin-right: 94px;
    }
    .mv-s-94 {
        margin-top: 94px;
        margin-bottom: 94px;
    }
    .mt-s-96 {
        margin-top: 96px;
    }
    .mr-s-96 {
        margin-right: 96px;
    }
    .mb-s-96 {
        margin-bottom: 96px;
    }
    .ml-s-96 {
        margin-left: 96px;
    }
    .m-s-96 {
        margin: 96px;
    }
    .mh-s-96 {
        margin-left: 96px;
        margin-right: 96px;
    }
    .mv-s-96 {
        margin-top: 96px;
        margin-bottom: 96px;
    }
    .mt-s-98 {
        margin-top: 98px;
    }
    .mr-s-98 {
        margin-right: 98px;
    }
    .mb-s-98 {
        margin-bottom: 98px;
    }
    .ml-s-98 {
        margin-left: 98px;
    }
    .m-s-98 {
        margin: 98px;
    }
    .mh-s-98 {
        margin-left: 98px;
        margin-right: 98px;
    }
    .mv-s-98 {
        margin-top: 98px;
        margin-bottom: 98px;
    }
    .mt-s-100 {
        margin-top: 100px;
    }
    .mr-s-100 {
        margin-right: 100px;
    }
    .mb-s-100 {
        margin-bottom: 100px;
    }
    .ml-s-100 {
        margin-left: 100px;
    }
    .m-s-100 {
        margin: 100px;
    }
    .mh-s-100 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .mv-s-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .mt-s-102 {
        margin-top: 102px;
    }
    .mr-s-102 {
        margin-right: 102px;
    }
    .mb-s-102 {
        margin-bottom: 102px;
    }
    .ml-s-102 {
        margin-left: 102px;
    }
    .m-s-102 {
        margin: 102px;
    }
    .mh-s-102 {
        margin-left: 102px;
        margin-right: 102px;
    }
    .mv-s-102 {
        margin-top: 102px;
        margin-bottom: 102px;
    }
    .mt-s-104 {
        margin-top: 104px;
    }
    .mr-s-104 {
        margin-right: 104px;
    }
    .mb-s-104 {
        margin-bottom: 104px;
    }
    .ml-s-104 {
        margin-left: 104px;
    }
    .m-s-104 {
        margin: 104px;
    }
    .mh-s-104 {
        margin-left: 104px;
        margin-right: 104px;
    }
    .mv-s-104 {
        margin-top: 104px;
        margin-bottom: 104px;
    }
    .mt-s-106 {
        margin-top: 106px;
    }
    .mr-s-106 {
        margin-right: 106px;
    }
    .mb-s-106 {
        margin-bottom: 106px;
    }
    .ml-s-106 {
        margin-left: 106px;
    }
    .m-s-106 {
        margin: 106px;
    }
    .mh-s-106 {
        margin-left: 106px;
        margin-right: 106px;
    }
    .mv-s-106 {
        margin-top: 106px;
        margin-bottom: 106px;
    }
    .mt-s-108 {
        margin-top: 108px;
    }
    .mr-s-108 {
        margin-right: 108px;
    }
    .mb-s-108 {
        margin-bottom: 108px;
    }
    .ml-s-108 {
        margin-left: 108px;
    }
    .m-s-108 {
        margin: 108px;
    }
    .mh-s-108 {
        margin-left: 108px;
        margin-right: 108px;
    }
    .mv-s-108 {
        margin-top: 108px;
        margin-bottom: 108px;
    }
    .mt-s-110 {
        margin-top: 110px;
    }
    .mr-s-110 {
        margin-right: 110px;
    }
    .mb-s-110 {
        margin-bottom: 110px;
    }
    .ml-s-110 {
        margin-left: 110px;
    }
    .m-s-110 {
        margin: 110px;
    }
    .mh-s-110 {
        margin-left: 110px;
        margin-right: 110px;
    }
    .mv-s-110 {
        margin-top: 110px;
        margin-bottom: 110px;
    }
    .mt-s-112 {
        margin-top: 112px;
    }
    .mr-s-112 {
        margin-right: 112px;
    }
    .mb-s-112 {
        margin-bottom: 112px;
    }
    .ml-s-112 {
        margin-left: 112px;
    }
    .m-s-112 {
        margin: 112px;
    }
    .mh-s-112 {
        margin-left: 112px;
        margin-right: 112px;
    }
    .mv-s-112 {
        margin-top: 112px;
        margin-bottom: 112px;
    }
    .mt-s-114 {
        margin-top: 114px;
    }
    .mr-s-114 {
        margin-right: 114px;
    }
    .mb-s-114 {
        margin-bottom: 114px;
    }
    .ml-s-114 {
        margin-left: 114px;
    }
    .m-s-114 {
        margin: 114px;
    }
    .mh-s-114 {
        margin-left: 114px;
        margin-right: 114px;
    }
    .mv-s-114 {
        margin-top: 114px;
        margin-bottom: 114px;
    }
    .mt-s-116 {
        margin-top: 116px;
    }
    .mr-s-116 {
        margin-right: 116px;
    }
    .mb-s-116 {
        margin-bottom: 116px;
    }
    .ml-s-116 {
        margin-left: 116px;
    }
    .m-s-116 {
        margin: 116px;
    }
    .mh-s-116 {
        margin-left: 116px;
        margin-right: 116px;
    }
    .mv-s-116 {
        margin-top: 116px;
        margin-bottom: 116px;
    }
    .mt-s-118 {
        margin-top: 118px;
    }
    .mr-s-118 {
        margin-right: 118px;
    }
    .mb-s-118 {
        margin-bottom: 118px;
    }
    .ml-s-118 {
        margin-left: 118px;
    }
    .m-s-118 {
        margin: 118px;
    }
    .mh-s-118 {
        margin-left: 118px;
        margin-right: 118px;
    }
    .mv-s-118 {
        margin-top: 118px;
        margin-bottom: 118px;
    }
    .mt-s-120 {
        margin-top: 120px;
    }
    .mr-s-120 {
        margin-right: 120px;
    }
    .mb-s-120 {
        margin-bottom: 120px;
    }
    .ml-s-120 {
        margin-left: 120px;
    }
    .m-s-120 {
        margin: 120px;
    }
    .mh-s-120 {
        margin-left: 120px;
        margin-right: 120px;
    }
    .mv-s-120 {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .mt-s-122 {
        margin-top: 122px;
    }
    .mr-s-122 {
        margin-right: 122px;
    }
    .mb-s-122 {
        margin-bottom: 122px;
    }
    .ml-s-122 {
        margin-left: 122px;
    }
    .m-s-122 {
        margin: 122px;
    }
    .mh-s-122 {
        margin-left: 122px;
        margin-right: 122px;
    }
    .mv-s-122 {
        margin-top: 122px;
        margin-bottom: 122px;
    }
    .mt-s-124 {
        margin-top: 124px;
    }
    .mr-s-124 {
        margin-right: 124px;
    }
    .mb-s-124 {
        margin-bottom: 124px;
    }
    .ml-s-124 {
        margin-left: 124px;
    }
    .m-s-124 {
        margin: 124px;
    }
    .mh-s-124 {
        margin-left: 124px;
        margin-right: 124px;
    }
    .mv-s-124 {
        margin-top: 124px;
        margin-bottom: 124px;
    }
    .mt-s-126 {
        margin-top: 126px;
    }
    .mr-s-126 {
        margin-right: 126px;
    }
    .mb-s-126 {
        margin-bottom: 126px;
    }
    .ml-s-126 {
        margin-left: 126px;
    }
    .m-s-126 {
        margin: 126px;
    }
    .mh-s-126 {
        margin-left: 126px;
        margin-right: 126px;
    }
    .mv-s-126 {
        margin-top: 126px;
        margin-bottom: 126px;
    }
    .mt-s-128 {
        margin-top: 128px;
    }
    .mr-s-128 {
        margin-right: 128px;
    }
    .mb-s-128 {
        margin-bottom: 128px;
    }
    .ml-s-128 {
        margin-left: 128px;
    }
    .m-s-128 {
        margin: 128px;
    }
    .mh-s-128 {
        margin-left: 128px;
        margin-right: 128px;
    }
    .mv-s-128 {
        margin-top: 128px;
        margin-bottom: 128px;
    }
    .mt-s-130 {
        margin-top: 130px;
    }
    .mr-s-130 {
        margin-right: 130px;
    }
    .mb-s-130 {
        margin-bottom: 130px;
    }
    .ml-s-130 {
        margin-left: 130px;
    }
    .m-s-130 {
        margin: 130px;
    }
    .mh-s-130 {
        margin-left: 130px;
        margin-right: 130px;
    }
    .mv-s-130 {
        margin-top: 130px;
        margin-bottom: 130px;
    }
    .mt-s-132 {
        margin-top: 132px;
    }
    .mr-s-132 {
        margin-right: 132px;
    }
    .mb-s-132 {
        margin-bottom: 132px;
    }
    .ml-s-132 {
        margin-left: 132px;
    }
    .m-s-132 {
        margin: 132px;
    }
    .mh-s-132 {
        margin-left: 132px;
        margin-right: 132px;
    }
    .mv-s-132 {
        margin-top: 132px;
        margin-bottom: 132px;
    }
    .mt-s-134 {
        margin-top: 134px;
    }
    .mr-s-134 {
        margin-right: 134px;
    }
    .mb-s-134 {
        margin-bottom: 134px;
    }
    .ml-s-134 {
        margin-left: 134px;
    }
    .m-s-134 {
        margin: 134px;
    }
    .mh-s-134 {
        margin-left: 134px;
        margin-right: 134px;
    }
    .mv-s-134 {
        margin-top: 134px;
        margin-bottom: 134px;
    }
    .mt-s-136 {
        margin-top: 136px;
    }
    .mr-s-136 {
        margin-right: 136px;
    }
    .mb-s-136 {
        margin-bottom: 136px;
    }
    .ml-s-136 {
        margin-left: 136px;
    }
    .m-s-136 {
        margin: 136px;
    }
    .mh-s-136 {
        margin-left: 136px;
        margin-right: 136px;
    }
    .mv-s-136 {
        margin-top: 136px;
        margin-bottom: 136px;
    }
    .mt-s-138 {
        margin-top: 138px;
    }
    .mr-s-138 {
        margin-right: 138px;
    }
    .mb-s-138 {
        margin-bottom: 138px;
    }
    .ml-s-138 {
        margin-left: 138px;
    }
    .m-s-138 {
        margin: 138px;
    }
    .mh-s-138 {
        margin-left: 138px;
        margin-right: 138px;
    }
    .mv-s-138 {
        margin-top: 138px;
        margin-bottom: 138px;
    }
    .mt-s-140 {
        margin-top: 140px;
    }
    .mr-s-140 {
        margin-right: 140px;
    }
    .mb-s-140 {
        margin-bottom: 140px;
    }
    .ml-s-140 {
        margin-left: 140px;
    }
    .m-s-140 {
        margin: 140px;
    }
    .mh-s-140 {
        margin-left: 140px;
        margin-right: 140px;
    }
    .mv-s-140 {
        margin-top: 140px;
        margin-bottom: 140px;
    }
    .m-s-0 {
        margin: 0;
    }
    .mt-s-0 {
        margin-top: 0;
    }
    .mr-s-0 {
        margin-right: 0;
    }
    .mb-s-0 {
        margin-bottom: 0;
    }
    .ml-s-0 {
        margin-left: 0;
    }
    .mv-s-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pt-s-2 {
        padding-top: 2px;
    }
    .pr-s-2 {
        padding-right: 2px;
    }
    .pb-s-2 {
        padding-bottom: 2px;
    }
    .pl-s-2 {
        padding-left: 2px;
    }
    .p-s-2 {
        padding: 2px;
    }
    .ph-s-2 {
        padding-left: 2px;
        padding-right: 2px;
    }
    .pv-s-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .pt-s-4 {
        padding-top: 4px;
    }
    .pr-s-4 {
        padding-right: 4px;
    }
    .pb-s-4 {
        padding-bottom: 4px;
    }
    .pl-s-4 {
        padding-left: 4px;
    }
    .p-s-4 {
        padding: 4px;
    }
    .ph-s-4 {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pv-s-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .pt-s-6 {
        padding-top: 6px;
    }
    .pr-s-6 {
        padding-right: 6px;
    }
    .pb-s-6 {
        padding-bottom: 6px;
    }
    .pl-s-6 {
        padding-left: 6px;
    }
    .p-s-6 {
        padding: 6px;
    }
    .ph-s-6 {
        padding-left: 6px;
        padding-right: 6px;
    }
    .pv-s-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .pt-s-8 {
        padding-top: 8px;
    }
    .pr-s-8 {
        padding-right: 8px;
    }
    .pb-s-8 {
        padding-bottom: 8px;
    }
    .pl-s-8 {
        padding-left: 8px;
    }
    .p-s-8 {
        padding: 8px;
    }
    .ph-s-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .pv-s-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .pt-s-10 {
        padding-top: 10px;
    }
    .pr-s-10 {
        padding-right: 10px;
    }
    .pb-s-10 {
        padding-bottom: 10px;
    }
    .pl-s-10 {
        padding-left: 10px;
    }
    .p-s-10 {
        padding: 10px;
    }
    .ph-s-10 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pv-s-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .pt-s-12 {
        padding-top: 12px;
    }
    .pr-s-12 {
        padding-right: 12px;
    }
    .pb-s-12 {
        padding-bottom: 12px;
    }
    .pl-s-12 {
        padding-left: 12px;
    }
    .p-s-12 {
        padding: 12px;
    }
    .ph-s-12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .pv-s-12 {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .pt-s-14 {
        padding-top: 14px;
    }
    .pr-s-14 {
        padding-right: 14px;
    }
    .pb-s-14 {
        padding-bottom: 14px;
    }
    .pl-s-14 {
        padding-left: 14px;
    }
    .p-s-14 {
        padding: 14px;
    }
    .ph-s-14 {
        padding-left: 14px;
        padding-right: 14px;
    }
    .pv-s-14 {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .pt-s-16 {
        padding-top: 16px;
    }
    .pr-s-16 {
        padding-right: 16px;
    }
    .pb-s-16 {
        padding-bottom: 16px;
    }
    .pl-s-16 {
        padding-left: 16px;
    }
    .p-s-16 {
        padding: 16px;
    }
    .ph-s-16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .pv-s-16 {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .pt-s-18 {
        padding-top: 18px;
    }
    .pr-s-18 {
        padding-right: 18px;
    }
    .pb-s-18 {
        padding-bottom: 18px;
    }
    .pl-s-18 {
        padding-left: 18px;
    }
    .p-s-18 {
        padding: 18px;
    }
    .ph-s-18 {
        padding-left: 18px;
        padding-right: 18px;
    }
    .pv-s-18 {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .pt-s-20 {
        padding-top: 20px;
    }
    .pr-s-20 {
        padding-right: 20px;
    }
    .pb-s-20 {
        padding-bottom: 20px;
    }
    .pl-s-20 {
        padding-left: 20px;
    }
    .p-s-20 {
        padding: 20px;
    }
    .ph-s-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pv-s-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .pt-s-22 {
        padding-top: 22px;
    }
    .pr-s-22 {
        padding-right: 22px;
    }
    .pb-s-22 {
        padding-bottom: 22px;
    }
    .pl-s-22 {
        padding-left: 22px;
    }
    .p-s-22 {
        padding: 22px;
    }
    .ph-s-22 {
        padding-left: 22px;
        padding-right: 22px;
    }
    .pv-s-22 {
        padding-top: 22px;
        padding-bottom: 22px;
    }
    .pt-s-24 {
        padding-top: 24px;
    }
    .pr-s-24 {
        padding-right: 24px;
    }
    .pb-s-24 {
        padding-bottom: 24px;
    }
    .pl-s-24 {
        padding-left: 24px;
    }
    .p-s-24 {
        padding: 24px;
    }
    .ph-s-24 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pv-s-24 {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .pt-s-26 {
        padding-top: 26px;
    }
    .pr-s-26 {
        padding-right: 26px;
    }
    .pb-s-26 {
        padding-bottom: 26px;
    }
    .pl-s-26 {
        padding-left: 26px;
    }
    .p-s-26 {
        padding: 26px;
    }
    .ph-s-26 {
        padding-left: 26px;
        padding-right: 26px;
    }
    .pv-s-26 {
        padding-top: 26px;
        padding-bottom: 26px;
    }
    .pt-s-28 {
        padding-top: 28px;
    }
    .pr-s-28 {
        padding-right: 28px;
    }
    .pb-s-28 {
        padding-bottom: 28px;
    }
    .pl-s-28 {
        padding-left: 28px;
    }
    .p-s-28 {
        padding: 28px;
    }
    .ph-s-28 {
        padding-left: 28px;
        padding-right: 28px;
    }
    .pv-s-28 {
        padding-top: 28px;
        padding-bottom: 28px;
    }
    .pt-s-30 {
        padding-top: 30px;
    }
    .pr-s-30 {
        padding-right: 30px;
    }
    .pb-s-30 {
        padding-bottom: 30px;
    }
    .pl-s-30 {
        padding-left: 30px;
    }
    .p-s-30 {
        padding: 30px;
    }
    .ph-s-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pv-s-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pt-s-32 {
        padding-top: 32px;
    }
    .pr-s-32 {
        padding-right: 32px;
    }
    .pb-s-32 {
        padding-bottom: 32px;
    }
    .pl-s-32 {
        padding-left: 32px;
    }
    .p-s-32 {
        padding: 32px;
    }
    .ph-s-32 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .pv-s-32 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .pt-s-34 {
        padding-top: 34px;
    }
    .pr-s-34 {
        padding-right: 34px;
    }
    .pb-s-34 {
        padding-bottom: 34px;
    }
    .pl-s-34 {
        padding-left: 34px;
    }
    .p-s-34 {
        padding: 34px;
    }
    .ph-s-34 {
        padding-left: 34px;
        padding-right: 34px;
    }
    .pv-s-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .pt-s-36 {
        padding-top: 36px;
    }
    .pr-s-36 {
        padding-right: 36px;
    }
    .pb-s-36 {
        padding-bottom: 36px;
    }
    .pl-s-36 {
        padding-left: 36px;
    }
    .p-s-36 {
        padding: 36px;
    }
    .ph-s-36 {
        padding-left: 36px;
        padding-right: 36px;
    }
    .pv-s-36 {
        padding-top: 36px;
        padding-bottom: 36px;
    }
    .pt-s-38 {
        padding-top: 38px;
    }
    .pr-s-38 {
        padding-right: 38px;
    }
    .pb-s-38 {
        padding-bottom: 38px;
    }
    .pl-s-38 {
        padding-left: 38px;
    }
    .p-s-38 {
        padding: 38px;
    }
    .ph-s-38 {
        padding-left: 38px;
        padding-right: 38px;
    }
    .pv-s-38 {
        padding-top: 38px;
        padding-bottom: 38px;
    }
    .pt-s-40 {
        padding-top: 40px;
    }
    .pr-s-40 {
        padding-right: 40px;
    }
    .pb-s-40 {
        padding-bottom: 40px;
    }
    .pl-s-40 {
        padding-left: 40px;
    }
    .p-s-40 {
        padding: 40px;
    }
    .ph-s-40 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pv-s-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .pt-s-42 {
        padding-top: 42px;
    }
    .pr-s-42 {
        padding-right: 42px;
    }
    .pb-s-42 {
        padding-bottom: 42px;
    }
    .pl-s-42 {
        padding-left: 42px;
    }
    .p-s-42 {
        padding: 42px;
    }
    .ph-s-42 {
        padding-left: 42px;
        padding-right: 42px;
    }
    .pv-s-42 {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    .pt-s-44 {
        padding-top: 44px;
    }
    .pr-s-44 {
        padding-right: 44px;
    }
    .pb-s-44 {
        padding-bottom: 44px;
    }
    .pl-s-44 {
        padding-left: 44px;
    }
    .p-s-44 {
        padding: 44px;
    }
    .ph-s-44 {
        padding-left: 44px;
        padding-right: 44px;
    }
    .pv-s-44 {
        padding-top: 44px;
        padding-bottom: 44px;
    }
    .pt-s-46 {
        padding-top: 46px;
    }
    .pr-s-46 {
        padding-right: 46px;
    }
    .pb-s-46 {
        padding-bottom: 46px;
    }
    .pl-s-46 {
        padding-left: 46px;
    }
    .p-s-46 {
        padding: 46px;
    }
    .ph-s-46 {
        padding-left: 46px;
        padding-right: 46px;
    }
    .pv-s-46 {
        padding-top: 46px;
        padding-bottom: 46px;
    }
    .pt-s-48 {
        padding-top: 48px;
    }
    .pr-s-48 {
        padding-right: 48px;
    }
    .pb-s-48 {
        padding-bottom: 48px;
    }
    .pl-s-48 {
        padding-left: 48px;
    }
    .p-s-48 {
        padding: 48px;
    }
    .ph-s-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .pv-s-48 {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .pt-s-50 {
        padding-top: 50px;
    }
    .pr-s-50 {
        padding-right: 50px;
    }
    .pb-s-50 {
        padding-bottom: 50px;
    }
    .pl-s-50 {
        padding-left: 50px;
    }
    .p-s-50 {
        padding: 50px;
    }
    .ph-s-50 {
        padding-left: 50px;
        padding-right: 50px;
    }
    .pv-s-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-s-52 {
        padding-top: 52px;
    }
    .pr-s-52 {
        padding-right: 52px;
    }
    .pb-s-52 {
        padding-bottom: 52px;
    }
    .pl-s-52 {
        padding-left: 52px;
    }
    .p-s-52 {
        padding: 52px;
    }
    .ph-s-52 {
        padding-left: 52px;
        padding-right: 52px;
    }
    .pv-s-52 {
        padding-top: 52px;
        padding-bottom: 52px;
    }
    .pt-s-54 {
        padding-top: 54px;
    }
    .pr-s-54 {
        padding-right: 54px;
    }
    .pb-s-54 {
        padding-bottom: 54px;
    }
    .pl-s-54 {
        padding-left: 54px;
    }
    .p-s-54 {
        padding: 54px;
    }
    .ph-s-54 {
        padding-left: 54px;
        padding-right: 54px;
    }
    .pv-s-54 {
        padding-top: 54px;
        padding-bottom: 54px;
    }
    .pt-s-56 {
        padding-top: 56px;
    }
    .pr-s-56 {
        padding-right: 56px;
    }
    .pb-s-56 {
        padding-bottom: 56px;
    }
    .pl-s-56 {
        padding-left: 56px;
    }
    .p-s-56 {
        padding: 56px;
    }
    .ph-s-56 {
        padding-left: 56px;
        padding-right: 56px;
    }
    .pv-s-56 {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .pt-s-58 {
        padding-top: 58px;
    }
    .pr-s-58 {
        padding-right: 58px;
    }
    .pb-s-58 {
        padding-bottom: 58px;
    }
    .pl-s-58 {
        padding-left: 58px;
    }
    .p-s-58 {
        padding: 58px;
    }
    .ph-s-58 {
        padding-left: 58px;
        padding-right: 58px;
    }
    .pv-s-58 {
        padding-top: 58px;
        padding-bottom: 58px;
    }
    .pt-s-60 {
        padding-top: 60px;
    }
    .pr-s-60 {
        padding-right: 60px;
    }
    .pb-s-60 {
        padding-bottom: 60px;
    }
    .pl-s-60 {
        padding-left: 60px;
    }
    .p-s-60 {
        padding: 60px;
    }
    .ph-s-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .pv-s-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .pt-s-62 {
        padding-top: 62px;
    }
    .pr-s-62 {
        padding-right: 62px;
    }
    .pb-s-62 {
        padding-bottom: 62px;
    }
    .pl-s-62 {
        padding-left: 62px;
    }
    .p-s-62 {
        padding: 62px;
    }
    .ph-s-62 {
        padding-left: 62px;
        padding-right: 62px;
    }
    .pv-s-62 {
        padding-top: 62px;
        padding-bottom: 62px;
    }
    .pt-s-64 {
        padding-top: 64px;
    }
    .pr-s-64 {
        padding-right: 64px;
    }
    .pb-s-64 {
        padding-bottom: 64px;
    }
    .pl-s-64 {
        padding-left: 64px;
    }
    .p-s-64 {
        padding: 64px;
    }
    .ph-s-64 {
        padding-left: 64px;
        padding-right: 64px;
    }
    .pv-s-64 {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .pt-s-66 {
        padding-top: 66px;
    }
    .pr-s-66 {
        padding-right: 66px;
    }
    .pb-s-66 {
        padding-bottom: 66px;
    }
    .pl-s-66 {
        padding-left: 66px;
    }
    .p-s-66 {
        padding: 66px;
    }
    .ph-s-66 {
        padding-left: 66px;
        padding-right: 66px;
    }
    .pv-s-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .pt-s-68 {
        padding-top: 68px;
    }
    .pr-s-68 {
        padding-right: 68px;
    }
    .pb-s-68 {
        padding-bottom: 68px;
    }
    .pl-s-68 {
        padding-left: 68px;
    }
    .p-s-68 {
        padding: 68px;
    }
    .ph-s-68 {
        padding-left: 68px;
        padding-right: 68px;
    }
    .pv-s-68 {
        padding-top: 68px;
        padding-bottom: 68px;
    }
    .pt-s-70 {
        padding-top: 70px;
    }
    .pr-s-70 {
        padding-right: 70px;
    }
    .pb-s-70 {
        padding-bottom: 70px;
    }
    .pl-s-70 {
        padding-left: 70px;
    }
    .p-s-70 {
        padding: 70px;
    }
    .ph-s-70 {
        padding-left: 70px;
        padding-right: 70px;
    }
    .pv-s-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-s-72 {
        padding-top: 72px;
    }
    .pr-s-72 {
        padding-right: 72px;
    }
    .pb-s-72 {
        padding-bottom: 72px;
    }
    .pl-s-72 {
        padding-left: 72px;
    }
    .p-s-72 {
        padding: 72px;
    }
    .ph-s-72 {
        padding-left: 72px;
        padding-right: 72px;
    }
    .pv-s-72 {
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .pt-s-74 {
        padding-top: 74px;
    }
    .pr-s-74 {
        padding-right: 74px;
    }
    .pb-s-74 {
        padding-bottom: 74px;
    }
    .pl-s-74 {
        padding-left: 74px;
    }
    .p-s-74 {
        padding: 74px;
    }
    .ph-s-74 {
        padding-left: 74px;
        padding-right: 74px;
    }
    .pv-s-74 {
        padding-top: 74px;
        padding-bottom: 74px;
    }
    .pt-s-76 {
        padding-top: 76px;
    }
    .pr-s-76 {
        padding-right: 76px;
    }
    .pb-s-76 {
        padding-bottom: 76px;
    }
    .pl-s-76 {
        padding-left: 76px;
    }
    .p-s-76 {
        padding: 76px;
    }
    .ph-s-76 {
        padding-left: 76px;
        padding-right: 76px;
    }
    .pv-s-76 {
        padding-top: 76px;
        padding-bottom: 76px;
    }
    .pt-s-78 {
        padding-top: 78px;
    }
    .pr-s-78 {
        padding-right: 78px;
    }
    .pb-s-78 {
        padding-bottom: 78px;
    }
    .pl-s-78 {
        padding-left: 78px;
    }
    .p-s-78 {
        padding: 78px;
    }
    .ph-s-78 {
        padding-left: 78px;
        padding-right: 78px;
    }
    .pv-s-78 {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    .pt-s-80 {
        padding-top: 80px;
    }
    .pr-s-80 {
        padding-right: 80px;
    }
    .pb-s-80 {
        padding-bottom: 80px;
    }
    .pl-s-80 {
        padding-left: 80px;
    }
    .p-s-80 {
        padding: 80px;
    }
    .ph-s-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .pv-s-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-s-82 {
        padding-top: 82px;
    }
    .pr-s-82 {
        padding-right: 82px;
    }
    .pb-s-82 {
        padding-bottom: 82px;
    }
    .pl-s-82 {
        padding-left: 82px;
    }
    .p-s-82 {
        padding: 82px;
    }
    .ph-s-82 {
        padding-left: 82px;
        padding-right: 82px;
    }
    .pv-s-82 {
        padding-top: 82px;
        padding-bottom: 82px;
    }
    .pt-s-84 {
        padding-top: 84px;
    }
    .pr-s-84 {
        padding-right: 84px;
    }
    .pb-s-84 {
        padding-bottom: 84px;
    }
    .pl-s-84 {
        padding-left: 84px;
    }
    .p-s-84 {
        padding: 84px;
    }
    .ph-s-84 {
        padding-left: 84px;
        padding-right: 84px;
    }
    .pv-s-84 {
        padding-top: 84px;
        padding-bottom: 84px;
    }
    .pt-s-86 {
        padding-top: 86px;
    }
    .pr-s-86 {
        padding-right: 86px;
    }
    .pb-s-86 {
        padding-bottom: 86px;
    }
    .pl-s-86 {
        padding-left: 86px;
    }
    .p-s-86 {
        padding: 86px;
    }
    .ph-s-86 {
        padding-left: 86px;
        padding-right: 86px;
    }
    .pv-s-86 {
        padding-top: 86px;
        padding-bottom: 86px;
    }
    .pt-s-88 {
        padding-top: 88px;
    }
    .pr-s-88 {
        padding-right: 88px;
    }
    .pb-s-88 {
        padding-bottom: 88px;
    }
    .pl-s-88 {
        padding-left: 88px;
    }
    .p-s-88 {
        padding: 88px;
    }
    .ph-s-88 {
        padding-left: 88px;
        padding-right: 88px;
    }
    .pv-s-88 {
        padding-top: 88px;
        padding-bottom: 88px;
    }
    .pt-s-90 {
        padding-top: 90px;
    }
    .pr-s-90 {
        padding-right: 90px;
    }
    .pb-s-90 {
        padding-bottom: 90px;
    }
    .pl-s-90 {
        padding-left: 90px;
    }
    .p-s-90 {
        padding: 90px;
    }
    .ph-s-90 {
        padding-left: 90px;
        padding-right: 90px;
    }
    .pv-s-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .pt-s-92 {
        padding-top: 92px;
    }
    .pr-s-92 {
        padding-right: 92px;
    }
    .pb-s-92 {
        padding-bottom: 92px;
    }
    .pl-s-92 {
        padding-left: 92px;
    }
    .p-s-92 {
        padding: 92px;
    }
    .ph-s-92 {
        padding-left: 92px;
        padding-right: 92px;
    }
    .pv-s-92 {
        padding-top: 92px;
        padding-bottom: 92px;
    }
    .pt-s-94 {
        padding-top: 94px;
    }
    .pr-s-94 {
        padding-right: 94px;
    }
    .pb-s-94 {
        padding-bottom: 94px;
    }
    .pl-s-94 {
        padding-left: 94px;
    }
    .p-s-94 {
        padding: 94px;
    }
    .ph-s-94 {
        padding-left: 94px;
        padding-right: 94px;
    }
    .pv-s-94 {
        padding-top: 94px;
        padding-bottom: 94px;
    }
    .pt-s-96 {
        padding-top: 96px;
    }
    .pr-s-96 {
        padding-right: 96px;
    }
    .pb-s-96 {
        padding-bottom: 96px;
    }
    .pl-s-96 {
        padding-left: 96px;
    }
    .p-s-96 {
        padding: 96px;
    }
    .ph-s-96 {
        padding-left: 96px;
        padding-right: 96px;
    }
    .pv-s-96 {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    .pt-s-98 {
        padding-top: 98px;
    }
    .pr-s-98 {
        padding-right: 98px;
    }
    .pb-s-98 {
        padding-bottom: 98px;
    }
    .pl-s-98 {
        padding-left: 98px;
    }
    .p-s-98 {
        padding: 98px;
    }
    .ph-s-98 {
        padding-left: 98px;
        padding-right: 98px;
    }
    .pv-s-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .pt-s-100 {
        padding-top: 100px;
    }
    .pr-s-100 {
        padding-right: 100px;
    }
    .pb-s-100 {
        padding-bottom: 100px;
    }
    .pl-s-100 {
        padding-left: 100px;
    }
    .p-s-100 {
        padding: 100px;
    }
    .ph-s-100 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .pv-s-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .pt-s-102 {
        padding-top: 102px;
    }
    .pr-s-102 {
        padding-right: 102px;
    }
    .pb-s-102 {
        padding-bottom: 102px;
    }
    .pl-s-102 {
        padding-left: 102px;
    }
    .p-s-102 {
        padding: 102px;
    }
    .ph-s-102 {
        padding-left: 102px;
        padding-right: 102px;
    }
    .pv-s-102 {
        padding-top: 102px;
        padding-bottom: 102px;
    }
    .pt-s-104 {
        padding-top: 104px;
    }
    .pr-s-104 {
        padding-right: 104px;
    }
    .pb-s-104 {
        padding-bottom: 104px;
    }
    .pl-s-104 {
        padding-left: 104px;
    }
    .p-s-104 {
        padding: 104px;
    }
    .ph-s-104 {
        padding-left: 104px;
        padding-right: 104px;
    }
    .pv-s-104 {
        padding-top: 104px;
        padding-bottom: 104px;
    }
    .pt-s-106 {
        padding-top: 106px;
    }
    .pr-s-106 {
        padding-right: 106px;
    }
    .pb-s-106 {
        padding-bottom: 106px;
    }
    .pl-s-106 {
        padding-left: 106px;
    }
    .p-s-106 {
        padding: 106px;
    }
    .ph-s-106 {
        padding-left: 106px;
        padding-right: 106px;
    }
    .pv-s-106 {
        padding-top: 106px;
        padding-bottom: 106px;
    }
    .pt-s-108 {
        padding-top: 108px;
    }
    .pr-s-108 {
        padding-right: 108px;
    }
    .pb-s-108 {
        padding-bottom: 108px;
    }
    .pl-s-108 {
        padding-left: 108px;
    }
    .p-s-108 {
        padding: 108px;
    }
    .ph-s-108 {
        padding-left: 108px;
        padding-right: 108px;
    }
    .pv-s-108 {
        padding-top: 108px;
        padding-bottom: 108px;
    }
    .pt-s-110 {
        padding-top: 110px;
    }
    .pr-s-110 {
        padding-right: 110px;
    }
    .pb-s-110 {
        padding-bottom: 110px;
    }
    .pl-s-110 {
        padding-left: 110px;
    }
    .p-s-110 {
        padding: 110px;
    }
    .ph-s-110 {
        padding-left: 110px;
        padding-right: 110px;
    }
    .pv-s-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .pt-s-112 {
        padding-top: 112px;
    }
    .pr-s-112 {
        padding-right: 112px;
    }
    .pb-s-112 {
        padding-bottom: 112px;
    }
    .pl-s-112 {
        padding-left: 112px;
    }
    .p-s-112 {
        padding: 112px;
    }
    .ph-s-112 {
        padding-left: 112px;
        padding-right: 112px;
    }
    .pv-s-112 {
        padding-top: 112px;
        padding-bottom: 112px;
    }
    .pt-s-114 {
        padding-top: 114px;
    }
    .pr-s-114 {
        padding-right: 114px;
    }
    .pb-s-114 {
        padding-bottom: 114px;
    }
    .pl-s-114 {
        padding-left: 114px;
    }
    .p-s-114 {
        padding: 114px;
    }
    .ph-s-114 {
        padding-left: 114px;
        padding-right: 114px;
    }
    .pv-s-114 {
        padding-top: 114px;
        padding-bottom: 114px;
    }
    .pt-s-116 {
        padding-top: 116px;
    }
    .pr-s-116 {
        padding-right: 116px;
    }
    .pb-s-116 {
        padding-bottom: 116px;
    }
    .pl-s-116 {
        padding-left: 116px;
    }
    .p-s-116 {
        padding: 116px;
    }
    .ph-s-116 {
        padding-left: 116px;
        padding-right: 116px;
    }
    .pv-s-116 {
        padding-top: 116px;
        padding-bottom: 116px;
    }
    .pt-s-118 {
        padding-top: 118px;
    }
    .pr-s-118 {
        padding-right: 118px;
    }
    .pb-s-118 {
        padding-bottom: 118px;
    }
    .pl-s-118 {
        padding-left: 118px;
    }
    .p-s-118 {
        padding: 118px;
    }
    .ph-s-118 {
        padding-left: 118px;
        padding-right: 118px;
    }
    .pv-s-118 {
        padding-top: 118px;
        padding-bottom: 118px;
    }
    .pt-s-120 {
        padding-top: 120px;
    }
    .pr-s-120 {
        padding-right: 120px;
    }
    .pb-s-120 {
        padding-bottom: 120px;
    }
    .pl-s-120 {
        padding-left: 120px;
    }
    .p-s-120 {
        padding: 120px;
    }
    .ph-s-120 {
        padding-left: 120px;
        padding-right: 120px;
    }
    .pv-s-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .pt-s-122 {
        padding-top: 122px;
    }
    .pr-s-122 {
        padding-right: 122px;
    }
    .pb-s-122 {
        padding-bottom: 122px;
    }
    .pl-s-122 {
        padding-left: 122px;
    }
    .p-s-122 {
        padding: 122px;
    }
    .ph-s-122 {
        padding-left: 122px;
        padding-right: 122px;
    }
    .pv-s-122 {
        padding-top: 122px;
        padding-bottom: 122px;
    }
    .pt-s-124 {
        padding-top: 124px;
    }
    .pr-s-124 {
        padding-right: 124px;
    }
    .pb-s-124 {
        padding-bottom: 124px;
    }
    .pl-s-124 {
        padding-left: 124px;
    }
    .p-s-124 {
        padding: 124px;
    }
    .ph-s-124 {
        padding-left: 124px;
        padding-right: 124px;
    }
    .pv-s-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .pt-s-126 {
        padding-top: 126px;
    }
    .pr-s-126 {
        padding-right: 126px;
    }
    .pb-s-126 {
        padding-bottom: 126px;
    }
    .pl-s-126 {
        padding-left: 126px;
    }
    .p-s-126 {
        padding: 126px;
    }
    .ph-s-126 {
        padding-left: 126px;
        padding-right: 126px;
    }
    .pv-s-126 {
        padding-top: 126px;
        padding-bottom: 126px;
    }
    .pt-s-128 {
        padding-top: 128px;
    }
    .pr-s-128 {
        padding-right: 128px;
    }
    .pb-s-128 {
        padding-bottom: 128px;
    }
    .pl-s-128 {
        padding-left: 128px;
    }
    .p-s-128 {
        padding: 128px;
    }
    .ph-s-128 {
        padding-left: 128px;
        padding-right: 128px;
    }
    .pv-s-128 {
        padding-top: 128px;
        padding-bottom: 128px;
    }
    .pt-s-130 {
        padding-top: 130px;
    }
    .pr-s-130 {
        padding-right: 130px;
    }
    .pb-s-130 {
        padding-bottom: 130px;
    }
    .pl-s-130 {
        padding-left: 130px;
    }
    .p-s-130 {
        padding: 130px;
    }
    .ph-s-130 {
        padding-left: 130px;
        padding-right: 130px;
    }
    .pv-s-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .pt-s-132 {
        padding-top: 132px;
    }
    .pr-s-132 {
        padding-right: 132px;
    }
    .pb-s-132 {
        padding-bottom: 132px;
    }
    .pl-s-132 {
        padding-left: 132px;
    }
    .p-s-132 {
        padding: 132px;
    }
    .ph-s-132 {
        padding-left: 132px;
        padding-right: 132px;
    }
    .pv-s-132 {
        padding-top: 132px;
        padding-bottom: 132px;
    }
    .pt-s-134 {
        padding-top: 134px;
    }
    .pr-s-134 {
        padding-right: 134px;
    }
    .pb-s-134 {
        padding-bottom: 134px;
    }
    .pl-s-134 {
        padding-left: 134px;
    }
    .p-s-134 {
        padding: 134px;
    }
    .ph-s-134 {
        padding-left: 134px;
        padding-right: 134px;
    }
    .pv-s-134 {
        padding-top: 134px;
        padding-bottom: 134px;
    }
    .pt-s-136 {
        padding-top: 136px;
    }
    .pr-s-136 {
        padding-right: 136px;
    }
    .pb-s-136 {
        padding-bottom: 136px;
    }
    .pl-s-136 {
        padding-left: 136px;
    }
    .p-s-136 {
        padding: 136px;
    }
    .ph-s-136 {
        padding-left: 136px;
        padding-right: 136px;
    }
    .pv-s-136 {
        padding-top: 136px;
        padding-bottom: 136px;
    }
    .pt-s-138 {
        padding-top: 138px;
    }
    .pr-s-138 {
        padding-right: 138px;
    }
    .pb-s-138 {
        padding-bottom: 138px;
    }
    .pl-s-138 {
        padding-left: 138px;
    }
    .p-s-138 {
        padding: 138px;
    }
    .ph-s-138 {
        padding-left: 138px;
        padding-right: 138px;
    }
    .pv-s-138 {
        padding-top: 138px;
        padding-bottom: 138px;
    }
    .pt-s-140 {
        padding-top: 140px;
    }
    .pr-s-140 {
        padding-right: 140px;
    }
    .pb-s-140 {
        padding-bottom: 140px;
    }
    .pl-s-140 {
        padding-left: 140px;
    }
    .p-s-140 {
        padding: 140px;
    }
    .ph-s-140 {
        padding-left: 140px;
        padding-right: 140px;
    }
    .pv-s-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    .p-s-0 {
        padding: 0;
    }
    .pt-s-0 {
        padding-top: 0;
    }
    .pr-s-0 {
        padding-right: 0;
    }
    .pb-s-0 {
        padding-bottom: 0;
    }
    .pl-s-0 {
        padding-left: 0;
    }
    .pv-s-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .rGap-s-2 {
        row-gap: 2px;
    }
    .rGap-s-4 {
        row-gap: 4px;
    }
    .rGap-s-6 {
        row-gap: 6px;
    }
    .rGap-s-8 {
        row-gap: 8px;
    }
    .rGap-s-10 {
        row-gap: 10px;
    }
    .rGap-s-12 {
        row-gap: 12px;
    }
    .rGap-s-14 {
        row-gap: 14px;
    }
    .rGap-s-16 {
        row-gap: 16px;
    }
    .rGap-s-18 {
        row-gap: 18px;
    }
    .rGap-s-20 {
        row-gap: 20px;
    }
    .rGap-s-22 {
        row-gap: 22px;
    }
    .rGap-s-24 {
        row-gap: 24px;
    }
    .rGap-s-26 {
        row-gap: 26px;
    }
    .rGap-s-28 {
        row-gap: 28px;
    }
    .rGap-s-30 {
        row-gap: 30px;
    }
    .rGap-s-32 {
        row-gap: 32px;
    }
    .rGap-s-34 {
        row-gap: 34px;
    }
    .rGap-s-36 {
        row-gap: 36px;
    }
    .rGap-s-38 {
        row-gap: 38px;
    }
    .rGap-s-40 {
        row-gap: 40px;
    }
    .rGap-s-42 {
        row-gap: 42px;
    }
    .rGap-s-44 {
        row-gap: 44px;
    }
    .rGap-s-46 {
        row-gap: 46px;
    }
    .rGap-s-48 {
        row-gap: 48px;
    }
    .rGap-s-50 {
        row-gap: 50px;
    }
    .rGap-s-52 {
        row-gap: 52px;
    }
    .rGap-s-54 {
        row-gap: 54px;
    }
    .rGap-s-56 {
        row-gap: 56px;
    }
    .rGap-s-58 {
        row-gap: 58px;
    }
    .rGap-s-60 {
        row-gap: 60px;
    }
    .rGap-s-62 {
        row-gap: 62px;
    }
    .rGap-s-64 {
        row-gap: 64px;
    }
    .rGap-s-66 {
        row-gap: 66px;
    }
    .rGap-s-68 {
        row-gap: 68px;
    }
    .rGap-s-70 {
        row-gap: 70px;
    }
    .rGap-s-72 {
        row-gap: 72px;
    }
    .rGap-s-74 {
        row-gap: 74px;
    }
    .rGap-s-76 {
        row-gap: 76px;
    }
    .rGap-s-78 {
        row-gap: 78px;
    }
    .rGap-s-80 {
        row-gap: 80px;
    }
    .rGap-s-82 {
        row-gap: 82px;
    }
    .rGap-s-84 {
        row-gap: 84px;
    }
    .rGap-s-86 {
        row-gap: 86px;
    }
    .rGap-s-88 {
        row-gap: 88px;
    }
    .rGap-s-90 {
        row-gap: 90px;
    }
    .rGap-s-92 {
        row-gap: 92px;
    }
    .rGap-s-94 {
        row-gap: 94px;
    }
    .rGap-s-96 {
        row-gap: 96px;
    }
    .rGap-s-98 {
        row-gap: 98px;
    }
    .rGap-s-100 {
        row-gap: 100px;
    }
    .rGap-s-102 {
        row-gap: 102px;
    }
    .rGap-s-104 {
        row-gap: 104px;
    }
    .rGap-s-106 {
        row-gap: 106px;
    }
    .rGap-s-108 {
        row-gap: 108px;
    }
    .rGap-s-110 {
        row-gap: 110px;
    }
    .rGap-s-112 {
        row-gap: 112px;
    }
    .rGap-s-114 {
        row-gap: 114px;
    }
    .rGap-s-116 {
        row-gap: 116px;
    }
    .rGap-s-118 {
        row-gap: 118px;
    }
    .rGap-s-120 {
        row-gap: 120px;
    }
    .rGap-s-122 {
        row-gap: 122px;
    }
    .rGap-s-124 {
        row-gap: 124px;
    }
    .rGap-s-126 {
        row-gap: 126px;
    }
    .rGap-s-128 {
        row-gap: 128px;
    }
    .rGap-s-130 {
        row-gap: 130px;
    }
    .rGap-s-132 {
        row-gap: 132px;
    }
    .rGap-s-134 {
        row-gap: 134px;
    }
    .rGap-s-136 {
        row-gap: 136px;
    }
    .rGap-s-138 {
        row-gap: 138px;
    }
    .rGap-s-140 {
        row-gap: 140px;
    }
    .rGap-s-0 {
        row-gap: 0;
    }
    .cGap-s-2 {
        column-gap: 2px;
    }
    .cGap-s-4 {
        column-gap: 4px;
    }
    .cGap-s-6 {
        column-gap: 6px;
    }
    .cGap-s-8 {
        column-gap: 8px;
    }
    .cGap-s-10 {
        column-gap: 10px;
    }
    .cGap-s-12 {
        column-gap: 12px;
    }
    .cGap-s-14 {
        column-gap: 14px;
    }
    .cGap-s-16 {
        column-gap: 16px;
    }
    .cGap-s-18 {
        column-gap: 18px;
    }
    .cGap-s-20 {
        column-gap: 20px;
    }
    .cGap-s-22 {
        column-gap: 22px;
    }
    .cGap-s-24 {
        column-gap: 24px;
    }
    .cGap-s-26 {
        column-gap: 26px;
    }
    .cGap-s-28 {
        column-gap: 28px;
    }
    .cGap-s-30 {
        column-gap: 30px;
    }
    .cGap-s-32 {
        column-gap: 32px;
    }
    .cGap-s-34 {
        column-gap: 34px;
    }
    .cGap-s-36 {
        column-gap: 36px;
    }
    .cGap-s-38 {
        column-gap: 38px;
    }
    .cGap-s-40 {
        column-gap: 40px;
    }
    .cGap-s-42 {
        column-gap: 42px;
    }
    .cGap-s-44 {
        column-gap: 44px;
    }
    .cGap-s-46 {
        column-gap: 46px;
    }
    .cGap-s-48 {
        column-gap: 48px;
    }
    .cGap-s-50 {
        column-gap: 50px;
    }
    .cGap-s-52 {
        column-gap: 52px;
    }
    .cGap-s-54 {
        column-gap: 54px;
    }
    .cGap-s-56 {
        column-gap: 56px;
    }
    .cGap-s-58 {
        column-gap: 58px;
    }
    .cGap-s-60 {
        column-gap: 60px;
    }
    .cGap-s-62 {
        column-gap: 62px;
    }
    .cGap-s-64 {
        column-gap: 64px;
    }
    .cGap-s-66 {
        column-gap: 66px;
    }
    .cGap-s-68 {
        column-gap: 68px;
    }
    .cGap-s-70 {
        column-gap: 70px;
    }
    .cGap-s-72 {
        column-gap: 72px;
    }
    .cGap-s-74 {
        column-gap: 74px;
    }
    .cGap-s-76 {
        column-gap: 76px;
    }
    .cGap-s-78 {
        column-gap: 78px;
    }
    .cGap-s-80 {
        column-gap: 80px;
    }
    .cGap-s-82 {
        column-gap: 82px;
    }
    .cGap-s-84 {
        column-gap: 84px;
    }
    .cGap-s-86 {
        column-gap: 86px;
    }
    .cGap-s-88 {
        column-gap: 88px;
    }
    .cGap-s-90 {
        column-gap: 90px;
    }
    .cGap-s-92 {
        column-gap: 92px;
    }
    .cGap-s-94 {
        column-gap: 94px;
    }
    .cGap-s-96 {
        column-gap: 96px;
    }
    .cGap-s-98 {
        column-gap: 98px;
    }
    .cGap-s-100 {
        column-gap: 100px;
    }
    .cGap-s-102 {
        column-gap: 102px;
    }
    .cGap-s-104 {
        column-gap: 104px;
    }
    .cGap-s-106 {
        column-gap: 106px;
    }
    .cGap-s-108 {
        column-gap: 108px;
    }
    .cGap-s-110 {
        column-gap: 110px;
    }
    .cGap-s-112 {
        column-gap: 112px;
    }
    .cGap-s-114 {
        column-gap: 114px;
    }
    .cGap-s-116 {
        column-gap: 116px;
    }
    .cGap-s-118 {
        column-gap: 118px;
    }
    .cGap-s-120 {
        column-gap: 120px;
    }
    .cGap-s-122 {
        column-gap: 122px;
    }
    .cGap-s-124 {
        column-gap: 124px;
    }
    .cGap-s-126 {
        column-gap: 126px;
    }
    .cGap-s-128 {
        column-gap: 128px;
    }
    .cGap-s-130 {
        column-gap: 130px;
    }
    .cGap-s-132 {
        column-gap: 132px;
    }
    .cGap-s-134 {
        column-gap: 134px;
    }
    .cGap-s-136 {
        column-gap: 136px;
    }
    .cGap-s-138 {
        column-gap: 138px;
    }
    .cGap-s-140 {
        column-gap: 140px;
    }
    .cGap-s-0 {
        column-gap: 0;
    }
}
@media screen and (max-width: 0) {
    .mt-xs-2 {
        margin-top: 2px;
    }
    .mr-xs-2 {
        margin-right: 2px;
    }
    .mb-xs-2 {
        margin-bottom: 2px;
    }
    .ml-xs-2 {
        margin-left: 2px;
    }
    .m-xs-2 {
        margin: 2px;
    }
    .mh-xs-2 {
        margin-left: 2px;
        margin-right: 2px;
    }
    .mv-xs-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .mt-xs-4 {
        margin-top: 4px;
    }
    .mr-xs-4 {
        margin-right: 4px;
    }
    .mb-xs-4 {
        margin-bottom: 4px;
    }
    .ml-xs-4 {
        margin-left: 4px;
    }
    .m-xs-4 {
        margin: 4px;
    }
    .mh-xs-4 {
        margin-left: 4px;
        margin-right: 4px;
    }
    .mv-xs-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .mt-xs-6 {
        margin-top: 6px;
    }
    .mr-xs-6 {
        margin-right: 6px;
    }
    .mb-xs-6 {
        margin-bottom: 6px;
    }
    .ml-xs-6 {
        margin-left: 6px;
    }
    .m-xs-6 {
        margin: 6px;
    }
    .mh-xs-6 {
        margin-left: 6px;
        margin-right: 6px;
    }
    .mv-xs-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .mt-xs-8 {
        margin-top: 8px;
    }
    .mr-xs-8 {
        margin-right: 8px;
    }
    .mb-xs-8 {
        margin-bottom: 8px;
    }
    .ml-xs-8 {
        margin-left: 8px;
    }
    .m-xs-8 {
        margin: 8px;
    }
    .mh-xs-8 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .mv-xs-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .mt-xs-10 {
        margin-top: 10px;
    }
    .mr-xs-10 {
        margin-right: 10px;
    }
    .mb-xs-10 {
        margin-bottom: 10px;
    }
    .ml-xs-10 {
        margin-left: 10px;
    }
    .m-xs-10 {
        margin: 10px;
    }
    .mh-xs-10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    .mv-xs-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mt-xs-12 {
        margin-top: 12px;
    }
    .mr-xs-12 {
        margin-right: 12px;
    }
    .mb-xs-12 {
        margin-bottom: 12px;
    }
    .ml-xs-12 {
        margin-left: 12px;
    }
    .m-xs-12 {
        margin: 12px;
    }
    .mh-xs-12 {
        margin-left: 12px;
        margin-right: 12px;
    }
    .mv-xs-12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .mt-xs-14 {
        margin-top: 14px;
    }
    .mr-xs-14 {
        margin-right: 14px;
    }
    .mb-xs-14 {
        margin-bottom: 14px;
    }
    .ml-xs-14 {
        margin-left: 14px;
    }
    .m-xs-14 {
        margin: 14px;
    }
    .mh-xs-14 {
        margin-left: 14px;
        margin-right: 14px;
    }
    .mv-xs-14 {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .mt-xs-16 {
        margin-top: 16px;
    }
    .mr-xs-16 {
        margin-right: 16px;
    }
    .mb-xs-16 {
        margin-bottom: 16px;
    }
    .ml-xs-16 {
        margin-left: 16px;
    }
    .m-xs-16 {
        margin: 16px;
    }
    .mh-xs-16 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .mv-xs-16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .mt-xs-18 {
        margin-top: 18px;
    }
    .mr-xs-18 {
        margin-right: 18px;
    }
    .mb-xs-18 {
        margin-bottom: 18px;
    }
    .ml-xs-18 {
        margin-left: 18px;
    }
    .m-xs-18 {
        margin: 18px;
    }
    .mh-xs-18 {
        margin-left: 18px;
        margin-right: 18px;
    }
    .mv-xs-18 {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .mt-xs-20 {
        margin-top: 20px;
    }
    .mr-xs-20 {
        margin-right: 20px;
    }
    .mb-xs-20 {
        margin-bottom: 20px;
    }
    .ml-xs-20 {
        margin-left: 20px;
    }
    .m-xs-20 {
        margin: 20px;
    }
    .mh-xs-20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    .mv-xs-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .mt-xs-22 {
        margin-top: 22px;
    }
    .mr-xs-22 {
        margin-right: 22px;
    }
    .mb-xs-22 {
        margin-bottom: 22px;
    }
    .ml-xs-22 {
        margin-left: 22px;
    }
    .m-xs-22 {
        margin: 22px;
    }
    .mh-xs-22 {
        margin-left: 22px;
        margin-right: 22px;
    }
    .mv-xs-22 {
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .mt-xs-24 {
        margin-top: 24px;
    }
    .mr-xs-24 {
        margin-right: 24px;
    }
    .mb-xs-24 {
        margin-bottom: 24px;
    }
    .ml-xs-24 {
        margin-left: 24px;
    }
    .m-xs-24 {
        margin: 24px;
    }
    .mh-xs-24 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .mv-xs-24 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .mt-xs-26 {
        margin-top: 26px;
    }
    .mr-xs-26 {
        margin-right: 26px;
    }
    .mb-xs-26 {
        margin-bottom: 26px;
    }
    .ml-xs-26 {
        margin-left: 26px;
    }
    .m-xs-26 {
        margin: 26px;
    }
    .mh-xs-26 {
        margin-left: 26px;
        margin-right: 26px;
    }
    .mv-xs-26 {
        margin-top: 26px;
        margin-bottom: 26px;
    }
    .mt-xs-28 {
        margin-top: 28px;
    }
    .mr-xs-28 {
        margin-right: 28px;
    }
    .mb-xs-28 {
        margin-bottom: 28px;
    }
    .ml-xs-28 {
        margin-left: 28px;
    }
    .m-xs-28 {
        margin: 28px;
    }
    .mh-xs-28 {
        margin-left: 28px;
        margin-right: 28px;
    }
    .mv-xs-28 {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .mt-xs-30 {
        margin-top: 30px;
    }
    .mr-xs-30 {
        margin-right: 30px;
    }
    .mb-xs-30 {
        margin-bottom: 30px;
    }
    .ml-xs-30 {
        margin-left: 30px;
    }
    .m-xs-30 {
        margin: 30px;
    }
    .mh-xs-30 {
        margin-left: 30px;
        margin-right: 30px;
    }
    .mv-xs-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .mt-xs-32 {
        margin-top: 32px;
    }
    .mr-xs-32 {
        margin-right: 32px;
    }
    .mb-xs-32 {
        margin-bottom: 32px;
    }
    .ml-xs-32 {
        margin-left: 32px;
    }
    .m-xs-32 {
        margin: 32px;
    }
    .mh-xs-32 {
        margin-left: 32px;
        margin-right: 32px;
    }
    .mv-xs-32 {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .mt-xs-34 {
        margin-top: 34px;
    }
    .mr-xs-34 {
        margin-right: 34px;
    }
    .mb-xs-34 {
        margin-bottom: 34px;
    }
    .ml-xs-34 {
        margin-left: 34px;
    }
    .m-xs-34 {
        margin: 34px;
    }
    .mh-xs-34 {
        margin-left: 34px;
        margin-right: 34px;
    }
    .mv-xs-34 {
        margin-top: 34px;
        margin-bottom: 34px;
    }
    .mt-xs-36 {
        margin-top: 36px;
    }
    .mr-xs-36 {
        margin-right: 36px;
    }
    .mb-xs-36 {
        margin-bottom: 36px;
    }
    .ml-xs-36 {
        margin-left: 36px;
    }
    .m-xs-36 {
        margin: 36px;
    }
    .mh-xs-36 {
        margin-left: 36px;
        margin-right: 36px;
    }
    .mv-xs-36 {
        margin-top: 36px;
        margin-bottom: 36px;
    }
    .mt-xs-38 {
        margin-top: 38px;
    }
    .mr-xs-38 {
        margin-right: 38px;
    }
    .mb-xs-38 {
        margin-bottom: 38px;
    }
    .ml-xs-38 {
        margin-left: 38px;
    }
    .m-xs-38 {
        margin: 38px;
    }
    .mh-xs-38 {
        margin-left: 38px;
        margin-right: 38px;
    }
    .mv-xs-38 {
        margin-top: 38px;
        margin-bottom: 38px;
    }
    .mt-xs-40 {
        margin-top: 40px;
    }
    .mr-xs-40 {
        margin-right: 40px;
    }
    .mb-xs-40 {
        margin-bottom: 40px;
    }
    .ml-xs-40 {
        margin-left: 40px;
    }
    .m-xs-40 {
        margin: 40px;
    }
    .mh-xs-40 {
        margin-left: 40px;
        margin-right: 40px;
    }
    .mv-xs-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .mt-xs-42 {
        margin-top: 42px;
    }
    .mr-xs-42 {
        margin-right: 42px;
    }
    .mb-xs-42 {
        margin-bottom: 42px;
    }
    .ml-xs-42 {
        margin-left: 42px;
    }
    .m-xs-42 {
        margin: 42px;
    }
    .mh-xs-42 {
        margin-left: 42px;
        margin-right: 42px;
    }
    .mv-xs-42 {
        margin-top: 42px;
        margin-bottom: 42px;
    }
    .mt-xs-44 {
        margin-top: 44px;
    }
    .mr-xs-44 {
        margin-right: 44px;
    }
    .mb-xs-44 {
        margin-bottom: 44px;
    }
    .ml-xs-44 {
        margin-left: 44px;
    }
    .m-xs-44 {
        margin: 44px;
    }
    .mh-xs-44 {
        margin-left: 44px;
        margin-right: 44px;
    }
    .mv-xs-44 {
        margin-top: 44px;
        margin-bottom: 44px;
    }
    .mt-xs-46 {
        margin-top: 46px;
    }
    .mr-xs-46 {
        margin-right: 46px;
    }
    .mb-xs-46 {
        margin-bottom: 46px;
    }
    .ml-xs-46 {
        margin-left: 46px;
    }
    .m-xs-46 {
        margin: 46px;
    }
    .mh-xs-46 {
        margin-left: 46px;
        margin-right: 46px;
    }
    .mv-xs-46 {
        margin-top: 46px;
        margin-bottom: 46px;
    }
    .mt-xs-48 {
        margin-top: 48px;
    }
    .mr-xs-48 {
        margin-right: 48px;
    }
    .mb-xs-48 {
        margin-bottom: 48px;
    }
    .ml-xs-48 {
        margin-left: 48px;
    }
    .m-xs-48 {
        margin: 48px;
    }
    .mh-xs-48 {
        margin-left: 48px;
        margin-right: 48px;
    }
    .mv-xs-48 {
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .mt-xs-50 {
        margin-top: 50px;
    }
    .mr-xs-50 {
        margin-right: 50px;
    }
    .mb-xs-50 {
        margin-bottom: 50px;
    }
    .ml-xs-50 {
        margin-left: 50px;
    }
    .m-xs-50 {
        margin: 50px;
    }
    .mh-xs-50 {
        margin-left: 50px;
        margin-right: 50px;
    }
    .mv-xs-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .mt-xs-52 {
        margin-top: 52px;
    }
    .mr-xs-52 {
        margin-right: 52px;
    }
    .mb-xs-52 {
        margin-bottom: 52px;
    }
    .ml-xs-52 {
        margin-left: 52px;
    }
    .m-xs-52 {
        margin: 52px;
    }
    .mh-xs-52 {
        margin-left: 52px;
        margin-right: 52px;
    }
    .mv-xs-52 {
        margin-top: 52px;
        margin-bottom: 52px;
    }
    .mt-xs-54 {
        margin-top: 54px;
    }
    .mr-xs-54 {
        margin-right: 54px;
    }
    .mb-xs-54 {
        margin-bottom: 54px;
    }
    .ml-xs-54 {
        margin-left: 54px;
    }
    .m-xs-54 {
        margin: 54px;
    }
    .mh-xs-54 {
        margin-left: 54px;
        margin-right: 54px;
    }
    .mv-xs-54 {
        margin-top: 54px;
        margin-bottom: 54px;
    }
    .mt-xs-56 {
        margin-top: 56px;
    }
    .mr-xs-56 {
        margin-right: 56px;
    }
    .mb-xs-56 {
        margin-bottom: 56px;
    }
    .ml-xs-56 {
        margin-left: 56px;
    }
    .m-xs-56 {
        margin: 56px;
    }
    .mh-xs-56 {
        margin-left: 56px;
        margin-right: 56px;
    }
    .mv-xs-56 {
        margin-top: 56px;
        margin-bottom: 56px;
    }
    .mt-xs-58 {
        margin-top: 58px;
    }
    .mr-xs-58 {
        margin-right: 58px;
    }
    .mb-xs-58 {
        margin-bottom: 58px;
    }
    .ml-xs-58 {
        margin-left: 58px;
    }
    .m-xs-58 {
        margin: 58px;
    }
    .mh-xs-58 {
        margin-left: 58px;
        margin-right: 58px;
    }
    .mv-xs-58 {
        margin-top: 58px;
        margin-bottom: 58px;
    }
    .mt-xs-60 {
        margin-top: 60px;
    }
    .mr-xs-60 {
        margin-right: 60px;
    }
    .mb-xs-60 {
        margin-bottom: 60px;
    }
    .ml-xs-60 {
        margin-left: 60px;
    }
    .m-xs-60 {
        margin: 60px;
    }
    .mh-xs-60 {
        margin-left: 60px;
        margin-right: 60px;
    }
    .mv-xs-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .mt-xs-62 {
        margin-top: 62px;
    }
    .mr-xs-62 {
        margin-right: 62px;
    }
    .mb-xs-62 {
        margin-bottom: 62px;
    }
    .ml-xs-62 {
        margin-left: 62px;
    }
    .m-xs-62 {
        margin: 62px;
    }
    .mh-xs-62 {
        margin-left: 62px;
        margin-right: 62px;
    }
    .mv-xs-62 {
        margin-top: 62px;
        margin-bottom: 62px;
    }
    .mt-xs-64 {
        margin-top: 64px;
    }
    .mr-xs-64 {
        margin-right: 64px;
    }
    .mb-xs-64 {
        margin-bottom: 64px;
    }
    .ml-xs-64 {
        margin-left: 64px;
    }
    .m-xs-64 {
        margin: 64px;
    }
    .mh-xs-64 {
        margin-left: 64px;
        margin-right: 64px;
    }
    .mv-xs-64 {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    .mt-xs-66 {
        margin-top: 66px;
    }
    .mr-xs-66 {
        margin-right: 66px;
    }
    .mb-xs-66 {
        margin-bottom: 66px;
    }
    .ml-xs-66 {
        margin-left: 66px;
    }
    .m-xs-66 {
        margin: 66px;
    }
    .mh-xs-66 {
        margin-left: 66px;
        margin-right: 66px;
    }
    .mv-xs-66 {
        margin-top: 66px;
        margin-bottom: 66px;
    }
    .mt-xs-68 {
        margin-top: 68px;
    }
    .mr-xs-68 {
        margin-right: 68px;
    }
    .mb-xs-68 {
        margin-bottom: 68px;
    }
    .ml-xs-68 {
        margin-left: 68px;
    }
    .m-xs-68 {
        margin: 68px;
    }
    .mh-xs-68 {
        margin-left: 68px;
        margin-right: 68px;
    }
    .mv-xs-68 {
        margin-top: 68px;
        margin-bottom: 68px;
    }
    .mt-xs-70 {
        margin-top: 70px;
    }
    .mr-xs-70 {
        margin-right: 70px;
    }
    .mb-xs-70 {
        margin-bottom: 70px;
    }
    .ml-xs-70 {
        margin-left: 70px;
    }
    .m-xs-70 {
        margin: 70px;
    }
    .mh-xs-70 {
        margin-left: 70px;
        margin-right: 70px;
    }
    .mv-xs-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .mt-xs-72 {
        margin-top: 72px;
    }
    .mr-xs-72 {
        margin-right: 72px;
    }
    .mb-xs-72 {
        margin-bottom: 72px;
    }
    .ml-xs-72 {
        margin-left: 72px;
    }
    .m-xs-72 {
        margin: 72px;
    }
    .mh-xs-72 {
        margin-left: 72px;
        margin-right: 72px;
    }
    .mv-xs-72 {
        margin-top: 72px;
        margin-bottom: 72px;
    }
    .mt-xs-74 {
        margin-top: 74px;
    }
    .mr-xs-74 {
        margin-right: 74px;
    }
    .mb-xs-74 {
        margin-bottom: 74px;
    }
    .ml-xs-74 {
        margin-left: 74px;
    }
    .m-xs-74 {
        margin: 74px;
    }
    .mh-xs-74 {
        margin-left: 74px;
        margin-right: 74px;
    }
    .mv-xs-74 {
        margin-top: 74px;
        margin-bottom: 74px;
    }
    .mt-xs-76 {
        margin-top: 76px;
    }
    .mr-xs-76 {
        margin-right: 76px;
    }
    .mb-xs-76 {
        margin-bottom: 76px;
    }
    .ml-xs-76 {
        margin-left: 76px;
    }
    .m-xs-76 {
        margin: 76px;
    }
    .mh-xs-76 {
        margin-left: 76px;
        margin-right: 76px;
    }
    .mv-xs-76 {
        margin-top: 76px;
        margin-bottom: 76px;
    }
    .mt-xs-78 {
        margin-top: 78px;
    }
    .mr-xs-78 {
        margin-right: 78px;
    }
    .mb-xs-78 {
        margin-bottom: 78px;
    }
    .ml-xs-78 {
        margin-left: 78px;
    }
    .m-xs-78 {
        margin: 78px;
    }
    .mh-xs-78 {
        margin-left: 78px;
        margin-right: 78px;
    }
    .mv-xs-78 {
        margin-top: 78px;
        margin-bottom: 78px;
    }
    .mt-xs-80 {
        margin-top: 80px;
    }
    .mr-xs-80 {
        margin-right: 80px;
    }
    .mb-xs-80 {
        margin-bottom: 80px;
    }
    .ml-xs-80 {
        margin-left: 80px;
    }
    .m-xs-80 {
        margin: 80px;
    }
    .mh-xs-80 {
        margin-left: 80px;
        margin-right: 80px;
    }
    .mv-xs-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .mt-xs-82 {
        margin-top: 82px;
    }
    .mr-xs-82 {
        margin-right: 82px;
    }
    .mb-xs-82 {
        margin-bottom: 82px;
    }
    .ml-xs-82 {
        margin-left: 82px;
    }
    .m-xs-82 {
        margin: 82px;
    }
    .mh-xs-82 {
        margin-left: 82px;
        margin-right: 82px;
    }
    .mv-xs-82 {
        margin-top: 82px;
        margin-bottom: 82px;
    }
    .mt-xs-84 {
        margin-top: 84px;
    }
    .mr-xs-84 {
        margin-right: 84px;
    }
    .mb-xs-84 {
        margin-bottom: 84px;
    }
    .ml-xs-84 {
        margin-left: 84px;
    }
    .m-xs-84 {
        margin: 84px;
    }
    .mh-xs-84 {
        margin-left: 84px;
        margin-right: 84px;
    }
    .mv-xs-84 {
        margin-top: 84px;
        margin-bottom: 84px;
    }
    .mt-xs-86 {
        margin-top: 86px;
    }
    .mr-xs-86 {
        margin-right: 86px;
    }
    .mb-xs-86 {
        margin-bottom: 86px;
    }
    .ml-xs-86 {
        margin-left: 86px;
    }
    .m-xs-86 {
        margin: 86px;
    }
    .mh-xs-86 {
        margin-left: 86px;
        margin-right: 86px;
    }
    .mv-xs-86 {
        margin-top: 86px;
        margin-bottom: 86px;
    }
    .mt-xs-88 {
        margin-top: 88px;
    }
    .mr-xs-88 {
        margin-right: 88px;
    }
    .mb-xs-88 {
        margin-bottom: 88px;
    }
    .ml-xs-88 {
        margin-left: 88px;
    }
    .m-xs-88 {
        margin: 88px;
    }
    .mh-xs-88 {
        margin-left: 88px;
        margin-right: 88px;
    }
    .mv-xs-88 {
        margin-top: 88px;
        margin-bottom: 88px;
    }
    .mt-xs-90 {
        margin-top: 90px;
    }
    .mr-xs-90 {
        margin-right: 90px;
    }
    .mb-xs-90 {
        margin-bottom: 90px;
    }
    .ml-xs-90 {
        margin-left: 90px;
    }
    .m-xs-90 {
        margin: 90px;
    }
    .mh-xs-90 {
        margin-left: 90px;
        margin-right: 90px;
    }
    .mv-xs-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }
    .mt-xs-92 {
        margin-top: 92px;
    }
    .mr-xs-92 {
        margin-right: 92px;
    }
    .mb-xs-92 {
        margin-bottom: 92px;
    }
    .ml-xs-92 {
        margin-left: 92px;
    }
    .m-xs-92 {
        margin: 92px;
    }
    .mh-xs-92 {
        margin-left: 92px;
        margin-right: 92px;
    }
    .mv-xs-92 {
        margin-top: 92px;
        margin-bottom: 92px;
    }
    .mt-xs-94 {
        margin-top: 94px;
    }
    .mr-xs-94 {
        margin-right: 94px;
    }
    .mb-xs-94 {
        margin-bottom: 94px;
    }
    .ml-xs-94 {
        margin-left: 94px;
    }
    .m-xs-94 {
        margin: 94px;
    }
    .mh-xs-94 {
        margin-left: 94px;
        margin-right: 94px;
    }
    .mv-xs-94 {
        margin-top: 94px;
        margin-bottom: 94px;
    }
    .mt-xs-96 {
        margin-top: 96px;
    }
    .mr-xs-96 {
        margin-right: 96px;
    }
    .mb-xs-96 {
        margin-bottom: 96px;
    }
    .ml-xs-96 {
        margin-left: 96px;
    }
    .m-xs-96 {
        margin: 96px;
    }
    .mh-xs-96 {
        margin-left: 96px;
        margin-right: 96px;
    }
    .mv-xs-96 {
        margin-top: 96px;
        margin-bottom: 96px;
    }
    .mt-xs-98 {
        margin-top: 98px;
    }
    .mr-xs-98 {
        margin-right: 98px;
    }
    .mb-xs-98 {
        margin-bottom: 98px;
    }
    .ml-xs-98 {
        margin-left: 98px;
    }
    .m-xs-98 {
        margin: 98px;
    }
    .mh-xs-98 {
        margin-left: 98px;
        margin-right: 98px;
    }
    .mv-xs-98 {
        margin-top: 98px;
        margin-bottom: 98px;
    }
    .mt-xs-100 {
        margin-top: 100px;
    }
    .mr-xs-100 {
        margin-right: 100px;
    }
    .mb-xs-100 {
        margin-bottom: 100px;
    }
    .ml-xs-100 {
        margin-left: 100px;
    }
    .m-xs-100 {
        margin: 100px;
    }
    .mh-xs-100 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .mv-xs-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .mt-xs-102 {
        margin-top: 102px;
    }
    .mr-xs-102 {
        margin-right: 102px;
    }
    .mb-xs-102 {
        margin-bottom: 102px;
    }
    .ml-xs-102 {
        margin-left: 102px;
    }
    .m-xs-102 {
        margin: 102px;
    }
    .mh-xs-102 {
        margin-left: 102px;
        margin-right: 102px;
    }
    .mv-xs-102 {
        margin-top: 102px;
        margin-bottom: 102px;
    }
    .mt-xs-104 {
        margin-top: 104px;
    }
    .mr-xs-104 {
        margin-right: 104px;
    }
    .mb-xs-104 {
        margin-bottom: 104px;
    }
    .ml-xs-104 {
        margin-left: 104px;
    }
    .m-xs-104 {
        margin: 104px;
    }
    .mh-xs-104 {
        margin-left: 104px;
        margin-right: 104px;
    }
    .mv-xs-104 {
        margin-top: 104px;
        margin-bottom: 104px;
    }
    .mt-xs-106 {
        margin-top: 106px;
    }
    .mr-xs-106 {
        margin-right: 106px;
    }
    .mb-xs-106 {
        margin-bottom: 106px;
    }
    .ml-xs-106 {
        margin-left: 106px;
    }
    .m-xs-106 {
        margin: 106px;
    }
    .mh-xs-106 {
        margin-left: 106px;
        margin-right: 106px;
    }
    .mv-xs-106 {
        margin-top: 106px;
        margin-bottom: 106px;
    }
    .mt-xs-108 {
        margin-top: 108px;
    }
    .mr-xs-108 {
        margin-right: 108px;
    }
    .mb-xs-108 {
        margin-bottom: 108px;
    }
    .ml-xs-108 {
        margin-left: 108px;
    }
    .m-xs-108 {
        margin: 108px;
    }
    .mh-xs-108 {
        margin-left: 108px;
        margin-right: 108px;
    }
    .mv-xs-108 {
        margin-top: 108px;
        margin-bottom: 108px;
    }
    .mt-xs-110 {
        margin-top: 110px;
    }
    .mr-xs-110 {
        margin-right: 110px;
    }
    .mb-xs-110 {
        margin-bottom: 110px;
    }
    .ml-xs-110 {
        margin-left: 110px;
    }
    .m-xs-110 {
        margin: 110px;
    }
    .mh-xs-110 {
        margin-left: 110px;
        margin-right: 110px;
    }
    .mv-xs-110 {
        margin-top: 110px;
        margin-bottom: 110px;
    }
    .mt-xs-112 {
        margin-top: 112px;
    }
    .mr-xs-112 {
        margin-right: 112px;
    }
    .mb-xs-112 {
        margin-bottom: 112px;
    }
    .ml-xs-112 {
        margin-left: 112px;
    }
    .m-xs-112 {
        margin: 112px;
    }
    .mh-xs-112 {
        margin-left: 112px;
        margin-right: 112px;
    }
    .mv-xs-112 {
        margin-top: 112px;
        margin-bottom: 112px;
    }
    .mt-xs-114 {
        margin-top: 114px;
    }
    .mr-xs-114 {
        margin-right: 114px;
    }
    .mb-xs-114 {
        margin-bottom: 114px;
    }
    .ml-xs-114 {
        margin-left: 114px;
    }
    .m-xs-114 {
        margin: 114px;
    }
    .mh-xs-114 {
        margin-left: 114px;
        margin-right: 114px;
    }
    .mv-xs-114 {
        margin-top: 114px;
        margin-bottom: 114px;
    }
    .mt-xs-116 {
        margin-top: 116px;
    }
    .mr-xs-116 {
        margin-right: 116px;
    }
    .mb-xs-116 {
        margin-bottom: 116px;
    }
    .ml-xs-116 {
        margin-left: 116px;
    }
    .m-xs-116 {
        margin: 116px;
    }
    .mh-xs-116 {
        margin-left: 116px;
        margin-right: 116px;
    }
    .mv-xs-116 {
        margin-top: 116px;
        margin-bottom: 116px;
    }
    .mt-xs-118 {
        margin-top: 118px;
    }
    .mr-xs-118 {
        margin-right: 118px;
    }
    .mb-xs-118 {
        margin-bottom: 118px;
    }
    .ml-xs-118 {
        margin-left: 118px;
    }
    .m-xs-118 {
        margin: 118px;
    }
    .mh-xs-118 {
        margin-left: 118px;
        margin-right: 118px;
    }
    .mv-xs-118 {
        margin-top: 118px;
        margin-bottom: 118px;
    }
    .mt-xs-120 {
        margin-top: 120px;
    }
    .mr-xs-120 {
        margin-right: 120px;
    }
    .mb-xs-120 {
        margin-bottom: 120px;
    }
    .ml-xs-120 {
        margin-left: 120px;
    }
    .m-xs-120 {
        margin: 120px;
    }
    .mh-xs-120 {
        margin-left: 120px;
        margin-right: 120px;
    }
    .mv-xs-120 {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .mt-xs-122 {
        margin-top: 122px;
    }
    .mr-xs-122 {
        margin-right: 122px;
    }
    .mb-xs-122 {
        margin-bottom: 122px;
    }
    .ml-xs-122 {
        margin-left: 122px;
    }
    .m-xs-122 {
        margin: 122px;
    }
    .mh-xs-122 {
        margin-left: 122px;
        margin-right: 122px;
    }
    .mv-xs-122 {
        margin-top: 122px;
        margin-bottom: 122px;
    }
    .mt-xs-124 {
        margin-top: 124px;
    }
    .mr-xs-124 {
        margin-right: 124px;
    }
    .mb-xs-124 {
        margin-bottom: 124px;
    }
    .ml-xs-124 {
        margin-left: 124px;
    }
    .m-xs-124 {
        margin: 124px;
    }
    .mh-xs-124 {
        margin-left: 124px;
        margin-right: 124px;
    }
    .mv-xs-124 {
        margin-top: 124px;
        margin-bottom: 124px;
    }
    .mt-xs-126 {
        margin-top: 126px;
    }
    .mr-xs-126 {
        margin-right: 126px;
    }
    .mb-xs-126 {
        margin-bottom: 126px;
    }
    .ml-xs-126 {
        margin-left: 126px;
    }
    .m-xs-126 {
        margin: 126px;
    }
    .mh-xs-126 {
        margin-left: 126px;
        margin-right: 126px;
    }
    .mv-xs-126 {
        margin-top: 126px;
        margin-bottom: 126px;
    }
    .mt-xs-128 {
        margin-top: 128px;
    }
    .mr-xs-128 {
        margin-right: 128px;
    }
    .mb-xs-128 {
        margin-bottom: 128px;
    }
    .ml-xs-128 {
        margin-left: 128px;
    }
    .m-xs-128 {
        margin: 128px;
    }
    .mh-xs-128 {
        margin-left: 128px;
        margin-right: 128px;
    }
    .mv-xs-128 {
        margin-top: 128px;
        margin-bottom: 128px;
    }
    .mt-xs-130 {
        margin-top: 130px;
    }
    .mr-xs-130 {
        margin-right: 130px;
    }
    .mb-xs-130 {
        margin-bottom: 130px;
    }
    .ml-xs-130 {
        margin-left: 130px;
    }
    .m-xs-130 {
        margin: 130px;
    }
    .mh-xs-130 {
        margin-left: 130px;
        margin-right: 130px;
    }
    .mv-xs-130 {
        margin-top: 130px;
        margin-bottom: 130px;
    }
    .mt-xs-132 {
        margin-top: 132px;
    }
    .mr-xs-132 {
        margin-right: 132px;
    }
    .mb-xs-132 {
        margin-bottom: 132px;
    }
    .ml-xs-132 {
        margin-left: 132px;
    }
    .m-xs-132 {
        margin: 132px;
    }
    .mh-xs-132 {
        margin-left: 132px;
        margin-right: 132px;
    }
    .mv-xs-132 {
        margin-top: 132px;
        margin-bottom: 132px;
    }
    .mt-xs-134 {
        margin-top: 134px;
    }
    .mr-xs-134 {
        margin-right: 134px;
    }
    .mb-xs-134 {
        margin-bottom: 134px;
    }
    .ml-xs-134 {
        margin-left: 134px;
    }
    .m-xs-134 {
        margin: 134px;
    }
    .mh-xs-134 {
        margin-left: 134px;
        margin-right: 134px;
    }
    .mv-xs-134 {
        margin-top: 134px;
        margin-bottom: 134px;
    }
    .mt-xs-136 {
        margin-top: 136px;
    }
    .mr-xs-136 {
        margin-right: 136px;
    }
    .mb-xs-136 {
        margin-bottom: 136px;
    }
    .ml-xs-136 {
        margin-left: 136px;
    }
    .m-xs-136 {
        margin: 136px;
    }
    .mh-xs-136 {
        margin-left: 136px;
        margin-right: 136px;
    }
    .mv-xs-136 {
        margin-top: 136px;
        margin-bottom: 136px;
    }
    .mt-xs-138 {
        margin-top: 138px;
    }
    .mr-xs-138 {
        margin-right: 138px;
    }
    .mb-xs-138 {
        margin-bottom: 138px;
    }
    .ml-xs-138 {
        margin-left: 138px;
    }
    .m-xs-138 {
        margin: 138px;
    }
    .mh-xs-138 {
        margin-left: 138px;
        margin-right: 138px;
    }
    .mv-xs-138 {
        margin-top: 138px;
        margin-bottom: 138px;
    }
    .mt-xs-140 {
        margin-top: 140px;
    }
    .mr-xs-140 {
        margin-right: 140px;
    }
    .mb-xs-140 {
        margin-bottom: 140px;
    }
    .ml-xs-140 {
        margin-left: 140px;
    }
    .m-xs-140 {
        margin: 140px;
    }
    .mh-xs-140 {
        margin-left: 140px;
        margin-right: 140px;
    }
    .mv-xs-140 {
        margin-top: 140px;
        margin-bottom: 140px;
    }
    .m-xs-0 {
        margin: 0;
    }
    .mt-xs-0 {
        margin-top: 0;
    }
    .mr-xs-0 {
        margin-right: 0;
    }
    .mb-xs-0 {
        margin-bottom: 0;
    }
    .ml-xs-0 {
        margin-left: 0;
    }
    .mv-xs-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pt-xs-2 {
        padding-top: 2px;
    }
    .pr-xs-2 {
        padding-right: 2px;
    }
    .pb-xs-2 {
        padding-bottom: 2px;
    }
    .pl-xs-2 {
        padding-left: 2px;
    }
    .p-xs-2 {
        padding: 2px;
    }
    .ph-xs-2 {
        padding-left: 2px;
        padding-right: 2px;
    }
    .pv-xs-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .pt-xs-4 {
        padding-top: 4px;
    }
    .pr-xs-4 {
        padding-right: 4px;
    }
    .pb-xs-4 {
        padding-bottom: 4px;
    }
    .pl-xs-4 {
        padding-left: 4px;
    }
    .p-xs-4 {
        padding: 4px;
    }
    .ph-xs-4 {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pv-xs-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .pt-xs-6 {
        padding-top: 6px;
    }
    .pr-xs-6 {
        padding-right: 6px;
    }
    .pb-xs-6 {
        padding-bottom: 6px;
    }
    .pl-xs-6 {
        padding-left: 6px;
    }
    .p-xs-6 {
        padding: 6px;
    }
    .ph-xs-6 {
        padding-left: 6px;
        padding-right: 6px;
    }
    .pv-xs-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .pt-xs-8 {
        padding-top: 8px;
    }
    .pr-xs-8 {
        padding-right: 8px;
    }
    .pb-xs-8 {
        padding-bottom: 8px;
    }
    .pl-xs-8 {
        padding-left: 8px;
    }
    .p-xs-8 {
        padding: 8px;
    }
    .ph-xs-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .pv-xs-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .pt-xs-10 {
        padding-top: 10px;
    }
    .pr-xs-10 {
        padding-right: 10px;
    }
    .pb-xs-10 {
        padding-bottom: 10px;
    }
    .pl-xs-10 {
        padding-left: 10px;
    }
    .p-xs-10 {
        padding: 10px;
    }
    .ph-xs-10 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pv-xs-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .pt-xs-12 {
        padding-top: 12px;
    }
    .pr-xs-12 {
        padding-right: 12px;
    }
    .pb-xs-12 {
        padding-bottom: 12px;
    }
    .pl-xs-12 {
        padding-left: 12px;
    }
    .p-xs-12 {
        padding: 12px;
    }
    .ph-xs-12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .pv-xs-12 {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .pt-xs-14 {
        padding-top: 14px;
    }
    .pr-xs-14 {
        padding-right: 14px;
    }
    .pb-xs-14 {
        padding-bottom: 14px;
    }
    .pl-xs-14 {
        padding-left: 14px;
    }
    .p-xs-14 {
        padding: 14px;
    }
    .ph-xs-14 {
        padding-left: 14px;
        padding-right: 14px;
    }
    .pv-xs-14 {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .pt-xs-16 {
        padding-top: 16px;
    }
    .pr-xs-16 {
        padding-right: 16px;
    }
    .pb-xs-16 {
        padding-bottom: 16px;
    }
    .pl-xs-16 {
        padding-left: 16px;
    }
    .p-xs-16 {
        padding: 16px;
    }
    .ph-xs-16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .pv-xs-16 {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .pt-xs-18 {
        padding-top: 18px;
    }
    .pr-xs-18 {
        padding-right: 18px;
    }
    .pb-xs-18 {
        padding-bottom: 18px;
    }
    .pl-xs-18 {
        padding-left: 18px;
    }
    .p-xs-18 {
        padding: 18px;
    }
    .ph-xs-18 {
        padding-left: 18px;
        padding-right: 18px;
    }
    .pv-xs-18 {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .pt-xs-20 {
        padding-top: 20px;
    }
    .pr-xs-20 {
        padding-right: 20px;
    }
    .pb-xs-20 {
        padding-bottom: 20px;
    }
    .pl-xs-20 {
        padding-left: 20px;
    }
    .p-xs-20 {
        padding: 20px;
    }
    .ph-xs-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pv-xs-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .pt-xs-22 {
        padding-top: 22px;
    }
    .pr-xs-22 {
        padding-right: 22px;
    }
    .pb-xs-22 {
        padding-bottom: 22px;
    }
    .pl-xs-22 {
        padding-left: 22px;
    }
    .p-xs-22 {
        padding: 22px;
    }
    .ph-xs-22 {
        padding-left: 22px;
        padding-right: 22px;
    }
    .pv-xs-22 {
        padding-top: 22px;
        padding-bottom: 22px;
    }
    .pt-xs-24 {
        padding-top: 24px;
    }
    .pr-xs-24 {
        padding-right: 24px;
    }
    .pb-xs-24 {
        padding-bottom: 24px;
    }
    .pl-xs-24 {
        padding-left: 24px;
    }
    .p-xs-24 {
        padding: 24px;
    }
    .ph-xs-24 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pv-xs-24 {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .pt-xs-26 {
        padding-top: 26px;
    }
    .pr-xs-26 {
        padding-right: 26px;
    }
    .pb-xs-26 {
        padding-bottom: 26px;
    }
    .pl-xs-26 {
        padding-left: 26px;
    }
    .p-xs-26 {
        padding: 26px;
    }
    .ph-xs-26 {
        padding-left: 26px;
        padding-right: 26px;
    }
    .pv-xs-26 {
        padding-top: 26px;
        padding-bottom: 26px;
    }
    .pt-xs-28 {
        padding-top: 28px;
    }
    .pr-xs-28 {
        padding-right: 28px;
    }
    .pb-xs-28 {
        padding-bottom: 28px;
    }
    .pl-xs-28 {
        padding-left: 28px;
    }
    .p-xs-28 {
        padding: 28px;
    }
    .ph-xs-28 {
        padding-left: 28px;
        padding-right: 28px;
    }
    .pv-xs-28 {
        padding-top: 28px;
        padding-bottom: 28px;
    }
    .pt-xs-30 {
        padding-top: 30px;
    }
    .pr-xs-30 {
        padding-right: 30px;
    }
    .pb-xs-30 {
        padding-bottom: 30px;
    }
    .pl-xs-30 {
        padding-left: 30px;
    }
    .p-xs-30 {
        padding: 30px;
    }
    .ph-xs-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pv-xs-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pt-xs-32 {
        padding-top: 32px;
    }
    .pr-xs-32 {
        padding-right: 32px;
    }
    .pb-xs-32 {
        padding-bottom: 32px;
    }
    .pl-xs-32 {
        padding-left: 32px;
    }
    .p-xs-32 {
        padding: 32px;
    }
    .ph-xs-32 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .pv-xs-32 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .pt-xs-34 {
        padding-top: 34px;
    }
    .pr-xs-34 {
        padding-right: 34px;
    }
    .pb-xs-34 {
        padding-bottom: 34px;
    }
    .pl-xs-34 {
        padding-left: 34px;
    }
    .p-xs-34 {
        padding: 34px;
    }
    .ph-xs-34 {
        padding-left: 34px;
        padding-right: 34px;
    }
    .pv-xs-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .pt-xs-36 {
        padding-top: 36px;
    }
    .pr-xs-36 {
        padding-right: 36px;
    }
    .pb-xs-36 {
        padding-bottom: 36px;
    }
    .pl-xs-36 {
        padding-left: 36px;
    }
    .p-xs-36 {
        padding: 36px;
    }
    .ph-xs-36 {
        padding-left: 36px;
        padding-right: 36px;
    }
    .pv-xs-36 {
        padding-top: 36px;
        padding-bottom: 36px;
    }
    .pt-xs-38 {
        padding-top: 38px;
    }
    .pr-xs-38 {
        padding-right: 38px;
    }
    .pb-xs-38 {
        padding-bottom: 38px;
    }
    .pl-xs-38 {
        padding-left: 38px;
    }
    .p-xs-38 {
        padding: 38px;
    }
    .ph-xs-38 {
        padding-left: 38px;
        padding-right: 38px;
    }
    .pv-xs-38 {
        padding-top: 38px;
        padding-bottom: 38px;
    }
    .pt-xs-40 {
        padding-top: 40px;
    }
    .pr-xs-40 {
        padding-right: 40px;
    }
    .pb-xs-40 {
        padding-bottom: 40px;
    }
    .pl-xs-40 {
        padding-left: 40px;
    }
    .p-xs-40 {
        padding: 40px;
    }
    .ph-xs-40 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pv-xs-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .pt-xs-42 {
        padding-top: 42px;
    }
    .pr-xs-42 {
        padding-right: 42px;
    }
    .pb-xs-42 {
        padding-bottom: 42px;
    }
    .pl-xs-42 {
        padding-left: 42px;
    }
    .p-xs-42 {
        padding: 42px;
    }
    .ph-xs-42 {
        padding-left: 42px;
        padding-right: 42px;
    }
    .pv-xs-42 {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    .pt-xs-44 {
        padding-top: 44px;
    }
    .pr-xs-44 {
        padding-right: 44px;
    }
    .pb-xs-44 {
        padding-bottom: 44px;
    }
    .pl-xs-44 {
        padding-left: 44px;
    }
    .p-xs-44 {
        padding: 44px;
    }
    .ph-xs-44 {
        padding-left: 44px;
        padding-right: 44px;
    }
    .pv-xs-44 {
        padding-top: 44px;
        padding-bottom: 44px;
    }
    .pt-xs-46 {
        padding-top: 46px;
    }
    .pr-xs-46 {
        padding-right: 46px;
    }
    .pb-xs-46 {
        padding-bottom: 46px;
    }
    .pl-xs-46 {
        padding-left: 46px;
    }
    .p-xs-46 {
        padding: 46px;
    }
    .ph-xs-46 {
        padding-left: 46px;
        padding-right: 46px;
    }
    .pv-xs-46 {
        padding-top: 46px;
        padding-bottom: 46px;
    }
    .pt-xs-48 {
        padding-top: 48px;
    }
    .pr-xs-48 {
        padding-right: 48px;
    }
    .pb-xs-48 {
        padding-bottom: 48px;
    }
    .pl-xs-48 {
        padding-left: 48px;
    }
    .p-xs-48 {
        padding: 48px;
    }
    .ph-xs-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .pv-xs-48 {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .pt-xs-50 {
        padding-top: 50px;
    }
    .pr-xs-50 {
        padding-right: 50px;
    }
    .pb-xs-50 {
        padding-bottom: 50px;
    }
    .pl-xs-50 {
        padding-left: 50px;
    }
    .p-xs-50 {
        padding: 50px;
    }
    .ph-xs-50 {
        padding-left: 50px;
        padding-right: 50px;
    }
    .pv-xs-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-xs-52 {
        padding-top: 52px;
    }
    .pr-xs-52 {
        padding-right: 52px;
    }
    .pb-xs-52 {
        padding-bottom: 52px;
    }
    .pl-xs-52 {
        padding-left: 52px;
    }
    .p-xs-52 {
        padding: 52px;
    }
    .ph-xs-52 {
        padding-left: 52px;
        padding-right: 52px;
    }
    .pv-xs-52 {
        padding-top: 52px;
        padding-bottom: 52px;
    }
    .pt-xs-54 {
        padding-top: 54px;
    }
    .pr-xs-54 {
        padding-right: 54px;
    }
    .pb-xs-54 {
        padding-bottom: 54px;
    }
    .pl-xs-54 {
        padding-left: 54px;
    }
    .p-xs-54 {
        padding: 54px;
    }
    .ph-xs-54 {
        padding-left: 54px;
        padding-right: 54px;
    }
    .pv-xs-54 {
        padding-top: 54px;
        padding-bottom: 54px;
    }
    .pt-xs-56 {
        padding-top: 56px;
    }
    .pr-xs-56 {
        padding-right: 56px;
    }
    .pb-xs-56 {
        padding-bottom: 56px;
    }
    .pl-xs-56 {
        padding-left: 56px;
    }
    .p-xs-56 {
        padding: 56px;
    }
    .ph-xs-56 {
        padding-left: 56px;
        padding-right: 56px;
    }
    .pv-xs-56 {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .pt-xs-58 {
        padding-top: 58px;
    }
    .pr-xs-58 {
        padding-right: 58px;
    }
    .pb-xs-58 {
        padding-bottom: 58px;
    }
    .pl-xs-58 {
        padding-left: 58px;
    }
    .p-xs-58 {
        padding: 58px;
    }
    .ph-xs-58 {
        padding-left: 58px;
        padding-right: 58px;
    }
    .pv-xs-58 {
        padding-top: 58px;
        padding-bottom: 58px;
    }
    .pt-xs-60 {
        padding-top: 60px;
    }
    .pr-xs-60 {
        padding-right: 60px;
    }
    .pb-xs-60 {
        padding-bottom: 60px;
    }
    .pl-xs-60 {
        padding-left: 60px;
    }
    .p-xs-60 {
        padding: 60px;
    }
    .ph-xs-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .pv-xs-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .pt-xs-62 {
        padding-top: 62px;
    }
    .pr-xs-62 {
        padding-right: 62px;
    }
    .pb-xs-62 {
        padding-bottom: 62px;
    }
    .pl-xs-62 {
        padding-left: 62px;
    }
    .p-xs-62 {
        padding: 62px;
    }
    .ph-xs-62 {
        padding-left: 62px;
        padding-right: 62px;
    }
    .pv-xs-62 {
        padding-top: 62px;
        padding-bottom: 62px;
    }
    .pt-xs-64 {
        padding-top: 64px;
    }
    .pr-xs-64 {
        padding-right: 64px;
    }
    .pb-xs-64 {
        padding-bottom: 64px;
    }
    .pl-xs-64 {
        padding-left: 64px;
    }
    .p-xs-64 {
        padding: 64px;
    }
    .ph-xs-64 {
        padding-left: 64px;
        padding-right: 64px;
    }
    .pv-xs-64 {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .pt-xs-66 {
        padding-top: 66px;
    }
    .pr-xs-66 {
        padding-right: 66px;
    }
    .pb-xs-66 {
        padding-bottom: 66px;
    }
    .pl-xs-66 {
        padding-left: 66px;
    }
    .p-xs-66 {
        padding: 66px;
    }
    .ph-xs-66 {
        padding-left: 66px;
        padding-right: 66px;
    }
    .pv-xs-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .pt-xs-68 {
        padding-top: 68px;
    }
    .pr-xs-68 {
        padding-right: 68px;
    }
    .pb-xs-68 {
        padding-bottom: 68px;
    }
    .pl-xs-68 {
        padding-left: 68px;
    }
    .p-xs-68 {
        padding: 68px;
    }
    .ph-xs-68 {
        padding-left: 68px;
        padding-right: 68px;
    }
    .pv-xs-68 {
        padding-top: 68px;
        padding-bottom: 68px;
    }
    .pt-xs-70 {
        padding-top: 70px;
    }
    .pr-xs-70 {
        padding-right: 70px;
    }
    .pb-xs-70 {
        padding-bottom: 70px;
    }
    .pl-xs-70 {
        padding-left: 70px;
    }
    .p-xs-70 {
        padding: 70px;
    }
    .ph-xs-70 {
        padding-left: 70px;
        padding-right: 70px;
    }
    .pv-xs-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-xs-72 {
        padding-top: 72px;
    }
    .pr-xs-72 {
        padding-right: 72px;
    }
    .pb-xs-72 {
        padding-bottom: 72px;
    }
    .pl-xs-72 {
        padding-left: 72px;
    }
    .p-xs-72 {
        padding: 72px;
    }
    .ph-xs-72 {
        padding-left: 72px;
        padding-right: 72px;
    }
    .pv-xs-72 {
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .pt-xs-74 {
        padding-top: 74px;
    }
    .pr-xs-74 {
        padding-right: 74px;
    }
    .pb-xs-74 {
        padding-bottom: 74px;
    }
    .pl-xs-74 {
        padding-left: 74px;
    }
    .p-xs-74 {
        padding: 74px;
    }
    .ph-xs-74 {
        padding-left: 74px;
        padding-right: 74px;
    }
    .pv-xs-74 {
        padding-top: 74px;
        padding-bottom: 74px;
    }
    .pt-xs-76 {
        padding-top: 76px;
    }
    .pr-xs-76 {
        padding-right: 76px;
    }
    .pb-xs-76 {
        padding-bottom: 76px;
    }
    .pl-xs-76 {
        padding-left: 76px;
    }
    .p-xs-76 {
        padding: 76px;
    }
    .ph-xs-76 {
        padding-left: 76px;
        padding-right: 76px;
    }
    .pv-xs-76 {
        padding-top: 76px;
        padding-bottom: 76px;
    }
    .pt-xs-78 {
        padding-top: 78px;
    }
    .pr-xs-78 {
        padding-right: 78px;
    }
    .pb-xs-78 {
        padding-bottom: 78px;
    }
    .pl-xs-78 {
        padding-left: 78px;
    }
    .p-xs-78 {
        padding: 78px;
    }
    .ph-xs-78 {
        padding-left: 78px;
        padding-right: 78px;
    }
    .pv-xs-78 {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    .pt-xs-80 {
        padding-top: 80px;
    }
    .pr-xs-80 {
        padding-right: 80px;
    }
    .pb-xs-80 {
        padding-bottom: 80px;
    }
    .pl-xs-80 {
        padding-left: 80px;
    }
    .p-xs-80 {
        padding: 80px;
    }
    .ph-xs-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .pv-xs-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-xs-82 {
        padding-top: 82px;
    }
    .pr-xs-82 {
        padding-right: 82px;
    }
    .pb-xs-82 {
        padding-bottom: 82px;
    }
    .pl-xs-82 {
        padding-left: 82px;
    }
    .p-xs-82 {
        padding: 82px;
    }
    .ph-xs-82 {
        padding-left: 82px;
        padding-right: 82px;
    }
    .pv-xs-82 {
        padding-top: 82px;
        padding-bottom: 82px;
    }
    .pt-xs-84 {
        padding-top: 84px;
    }
    .pr-xs-84 {
        padding-right: 84px;
    }
    .pb-xs-84 {
        padding-bottom: 84px;
    }
    .pl-xs-84 {
        padding-left: 84px;
    }
    .p-xs-84 {
        padding: 84px;
    }
    .ph-xs-84 {
        padding-left: 84px;
        padding-right: 84px;
    }
    .pv-xs-84 {
        padding-top: 84px;
        padding-bottom: 84px;
    }
    .pt-xs-86 {
        padding-top: 86px;
    }
    .pr-xs-86 {
        padding-right: 86px;
    }
    .pb-xs-86 {
        padding-bottom: 86px;
    }
    .pl-xs-86 {
        padding-left: 86px;
    }
    .p-xs-86 {
        padding: 86px;
    }
    .ph-xs-86 {
        padding-left: 86px;
        padding-right: 86px;
    }
    .pv-xs-86 {
        padding-top: 86px;
        padding-bottom: 86px;
    }
    .pt-xs-88 {
        padding-top: 88px;
    }
    .pr-xs-88 {
        padding-right: 88px;
    }
    .pb-xs-88 {
        padding-bottom: 88px;
    }
    .pl-xs-88 {
        padding-left: 88px;
    }
    .p-xs-88 {
        padding: 88px;
    }
    .ph-xs-88 {
        padding-left: 88px;
        padding-right: 88px;
    }
    .pv-xs-88 {
        padding-top: 88px;
        padding-bottom: 88px;
    }
    .pt-xs-90 {
        padding-top: 90px;
    }
    .pr-xs-90 {
        padding-right: 90px;
    }
    .pb-xs-90 {
        padding-bottom: 90px;
    }
    .pl-xs-90 {
        padding-left: 90px;
    }
    .p-xs-90 {
        padding: 90px;
    }
    .ph-xs-90 {
        padding-left: 90px;
        padding-right: 90px;
    }
    .pv-xs-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .pt-xs-92 {
        padding-top: 92px;
    }
    .pr-xs-92 {
        padding-right: 92px;
    }
    .pb-xs-92 {
        padding-bottom: 92px;
    }
    .pl-xs-92 {
        padding-left: 92px;
    }
    .p-xs-92 {
        padding: 92px;
    }
    .ph-xs-92 {
        padding-left: 92px;
        padding-right: 92px;
    }
    .pv-xs-92 {
        padding-top: 92px;
        padding-bottom: 92px;
    }
    .pt-xs-94 {
        padding-top: 94px;
    }
    .pr-xs-94 {
        padding-right: 94px;
    }
    .pb-xs-94 {
        padding-bottom: 94px;
    }
    .pl-xs-94 {
        padding-left: 94px;
    }
    .p-xs-94 {
        padding: 94px;
    }
    .ph-xs-94 {
        padding-left: 94px;
        padding-right: 94px;
    }
    .pv-xs-94 {
        padding-top: 94px;
        padding-bottom: 94px;
    }
    .pt-xs-96 {
        padding-top: 96px;
    }
    .pr-xs-96 {
        padding-right: 96px;
    }
    .pb-xs-96 {
        padding-bottom: 96px;
    }
    .pl-xs-96 {
        padding-left: 96px;
    }
    .p-xs-96 {
        padding: 96px;
    }
    .ph-xs-96 {
        padding-left: 96px;
        padding-right: 96px;
    }
    .pv-xs-96 {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    .pt-xs-98 {
        padding-top: 98px;
    }
    .pr-xs-98 {
        padding-right: 98px;
    }
    .pb-xs-98 {
        padding-bottom: 98px;
    }
    .pl-xs-98 {
        padding-left: 98px;
    }
    .p-xs-98 {
        padding: 98px;
    }
    .ph-xs-98 {
        padding-left: 98px;
        padding-right: 98px;
    }
    .pv-xs-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .pt-xs-100 {
        padding-top: 100px;
    }
    .pr-xs-100 {
        padding-right: 100px;
    }
    .pb-xs-100 {
        padding-bottom: 100px;
    }
    .pl-xs-100 {
        padding-left: 100px;
    }
    .p-xs-100 {
        padding: 100px;
    }
    .ph-xs-100 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .pv-xs-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .pt-xs-102 {
        padding-top: 102px;
    }
    .pr-xs-102 {
        padding-right: 102px;
    }
    .pb-xs-102 {
        padding-bottom: 102px;
    }
    .pl-xs-102 {
        padding-left: 102px;
    }
    .p-xs-102 {
        padding: 102px;
    }
    .ph-xs-102 {
        padding-left: 102px;
        padding-right: 102px;
    }
    .pv-xs-102 {
        padding-top: 102px;
        padding-bottom: 102px;
    }
    .pt-xs-104 {
        padding-top: 104px;
    }
    .pr-xs-104 {
        padding-right: 104px;
    }
    .pb-xs-104 {
        padding-bottom: 104px;
    }
    .pl-xs-104 {
        padding-left: 104px;
    }
    .p-xs-104 {
        padding: 104px;
    }
    .ph-xs-104 {
        padding-left: 104px;
        padding-right: 104px;
    }
    .pv-xs-104 {
        padding-top: 104px;
        padding-bottom: 104px;
    }
    .pt-xs-106 {
        padding-top: 106px;
    }
    .pr-xs-106 {
        padding-right: 106px;
    }
    .pb-xs-106 {
        padding-bottom: 106px;
    }
    .pl-xs-106 {
        padding-left: 106px;
    }
    .p-xs-106 {
        padding: 106px;
    }
    .ph-xs-106 {
        padding-left: 106px;
        padding-right: 106px;
    }
    .pv-xs-106 {
        padding-top: 106px;
        padding-bottom: 106px;
    }
    .pt-xs-108 {
        padding-top: 108px;
    }
    .pr-xs-108 {
        padding-right: 108px;
    }
    .pb-xs-108 {
        padding-bottom: 108px;
    }
    .pl-xs-108 {
        padding-left: 108px;
    }
    .p-xs-108 {
        padding: 108px;
    }
    .ph-xs-108 {
        padding-left: 108px;
        padding-right: 108px;
    }
    .pv-xs-108 {
        padding-top: 108px;
        padding-bottom: 108px;
    }
    .pt-xs-110 {
        padding-top: 110px;
    }
    .pr-xs-110 {
        padding-right: 110px;
    }
    .pb-xs-110 {
        padding-bottom: 110px;
    }
    .pl-xs-110 {
        padding-left: 110px;
    }
    .p-xs-110 {
        padding: 110px;
    }
    .ph-xs-110 {
        padding-left: 110px;
        padding-right: 110px;
    }
    .pv-xs-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .pt-xs-112 {
        padding-top: 112px;
    }
    .pr-xs-112 {
        padding-right: 112px;
    }
    .pb-xs-112 {
        padding-bottom: 112px;
    }
    .pl-xs-112 {
        padding-left: 112px;
    }
    .p-xs-112 {
        padding: 112px;
    }
    .ph-xs-112 {
        padding-left: 112px;
        padding-right: 112px;
    }
    .pv-xs-112 {
        padding-top: 112px;
        padding-bottom: 112px;
    }
    .pt-xs-114 {
        padding-top: 114px;
    }
    .pr-xs-114 {
        padding-right: 114px;
    }
    .pb-xs-114 {
        padding-bottom: 114px;
    }
    .pl-xs-114 {
        padding-left: 114px;
    }
    .p-xs-114 {
        padding: 114px;
    }
    .ph-xs-114 {
        padding-left: 114px;
        padding-right: 114px;
    }
    .pv-xs-114 {
        padding-top: 114px;
        padding-bottom: 114px;
    }
    .pt-xs-116 {
        padding-top: 116px;
    }
    .pr-xs-116 {
        padding-right: 116px;
    }
    .pb-xs-116 {
        padding-bottom: 116px;
    }
    .pl-xs-116 {
        padding-left: 116px;
    }
    .p-xs-116 {
        padding: 116px;
    }
    .ph-xs-116 {
        padding-left: 116px;
        padding-right: 116px;
    }
    .pv-xs-116 {
        padding-top: 116px;
        padding-bottom: 116px;
    }
    .pt-xs-118 {
        padding-top: 118px;
    }
    .pr-xs-118 {
        padding-right: 118px;
    }
    .pb-xs-118 {
        padding-bottom: 118px;
    }
    .pl-xs-118 {
        padding-left: 118px;
    }
    .p-xs-118 {
        padding: 118px;
    }
    .ph-xs-118 {
        padding-left: 118px;
        padding-right: 118px;
    }
    .pv-xs-118 {
        padding-top: 118px;
        padding-bottom: 118px;
    }
    .pt-xs-120 {
        padding-top: 120px;
    }
    .pr-xs-120 {
        padding-right: 120px;
    }
    .pb-xs-120 {
        padding-bottom: 120px;
    }
    .pl-xs-120 {
        padding-left: 120px;
    }
    .p-xs-120 {
        padding: 120px;
    }
    .ph-xs-120 {
        padding-left: 120px;
        padding-right: 120px;
    }
    .pv-xs-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .pt-xs-122 {
        padding-top: 122px;
    }
    .pr-xs-122 {
        padding-right: 122px;
    }
    .pb-xs-122 {
        padding-bottom: 122px;
    }
    .pl-xs-122 {
        padding-left: 122px;
    }
    .p-xs-122 {
        padding: 122px;
    }
    .ph-xs-122 {
        padding-left: 122px;
        padding-right: 122px;
    }
    .pv-xs-122 {
        padding-top: 122px;
        padding-bottom: 122px;
    }
    .pt-xs-124 {
        padding-top: 124px;
    }
    .pr-xs-124 {
        padding-right: 124px;
    }
    .pb-xs-124 {
        padding-bottom: 124px;
    }
    .pl-xs-124 {
        padding-left: 124px;
    }
    .p-xs-124 {
        padding: 124px;
    }
    .ph-xs-124 {
        padding-left: 124px;
        padding-right: 124px;
    }
    .pv-xs-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .pt-xs-126 {
        padding-top: 126px;
    }
    .pr-xs-126 {
        padding-right: 126px;
    }
    .pb-xs-126 {
        padding-bottom: 126px;
    }
    .pl-xs-126 {
        padding-left: 126px;
    }
    .p-xs-126 {
        padding: 126px;
    }
    .ph-xs-126 {
        padding-left: 126px;
        padding-right: 126px;
    }
    .pv-xs-126 {
        padding-top: 126px;
        padding-bottom: 126px;
    }
    .pt-xs-128 {
        padding-top: 128px;
    }
    .pr-xs-128 {
        padding-right: 128px;
    }
    .pb-xs-128 {
        padding-bottom: 128px;
    }
    .pl-xs-128 {
        padding-left: 128px;
    }
    .p-xs-128 {
        padding: 128px;
    }
    .ph-xs-128 {
        padding-left: 128px;
        padding-right: 128px;
    }
    .pv-xs-128 {
        padding-top: 128px;
        padding-bottom: 128px;
    }
    .pt-xs-130 {
        padding-top: 130px;
    }
    .pr-xs-130 {
        padding-right: 130px;
    }
    .pb-xs-130 {
        padding-bottom: 130px;
    }
    .pl-xs-130 {
        padding-left: 130px;
    }
    .p-xs-130 {
        padding: 130px;
    }
    .ph-xs-130 {
        padding-left: 130px;
        padding-right: 130px;
    }
    .pv-xs-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .pt-xs-132 {
        padding-top: 132px;
    }
    .pr-xs-132 {
        padding-right: 132px;
    }
    .pb-xs-132 {
        padding-bottom: 132px;
    }
    .pl-xs-132 {
        padding-left: 132px;
    }
    .p-xs-132 {
        padding: 132px;
    }
    .ph-xs-132 {
        padding-left: 132px;
        padding-right: 132px;
    }
    .pv-xs-132 {
        padding-top: 132px;
        padding-bottom: 132px;
    }
    .pt-xs-134 {
        padding-top: 134px;
    }
    .pr-xs-134 {
        padding-right: 134px;
    }
    .pb-xs-134 {
        padding-bottom: 134px;
    }
    .pl-xs-134 {
        padding-left: 134px;
    }
    .p-xs-134 {
        padding: 134px;
    }
    .ph-xs-134 {
        padding-left: 134px;
        padding-right: 134px;
    }
    .pv-xs-134 {
        padding-top: 134px;
        padding-bottom: 134px;
    }
    .pt-xs-136 {
        padding-top: 136px;
    }
    .pr-xs-136 {
        padding-right: 136px;
    }
    .pb-xs-136 {
        padding-bottom: 136px;
    }
    .pl-xs-136 {
        padding-left: 136px;
    }
    .p-xs-136 {
        padding: 136px;
    }
    .ph-xs-136 {
        padding-left: 136px;
        padding-right: 136px;
    }
    .pv-xs-136 {
        padding-top: 136px;
        padding-bottom: 136px;
    }
    .pt-xs-138 {
        padding-top: 138px;
    }
    .pr-xs-138 {
        padding-right: 138px;
    }
    .pb-xs-138 {
        padding-bottom: 138px;
    }
    .pl-xs-138 {
        padding-left: 138px;
    }
    .p-xs-138 {
        padding: 138px;
    }
    .ph-xs-138 {
        padding-left: 138px;
        padding-right: 138px;
    }
    .pv-xs-138 {
        padding-top: 138px;
        padding-bottom: 138px;
    }
    .pt-xs-140 {
        padding-top: 140px;
    }
    .pr-xs-140 {
        padding-right: 140px;
    }
    .pb-xs-140 {
        padding-bottom: 140px;
    }
    .pl-xs-140 {
        padding-left: 140px;
    }
    .p-xs-140 {
        padding: 140px;
    }
    .ph-xs-140 {
        padding-left: 140px;
        padding-right: 140px;
    }
    .pv-xs-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    .p-xs-0 {
        padding: 0;
    }
    .pt-xs-0 {
        padding-top: 0;
    }
    .pr-xs-0 {
        padding-right: 0;
    }
    .pb-xs-0 {
        padding-bottom: 0;
    }
    .pl-xs-0 {
        padding-left: 0;
    }
    .pv-xs-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .rGap-xs-2 {
        row-gap: 2px;
    }
    .rGap-xs-4 {
        row-gap: 4px;
    }
    .rGap-xs-6 {
        row-gap: 6px;
    }
    .rGap-xs-8 {
        row-gap: 8px;
    }
    .rGap-xs-10 {
        row-gap: 10px;
    }
    .rGap-xs-12 {
        row-gap: 12px;
    }
    .rGap-xs-14 {
        row-gap: 14px;
    }
    .rGap-xs-16 {
        row-gap: 16px;
    }
    .rGap-xs-18 {
        row-gap: 18px;
    }
    .rGap-xs-20 {
        row-gap: 20px;
    }
    .rGap-xs-22 {
        row-gap: 22px;
    }
    .rGap-xs-24 {
        row-gap: 24px;
    }
    .rGap-xs-26 {
        row-gap: 26px;
    }
    .rGap-xs-28 {
        row-gap: 28px;
    }
    .rGap-xs-30 {
        row-gap: 30px;
    }
    .rGap-xs-32 {
        row-gap: 32px;
    }
    .rGap-xs-34 {
        row-gap: 34px;
    }
    .rGap-xs-36 {
        row-gap: 36px;
    }
    .rGap-xs-38 {
        row-gap: 38px;
    }
    .rGap-xs-40 {
        row-gap: 40px;
    }
    .rGap-xs-42 {
        row-gap: 42px;
    }
    .rGap-xs-44 {
        row-gap: 44px;
    }
    .rGap-xs-46 {
        row-gap: 46px;
    }
    .rGap-xs-48 {
        row-gap: 48px;
    }
    .rGap-xs-50 {
        row-gap: 50px;
    }
    .rGap-xs-52 {
        row-gap: 52px;
    }
    .rGap-xs-54 {
        row-gap: 54px;
    }
    .rGap-xs-56 {
        row-gap: 56px;
    }
    .rGap-xs-58 {
        row-gap: 58px;
    }
    .rGap-xs-60 {
        row-gap: 60px;
    }
    .rGap-xs-62 {
        row-gap: 62px;
    }
    .rGap-xs-64 {
        row-gap: 64px;
    }
    .rGap-xs-66 {
        row-gap: 66px;
    }
    .rGap-xs-68 {
        row-gap: 68px;
    }
    .rGap-xs-70 {
        row-gap: 70px;
    }
    .rGap-xs-72 {
        row-gap: 72px;
    }
    .rGap-xs-74 {
        row-gap: 74px;
    }
    .rGap-xs-76 {
        row-gap: 76px;
    }
    .rGap-xs-78 {
        row-gap: 78px;
    }
    .rGap-xs-80 {
        row-gap: 80px;
    }
    .rGap-xs-82 {
        row-gap: 82px;
    }
    .rGap-xs-84 {
        row-gap: 84px;
    }
    .rGap-xs-86 {
        row-gap: 86px;
    }
    .rGap-xs-88 {
        row-gap: 88px;
    }
    .rGap-xs-90 {
        row-gap: 90px;
    }
    .rGap-xs-92 {
        row-gap: 92px;
    }
    .rGap-xs-94 {
        row-gap: 94px;
    }
    .rGap-xs-96 {
        row-gap: 96px;
    }
    .rGap-xs-98 {
        row-gap: 98px;
    }
    .rGap-xs-100 {
        row-gap: 100px;
    }
    .rGap-xs-102 {
        row-gap: 102px;
    }
    .rGap-xs-104 {
        row-gap: 104px;
    }
    .rGap-xs-106 {
        row-gap: 106px;
    }
    .rGap-xs-108 {
        row-gap: 108px;
    }
    .rGap-xs-110 {
        row-gap: 110px;
    }
    .rGap-xs-112 {
        row-gap: 112px;
    }
    .rGap-xs-114 {
        row-gap: 114px;
    }
    .rGap-xs-116 {
        row-gap: 116px;
    }
    .rGap-xs-118 {
        row-gap: 118px;
    }
    .rGap-xs-120 {
        row-gap: 120px;
    }
    .rGap-xs-122 {
        row-gap: 122px;
    }
    .rGap-xs-124 {
        row-gap: 124px;
    }
    .rGap-xs-126 {
        row-gap: 126px;
    }
    .rGap-xs-128 {
        row-gap: 128px;
    }
    .rGap-xs-130 {
        row-gap: 130px;
    }
    .rGap-xs-132 {
        row-gap: 132px;
    }
    .rGap-xs-134 {
        row-gap: 134px;
    }
    .rGap-xs-136 {
        row-gap: 136px;
    }
    .rGap-xs-138 {
        row-gap: 138px;
    }
    .rGap-xs-140 {
        row-gap: 140px;
    }
    .rGap-xs-0 {
        row-gap: 0;
    }
    .cGap-xs-2 {
        column-gap: 2px;
    }
    .cGap-xs-4 {
        column-gap: 4px;
    }
    .cGap-xs-6 {
        column-gap: 6px;
    }
    .cGap-xs-8 {
        column-gap: 8px;
    }
    .cGap-xs-10 {
        column-gap: 10px;
    }
    .cGap-xs-12 {
        column-gap: 12px;
    }
    .cGap-xs-14 {
        column-gap: 14px;
    }
    .cGap-xs-16 {
        column-gap: 16px;
    }
    .cGap-xs-18 {
        column-gap: 18px;
    }
    .cGap-xs-20 {
        column-gap: 20px;
    }
    .cGap-xs-22 {
        column-gap: 22px;
    }
    .cGap-xs-24 {
        column-gap: 24px;
    }
    .cGap-xs-26 {
        column-gap: 26px;
    }
    .cGap-xs-28 {
        column-gap: 28px;
    }
    .cGap-xs-30 {
        column-gap: 30px;
    }
    .cGap-xs-32 {
        column-gap: 32px;
    }
    .cGap-xs-34 {
        column-gap: 34px;
    }
    .cGap-xs-36 {
        column-gap: 36px;
    }
    .cGap-xs-38 {
        column-gap: 38px;
    }
    .cGap-xs-40 {
        column-gap: 40px;
    }
    .cGap-xs-42 {
        column-gap: 42px;
    }
    .cGap-xs-44 {
        column-gap: 44px;
    }
    .cGap-xs-46 {
        column-gap: 46px;
    }
    .cGap-xs-48 {
        column-gap: 48px;
    }
    .cGap-xs-50 {
        column-gap: 50px;
    }
    .cGap-xs-52 {
        column-gap: 52px;
    }
    .cGap-xs-54 {
        column-gap: 54px;
    }
    .cGap-xs-56 {
        column-gap: 56px;
    }
    .cGap-xs-58 {
        column-gap: 58px;
    }
    .cGap-xs-60 {
        column-gap: 60px;
    }
    .cGap-xs-62 {
        column-gap: 62px;
    }
    .cGap-xs-64 {
        column-gap: 64px;
    }
    .cGap-xs-66 {
        column-gap: 66px;
    }
    .cGap-xs-68 {
        column-gap: 68px;
    }
    .cGap-xs-70 {
        column-gap: 70px;
    }
    .cGap-xs-72 {
        column-gap: 72px;
    }
    .cGap-xs-74 {
        column-gap: 74px;
    }
    .cGap-xs-76 {
        column-gap: 76px;
    }
    .cGap-xs-78 {
        column-gap: 78px;
    }
    .cGap-xs-80 {
        column-gap: 80px;
    }
    .cGap-xs-82 {
        column-gap: 82px;
    }
    .cGap-xs-84 {
        column-gap: 84px;
    }
    .cGap-xs-86 {
        column-gap: 86px;
    }
    .cGap-xs-88 {
        column-gap: 88px;
    }
    .cGap-xs-90 {
        column-gap: 90px;
    }
    .cGap-xs-92 {
        column-gap: 92px;
    }
    .cGap-xs-94 {
        column-gap: 94px;
    }
    .cGap-xs-96 {
        column-gap: 96px;
    }
    .cGap-xs-98 {
        column-gap: 98px;
    }
    .cGap-xs-100 {
        column-gap: 100px;
    }
    .cGap-xs-102 {
        column-gap: 102px;
    }
    .cGap-xs-104 {
        column-gap: 104px;
    }
    .cGap-xs-106 {
        column-gap: 106px;
    }
    .cGap-xs-108 {
        column-gap: 108px;
    }
    .cGap-xs-110 {
        column-gap: 110px;
    }
    .cGap-xs-112 {
        column-gap: 112px;
    }
    .cGap-xs-114 {
        column-gap: 114px;
    }
    .cGap-xs-116 {
        column-gap: 116px;
    }
    .cGap-xs-118 {
        column-gap: 118px;
    }
    .cGap-xs-120 {
        column-gap: 120px;
    }
    .cGap-xs-122 {
        column-gap: 122px;
    }
    .cGap-xs-124 {
        column-gap: 124px;
    }
    .cGap-xs-126 {
        column-gap: 126px;
    }
    .cGap-xs-128 {
        column-gap: 128px;
    }
    .cGap-xs-130 {
        column-gap: 130px;
    }
    .cGap-xs-132 {
        column-gap: 132px;
    }
    .cGap-xs-134 {
        column-gap: 134px;
    }
    .cGap-xs-136 {
        column-gap: 136px;
    }
    .cGap-xs-138 {
        column-gap: 138px;
    }
    .cGap-xs-140 {
        column-gap: 140px;
    }
    .cGap-xs-0 {
        column-gap: 0;
    }
}
.header-links {
    position: relative;
}
.header--search {
    background: #342c3e;
}
@media screen and (max-width: 1024px) {
    .header--search {
        background: #0c0216;
    }
}
.header-search {
    flex: 1;
}
@media screen and (max-width: 1024px) {
    .header-search {
        flex: unset;
        min-height: 40px;
        display: flex;
        align-items: center;
    }
    .header-search .flex {
        height: 100%;
    }
}
.header-search__result {
    max-width: 962px;
    max-height: 475px;
    min-height: 320px;
    background: #1e1529;
    border-radius: 16px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
    margin: 32px auto 0 auto;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 24px;
}
.header-search__result-scroll {
    min-height: 320px;
    max-height: 475px;
    overflow-y: auto;
    padding: 32px;
    box-sizing: border-box;
}
.header-search__result-scroll::-webkit-scrollbar {
    width: 0px;
    padding-right: 6px;
    border-radius: 6px;
}
.header-search__result-scroll::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 0px;
}
.header-search__result-scroll::-webkit-scrollbar-thumb {
    width: 0px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);
}
.header-search__result .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.header-search__result .swiper-wrapper {
    gap: 24px 8px !important;
}
.header-search__result .film-list {
    padding: 0 !important;
    margin: 0 !important;
}
.header-search__result .film-card {
    display: flex !important;
    width: 100% !important;
    align-items: flex-start !important;
}
.header-search__result .film-card {
    padding: 6px 0;
}
.header-search__result .film-card h5 {
    margin-bottom: 8px !important;
    font-size: 14px;
    line-height: 24px;
}
.header-search__result .film-card .img-wrap {
    width: 12.2vw !important;
    height: 7.23vw !important;
    margin-right: 16px;
}
@media screen and (max-width: 1024px) {
    .header-search__result {
        top: 64px;
        padding: 0;
        max-height: 100%;
        min-height: unset;
        height: 100%;
        margin: 0 auto;
        background-color: transparent;
        position: unset;
        width: 100%;
        padding: 20px 0 32px 0;
    }
    .header-search__result-scroll {
        padding: 0;
        max-height: 100%;
        min-height: unset;
        height: 100%;
    }
    .header-search__result .loader {
        height: max-content;
        top: 80px;
    }
    .header-search__result .swiper-wrapper {
        display: flex !important;
    }
    .header-search__result .film-card {
        padding: 0;
        margin-bottom: 0 !important;
        width: calc(50vw - 20px) !important;
        display: block !important;
    }
    .header-search__result .film-card .img-wrap {
        width: 100% !important;
        height: 25.6vw !important;
        margin-right: 0;
    }
}
.header-search__input {
    flex: 1;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    margin-left: 16px;
}
.header-search__input:placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.film-menu {
    padding: 32px;
    background-color: var(--bg-200);
    border-radius: var(--border-radius);
    font-weight: 600;
    font-size: 15px;
    line-height: 160%;
    min-height: 24px;
    position: absolute;
    top: calc(100% + 16px);
    z-index: 2;
    column-gap: 62px;
}
.film-menu__title {
    margin-bottom: 16px;
}
.film-menu__list {
    row-gap: 8px;
}
.film-menu__list-item {
    opacity: 0.5;
    display: block;
}
.film-menu__categories {
    width: 216px;
    margin-left: 100px;
}
.film-menu__categories .categories-list-item {
    height: 112px;
}
.film-menu__categories .categories-list-item-info span {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    top: 20px;
    left: 20px;
}
@media screen and (max-width: 1024px) {
    .film-menu__categories .categories-list-item-info span {
        left: 16px;
        top: 16px;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 80px;
    transition:
        background-color,
        opacity 0.2s ease-in-out;
}
.btn-primary.success {
    background-color: var(--success);
}
.btn-white {
    background-color: #fff;
    color: #212121;
}
.btn-primary:active {
    background-color: var(--dark-blue);
}
.btn-primary:disabled,
.btn-primary:disabled:hover {
    background-color: var(--grey-300);
    color: var(--grey-400);
}
.btn-secondary {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 40px;
}
.btn-secondary:not(:disabled):hover {
    border-color: rgb(255, 255, 255);
}
.btn-secondary:disabled {
    color: #94a0b1;
}
.btn-purple {
    background-color: #7027c2;
    color: #ffffff;
}
.btn-purple:not(:disabled):active,
.btn-purple:not(:disabled):hover {
    background-color: #5817a0;
}
@media (max-width: 768px) {
    .btn-secondary:not(:disabled):hover {
        border: 2px solid hsla(0deg, 0%, 100%, 0.3);
    }
}
.btn-tinkoff {
    background-color: #fedd2c;
    color: #212121;
    white-space: nowrap;
    gap: 4px;
    line-height: 23px;
    touch-action: none;
}
.btn--xs {
    padding: 9px 20px;
    font-size: 14px;
    line-height: 22px;
}
.btn--s {
    height: 48px;
    padding: 0 32px;
}
.btn--m {
    height: 56px;
    padding: 0 32px;
}
.btn--icon-before {
    column-gap: 9px;
}
.btn-success {
    background-color: #0dc268;
    padding-left: 24px;
}
.btn--loading {
    padding-left: 22px;
}
.btn--loading {
    gap: 14px;
}
.btn .loader {
    height: 20px;
    width: 20px;
}
.btn .loader path {
    stroke: #fff;
}
@media screen and (max-width: 768px) {
    .btn-load-more {
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    .btn--m {
        padding: 0 26px;
    }
}

.show-more-block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    width: 100%;
}
@media screen and (max-width: 998px) {
    .show-more-block {
        margin-top: 40px;
    }
    .show-more-block button {
        width: 100%;
    }
}

.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
}
.icon-btn--round {
    border-radius: 50%;
}
.icon-btn--opacity {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}
.icon-btn--opacity:hover {
    opacity: 1;
}
.icon-btn--pink {
    background-color: #fd4aa7;
}
.icon-btn--xss {
    width: 32px;
    height: 32px;
}
.icon-btn--xs {
    width: 40px;
    height: 40px;
}
.icon-btn--s {
    width: 48px;
    height: 48px;
}
.icon-btn--m {
    width: 56px;
    height: 56px;
}
.icon-btn--l {
    width: 64px;
    height: 64px;
}

.swiper-layout {
    position: relative;
    width: 100%;
}
section.film-list.genres-format .slider-control-arrow {
    top: 50%;
}

.slider-next {
    right: 40px;
}

.slider-prev {
    left: 40px;
}

.slider-control-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 80px;
    cursor: pointer;
    transition: all 100ms ease-out;
    top: calc(50% - 32px);
}
.slider-control-arrow.swiper-button-disabled {
    visibility: hidden;
}
.slider-control-arrow:hover {
    width: 72px;
    height: 72px;
    margin: -4px;
}

.modal-rate {
    padding: 32px;
    box-sizing: border-box;
    width: 720px;
    display: flex;
    align-items: stretch;
    justify-content: center;
}
.modal-rate__content {
    width: max-content;
    margin: 0 auto;
}
.modal-rate__rate-btn {
    display: none;
}
.modal-rate__options {
    display: flex;
    border: 2px solid #342c3e;
    border-radius: 8px;
    margin: 84px auto 24px auto;
    width: max-content;
}
.modal-rate__options-item {
    padding: 16px 24px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    border-radius: 8px;
    background: #281f34;
}
.modal-rate__options-item:hover {
    background: #342c3e;
}
.modal-rate__options-item--active {
    background: #005bff;
}
.modal-rate__icons-row {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin: 0 auto;
    box-sizing: border-box;
}
.modal-rate-success {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-rate-success .modal-title {
    margin: 24px 0 8px;
}
@media screen and (max-width: 768px) {
    .modal-rate {
        max-width: 100%;
        padding: 0 !important;
        background: #0c0216 !important;
    }
    .modal-rate .modal-title {
        padding: 32px 16px 0 16px;
        font-size: 20px;
        line-height: 26px;
    }
    .modal-rate__content {
        width: 100%;
        overflow: hidden;
    }
    .modal-rate__options-scroll {
        width: 100%;
        position: relative;
        overflow: auto;
        box-sizing: border-box;
    }
    .modal-rate__options-wrapper {
        width: 100%;
        position: relative;
        box-sizing: border-box;
    }
    .modal-rate__options-wrapper:before,
    .modal-rate__options-wrapper:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(270deg, #0c0216 -9.72%, rgba(12, 2, 22, 0) 100%);
        z-index: 1;
        pointer-events: none;
    }
    .modal-rate__options-wrapper:before {
        left: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    .modal-rate__options-wrapper:after {
        right: 0;
    }
    .modal-rate__options-wrapper.rated-left:before {
        display: none;
    }
    .modal-rate__options-wrapper.rated-right:after {
        display: none;
    }
    .modal-rate__options {
        margin: 0;
        border: none;
        padding: 0 16px;
    }
    .modal-rate__options-item,
    .modal-rate__options-item:hover {
        background: transparent;
    }
    .modal-rate__options-item {
        cursor: pointer;
        font-size: 24px;
        line-height: 31px;
    }
    .modal-rate__options-item.bg-blue {
        background-color: #005bff;
        z-index: 2;
    }
    .modal-rate__rate-btn {
        display: block;
        margin: 0 16px 16px 16px;
    }
    .modal-rate-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .modal-rate__icons-row {
        margin-top: 24px;
    }
    .modal-rate-success {
        justify-content: center;
    }
    .modal-rate-success .modal-title {
        padding: 0;
    }
}

.verify-modal {
    max-width: 480px;
    min-height: unset !important;
}
.verify-modal .modal-title {
    margin-bottom: 24px;
    padding-right: 44px;
}
@media screen and (max-width: 768px) {
    .verify-modal {
        height: 100%;
        max-width: unset;
        width: 100%;
    }
}

.delete-modal {
    width: 408px;
    min-height: unset !important;
}
.delete-modal .modal-title {
    max-width: 253px;
    margin-bottom: 42px;
}
.delete-modal__btns {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin: 0 auto;
    column-gap: 32px;
}
@media screen and (max-width: 768px) {
    .delete-modal {
        width: 100%;
        min-height: 100% !important;
        display: flex;
    }
    .delete-modal .modal-title {
        max-width: unset;
    }
}

.tip-error-modal .textarea {
    height: 136px;
    background-color: #281f34;
}
@media screen and (max-width: 768px) {
    .tip-error-modal .btn {
        flex: 1;
    }
}

.test-mode-modal .textarea {
    height: 136px;
    background-color: #281f34;
}
.test-mode-modal__file-field_drop-zone {
    background-color: #281f34;
    border-radius: 8px;
    display: block;
}
.test-mode-modal__file-field_drop-zone span {
    color: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 768px) {
    .test-mode-modal .btn {
        flex: 1;
    }
}

.verify-email-request-modal {
    min-height: unset !important;
    padding-right: 64px !important;
}
@media screen and (max-width: 768px) {
    .verify-email-request-modal {
        min-height: 100% !important;
        padding-right: 30px !important;
    }
}

.greetings-modal__prev-btn {
    position: absolute;
    left: 16px;
    top: 16px;
    opacity: 0.5;
}
.greetings-modal .input,
.greetings-modal input,
.greetings-modal .select-list-title,
.greetings-modal .select-list-items {
    background: #281f34 !important;
}
.greetings-modal .select-list-title {
    width: 100% !important;
    box-sizing: border-box !important;
}
.greetings-modal .modal-block-tumbler {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    border: 2px solid #fff;
}
.greetings-modal .modal-block-tumbler .active {
    color: #212121 !important;
}
.greetings-modal .modal-tumbler-bg {
    background: #fff !important;
}
@media screen and (max-width: 768px) {
    .greetings-modal-wrapper {
        max-width: unset;
        padding: 64px 16px 16px 16px !important;
    }
}
.greetings-modal .row .btn {
    width: 170px;
}
.greetings-modal .btn {
    width: 240px;
}

.complaint-modal {
    min-width: 460px;
    overflow: hidden;
}
.complaint-modal__footer {
    transition: max-height 0.3s ease-in-out;
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
    max-height: 0;
}
.complaint-modal__footer:before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    background-color: #342c3e;
    height: 0;
    transition: height 0.3s ease-in-out;
}
.complaint-modal__footer.open {
    transform: unset;
    max-height: 78px;
}
.complaint-modal__footer.open:before {
    height: 1px;
}
@media screen and (max-width: 768px) {
    .complaint-modal {
        min-width: unset;
        height: 100%;
    }
    .complaint-modal__footer {
        margin-left: -16px;
        margin-right: -16px;
        padding: 0 16px;
    }
}
.complaint-modal-wrapper {
    height: max-content !important;
    min-height: unset !important;
}
@media screen and (max-width: 768px) {
    .complaint-modal-wrapper {
        height: 100% !important;
        min-height: 100% !important;
        padding: 0 !important;
    }
}

.modal-block {
    min-height: 320px;
}

.modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
}
.modal-subtitle {
    font-size: 14px;
    line-height: 22px;
}
.modal-content {
    margin: auto;
}
@media screen and (max-width: 768px) {
    .modal-content {
        margin: unset;
        height: 100%;
        width: 100%;
    }
}

.error {
    color: #ed0a34;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
}
.error-input {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}

.input {
    position: relative;
    display: block;
    width: 100%;
    background: #1e1529;
    border-radius: 8px;
    height: 56px;
    font-size: 15px;
    padding: 0 16px;
    outline: none;
    box-sizing: border-box;
    color: #fff;
}
.input:disabled {
    cursor: default;
    background: #1e1529;
    color: #ffffff;
    opacity: 0.3;
}
.input-wrapper {
    position: relative;
}
.input-wrapper svg {
    opacity: 0.5;
}
.input-pass {
    padding-right: 48px;
}
.input-btn {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.label {
    position: relative;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    opacity: 0.4;
    margin-bottom: 5px;
}

.input-radio {
    cursor: pointer;
}

.textarea {
    height: 136px;
    padding-top: 16px;
}
@media screen and (max-width: 768px) {
    .textarea {
        height: 96px;
    }
}

.checkbox {
    -webkit-appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin-right: 12px;
    transition: all 0.4s ease;
}
.checkbox + label {
    color: rgba(255, 255, 255, 0.5);
}
.checkbox + label a {
    color: #fff;
}
.checkbox:checked {
    background: #005bff;
    border-color: #005bff;
    &:after {
        position: absolute;
        content: url("/public/images/check-small.svg");
        top: 1px;
        left: 4px;
    }
}
.checkbox:disabled {
    cursor: default;
}
.select-list .loader,
.select-list svg {
    height: 22px !important;
    width: 22px !important;
}
.select-list .loader path,
.select-list svg path {
    stroke: #fff;
}
.select-list svg {
    right: 8px !important;
    margin-right: 0 !important;
}
.select-list input {
    padding: 0 !important;
    border: none !important;
    height: 52px !important;
}

.select-list-items {
    min-height: 32px;
}
.select-list-items--loading {
    line-height: 28px;
}

.email-veirify-hint {
    background-color: #fd4aa7;
    padding: 12px 20px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}
.email-veirify-hint__left {
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}
.email-veirify-hint__left p {
    flex: 1;
}
.email-veirify-hint .btn {
    white-space: nowrap;
    width: 225px;
}
@media screen and (max-width: 1024px) {
    .email-veirify-hint {
        padding-left: 16px;
        margin-top: 8px;
    }
    .email-veirify-hint__left {
        align-items: flex-start;
        font-size: 13px;
        line-height: 18px;
        column-gap: 14px;
    }
    .email-veirify-hint__left p {
        max-width: 120px;
    }
}

.img-wrap {
    width: 100%;
}
.img-wrap img {
    object-fit: cover;
    border-radius: inherit;
    transition: transform 300ms linear;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.img-wrapper {
    width: 100%;
    border-radius: inherit;
    height: 100%;
    overflow: hidden;
}
.img-wrapper img {
    object-fit: cover;
    border-radius: inherit;
    transition: transform 300ms linear;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.card-preview {
    position: relative;
    overflow: hidden;
    transition: transform 300ms linear;
    display: flex;
}
.card-preview.gradient-border {
    background: linear-gradient(218.75deg, #5177ff 0%, #a04bff 51.04%, #ff349d 100%);
    padding: 3px;
}
.card-preview.square {
    box-sizing: border-box;
}
.card-preview.square .skeleton {
    height: unset;
}
.card-preview.square.xl {
    border-radius: 16px;
    width: 72px;
    height: 72px;
}
.card-preview.square.l {
    border-radius: 16px;
    width: 56px;
    height: 56px;
}
.card-preview.square.m {
    border-radius: 16px;
    width: 48px;
    height: 48px;
}
.card-preview.square.s {
    border-radius: 12px;
    width: 40px;
    height: 40px;
}
.card-preview.square.s.gradient-border {
    padding: 2px;
}
.card-preview.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.card-preview.m.gradient-border {
    padding: 2px;
}
.card-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.film-list-element-genres {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}
.film-list-element-genres span {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 13px;
    padding: 0 10px;
    line-height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}
.film-list-element-genres span:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.film-card {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: 0;
    transition: opacity 300ms linear;
    color: #fff;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.film-card__progress-line {
    overflow: hidden;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 3;
    height: 3px;
}
.film-card__progress-line p {
    height: 3px;
    background-color: #fd4aa7;
    border-radius: 16px;
}
.film-card h5 {
    font-size: 18px;
    line-height: 24px;
    margin: 12px 0;
    white-space: break-spaces;
    opacity: 0.8;
}
@media screen and (max-width: 768px) {
    .film-card h5 {
        font-size: 13px;
        margin: 8px 0;
    }
}
.film-card--profile {
    width: calc(33.3333333333% - 18px);
    min-height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    .film-card--profile {
        width: calc(50% - 4px);
    }
}
.film-card--profile .img-wrap {
    height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    .film-card--profile .img-wrap {
        height: 25.39vw;
    }
}
.film-card .img-wrap {
    border-radius: 16px;
    transition: all 300ms ease-out;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    .film-card .img-wrap {
        border-radius: 12px;
    }
}
.film-card:hover img {
    transform: scale(1.015);
}
.film-card:hover h5 {
    opacity: 1;
}
@media screen and (max-width: 968px) {
    .film-card:hover img {
        transform: unset;
    }
    .film-card:hover h5 {
        opacity: 0.8;
    }
}
.film-card__overlay {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}
.film-card .remove-btn {
    margin: 10px 10px 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 30px;
}
.film-card .remove-btn img {
    width: 24px;
    height: 24px;
}
@media screen and (max-width: 1024px) {
    .film-card .remove-btn {
        right: 4px;
        top: 4px;
    }
}
@media screen and (max-width: 1024px) {
    .film-card .film-list-element-genres {
        display: none;
    }
}

.card {
    display: inline-block;
    color: #fff;
    vertical-align: top;
    cursor: pointer;
}
.card .delete-fav-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.2);
    z-index: 1;
}
.card:hover .card__title {
    opacity: 1;
}
.card .channel-preview {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
}
.card .channel-name {
    width: 100%;
    white-space: break-spaces;
}
.card__title {
    white-space: break-spaces;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    box-orient: vertical;
}
.card--xs {
    width: calc((100% - 72px) / 4);
}
.card--xs .card__preview {
    height: 12.223vw;
}
.card--xs:hover img {
    transform: scale(1.03269);
}
.card--s {
    width: calc((100% - 48px) / 3);
}
.card--s .card__preview {
    height: 17.778vw;
}
.card--s:hover img {
    transform: scale(1.03269);
}
.card--xs .card__title,
.card--s .card__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
}
.card--v {
    width: calc((100% - 96px) / 5);
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}
.card--v:hover img {
    transform: scale(1.02613);
}
.card--v .card__preview {
    height: 29.4445vw;
}
.card--v .card__info {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.card--v .card__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    flex: 1;
}
@media screen and (max-width: 1024px) {
    .card--v {
        width: calc((100% - 48px) / 4);
    }
    .card--v .card__preview {
        height: 37.895vw;
    }
    .card--v .card__title {
        font-size: 14px;
        line-height: 19px;
        line-clamp: 2;
        -webkit-line-clamp: 2;
    }
}
@media screen and (max-width: 768px) {
    .card--v {
        width: calc((100% - 8px) / 2);
    }
    .card--v .card__preview {
        height: 78.94vw;
    }
}
@media screen and (max-width: 768px) {
    .card--xs:hover img,
    .card--s:hover img,
    .card--v:hover img {
        transform: unset;
    }
}
.card--h {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;
    padding: 6px;
}
.card--h .card__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}
.card--h .card__preview {
    align-self: stretch;
    max-width: 176px;
    height: 7.223vw;
}
.card--h .card__info {
    flex: 1;
}
.card__preview {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}
.card__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 300ms linear;
    border-radius: inherit;
}
.card__preview-badge {
    border-radius: 8px;
    padding: 3.5px 8px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.card__preview-badge span {
    position: relative;
    font-size: 13px;
    line-height: 21px;
}
.card__preview-badge--live {
    background: #ed0a34;
    padding-left: 22px;
}
.card__preview-badge--live span:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    left: -6px;
    transform: translate(-100%, -50%);
    top: 50%;
}
.card__preview-badge--trend {
    background-color: #fd4aa7;
}
.card__preview-badge--videos_count {
    top: unset;
    left: unset;
    right: 8px;
    bottom: 8px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
}
.card__channel {
    display: flex;
    margin-top: 32px;
}
.card__channel img {
    margin-right: 12px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
}
.card__channel-title {
    white-space: break-spaces;
    font-size: 13px;
    line-height: 18px;
}
.card__channel-time {
    font-size: 13px;
    line-height: 18px;
    opacity: 0.5;
    white-space: break-spaces;
}
.card__views {
    font-size: 13px;
    line-height: 18px;
    min-height: 18px;
    opacity: 0.5;
    padding-left: 22px;
    position: relative;
}
@media screen and (max-width: 1024px) {
    .card__title {
        line-clamp: 2;
        -webkit-line-clamp: 2;
    }
    .card--xs {
        width: calc((100% - 8px) / 2);
    }
    .card--xs .card__preview {
        height: 27.334vw;
    }
    .card--xs.card-slide {
        width: 68.27vw;
    }
    .card--xs.card-slide .card__preview {
        height: 38.4vw;
    }
    .card--s {
        width: 100%;
    }
    .card--s .card__preview {
        height: 53.334vw;
    }
    .card--s.card-slide {
        width: 87.4vw;
    }
    .card--s.card-slide .card__preview {
        height: 49.07vw;
    }
}
@media screen and (max-width: 768px) {
    .card--xs {
        width: 100%;
    }
    .card--xs .card__preview {
        height: 53.334vw;
    }
}
.card .two-lines {
    opacity: 0.8;
}

.two-lines {
    white-space: break-spaces;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: 2;
}

.one-lines,
.one-line {
    white-space: break-spaces;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    word-break: break-word;
}

.card-container {
    display: inline-flex;
    flex-direction: column;
    color: #fff;
    cursor: pointer;
    position: relative;
}
.card-container .card-title {
    white-space: break-spaces;
    opacity: 0.8;
    margin: 12px 0;
}
.card-container:hover .card-preview {
    transform: scale(1.0408);
}
@media screen and (max-width: 1024px) {
    .card-container:hover .card-preview {
        transform: unset;
    }
}
.card-container:hover .card-title {
    opacity: 1;
}

.card-badge {
    position: absolute;
    border-radius: 8px;
}
.card-badge-dur {
    background: rgba(0, 0, 0, 0.7);
    bottom: 8px;
    right: 8px;
}
.card-badge-new {
    border-radius: 6px;
    letter-spacing: 0.211px;
}
.card-badge-videos {
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.7);
}

.timecode-progress-line {
    overflow: hidden;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 3;
    height: 3px;
}
.timecode-progress-line p {
    height: 3px;
    background-color: #fd4aa7;
    border-radius: 16px;
}

.share-menu {
    width: 256px;
}
.share-menu-divider:before {
    position: absolute;
    content: "";
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #342c3e;
}
.share-menu li {
    cursor: pointer;
}

.actions-menu {
    width: max-content;
}
.actions-menu button {
    column-gap: 20px;
    flex-wrap: nowrap;
}

.popup-menu {
    white-space: nowrap;
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 48px;
    transform: translate(0, 0%);
}
.popup-menu__content {
    box-shadow: 0px 8px 64px rgba(12, 2, 22, 0.7);
    display: none;
    border-radius: inherit;
}
.popup-menu__content.active {
    display: block;
}
@media screen and (max-width: 1024px) {
    .popup-menu {
        bottom: 0;
        right: 48px;
    }
}

.tooltip {
    position: absolute;
    content: "";
    top: -14px;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 2;
    display: none;
    width: max-content;
}
.tooltip-wrap:hover .tooltip {
    display: block;
}
.tooltip-text {
    max-width: 140px;
    width: 100%;
}
.tooltip-text:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    border-width: 5.5px 7px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent;
}

.tooltip-apple-app {
    position: fixed;
    bottom: 16px;
    top: unset;
    display: flex;
    left: 0;
    right: 0;
    transform: unset;
    width: calc(100% - 64px);
    background-color: #342c3e;
    opacity: 1;
    left: 32px;
    right: 32px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
}
.tooltip-apple-app.visible {
    opacity: 1;
}
.tooltip-apple-app .tooltip-text {
    max-width: unset;
}
.tooltip-apple-app .tooltip-text:after {
    border-color: #342c3e transparent transparent transparent;
}

@media screen and (max-width: 768px) {
    .test-mode-banner__text {
        max-width: 350px;
    }
}

.not-found-page {
    width: 100%;
    height: 100vh;
    max-height: 680px;
    position: relative;
}
@media screen and (max-width: 1024px) {
    .not-found-page {
        max-height: 540px;
    }
}
.not-found-page__overlay {
    background: linear-gradient(180deg, rgba(12, 2, 22, 0) 27.31%, #0c0216 100%),
        linear-gradient(360deg, rgba(12, 2, 22, 0) 60.27%, #0c0216 95.62%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    padding: 128px 72px;
    box-sizing: border-box;
}
.not-found-page__overlay h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 115%;
    color: #fff;
    animation: asdd 2s linear infinite;
    margin-bottom: 34px;
}
.not-found-page__overlay button {
    white-space: nowrap;
}
@media screen and (max-width: 1024px) {
    .not-found-page__overlay {
        padding: 56px 16px;
    }
    .not-found-page__overlay h1 {
        font-size: 32px;
        top: 56px;
        text-align: center;
        margin-bottom: 24px;
    }
    .not-found-page__overlay button {
        margin: 0 auto;
    }
}
.not-found-page h2 {
    z-index: 3;
    position: absolute;
    font:
        bold 200px/200px Arial,
        sans-serif;
    left: 50%;
    top: 50%;
    margin-top: -100px;
    width: 100%;
    margin-left: -50%;
    height: 200px;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    animation: asdd 2s linear infinite;
}
.not-found-page canvas {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.not-found-page .caps {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: as 8s linear infinite;
}
.not-found-page .caps img {
    display: block;
    width: 100%;
    height: 100%;
}
.not-found-page .frame {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(12, 2, 22, 0) 27.31%, #0c0216 100%),
        linear-gradient(360deg, rgba(12, 2, 22, 0) 60.27%, #0c0216 95.62%);
    /* W3C */
}
.not-found-page .frame div {
    position: absolute;
    left: 0;
    top: 0%;
    width: 100%;
    height: 20%;
    background-color: rgba(12, 2, 22, 0.12);
    box-shadow: 0 0 10px rgba(12, 2, 22, 0.3);
    animation: asd 12s linear infinite;
}
.not-found-page .frame div:nth-child(1) {
    animation-delay: 0;
}
.not-found-page .frame div:nth-child(2) {
    animation-delay: 4s;
}
.not-found-page .frame div:nth-child(3) {
    animation-delay: 8s;
}

@keyframes as {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0.3;
    }
    20% {
        opacity: 0.1;
    }
    30% {
        opacity: 0.5;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    55% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
}
@keyframes asd {
    0% {
        top: 0%;
    }
    100% {
        top: 100%;
    }
}
@keyframes asdd {
    0% {
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    }
    33% {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    66% {
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    100% {
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
    }
}
.reset-page,
.feedback-page {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    min-height: 688px;
    flex-wrap: wrap;
}
.reset-page__form,
.feedback-page__form {
    max-width: 400px;
    width: 400px;
}
.reset-page__form .form-field:not(:last-child),
.feedback-page__form .form-field:not(:last-child) {
    margin-bottom: 22px;
}
.reset-page__form-title,
.feedback-page__form-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 115%;
    margin-bottom: 20px;
}
.reset-page button[type="submit"],
.feedback-page button[type="submit"] {
    margin-top: 32px;
}
.reset-page--success,
.feedback-page--success {
    justify-content: center;
    max-width: unset;
    padding-top: 64px;
}
.reset-page--success svg,
.feedback-page--success svg {
    margin: 0 auto 24px auto;
    display: block;
}
.reset-page--success h3,
.feedback-page--success h3 {
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
    .reset-page,
    .feedback-page {
        max-width: 90%;
        min-height: 488px;
    }
    .reset-page__form,
    .feedback-page__form {
        width: 100%;
    }
    .reset-page__svg-figure,
    .feedback-page__svg-figure {
        width: 200px;
        height: 200px;
    }
}
@media screen and (max-width: 768px) {
    .reset-page,
    .feedback-page {
        min-height: unset;
        justify-content: center;
        padding-bottom: 32px;
        max-width: unset;
    }
    .reset-page__svg-figure,
    .feedback-page__svg-figure {
        width: 112px;
        height: 112px;
        margin-bottom: 48px;
    }
    .reset-page__form,
    .feedback-page__form {
        max-width: unset;
    }
    .reset-page__form-wrapper,
    .feedback-page__form-wrapper {
        width: 100%;
        order: 1;
    }
    .reset-page__form-title,
    .feedback-page__form-title {
        font-size: 24px;
        line-height: 115%;
    }
    .reset-page--success,
    .feedback-page--success {
        padding: 0 0 164px 0;
    }
    .reset-page--success svg,
    .feedback-page--success svg {
        margin-bottom: 48px;
    }
}

.bloggers-page {
    row-gap: 64px;
}
.bloggers-page .card__channel {
    margin-top: 12px;
}
.bloggers-page .card__title {
    line-height: 19px;
    height: 38px;
}
@media screen and (max-width: 1024px) {
    .bloggers-page .swiper-slide {
        margin-right: 8px !important;
    }
}
@media screen and (max-width: 1024px) {
    .bloggers-page {
        row-gap: 32px;
    }
}
.bloggers-page .film-list {
    min-height: unset !important;
}
.bloggers-page .film-list.v-format .card {
    position: relative;
}
.bloggers-page .film-list.v-format .card__title {
    font-size: 18px;
    line-height: 19px;
}
.bloggers-page .film-list.v-format .card__channel {
    margin-top: 8px;
}
@media screen and (max-width: 1024px) {
    .bloggers-page .film-list.v-format .card__title {
        font-size: 14px;
        line-height: 17px;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        height: unset;
    }
}
.bloggers-page .film-list.bloggers-page__live {
    min-height: calc(83px + 31.667vw + 12px + 38px + 8px + 36px) !important;
}
.bloggers-page .film-list.bloggers-page__live .card {
    width: calc((100% - 72px) / 4);
}
.bloggers-page .film-list.bloggers-page__live .card__preview {
    height: 31.667vw;
}
@media screen and (max-width: 1024px) {
    .bloggers-page .film-list.bloggers-page__live {
        min-height: calc(56px + 70.4vw + 17px + 12px + 8px + 36px) !important;
    }
    .bloggers-page .film-list.bloggers-page__live .card {
        width: calc((100% - 16px) / 2);
    }
    .bloggers-page .film-list.bloggers-page__live .card__preview {
        height: 70.4vw;
    }
}
.bloggers-page .film-list.bloggers-page__shorts {
    min-height: calc(83px + 29.445vw + 12px + 38px + 8px + 36px) !important;
}
.bloggers-page .film-list.bloggers-page__shorts .card {
    width: calc((100% - 96px) / 5);
}
.bloggers-page .film-list.bloggers-page__shorts .card__preview {
    height: 29.445vw;
}
@media screen and (max-width: 1024px) {
    .bloggers-page .film-list.bloggers-page__shorts {
        min-height: calc(56px + 78.934vw + 17px + 12px + 8px + 36px) !important;
    }
    .bloggers-page .film-list.bloggers-page__shorts .card {
        width: calc((100% - 16px) / 2);
    }
    .bloggers-page .film-list.bloggers-page__shorts .card__preview {
        height: 78.934vw;
    }
}

.bloggers-videos-page,
.bloggers-page {
    row-gap: 64px;
}
.bloggers-videos-page #main-menu,
.bloggers-page #main-menu {
    overflow: hidden;
}
.bloggers-videos-page #main-menu .slider-control-arrow,
.bloggers-page #main-menu .slider-control-arrow {
    width: 40px !important;
    height: 40px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    opacity: 0 !important;
    transition: opacity 0.2s ease-in-out;
}
.bloggers-videos-page #main-menu .slider-control-arrow svg,
.bloggers-page #main-menu .slider-control-arrow svg {
    width: 16px !important;
}
.bloggers-videos-page #main-menu .slider-control-arrow:hover,
.bloggers-page #main-menu .slider-control-arrow:hover {
    margin: unset;
}
.bloggers-videos-page #main-menu .swiper-container,
.bloggers-page #main-menu .swiper-container {
    width: 100%;
}
.bloggers-videos-page #main-menu .swiper-container:hover .slider-control-arrow:not(:disabled),
.bloggers-page #main-menu .swiper-container:hover .slider-control-arrow:not(:disabled) {
    opacity: 1 !important;
}
.bloggers-videos-page #main-menu .slider-next,
.bloggers-page #main-menu .slider-next {
    right: 20px !important;
    margin-right: -72px !important;
    box-shadow: 21px 0px 28px #212121;
}
.bloggers-videos-page #main-menu .slider-prev,
.bloggers-page #main-menu .slider-prev {
    left: 20px !important;
    margin-left: -72px !important;
    box-shadow: -21px 0px 28px #212121;
}
.bloggers-videos-page #main-menu .swiper-wrapper,
.bloggers-page #main-menu .swiper-wrapper {
    display: block;
    white-space: nowrap;
    font-size: 0;
}
.bloggers-videos-page #main-menu a,
.bloggers-page #main-menu a {
    padding: 0 16px;
    display: inline-block !important;
}
.bloggers-videos-page #main-menu .container,
.bloggers-page #main-menu .container {
    gap: 0 !important;
}
.bloggers-videos-page #main-menu .container:before,
.bloggers-page #main-menu .container:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(90deg, #1e1529 0%, rgba(30, 21, 41, 0) 100%);
}
@media screen and (max-width: 1024px) {
    .bloggers-videos-page,
    .bloggers-page {
        row-gap: 32px;
    }
    .bloggers-videos-page #main-menu,
    .bloggers-page #main-menu {
        margin-top: -25px;
    }
    .bloggers-videos-page #main-menu a,
    .bloggers-page #main-menu a {
        font-size: 14px !important;
        line-height: 34px !important;
    }
}

.bloggers-page .channels-swiper .slider-control-arrow,
.bloggers-profile .channels-swiper .slider-control-arrow {
    top: calc(83px + 5.93vw - 16px) !important;
}
.bloggers-page .channels-swiper .card-title,
.bloggers-profile .channels-swiper .card-title {
    margin-bottom: 0 !important;
}

.playlist-card-item {
    position: relative;
    display: block;
    width: calc(25% - 18px);
    height: unset !important;
    border-radius: 16px;
}
.playlist-card .card__preview {
    width: 100%;
    height: calc(13.4vw - 18px);
    z-index: 2;
}
@media screen and (max-width: 1024px) {
    .playlist-card .card__preview {
        height: 25.39vw;
        border-radius: 12px;
    }
}
.playlist-card .categories-list-item-back2,
.playlist-card .categories-list-item-back2-img {
    top: -9px !important;
}
.playlist-card .categories-list-item-back1,
.playlist-card .categories-list-item-back1-img {
    top: -16px !important;
}
.playlist-card .categories-list-item-back1 {
    background: rgba(0, 0, 0, 0.45) !important;
    z-index: 1;
}
.playlist-card .categories-list-item-back1-img {
    z-index: 0;
}
.playlist-card .categories-list-item-back2 {
    background: rgba(0, 0, 0, 0.3) !important;
    z-index: 2;
}
.playlist-card .categories-list-item-back2-img {
    z-index: 1;
}
.playlist-card .categories-list-item-back1-img,
.playlist-card .categories-list-item-back2-img {
    width: 100%;
    height: 100%;
}
.playlist-card:hover .card__preview img {
    transform: scale(1.015);
}
.playlist-card:hover .categories-list-item-back1 {
    top: -19px !important;
}
.playlist-card:hover .categories-list-item-back2 {
    top: -10px !important;
}

.blogger-video {
    flex-wrap: nowrap !important;
    margin-top: -25px !important;
    margin: 0 auto;
    max-width: 1480px;
}
.blogger-video__left {
    flex: 1;
    min-width: 520px;
}
.blogger-video__right {
    max-width: 416px;
}
.blogger-video__right .blogger-card {
    width: 20.1933333333%;
}
.blogger-video__right-content__channels .row {
    column-gap: 9.6%;
    justify-content: center;
}
.blogger-video__player {
    height: 33.056vw;
    border-radius: 24px;
    height: auto;
    position: relative;
}
.blogger-video__player .skeleton {
    position: absolute;
}
.blogger-video__title {
    max-width: 78%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    min-height: 24px;
}
.blogger-video__desc {
    max-width: 746px;
}
.blogger-video__desc-text {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 42px;
    color: rgba(255, 255, 255, 0.6);
}
.blogger-video__desc a,
.blogger-video__desc li {
    color: #fff;
}
.blogger-video .blogger-info-row .certified-icon svg {
    width: 8px;
    height: 8px;
}
.blogger-video__comments .select-list {
    height: unset !important;
}
.blogger-video__comments .select-list-items {
    top: 40px !important;
}
@media screen and (max-width: 1024px) {
    .blogger-video {
        margin-top: -24px;
        padding-top: calc(57.6vw + 46px + 64px + 24px);
        flex-wrap: wrap !important;
    }
    .blogger-video__desc {
        position: relative;
        transition: padding 0.4s ease-in-out;
    }
    .blogger-video__desc.hidden:after {
        visibility: hidden;
    }
    .blogger-video__desc:after {
        transition: visibility 0.1s ease-in-out;
        position: absolute;
        content: "";
        bottom: 16px;
        left: -16px;
        right: -16px;
        height: 1px;
        background-color: #342c3e;
    }
    .blogger-video__desc-text {
        max-height: 0;
    }
    .blogger-video__left {
        width: 100%;
        min-width: unset;
    }
    .blogger-video__right {
        max-width: unset;
        width: 100%;
    }
    .blogger-video__right .blogger-card {
        width: calc((100% - 16px) / 3);
    }
    .blogger-video__right-content__channels .row {
        column-gap: unset;
        justify-content: space-around;
    }
    .blogger-video__player,
    .blogger-video .blogger-info-row {
        margin-left: -16px;
        border-radius: 0;
        margin-right: -16px;
    }
    .blogger-video__player {
        height: 57.6vw;
        width: 100vw;
        position: fixed;
        content: "";
        top: 110px;
        left: 16px;
        right: 0;
        z-index: 4;
    }
    .blogger-video__title {
        max-width: unset;
        font-size: 15px;
        line-height: 19px;
    }
    .blogger-video__comments {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
        box-sizing: border-box;
        border-bottom: 1px solid #342c3e;
    }
    .blogger-video__comments .comments-header {
        position: sticky;
        top: -1px;
        background: #0c0216;
        z-index: 3;
    }
    .blogger-video__comments .comments-list__item .comment-form__wrapper {
        margin-right: 2px;
    }
    .blogger-video__comments .select-list-items {
        left: unset !important;
        right: 0 !important;
        z-index: 3;
    }
    .blogger-video__comments .select-list-items svg {
        margin-right: 0 !important;
    }
    .blogger-video__comments.open {
        width: 100%;
        background: #0c0216;
        margin-top: -24px;
        position: fixed;
        content: "";
        top: calc(57.6vw + 64px + 46px + 24px);
        bottom: 0;
        z-index: 3;
        height: calc(100vh - (57.6vw + 46px + 64px));
        overflow: auto;
    }
}
@media screen and (max-width: 768px) {
    .blogger-video {
        padding-top: calc(57.6vw + 66px + 64px + 24px);
    }
    .blogger-video__player {
        top: 130px;
    }
    .blogger-video__comments.open {
        top: calc(57.6vw + 64px + 66px + 23px);
        padding-bottom: 64px;
    }
}

@media screen and (max-width: 768px) {
    .feedback-page button[type="submit"] {
        width: 100%;
    }
}

.profile-tips-item__product {
    position: relative;
}
.profile-tips-item__product-bottom {
    padding-right: 160px;
}
@media screen and (max-width: 1200px) {
    .profile-tips-item__product-bottom {
        padding-right: 0;
    }
}
@media screen and (max-width: 1200px) {
    .profile-tips-item__product .product-info-title {
        flex-wrap: wrap;
    }
}
.profile-tips-item__product:after {
    position: absolute;
    content: "";
    top: 50%;
    bottom: 0;
    right: -24px;
    width: 1px;
    height: calc(100% + 24px);
    transform: translate(0, -50%);
    background-color: #342c3e;
}
@media screen and (max-width: 1024px) {
    .profile-tips-item__product:after {
        display: none;
    }
}
.profile-tips-item__product:hover .profile-tips-item__error-btn {
    display: block;
}
@media screen and (max-width: 1024px) {
    .profile-tips-item__product {
        padding-top: 24px;
    }
    .profile-tips-item__product .profile-tips-item__error-btn {
        display: block;
        bottom: unset;
        top: 0;
        transform: translateY(-100%);
    }
}
.profile-tips-item__product .product-info-title {
    display: flex;
    justify-content: space-between;
}
.profile-tips-item__product .product-info-price {
    white-space: nowrap;
}
.profile-tips-item__error-btn {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.T12 {
    font-size: 12px;
    line-height: 17px;
}

.user-subs-page .blogger-card {
    width: unset;
}
.user-subs-page__card-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 3.5% 32px;
}
@media screen and (max-width: 1200px) {
    .user-subs-page__card-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
}
@media screen and (max-width: 1024px) {
    .user-subs-page__card-list {
        display: grid;
        grid-gap: 24px 16px;
    }
}
@media screen and (max-width: 998px) {
    .user-subs-page__card-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (max-width: 768px) {
    .user-subs-page__card-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

.user-profile-fav {
    overflow-x: hidden;
    width: 100%;
}
.user-profile-fav__section-shorts {
    overflow: hidden;
}
.user-profile-fav__section-shorts .card--v {
    width: calc((100% - 24px) / 4);
}
.user-profile-fav__section-shorts .card--v .card__preview {
    height: 29.112vw;
}
.user-profile-fav__section-shorts .slider-control-arrow {
    top: calc(50% - 40px) !important;
    right: 40px !important;
    transform: translate(0, -50%) !important;
}
@media screen and (max-width: 1024px) {
    .user-profile-fav__section-shorts .slider-control-arrow {
        display: none !important;
    }
    .user-profile-fav__section-shorts .card--v {
        width: calc((100% - 24px) / 4);
    }
    .user-profile-fav__section-shorts .card--v .card__preview {
        height: 41.445vw;
    }
}
@media screen and (max-width: 768px) {
    .user-profile-fav__section-shorts .card--v {
        width: calc((100% - 8px) / 2);
    }
    .user-profile-fav__section-shorts .card--v .card__preview {
        height: 78.94vw;
    }
}
.user-profile-fav__section-videos {
    overflow: hidden;
}
.user-profile-fav__section-videos .blogger-channel-card {
    width: calc((100% - 48px) / 3);
}
.user-profile-fav__section-videos .blogger-channel-card:hover {
    background-color: unset !important;
}
.user-profile-fav__section-videos .blogger-channel-card .preview {
    height: 12.2223vw;
}
.user-profile-fav__section-videos .blogger-channel-card .video-title {
    font-size: 16px;
    line-height: 19px;
    min-height: 19px;
}
@media screen and (max-width: 1024px) {
    .user-profile-fav__section-videos .blogger-channel-card .preview {
        height: 18.334vw;
    }
}
@media screen and (max-width: 768px) {
    .user-profile-fav__section-videos .blogger-channel-card {
        width: 100% !important;
    }
    .user-profile-fav__section-videos .blogger-channel-card .preview {
        height: 53.334vw;
    }
}

.user-profile__container-right {
    margin-right: 72px;
}
@media screen and (max-width: 1024px) {
    .user-profile__container-right {
        margin-right: 16px;
        margin-left: 16px;
        width: unset !important;
    }
}
@media screen and (max-width: 1280px) {
    .user-profile__right {
        order: -1;
        width: 100%;
        margin-bottom: 24px;
    }
}
.user-profile__del-profile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
}
.user-profile__del-profile-btn:hover {
    opacity: 1;
}

.shorts-page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    top: 118px;
    position: absolute;
}
.shorts-page__back-btn {
    position: absolute;
    content: "";
    top: 16px;
    left: 72px;
    z-index: 1;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}
@media screen and (max-width: 1024px) {
    .shorts-page__back-btn {
        left: 16px;
        top: 16px;
    }
}
.shorts-page-inner {
    height: 100%;
    overflow: hidden;
}
.shorts-page__swiper-nav-btn {
    position: absolute;
    content: "";
    right: 20px;
    left: unset;
    transition: all 0.3s ease-in-out;
}
.shorts-page__swiper-nav-btn:hover {
    width: 70px;
    height: 70px;
    right: 17px;
}
.shorts-page__swiper-nav-btn.prev {
    bottom: 96px;
}
.shorts-page__swiper-nav-btn.next {
    bottom: 20px;
}
.shorts-page__swiper-nav-btn.next:disabled ~ .prev {
    bottom: 20px;
}
.shorts-page__swiper-nav-btn:disabled {
    display: none;
}
.shorts-page .swiper-container {
    padding-top: 16px;
    margin-top: -1px;
    height: calc(100vh - 88px - 75px - 46px);
    width: 100%;
}
@media screen and (max-height: 778px) {
    .shorts-page .swiper-container {
        padding-top: 0;
        margin-top: 0;
        height: calc(100vh - 88px - 75px);
    }
}
@media screen and (max-width: 768px) {
    .shorts-page .swiper-container {
        height: 100%;
    }
}
.shorts-page .swiper-wrapper {
    width: 100%;
    height: 100%;
}
.shorts-page .swiper-slide {
    height: 75.6vh;
    width: auto;
    height: calc(100vh - 88px - 75px - 46px);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
}
.shorts-page .swiper-slide:first-child {
    margin-top: 0;
}
.shorts-page .swiper-slide:last-child {
    margin-bottom: 0;
}
@media screen and (max-height: 778px) {
    .shorts-page .swiper-slide {
        height: calc(100vh - 88px - 75px);
        margin-bottom: 30px;
    }
}
.shorts-page .swiper-slide-next {
    opacity: 0.4;
}
@media screen and (max-width: 768px) {
    .shorts-page .swiper-slide-next {
        opacity: 1;
    }
}
@media screen and (max-width: 768px) {
    .shorts-page {
        top: 0;
        position: fixed;
    }
    .shorts-page__back-btn {
        display: flex;
        left: 0;
        top: 0;
        width: 56px;
        height: 56px;
        background: transparent;
        backdrop-filter: unset;
    }
    .shorts-page .swiper-container {
        padding-top: 0;
    }
    .shorts-page .swiper-slide {
        width: 100%;
        height: 100%;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        min-width: unset;
    }
    .shorts-page__swiper-nav-btn {
        display: none;
    }
}

.blogger-profile {
    margin-top: -25px !important;
}
@media (max-width: 1024px) {
    .blogger-profile {
        margin-top: -25px !important;
    }
}
.blogger-profile .film-list {
    min-height: unset !important;
}
.blogger-profile .film-list:last-child {
    margin-bottom: 0;
}
.blogger-profile #all-categories {
    margin-top: 0;
}
.blogger-profile-top__img {
    height: 33.334vw;
    position: relative;
}
.blogger-profile-top__img:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.23%, rgba(0, 0, 0, 0.7) 100%);
    opacity: 0.7;
}
@media (max-width: 1024px) {
    .blogger-profile-top__img {
        height: 38.4vw;
    }
}
.blogger-profile-top__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    z-index: 1;
    box-sizing: border-box;
    border-radius: 32px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.23%, rgba(0, 0, 0, 0.7) 100%);
}
@media (max-width: 1024px) {
    .blogger-profile-top__overlay {
        position: unset;
        background: unset;
        border-radius: 0;
    }
    .blogger-profile-top__overlay .blogger-info-row {
        flex-wrap: wrap !important;
    }
    .blogger-profile-top__overlay .blogger-info-row__user {
        width: 100% !important;
    }
    .blogger-profile-top__overlay .blogger-info-row__user.short {
        width: unset !important;
    }
    .blogger-profile-top__overlay .blogger-info-row__user-right {
        width: unset !important;
    }
    .blogger-profile-top__overlay .blogger-info-row .icon-btn {
        opacity: 0.5;
    }
}
.blogger-profile__sub-btn {
    width: 158px !important;
    column-gap: 6 !important;
}
.blogger-profile__sub-btn.btn-white {
    width: 192px !important;
    padding-left: 24px !important;
}
.blogger-profile__sub-btn.btn-white:hover,
.blogger-profile__sub-btn.btn-white:hover svg path {
    color: #005bff;
    fill: #005bff;
}
@media screen and (max-width: 768px) {
    .blogger-profile__sub-btn {
        order: -1;
        width: 131px;
        column-gap: 2px !important;
        font-size: 14px !important;
        line-height: 22px !important;
    }
    .blogger-profile__sub-btn.btn-white {
        width: 152px;
        padding-left: 10px !important;
        padding-right: 16px !important;
    }
}
.blogger-profile .select-list {
    height: max-content !important;
}
@media screen and (max-width: 1024px) {
    .blogger-profile .select-list-items {
        left: unset !important;
        right: 0 !important;
        top: 40px !important;
    }
}
.blogger-profile-playlists .categories-list {
    gap: 104px 24px !important;
}
@media screen and (max-width: 1024px) {
    .blogger-profile-playlists .categories-list {
        justify-content: space-between !important;
        gap: 92px 8px !important;
    }
}
.blogger-profile-main__swipper {
    margin-bottom: 64px !important;
}
.blogger-profile-main__swipper.playlist-swiper .section-title {
    margin-bottom: 40px !important;
}
.blogger-profile-main__swipper.playlist-swiper .playlist-card {
    height: calc(13.4vw + 60px);
}
@media screen and (max-width: 1024px) {
    .blogger-profile-main__swipper.playlist-swiper .playlist-card {
        height: calc(25.4vw + 60px);
        width: calc((100% - 8px) / 2);
    }
    .blogger-profile-main__swipper.playlist-swiper .playlist-card .card__preview {
        height: 25.4vw;
    }
}
@media screen and (max-width: 768px) {
    .blogger-profile-main__swipper {
        margin-bottom: 32px !important;
    }
    .blogger-profile-main__swipper:first-child {
        min-height: 292px !important;
    }
}

.blogger-profile-shorts {
    min-height: 560px;
}
@media screen and (max-width: 1024px) {
    .blogger-profile-shorts {
        min-height: calc(56px + 78.94vw + 12px + 63px);
    }
}

.blogger-profile-videos {
    min-height: 560px;
}
@media screen and (max-width: 1024px) {
    .blogger-profile-videos {
        min-height: calc(56px + 53.334vw + 12px + 63px);
    }
}

.blogger-profile-playlists__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 58px 24px;
}
.blogger-profile-playlists__list .playlist-card {
    min-height: calc(13.4vw + 60px);
}
@media screen and (max-width: 1024px) {
    .blogger-profile-playlists__list {
        gap: 42px 8px;
    }
}
@media screen and (max-width: 768px) {
    .blogger-profile-playlists__list {
        gap: 42px 8px;
    }
    .blogger-profile-playlists__list .playlist-card {
        width: calc((100% - 8px) / 2);
    }
}

.blogger-profile-main .section-new {
    min-height: calc(83px + 12.223vw + 12px + 19px + 14px + 38px) !important;
}
@media screen and (max-width: 1024px) {
    .blogger-profile-main .section-new {
        min-height: calc(56px + 38.4vw + 12px + 19px + 6px + 38px) !important;
    }
}
.blogger-profile-main .section-popular {
    min-height: calc(83px + 17.778vw + 12px + 19px + 14px + 38px) !important;
}
.blogger-profile-main .section-popular .slider-control-arrow {
    top: calc(50% - 32px) !important;
}
@media screen and (max-width: 1024px) {
    .blogger-profile-main .section-popular {
        min-height: calc(56px + 49.07vw + 12px + 19px + 6px + 38px) !important;
    }
}
.blogger-profile-main .channels-swiper .slider-control-arrow {
    top: calc(50% + 20px) !important;
}
.blogger-profile-main .channels-swiper .card-title {
    margin-bottom: 0 !important;
}

.bloggers-videos-page {
    min-height: 600px;
}
.bloggers-videos-page .film-list {
    min-height: unset !important;
}
.bloggers-videos-page__section-title {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 46px;
    min-height: 46px;
}
.bloggers-videos-page .blogger-channel-card {
    width: calc((100% - 72px) / 4);
}
.bloggers-videos-page .blogger-channel-card .preview {
    height: 12.223vw;
}
.bloggers-videos-page .blogger-channel-card .video-title {
    font-size: 16px;
    line-height: 19px;
    min-height: 19px;
}
@media screen and (max-width: 1024px) {
    .bloggers-videos-page__section-title {
        font-size: 24px;
        min-height: 29px;
        line-height: 29px;
    }
    .bloggers-videos-page .blogger-channel-card {
        width: calc((100% - 8px) / 2);
    }
    .bloggers-videos-page .blogger-channel-card .preview {
        height: 27.334vw;
    }
}
@media screen and (max-width: 768px) {
    .bloggers-videos-page .blogger-channel-card {
        width: 100%;
    }
    .bloggers-videos-page .blogger-channel-card .preview {
        height: 53.334vw;
    }
}

.sellers-page .custom-pagination {
    height: 8px;
    display: flex;
    width: max-content;
    column-gap: 8px;
    margin: 24px auto 0 auto;
}
.sellers-page .custom-pagination .swiper-pagination-bullet {
    display: block;
    width: 8px;
    height: 8px;
    background-color: #342c3e;
    border-radius: 50%;
}
.sellers-page .custom-pagination .swiper-pagination-bullet-active {
    background-color: #005bff;
}
.sellers-page__top-section .row {
    flex-wrap: nowrap;
}
.sellers-page__top-section-left {
    max-width: 528px;
    width: 100%;
}
.sellers-page__top-section-right {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    background-size: cover;
}
@media screen and (max-width: 768px) {
    .sellers-page__top-section {
        padding-top: 84vw;
    }
    .sellers-page__top-section-left {
        max-width: unset;
    }
    .sellers-page__top-section-left h2,
    .sellers-page__top-section-left h5 {
        max-width: 340px;
    }
    .sellers-page__top-section .row {
        flex-wrap: wrap;
        justify-content: center;
    }
    .sellers-page__top-section .row p,
    .sellers-page__top-section .row .btn {
        width: 100%;
    }
    .sellers-page__top-section .row p {
        max-width: 340px;
    }
    .sellers-page__top-section-right {
        width: 100vw;
        height: 120vw;
        right: unset;
        left: 0;
        top: 0;
    }
}
@media screen and (max-width: 768px) {
    .sellers-page {
        margin-top: -40px;
    }
}
.sellers-page__how-section li {
    width: calc((100% - 64px) / 5);
}
.sellers-page__how-section h1 {
    position: relative;
    z-index: 1;
    font-size: 48px;
    line-height: 55px;
    font-weight: 400;
}
.sellers-page__how-section h1 span {
    display: block;
    background: #3586ff;
    opacity: 0.4;
    filter: blur(32px);
    position: absolute;
    content: "";
    top: -13px;
    left: -11px;
    width: 80px;
    height: 96px;
    z-index: -1;
}
.sellers-page__how-section .swiper-container {
    width: 100%;
}
.sellers-page__how-section .swiper-wrapper {
    white-space: nowrap;
}
@media screen and (max-width: 1024px) {
    .sellers-page__how-section li {
        width: calc(100% - 16px);
        display: inline-flex;
        min-height: 200px;
    }
    .sellers-page__how-section li p {
        white-space: break-spaces;
    }
}
.sellers-page__how-work-section {
    flex-wrap: nowrap;
}
.sellers-page__how-work-section li {
    position: relative;
    width: calc((100% - 72px) / 4);
}
.sellers-page__how-work-section li img:first-child {
    width: 160px;
    height: 160px;
    z-index: 1;
}
.sellers-page__how-work-section li img:nth-child(2) {
    position: absolute;
}
.sellers-page__how-work-section li:nth-child(2) img:nth-child(2),
.sellers-page__how-work-section li:nth-child(3) img:nth-child(2),
.sellers-page__how-work-section li:nth-child(4) img:nth-child(2) {
    bottom: -72px;
}
.sellers-page__how-work-section li p {
    max-width: 254px;
}
@media screen and (max-width: 1024px) {
    .sellers-page__how-work-section li {
        width: 100%;
    }
    .sellers-page__how-work-section li:nth-child(2) img:nth-child(2),
    .sellers-page__how-work-section li:nth-child(3) img:nth-child(2) {
        bottom: -60px;
    }
    .sellers-page__how-work-section li:nth-child(1) img:nth-child(2),
    .sellers-page__how-work-section li:nth-child(4) img:nth-child(2) {
        bottom: -32px;
    }
    .sellers-page__how-work-section li p {
        max-width: 284px;
    }
}
.sellers-page__you-get-section img {
    margin: 0 auto;
    width: 86.364%;
    min-height: 304px;
}
.sellers-page__you-get-section li p {
    max-width: 307px;
}
.sellers-page__you-get-section li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    .sellers-page__you-get-section img {
        min-height: unset;
        width: 280px;
        height: 280px;
    }
    .sellers-page__you-get-section li p {
        max-width: 307px;
    }
}
.sellers-page__start-section {
    border-radius: 40px;
    // background-image: url("/static/img/sellers/start-company-section.svg");
}
@media screen and (max-width: 968px) {
    .sellers-page__start-section {
        border-radius: 24px;
        // background-image: url("/static/img/sellers/start-company-section-mob.png");
    }
}

.creators-page {
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    .creators-page .sellers-page__top-section {
        padding-top: 84vw;
    }
    .creators-page .sellers-page__top-section-right {
        top: 0px;
        width: 100%;
    }
}
.creators-page__cabinet-section {
    position: relative;
    overflow: visible;
}
.creators-page__cabinet-section:before {
    position: absolute;
    content: "";
    top: -24px;
    left: 64px;
    right: 20px;
    background-image: url("/public/images/creators/bg.jpg");
    top: -294px;
    background-size: cover;
    z-index: -2;
    top: -564px;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: -80px;
}
.creators-page__cabinet-section li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
}
.creators-page__cabinet-section li h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.creators-page__cabinet-section .point-2,
.creators-page__cabinet-section .point-3,
.creators-page__cabinet-section .point-4,
.creators-page__cabinet-section .point-5 {
    margin-top: -96px;
}
.creators-page__cabinet-section .point-1 h5 {
    max-width: 239px;
}
.creators-page__cabinet-section .point-2,
.creators-page__cabinet-section .point-4 {
    margin-left: auto;
}
.creators-page__cabinet-section img {
    margin-bottom: 24px;
    width: 40.84vw;
}
@media screen and (max-width: 968px) {
    .creators-page__cabinet-section li {
        width: 100%;
    }
    .creators-page__cabinet-section .point-1 h5 {
        max-width: unset;
    }
    .creators-page__cabinet-section .point-2,
    .creators-page__cabinet-section .point-4 {
        margin-left: unset;
    }
    .creators-page__cabinet-section .point-2,
    .creators-page__cabinet-section .point-3,
    .creators-page__cabinet-section .point-4,
    .creators-page__cabinet-section .point-5 {
        margin-top: unset;
    }
    .creators-page__cabinet-section li {
        margin-top: unset1;
    }
    .creators-page__cabinet-section li h5 {
        font-size: 15px;
        line-height: 21px;
    }
    .creators-page__cabinet-section img {
        width: calc(100vw - 32px);
    }
}
.creators-page__capabilities-section .row {
    flex-wrap: nowrap;
}
.creators-page__capabilities-section li {
    background: #1e1529;
    border-radius: 24px;
    width: calc((100% - 96px) / 4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: contain;
    line-height: 21px;
    min-height: 460px;
}
.creators-page__capabilities-section li .figure-wrap {
    margin: 0 auto 32px auto;
}
.creators-page__capabilities-section li .figure-wrap img {
    width: 100%;
}
// .creators-page__capabilities-section li:nth-child(1) {
//     background-image: url("/static/img/creators/card-bg-1.svg");
// }
// .creators-page__capabilities-section li:nth-child(2) {
//     background-image: url("/static/img/creators/card-bg-2.svg");
// }
// .creators-page__capabilities-section li:nth-child(3) {
//     background-image: url("/static/img/creators/card-bg-3.svg");
// }
// .creators-page__capabilities-section li:nth-child(4) {
//     background-image: url("/static/img/creators/card-bg-4.svg");
// }
@media screen and (max-width: 1024px) {
    .creators-page__capabilities-section .row {
        flex-wrap: wrap;
    }
    .creators-page__capabilities-section li {
        width: 100%;
        background-size: cover;
    }
}
@media screen and (max-width: 768px) {
    .creators-page .sellers-page__top-section .row {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.eplusawards-page__top-section {
    padding-top: 46px;
}
.eplusawards-page__top-section h1 {
    font-weight: 600;
    font-size: 96px;
    line-height: 106px;
    max-width: 500px;
}
.eplusawards-page__top-section-right {
    z-index: -1;
    position: absolute;
    top: 48px;
    right: 0;
    background-size: cover;
}
.eplusawards-page__top-section-left_desc {
    max-width: 440px;
}
@media screen and (max-width: 768px) {
    .eplusawards-page__top-section {
        padding-top: 280px;
    }
    .eplusawards-page__top-section h1 {
        font-size: 32px;
        line-height: 37px;
    }
    .eplusawards-page__top-section-right {
        width: 100vw;
        max-width: 100%;
        height: 120vw;
        right: unset;
        left: 0;
        top: 66px;
    }
}
.eplusawards-page__gallery {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    min-height: 100vh;
}
.eplusawards-page__gallery-card {
    width: calc((100% - 72px) / 4);
    height: 15vw;
    position: relative;
    transition: transform 0.4s ease-in-out;
}
.eplusawards-page__gallery-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}
.eplusawards-page__gallery-card_download-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    content: "";
    top: 8px;
    right: 8px;
    z-index: 1;
}
.eplusawards-page__gallery-card_download-btn:hover {
    z-index: 3;
}
.eplusawards-page__gallery-card_zoom-in-btn {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: none;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    transition: display 0.4s ease-in-out;
}
.eplusawards-page__gallery-card_zoom-in-btn path {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
}
.eplusawards-page__gallery-card_zoom-in-btn:hover path {
    opacity: 1;
}
.eplusawards-page__gallery-card:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
}
.eplusawards-page__gallery-card:hover {
    transform: scale(1.05);
}
.eplusawards-page__gallery-card:hover:before {
    background: rgba(0, 0, 0, 0.3);
}
.eplusawards-page__gallery-card:hover .eplusawards-page__gallery-card_zoom-in-btn {
    display: flex;
}
.eplusawards-page__slider-slide img {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.eplusawards-page .swiper-slide {
    display: inline-flex !important;
}
.eplusawards-page .swiper-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.eplusawards-page__slider,
.eplusawards-page .swiper-container,
.eplusawards-page .swiper-wrapper,
.eplusawards-page .swiper-slide {
    height: 100%;
}
.eplusawards-page__gallery-card,
.eplusawards-page__slider {
    border-radius: 16px;
    overflow: hidden;
}
.eplusawards-page__slider,
.eplusawards-page .swiper-slide,
.eplusawards-page .swiper-wrapper {
    height: 51.389vw;
    max-height: 720px;
}
.eplusawards-page__slider {
    width: calc(100% - 32px);
    max-width: 1076px;
}
.eplusawards-page .swiper-wrapper,
.eplusawards-page .swiper-slide {
    width: 100%;
}
.eplusawards-page .swiper-wrapper {
    position: relative;
    display: block;
    white-space: nowrap;
    font-size: 0;
}
.eplusawards-page__slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.eplusawards-page__slider {
    position: relative;
    margin: 0 auto;
}
.eplusawards-page__slider_close-btn,
.eplusawards-page__slider_download-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    position: absolute;
    content: "";
    right: 24px;
    z-index: 1;
}
.eplusawards-page__slider_close-btn {
    top: 24px;
}
.eplusawards-page__slider_download-btn {
    top: 88px;
}
.eplusawards-page__slider-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #281f34;
    background-color: #281f34;
}
.eplusawards-page .swiper-arrow-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.eplusawards-page .swiper-arrow-btn_next {
    right: 24px !important;
}
.eplusawards-page .swiper-arrow-btn_prev {
    left: 24px !important;
}
.eplusawards-page .swiper-arrow-btn:hover {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0;
}
.eplusawards-page__slider {
    background-position-x: 180%;
    animation: shine 1.5s ease infinite;
    background: linear-gradient(100deg, rgba(52, 44, 62, 0) 40%, rgba(52, 44, 62, 0.5) 50%, rgba(52, 44, 62, 0) 60%)
        #1e1529;
    background-position-x: 180%;
    background-size: 200% 100%;
}
.eplusawards-page__slider .eplusawards-page__slider_close-btn,
.eplusawards-page__slider .eplusawards-page__slider_download-btn {
    display: none;
}
.eplusawards-page__slider:has(.swiper-container) {
    background: none;
    animation: none;
}
.eplusawards-page__slider:has(.swiper-container) .eplusawards-page__slider_close-btn,
.eplusawards-page__slider:has(.swiper-container) .eplusawards-page__slider_download-btn {
    display: flex;
}
@media screen and (max-width: 768px) {
    .eplusawards-page h3 {
        max-width: 274px;
        margin: 0 auto;
    }
    .eplusawards-page__gallery {
        gap: 16px;
    }
    .eplusawards-page__gallery-card {
        width: 100%;
        height: 57.6vw;
    }
    .eplusawards-page__gallery-card:hover {
        transform: scale(1);
    }
    .eplusawards-page__gallery-card:hover:before {
        background: none;
    }
    .eplusawards-page__gallery-card:hover .eplusawards-page__gallery-card_zoom-in-btn {
        display: none;
    }
    .eplusawards-page__slider,
    .eplusawards-page .swiper-slide,
    .eplusawards-page .swiper-wrapper {
        height: 97vw;
    }
    .eplusawards-page .swiper-slide {
        border-radius: 16px;
        overflow: hidden;
    }
    .eplusawards-page__slider .icon-btn,
    .eplusawards-page__slider .swiper-arrow-btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .eplusawards-page__slider_download-btn,
    .eplusawards-page__slider_close-btn {
        top: 8px;
    }
    .eplusawards-page__slider_download-btn {
        right: 56px;
    }
    .eplusawards-page__slider_close-btn {
        right: 8px;
    }
    .eplusawards-page__slider .swiper-arrow-btn_next {
        right: 8px !important;
    }
    .eplusawards-page__slider .swiper-arrow-btn_prev {
        left: 8px !important;
    }
    .eplusawards-page__slider .swiper-arrow-btn svg {
        width: 24px;
        height: 24px;
    }
    .eplusawards-page__slider .swiper-arrow-btn:hover {
        width: 40px !important;
        height: 40px !important;
    }
}

@keyframes shine {
    to {
        background-position-x: -20%;
    }
}
.privacy-page {
    position: relative;
}
.privacy-page__bg-img {
    z-index: -1;
    position: absolute;
    top: -112px;
    right: 0;
}
.privacy-page__content {
    max-width: 966px;
}
.privacy-page ul li {
    list-style-type: disc;
}
.privacy-page ul {
    margin-left: 42px;
}
.privacy-page .dash-list {
    margin-left: 30px;
}
.privacy-page .dash-list li {
    list-style: none;
}

.about-page .sellers-page__top-section-left {
    max-width: 525px;
}
.about-page .sellers-page__top-section-right {
    width: 688px;
    height: 688px;
}
@media screen and (max-width: 768px) {
    .about-page .sellers-page__top-section-right {
        width: 100%;
        height: unset;
        top: 67px;
    }
}
@media screen and (max-width: 768px) {
    .about-page .sellers-page__top-section {
        padding-top: 390px;
    }
}
.about-page .creators-page__cabinet-section:before {
    background-image: url("/public/images/about/bg.png");
    top: -294px;
}
.about-page .creators-page__cabinet-section h5 {
    max-width: 508px;
}
.about-page .creators-page__cabinet-section .point-2 {
    margin-top: -166px;
}
@media screen and (max-width: 968px) {
    .about-page .creators-page__cabinet-section li {
        width: 100%;
    }
    .about-page .creators-page__cabinet-section img {
        margin-bottom: 16px;
    }
    .about-page .creators-page__cabinet-section .point-2 {
        margin-top: unset;
    }
}
.about-page__video-section {
    display: flex;
    gap: 16px 24px;
    flex-wrap: nowrap;
}
.about-page__video-section__block {
    width: calc((100% - 24px) / 2);
    border-radius: 40px;
    height: 32.5vw;
    padding: 48px 0 0 48px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}
.about-page__video-section__block-subtitle {
    max-width: 420px;
}
.about-page__video-section__block img {
    z-index: 1;
}
.about-page__video-section__block:first-child {
    background: radial-gradient(185.53% 329.56% at 154.95% 160.91%, #e336d9 16.5%, #3d13b3 70.46%, #0c0216 100%),
        #1e1529;
}
.about-page__video-section__block:first-child:before {
    position: absolute;
    content: "";
    background-image: url("/public/images/about/video-section-block-bg-1.png");
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
}
.about-page__video-section__block:last-child {
    background-size: cover;
    background-position: center;
    background-image: url("/public/images/about/video-section-block-img-2.jpeg");
}
@media screen and (max-width: 1024px) {
    .about-page__video-section {
        flex-wrap: wrap;
    }
    .about-page__video-section__block {
        width: 100%;
        border-radius: 24px;
        padding: 32px 0 0 24px;
    }
    .about-page__video-section__block:nth-child(1) {
        height: 98.667vw;
    }
    .about-page__video-section__block:nth-child(2) {
        height: 59.734vw;
    }
    .about-page__video-section__block-subtitle {
        font-size: 15px;
        line-height: 21px;
    }
}
.about-page .sellers-page__how-work-section li {
    width: calc((100% - 96px) / 5);
    justify-content: flex-start;
}
.about-page .sellers-page__how-work-section li p {
    max-width: 231px;
}
.about-page .sellers-page__how-work-section li img:nth-child(2) {
    bottom: 0 !important;
}
@media screen and (max-width: 1024px) {
    .about-page .sellers-page__how-work-section li {
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .about-page__more-btn {
        width: 100%;
        margin-top: 32px;
    }
    .about-page__more-btn button {
        width: 100%;
    }
}
.about-page__seller-section .swiper-container {
    margin-left: 72px;
}
.about-page__seller-section .swiper-container .slider-control-arrow {
    top: calc(50% + 32px);
}
@media screen and (max-width: 1024px) {
    .about-page__seller-section .swiper-container {
        margin-left: 16px;
    }
}
@media screen and (max-width: 768px) {
    .about-page__seller-section .swiper-container .slider-control-arrow {
        top: calc(50% - 32px);
        transform: unset;
    }
    .about-page__seller-section .swiper-container .slider-control-arrow:hover {
        width: 42px;
        height: 42px;
    }
}
.about-page__seller-section .swiper-wrapper .swiper-slide:first-child {
    background: linear-gradient(282.75deg, #e336d9 -44.86%, #3d13b3 135.77%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(2) {
    background: linear-gradient(287.52deg, #d11e9f -27.81%, #151efe 132.32%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(3) {
    background: linear-gradient(286.94deg, #ec30e1 -3%, #f3a345 110.78%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(4) {
    background: linear-gradient(292.7deg, #80d9b4 4.5%, #2454ff 118.27%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(5) {
    background: linear-gradient(294.1deg, #4e36e3 -43.6%, #ff4bed 115.4%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(6) {
    background: linear-gradient(285.95deg, #26c5f3 -45.29%, #8711c1 101.07%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(7) {
    background: linear-gradient(288.44deg, #f4e5f0 -30.66%, #e536ab 49.95%, #5203a8 137.22%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(8) {
    background: linear-gradient(282.75deg, #e336d9 -44.86%, #3d13b3 135.77%), #1e1529;
}
.about-page__seller-section .swiper-wrapper .swiper-slide:nth-child(9) {
    background: linear-gradient(292.98deg, #ed5cc4 4.5%, #151efe 107.97%), #1e1529;
}
.about-page__seller-section__list-item {
    border-radius: 40px;
    height: 20.9799vw;
    width: calc((100% - 72px - 72px) / 4);
    height: 15.4vw;
    padding: 32px;
    box-sizing: border-box;
    display: inline-flex;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width: 1440px) {
    .about-page__seller-section__list-item {
        width: calc((100% - 48px - 72px) / 3);
        height: 20.9799vw;
    }
}
.about-page__seller-section__list-item img {
    position: absolute;
    right: 0;
    bottom: 0;
}
@media screen and (max-width: 1024px) {
    .about-page__seller-section__list-item {
        width: calc((100% - 48px - 32px) / 3);
    }
}
@media screen and (max-width: 998px) {
    .about-page__seller-section__list-item {
        width: calc((100% - 32px - 32px) / 2);
    }
}
@media screen and (max-width: 768px) {
    .about-page__seller-section__list-item {
        width: calc(100% - 16px - 32px);
        height: 49.6vw;
    }
}
.about-page__seller-section__list-item p {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    white-space: break-spaces;
}
@media screen and (max-width: 998px) {
    .about-page__seller-section__list-item {
        border-radius: 24px;
        padding: 24px;
    }
}
.about-page__seller-section .swiper-wrapper,
.about-page__creators-section .swiper-wrapper {
    display: block;
    white-space: nowrap;
    font-size: 0;
}
.about-page__seller-section .swiper-container,
.about-page__creators-section .swiper-container {
    padding-right: 16px;
}
.about-page__creators-section__list {
    box-sizing: border-box;
    gap: 24px;
}
.about-page__creators-section__list-item {
    height: 23.334vw;
    border-radius: 40px;
    padding: 40px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
}
.about-page__creators-section__list-item:nth-child(1),
.about-page__creators-section__list-item:nth-child(2) {
    width: calc((100% - 24px) / 2);
}
.about-page__creators-section__list-item:nth-child(3),
.about-page__creators-section__list-item:nth-child(4),
.about-page__creators-section__list-item:nth-child(5) {
    width: calc((100% - 48px) / 3);
}
.about-page__creators-section__list-item p {
    max-width: 270px;
}
@media screen and (max-width: 1024px) {
    .about-page__creators-section .swiper-container {
        margin-left: 16px;
    }
    .about-page__creators-section .swiper-slide {
        display: inline-flex;
        width: calc(100% - 16px - 16px) !important;
        height: 59.734vw;
    }
    .about-page__creators-section__list-item {
        width: 100% !important;
        height: 100%;
        padding: 24px;
        border-radius: 24px;
    }
    .about-page__creators-section__list-item:nth-child(1) {
        background-position: 18% center;
    }
    .about-page__creators-section__list-item p {
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
        white-space: break-spaces;
    }
}
.about-page .creators-page__capabilities-section li {
    justify-content: flex-start;
    width: calc((100% - 48px) / 3);
    background-image: url("/public/images/about/capabilities-bg.svg") !important;
    background-size: cover;
}
.about-page .creators-page__capabilities-section li img {
    width: 180px;
    height: 180px;
}
.about-page .creators-page__capabilities-section li p {
    margin-bottom: 0;
}
.about-page .creators-page__capabilities-section li .figure-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
    .about-page .creators-page__capabilities-section li {
        width: 100%;
        max-height: 360px;
        min-height: 360px;
    }
}

.mob-app-page .sellers-page__top-section-right {
    width: 688px;
    height: 688px;
    top: 47px;
}
@media screen and (max-width: 768px) {
    .mob-app-page .sellers-page__top-section-right {
        width: 100%;
        height: unset;
        top: 67px;
    }
}
.mob-app-page__how-section {
    position: relative;
}
.mob-app-page__how-section:before {
    position: absolute;
    content: "";
    background-size: cover;
    z-index: -2;
    left: 0;
    right: 0;
    bottom: -80px;
    background-image: url("/public/images/bg.png");
    top: -564px;
}
.mob-app-page__how-section__blocks {
    display: flex;
    gap: 16px 24px;
    flex-wrap: nowrap;
}
@media screen and (max-width: 1024px) {
    .mob-app-page__how-section__blocks {
        flex-wrap: wrap;
    }
}
.mob-app-page__how-section__block {
    width: calc((100% - 24px) / 2);
    border-radius: 40px;
    height: 32.5vw;
    padding: 48px 0 0 48px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}
.mob-app-page__how-section__block img {
    width: 54.422%;
    position: absolute;
    right: 0;
    bottom: 0;
}
.mob-app-page__how-section__block:first-child {
    background: linear-gradient(287.52deg, #d11e9f -27.81%, #151efe 132.32%), #1e1529;
}
.mob-app-page__how-section__block:nth-child(2) {
    background: linear-gradient(310.04deg, #ec30e1 0.65%, #f3a345 113.56%), #1e1529;
}
.mob-app-page__how-section__block:nth-child(2) p {
    max-width: 420px;
}
@media screen and (max-width: 1024px) {
    .mob-app-page__how-section__block {
        width: 100%;
        border-radius: 24px;
        padding: 32px 0 0 24px;
        height: 85.334vw;
    }
    .mob-app-page__how-section__block:nth-child(2) p {
        max-width: 260px;
    }
}

.main-home,
.main-watch {
    min-height: 100vh;
}

@media screen and (max-width: 1024px) {
    .main-home .film-card .film-list-element-genres {
        display: flex !important;
    }
}

main {
    min-height: 400px;
}

@media screen and (max-width: 768px) {
    .home-page__app-banner__title {
        font-size: 16px;
        line-height: 19px;
        max-width: 180px;
    }
}
.home-page__app-banner .btn {
    width: 146px;
}

.home-page-cookies {
    border-radius: 80px;
    box-shadow: 0px 8px 64px rgba(12, 2, 22, 0.7);
    position: fixed;
    bottom: 24px;
    width: calc(100% - 144px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    padding: 16px 24px 16px 40px;
    justify-content: space-between;
}
.home-page-cookies .btn {
    min-width: 104px;
    font-size: 14px;
    line-height: 22px;
}
@media screen and (max-width: 968px) {
    .home-page-cookies {
        justify-content: flex-end;
        border-radius: 24px;
        padding-top: 22px;
        padding: 22px 16px 16px 24px;
        width: calc(100% - 64px);
        bottom: 8px;
    }
    .home-page-cookies__title {
        width: 100%;
        margin-bottom: 8px;
    }
    .home-page-cookies__title p {
        max-width: 300px;
        font-size: 12px;
        line-height: 16px;
    }
    .home-page-cookies .btn {
        align-self: flex-end;
    }
}

.categories-list-item {
    position: relative;
    width: 100%;
    border-radius: 16px;
}
@media screen and (max-width: 1024px) {
    .categories-list-item {
        border-radius: 12px;
    }
}
.categories-list-item .categories-list-item-back1,
.categories-list-item .categories-list-item-back2 {
    transition: top 0.3s ease-in-out;
}
.categories-list-item .categories-list-item-back1 {
    position: absolute;
    display: block;
    top: 0;
    left: 10%;
    width: 80%;
    height: 12.22vw;
    border-radius: 8px;
}
.categories-list-item .categories-list-item-back2 {
    position: absolute;
    display: block;
    top: 8px;
    left: 5%;
    width: 90%;
    height: 12.22vw;
    border-radius: 12px;
}
@media screen and (max-width: 1024px) {
    .categories-list-item .categories-list-item-back2 {
        top: 7px;
    }
}
.categories-list-item .categories-list-item-info {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    overflow: hidden;
}
.categories-list-item .categories-list-item-info span {
    position: absolute;
    display: block;
}
.categories-list-item .categories-list-item-info img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    transition: all 300ms ease-out;
}
.categories-list-item .categories-list-item-info .categories-list-item-back3 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.categories-list-item:hover .categories-list-item-info img {
    transform: scale(1.015);
}
.categories-list-item.color1 .categories-list-item-back1 {
    background: #003dac;
}
.categories-list-item.color1 .categories-list-item-back2 {
    background: #004dd7;
}
.categories-list-item.color1 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
.categories-list-item.color2 .categories-list-item-back1 {
    background: #aa0c5f;
}
.categories-list-item.color2 .categories-list-item-back2 {
    background: #cb1d78;
}
.categories-list-item.color2 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #e33b92 38%, rgba(227, 59, 146, 0) 121.98%);
}
.categories-list-item.color3 .categories-list-item-back1 {
    background: #4c0f90;
}
.categories-list-item.color3 .categories-list-item-back2 {
    background: #601bad;
}
.categories-list-item.color3 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #7027c2 38%, rgba(112, 39, 194, 0) 121.98%);
}
.categories-list-item.color4 .categories-list-item-back1 {
    background: #c55809;
}
.categories-list-item.color4 .categories-list-item-back2 {
    background: #e9741f;
}
.categories-list-item.color4 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #ff9e00 38%, rgba(255, 158, 0, 0) 121.98%);
}
.categories-list-item.color5 .categories-list-item-back1 {
    background: #a90e2b;
}
.categories-list-item.color5 .categories-list-item-back2 {
    background: #ce1b3d;
}
.categories-list-item.color5 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #eb2b4f 38%, rgba(235, 43, 79, 0) 121.98%);
}
.categories-list-item.color6 .categories-list-item-back1 {
    background: #b98500;
}
.categories-list-item.color6 .categories-list-item-back2 {
    background: #d9a00e;
}
.categories-list-item.color6 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #e6b711 38%, rgba(229, 183, 17, 0) 121.98%);
}
.categories-list-item.color7 .categories-list-item-back1 {
    background: #087554;
}
.categories-list-item.color7 .categories-list-item-back2 {
    background: #09946a;
}
.categories-list-item.color7 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #10bc88 38%, rgba(16, 188, 136, 0) 121.98%);
}
.categories-list-item.color8 .categories-list-item-back1 {
    background: #6c138b;
}
.categories-list-item.color8 .categories-list-item-back2 {
    background: #8719ad;
}
.categories-list-item.color8 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #9e20ca 38%, rgba(158, 32, 202, 0) 121.98%);
}
.categories-list-item.color9 .categories-list-item-back1 {
    background: #4c0f90;
}
.categories-list-item.color9 .categories-list-item-back2 {
    background: #601bad;
}
.categories-list-item.color9 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #7027c2 38%, rgba(112, 39, 194, 0) 121.98%);
}
.categories-list-item.color10 .categories-list-item-back1 {
    background: #003dac;
}
.categories-list-item.color10 .categories-list-item-back2 {
    background: #004dd7;
}
.categories-list-item.color10 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
.categories-list-item.color11 .categories-list-item-back1 {
    background: #aa0c5f;
}
.categories-list-item.color11 .categories-list-item-back2 {
    background: #cb1d78;
}
.categories-list-item.color11 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #e33b92 38%, rgba(227, 59, 146, 0) 121.98%);
}

.player-frame {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: none;
}
.player-close-btn {
    position: fixed;
    z-index: 2;
    top: 32px;
    right: 32px;
}

.film-rating-block {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #1e1529;
    border-radius: 16px;
    width: 416px;
    height: 348px;
    text-align: center;
}
.film-rating-block .film-rating {
    margin-top: 16px;
    font-size: 40px;
    line-height: 62px;
    width: 104px;
    height: 62px;
    left: calc(50% - 52px - 1px);
    border: 2px solid #0dc268;
    border-radius: 16px;
    margin-bottom: 40px;
}
.film-rating-block b {
    position: relative;
    font-size: 18px;
    margin-bottom: 7px;
}
@media screen and (max-width: 1024px) {
    .film-rating-block b {
        display: none;
    }
}
.film-rating-block small {
    position: relative;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 24px;
    opacity: 0.5;
}
@media screen and (max-width: 1024px) {
    .film-rating-block small {
        display: none;
    }
}
.film-rating-block__rate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 127px;
    height: 56px;
    box-sizing: border-box;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 80px;
}
.film-rating-block__rate-btn:hover {
    border: 2px solid rgb(255, 255, 255);
}
.film-rating-block__user-rate {
    box-sizing: border-box;
}
.film-rating-block__user-rate-btn {
    border-radius: 8px;
    padding: 6px 23px;
    border: 2px solid rgba(255, 255, 255, 0.3);
}
.film-rating-block__user-rate-btn:hover {
    border: 2px solid rgb(255, 255, 255);
}
@media screen and (max-width: 1024px) {
    .film-rating-block {
        width: 100%;
        margin-bottom: 120px;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 12px 0 23px;
        height: 72px;
        flex-wrap: wrap;
    }
    .film-rating-block.rated {
        margin-bottom: 136px;
    }
    .film-rating-block__user-rate {
        position: absolute;
        top: calc(100% + 16px);
        left: 0;
    }
    .film-rating-block .film-rating {
        margin: 0;
        line-height: 48px;
        width: 88px;
        height: 48px;
        font-size: 28px;
    }
    .film-rating-block b,
    .film-rating-block small {
        display: none;
    }
    .film-rating-block__rate-btn {
        position: absolute;
        display: block;
        top: calc(100% + 16px);
        width: 100%;
        line-height: 56px;
        left: 0;
    }
}

.blogger-card {
    width: calc((100% - 120px) / 6);
}
.blogger-card .card-preview {
    border-radius: 48px;
}
.blogger-card img {
    transition: transform 300ms linear;
}
.blogger-card:hover .card-preview {
    transform: unset;
}
.blogger-card:hover img {
    transform: scale(1.0408);
}
@media screen and (max-width: 1024px) {
    .blogger-card:hover img {
        transform: unset;
    }
}
.blogger-card__subscribe-btn {
    width: 40px;
    height: 40px;
    border: 4px solid #0c0216;
    position: absolute;
    content: "";
    top: -10px;
    right: -10px;
    z-index: 2;
    background-color: #fd4aa7;
    border-radius: 80px;
    transition: transform 0.3s ease-in-out;
}
.blogger-card__subscribe-btn:hover {
    transform: scale(1.1);
}
.blogger-card__subscribe-btn--white {
    background-color: #fff;
}
.blogger-card__subscribe-btn--white svg path {
    fill: #212121;
}
.blogger-card--s .card-preview {
    border-radius: 24px;
}
.blogger-card--s .blogger-card__subscribe-btn {
    top: -8px;
    right: -8px;
}
.blogger-card--m .card-preview {
    border-radius: 32px;
}
@media screen and (max-width: 1024px) {
    .blogger-card {
        width: calc((100% - 24px) / 4);
    }
    .blogger-card .card-preview {
        border-radius: 24px;
    }
}
@media screen and (max-width: 768px) {
    .blogger-card {
        width: calc((100% - 24px) / 3);
    }
    .blogger-card__subscribe-btn {
        right: -4px;
    }
}

.blogger-page__menu {
    border-radius: 24px;
}
.blogger-page__menu .icon-btn {
    opacity: 0.8;
    width: 40px;
    height: 40px;
}
.blogger-page__menu .active span,
.blogger-page__menu li:hover span,
.blogger-page__menu .icon-btn:hover {
    opacity: 1;
}
@media screen and (max-width: 1024px) {
    .blogger-page__menu {
        border-radius: 0;
        overflow-x: auto;
    }
    .blogger-page__menu li {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
    }
}

.channel-desc__info {
    max-width: 742px;
    flex: 1;
}
@media screen and (max-width: 1024px) {
    .channel-desc__info {
        max-width: unset;
        flex: unset;
        width: 100%;
    }
}
.channel-desc__stat {
    width: 416px;
}
.channel-desc__stat li:not(:last-child) {
    border-bottom: 1px solid #342c3e;
}
@media screen and (max-width: 1024px) {
    .channel-desc__stat {
        width: 100%;
    }
}
.channel-desc__social-square {
    width: calc((100% - 72px) / 4);
    transition: 0.4s transform ease-in-out;
    border-radius: 4.44vw;
}
.channel-desc__social-square:hover {
    transform: scale(1.05);
}
@media screen and (max-width: 1024px) {
    .channel-desc__social-square {
        width: calc((100% - 24px) / 4);
        border-radius: 10.66vw;
    }
    .channel-desc__social-square:hover {
        transform: unset;
    }
}
@media screen and (max-width: 768px) {
    .channel-desc__social-square {
        width: calc((100% - 8px) / 2);
    }
}
.channel-desc__social-square svg {
    width: 48px;
    height: 48px;
    margin: 0 auto;
}
.channel-desc__social-square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.channel-desc__social-square.website {
    background: linear-gradient(135.79deg, #7027c2 9.64%, #4b0e8f 92.34%);
}
.channel-desc__social-square.youtube {
    background: linear-gradient(133.46deg, #f92517 7.15%, #a60303 86.29%);
}
.channel-desc__social-square.telegram {
    background: linear-gradient(141.78deg, #5bc4ff 5.76%, #1269ba 96.4%);
}
.channel-desc__social-square.vk {
    background-color: #0077ff;
}

.playlists-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 56px 24px;
    flex-wrap: wrap;
}

.playlist-card {
    position: relative;
}
.playlist-card .card__preview {
    z-index: 3;
    transition:
        margin,
        transform 0.3s ease-in-out;
}
.playlist-card-b1 {
    border-radius: 12px;
    overflow: hidden;
    margin-left: 10%;
    width: 80%;
    height: 100%;
    position: relative;
    transform: translateY(-10px);
}
.playlist-card-b1 .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
}
.playlist-card-back1,
.playlist-card-back2,
.playlist-card-back1-img,
.playlist-card-back2-img {
    position: absolute;
    display: block;
    height: 12.22vw;
    transition: top 0.3s ease-in-out;
    border-radius: 8px;
    background-blend-mode: overlay;
}
.playlist-card-back1,
.playlist-card-back1-img {
    left: 10%;
    width: 80%;
    top: -16px;
}
.playlist-card-back1 {
    background: rgba(0, 0, 0, 0.45) !important;
    z-index: 1;
    z-index: -2;
}
.playlist-card-back1-img {
    z-index: 0;
    z-index: -3;
}
.playlist-card-back2,
.playlist-card-back2-img {
    left: 5%;
    width: 90%;
    top: -9px;
}
.playlist-card-back2 {
    background: rgba(0, 0, 0, 0.3) !important;
    z-index: 2;
    z-index: -1;
}
.playlist-card-back2-img {
    z-index: 1;
    z-index: -2;
}
.playlist-card:hover .card__preview {
    transform: translateY(2px);
}
.playlist-card:hover .card__preview img {
    transform: scale(1.015);
}
.playlist-card:hover .playlist-card-back1,
.playlist-card:hover .playlist-card-back1-img {
    top: -19px;
}
.playlist-card:hover .playlist-card-back2,
.playlist-card:hover .playlist-card-back2-img {
    top: -10px;
}
.playlist-card:hover img {
    transform: none;
}
@media screen and (max-width: 768px) {
    .playlist-card:hover .card__preview {
        margin-top: unset;
    }
    .playlist-card:hover .card__preview img {
        transform: unset;
    }
    .playlist-card:hover .playlist-card-back1,
    .playlist-card:hover .playlist-card-back1-img {
        top: -16px;
    }
    .playlist-card:hover .playlist-card-back2,
    .playlist-card:hover .playlist-card-back2-img {
        top: -9px;
    }
}

.blogger-channel-card {
    position: relative;
    border-radius: 16px;
    transition: background-color 0.2s ease-in-out;
    width: unset;
}
.blogger-channel-card__blur-bg {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    -webkit-filter: blur(5px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.blogger-channel-card--shorts .preview img {
    object-fit: contain;
}
.blogger-channel-card .delete-fav-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.2);
    z-index: 1;
}
.blogger-channel-card .actions-menu {
    top: 100% !important;
    bottom: unset !important;
}
.blogger-channel-card .preview {
    border-radius: 16px;
    overflow: hidden;
}
.blogger-channel-card:hover img {
    transform: scale(1.03269);
}
.blogger-channel-card--h .preview {
    align-self: stretch;
    width: 43.565%;
    max-width: 176px;
    height: 7.223vw;
    max-height: 104px;
    min-height: 104px;
}
.blogger-channel-card--h:hover {
    background-color: #1e1529;
}
.blogger-channel-card--h:hover img {
    transform: unset;
}
.blogger-channel-card__info {
    align-self: stretch;
}
.blogger-channel-card .card-badge-dur {
    right: 4px;
    bottom: 4px;
}
.blogger-channel-card .card-badge-new {
    right: 16px;
    bottom: 14px;
}
.blogger-channel-card .video-title {
    line-height: 18px;
    min-height: 17px;
}
.blogger-channel-card .channel-preview {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
}
.blogger-channel-card .channel-name {
    min-height: 18px;
    line-height: 18px;
}
@media screen and (max-width: 1024px) {
    .blogger-channel-card--h:hover {
        background-color: unset;
    }
    .blogger-channel-card .preview {
        border-radius: 12px;
        width: 100%;
        height: 53.334vw;
    }
    .blogger-channel-card:hover img {
        transform: unset;
    }
    .blogger-channel-card .card-badge-new {
        right: unset;
        left: 8px;
        top: 8px;
        bottom: unset;
    }
    .blogger-channel-card .card-badge-dur {
        right: 8px;
        bottom: 8px;
    }
    .blogger-channel-card .T14-bold {
        line-height: 17px !important;
    }
}

.shorts-card {
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.shorts-card-wrap {
    position: relative;
    height: 100%;
}
@media screen and (max-width: 768px) {
    .shorts-card-wrap {
        width: 100%;
    }
    .shorts-card-wrap img {
        width: 100%;
    }
}
.shorts-card__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    border-radius: 16px;
}
.shorts-card__content-bottom {
    z-index: 1;
}
.shorts-card__content .blogger-info-row__user {
    width: calc(100% - 128px - 16px);
    transition: width 0.3s ease-in-out;
}
.shorts-card__content .blogger-info-row__user.short {
    width: calc(100% - 138px - 16px);
    transition: width 0.3s ease-in-out;
}
.shorts-card__content .blogger-info-row__user-right {
    width: calc(100% - 40px);
    padding-right: 8px;
}
.shorts-card-actions {
    padding-bottom: 12px;
    position: absolute;
    content: "";
    right: -24px;
    transform: translate(100%, -100%);
    z-index: 1;
}
.shorts-card-actions .btn,
.shorts-card-actions .icon-btn {
    min-height: 32px;
    font-size: 14px !important;
}
.shorts-card-actions .popup-menu {
    transform: translate(0, 0);
    top: unset;
    bottom: 0;
    left: 100%;
}
@media screen and (max-width: 768px) {
    .shorts-card-actions .popup-menu {
        left: unset;
    }
}
.shorts-card__sub-btn {
    width: 128px !important;
    transition: width 0.3s ease-in-out;
}
.shorts-card__sub-btn svg {
    display: none;
}
.shorts-card__sub-btn.btn-white {
    width: unset !important;
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(20px);
    padding-left: 20px !important;
    padding-right: 20px !important;
    color: #fff;
}
.shorts-card__sub-btn.btn-white svg path {
    fill: #fff !important;
}
.shorts-card__sub-btn.btn-white:hover {
    color: #fff !important;
}
@media screen and (max-width: 768px) {
    .shorts-card {
        border-radius: 0;
    }
    .shorts-card__content {
        justify-content: space-between;
        border-radius: 0;
    }
    .shorts-card-actions {
        right: -16px;
        padding-bottom: 128px;
        transform: translate(-50%, -100%);
    }
}

.shorts-comments-modal {
    width: 720px;
    position: relative;
}
.shorts-comments-modal__inner {
    height: 530px;
}
.shorts-comments-modal__comments-wrapper {
    overflow-y: auto;
}
.shorts-comments-modal__comments-list {
    min-height: 100%;
}
@media screen and (max-width: 768px) {
    .shorts-comments-modal {
        width: 100%;
        height: max-content;
        padding: 0 !important;
    }
    .shorts-comments-modal__inner {
        height: max-content;
    }
    .shorts-comments-modal__header,
    .shorts-comments-modal__footer {
        position: fixed !important;
        left: 0;
        right: 0;
        background-color: #1e1529;
        z-index: 1;
    }
    .shorts-comments-modal__header {
        top: 0;
    }
    .shorts-comments-modal__footer {
        bottom: 0;
    }
    .shorts-comments-modal__comments-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        padding-top: 80px;
        padding-bottom: 104px;
        width: 100%;
    }
}

.comments-header .select-list-items {
    left: auto !important;
    right: 0 !important;
    top: 40px !important;
}

.comments-list-scroller .infinite-scroll-component {
    padding-bottom: 84px;
}
.comments-list__item .actions-menu {
    top: 100% !important;
    bottom: unset !important;
    left: 0 !important;
}
.comments-list__item-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #342c3e;
}
.comments-list__item .like-btn {
    font-size: 13px !important;
    line-height: 21px !important;
    font-weight: 400 !important;
}
.comments-list__item .comments-form {
    margin-right: 2px;
}
@media screen and (max-width: 768px) {
    .comments-list__item-avatar {
        width: 36px;
        height: 36px;
    }
    .comments-list__item .actions-menu {
        bottom: 100% !important;
        top: unset !important;
        left: unset !important;
        right: 0;
    }
}

.comments-form__avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comments-form__react-emoji {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    flex: 1;
}
.comments-form .react-emoji {
    border: 1px solid #342c3e;
    border-radius: 24px;
    transition: background-color 0.2s ease-in-out;
}
.comments-form .react-emoji-picker--wrapper {
    bottom: 8px;
}
.comments-form .react-input-emoji--container {
    background-color: transparent;
    border: none !important;
    margin: 0;
}
.comments-form .react-input-emoji--input {
    overflow: hidden;
}
.comments-form .react-input-emoji--input,
.comments-form .react-input-emoji--placeholder {
    font-size: 15px;
    font-family: "Averta CY";
    color: #ffffff;
    padding: 14px 16px 14px 20px;
}
.comments-form .react-input-emoji--placeholder {
    opacity: 0.5;
    left: 0;
}
.comments-form .react-input-emoji--button,
.comments-form .react-input-emoji--button__show {
    padding: 0 8px;
    margin-right: 8px;
    transition: transform 0.3s ease-in-out;
    align-self: flex-end;
    margin-bottom: 9px;
}
.comments-form .react-input-emoji--button svg,
.comments-form .react-input-emoji--button__show svg {
    fill: #fff;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    margin-top: 3px;
}
.comments-form .react-input-emoji--button:hover svg,
.comments-form .react-input-emoji--button__show:hover svg {
    opacity: 1;
}
.comments-form__send-btn-wrap {
    position: absolute;
    right: 4px;
    bottom: 5px;
    overflow: hidden;
    pointer-events: none;
}
.comments-form__send-btn-movable {
    transition: transform 0.3s ease-in-out;
    transform: translate(calc(100% + 4px), 0%);
}
.comments-form__send-btn-movable.open {
    transform: translate(0%, 0%);
}
.comments-form.active .react-input-emoji--container {
    margin-right: 116px;
}
.comments-form.active .react-input-emoji--button {
    transform: translate(-114px, 0%);
}
.comments-form.active .comments-form__send-btn-wrap {
    pointer-events: unset;
}
.comments-form.focus .react-emoji {
    background-color: #342c3e;
}
@media screen and (max-width: 1024px) {
    .comments-form__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .comments-form__react-emoji {
        width: calc(100% - 40px - 12px);
        max-width: calc(100% - 40px - 12px);
    }
    .comments-form.active .react-input-emoji--container {
        margin-right: 40px;
    }
    .comments-form.active .react-input-emoji--button {
        transform: translate(-38px, 0%);
        margin-right: 0;
    }
    .comments-form__send-btn-wrap {
        bottom: 7px;
    }
    .comments-form .react-emoji-picker--wrapper {
        bottom: unset;
        top: 50px;
        right: -24px;
    }
    .comments-form .react-input-emoji--button {
        margin-bottom: 6px;
        margin-right: 0;
    }
    .comments-form .react-input-emoji--input,
    .comments-form .react-input-emoji--placeholder {
        padding: 11px 16px 11px 20px;
    }
}

.blogger-info-row {
    flex-wrap: nowrap !important;
}
.blogger-info-row__user {
    width: calc(100% - 128px - 16px);
    transition: width 0.3s ease-in-out;
}
.blogger-info-row__user.short {
    width: calc(100% - 160px - 16px);
    transition: width 0.3s ease-in-out;
}
.blogger-info-row__user-right {
    width: calc(100% - 40px);
    padding-right: 8px;
}
@media screen and (max-width: 768px) {
    .blogger-info-row__rating,
    .blogger-info-row__sub-btn {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        height: 40px;
    }
}
.blogger-info-row__sub-btn {
    width: 127px;
    transition: width 0.3s ease-in-out;
}
.blogger-info-row__sub-btn.btn-white {
    width: 160px;
}
.blogger-info-row__sub-btn.btn-white svg path {
    fill: #212121;
}
.blogger-info-row__sub-btn.btn-white:hover,
.blogger-info-row__sub-btn.btn-white:hover svg path {
    color: #005bff;
    fill: #005bff;
}
@media screen and (max-width: 768px) {
    .blogger-info-row .share-menu {
        right: 88px !important;
    }
    .blogger-info-row .actions-menu {
        right: 40px !important;
    }
}

.shorts-desc-modal {
    width: 460px;
    min-height: unset !important;
}
.shorts-desc-modal__title {
    position: absolute;
    top: 16px;
    left: 24px;
}
@media screen and (max-width: 768px) {
    .shorts-desc-modal {
        width: 100%;
        min-height: 100% !important;
    }
    .shorts-desc-modal__title {
        position: absolute;
        left: 30px;
    }
}

.no-wrap {
    flex-wrap: nowrap;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.flex-auto {
    flex: 0 0 auto;
}

.end {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media only screen and (min-width: 376px) and (max-width: 767px) {
    .col-xs-none {
        display: none !important;
    }
    .row.xs-none {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .col-xs-none {
        display: block;
    }
    .col-s-none {
        display: none !important;
    }
    .row.s-none {
        display: none !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .col-s-none {
        display: block;
    }
    .col-xs-none {
        display: block;
    }
    .col-m-none {
        display: none !important;
    }
    .row.m-none {
        display: none !important;
    }
}
@media only screen and (min-width: 1440px) {
    .col-s-none {
        display: block;
    }
    .col-xs-none {
        display: block;
    }
    .col-m-none {
        display: block;
    }
    .col-l-none {
        display: none !important;
    }
    .row.l-none {
        display: none !important;
    }
}
@media only screen and (min-width: 376px) and (max-width: 767px) {
    .row.nowrap-xs {
        flex-wrap: nowrap;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .row.nowrap-s {
        flex-wrap: nowrap;
    }
}
@media screen and (min-width: 1025px) {
    .row.nowrap-m {
        flex-wrap: nowrap;
    }
}
@media only screen and (min-width: 1440px) {
    .row.nowrap-l {
        flex-wrap: nowrap;
    }
}
/* Uncomment and set these variables to customize the grid. */
.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row.reverse {
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.col.reverse {
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}

.col {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
}

.col-xs-auto {
    box-sizing: border-box;
    flex: unset;
}

.col-xs {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
}

.col-xs-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
}

.col-xs-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
}

.col-xs-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
}

.col-xs-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
}

.col-xs-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
}

.col-xs-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
}

.col-xs-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
}

.col-xs-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
}

.col-xs-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
}

.col-xs-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
}

.col-xs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

.col-xs-offset-1 {
    margin-left: 8.333%;
}

.col-xs-offset-2 {
    margin-left: 16.667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.333%;
}

.col-xs-offset-5 {
    margin-left: 41.667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.333%;
}

.col-xs-offset-8 {
    margin-left: 66.667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.333%;
}

.col-xs-offset-11 {
    margin-left: 91.667%;
}

.stretch-xs {
    justify-content: stretch;
}

.start-xs {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.end-xs {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
}

.middle-xs {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.bottom-xs {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
}

.around-xs {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.between-xs {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.first-xs {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
}

.last-xs {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
}

@media screen and (max-width: 768px) {
    .col-s,
    .col-s-1,
    .col-s-2,
    .col-s-3,
    .col-s-4,
    .col-s-5,
    .col-s-6,
    .col-s-7,
    .col-s-8,
    .col-s-9,
    .col-s-10,
    .col-s-11,
    .col-s-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
    }
    .col-s-auto {
        box-sizing: border-box;
        flex-basis: auto;
        flex-grow: initial;
    }
    .col-s {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }
    .col-s-1 {
        -ms-flex-preferred-size: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .col-s-2 {
        -ms-flex-preferred-size: 16.667%;
        flex-basis: 16.667%;
        max-width: 16.667%;
    }
    .col-s-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-s-4 {
        -ms-flex-preferred-size: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .col-s-5 {
        -ms-flex-preferred-size: 41.667%;
        flex-basis: 41.667%;
        max-width: 41.667%;
    }
    .col-s-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-s-7 {
        -ms-flex-preferred-size: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .col-s-8 {
        -ms-flex-preferred-size: 66.667%;
        flex-basis: 66.667%;
        max-width: 66.667%;
    }
    .col-s-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-s-10 {
        -ms-flex-preferred-size: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .col-s-11 {
        -ms-flex-preferred-size: 91.667%;
        flex-basis: 91.667%;
        max-width: 91.667%;
    }
    .col-s-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .col-s-offset-1 {
        margin-left: 8.333%;
    }
    .col-s-offset-2 {
        margin-left: 16.667%;
    }
    .col-s-offset-3 {
        margin-left: 25%;
    }
    .col-s-offset-4 {
        margin-left: 33.333%;
    }
    .col-s-offset-5 {
        margin-left: 41.667%;
    }
    .col-s-offset-6 {
        margin-left: 50%;
    }
    .col-s-offset-7 {
        margin-left: 58.333%;
    }
    .col-s-offset-8 {
        margin-left: 66.667%;
    }
    .col-s-offset-9 {
        margin-left: 75%;
    }
    .col-s-offset-10 {
        margin-left: 83.333%;
    }
    .col-s-offset-11 {
        margin-left: 91.667%;
    }
    .start-s {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .center-s {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .end-s {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
    .top-s {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }
    .middle-s {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }
    .bottom-s {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }
    .around-s {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .between-s {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .first-s {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .last-s {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}
@media screen and (max-width: 1024px) {
    .col-m,
    .col-m-1,
    .col-m-2,
    .col-m-3,
    .col-m-4,
    .col-m-5,
    .col-m-6,
    .col-m-7,
    .col-m-8,
    .col-m-9,
    .col-m-10,
    .col-m-11,
    .col-m-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
    }
    .col-m-auto {
        box-sizing: border-box;
        flex-basis: auto;
        flex-grow: initial;
    }
    .col-m {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }
    .col-m-1 {
        -ms-flex-preferred-size: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .col-m-2 {
        -ms-flex-preferred-size: 16.667%;
        flex-basis: 16.667%;
        max-width: 16.667%;
    }
    .col-m-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-m-4 {
        -ms-flex-preferred-size: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .col-m-5 {
        -ms-flex-preferred-size: 41.667%;
        flex-basis: 41.667%;
        max-width: 41.667%;
    }
    .col-m-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-m-7 {
        -ms-flex-preferred-size: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .col-m-8 {
        -ms-flex-preferred-size: 66.667%;
        flex-basis: 66.667%;
        max-width: 66.667%;
    }
    .col-m-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-m-10 {
        -ms-flex-preferred-size: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .col-m-11 {
        -ms-flex-preferred-size: 91.667%;
        flex-basis: 91.667%;
        max-width: 91.667%;
    }
    .col-m-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .col-m-offset-1 {
        margin-left: 8.333%;
    }
    .col-m-offset-2 {
        margin-left: 16.667%;
    }
    .col-m-offset-3 {
        margin-left: 25%;
    }
    .col-m-offset-4 {
        margin-left: 33.333%;
    }
    .col-m-offset-5 {
        margin-left: 41.667%;
    }
    .col-m-offset-6 {
        margin-left: 50%;
    }
    .col-m-offset-7 {
        margin-left: 58.333%;
    }
    .col-m-offset-8 {
        margin-left: 66.667%;
    }
    .col-m-offset-9 {
        margin-left: 75%;
    }
    .col-m-offset-10 {
        margin-left: 83.333%;
    }
    .col-m-offset-11 {
        margin-left: 91.667%;
    }
    .start-m {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .center-m {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .end-m {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
    .top-m {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }
    .middle-m {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }
    .bottom-m {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }
    .around-m {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .between-m {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .first-m {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .last-m {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}
@media (min-width: 1440px) {
    .col-l,
    .col-l-1,
    .col-l-2,
    .col-l-3,
    .col-l-4,
    .col-l-5,
    .col-l-6,
    .col-l-7,
    .col-l-8,
    .col-l-9,
    .col-l-10,
    .col-l-11,
    .col-l-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
    }
    .col-l-auto {
        box-sizing: border-box;
        flex-basis: auto;
        flex-grow: initial;
    }
    .col-l {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }
    .col-l-1 {
        -ms-flex-preferred-size: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .col-l-2 {
        -ms-flex-preferred-size: 16.667%;
        flex-basis: 16.667%;
        max-width: 16.667%;
    }
    .col-l-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-l-4 {
        -ms-flex-preferred-size: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .col-l-5 {
        -ms-flex-preferred-size: 41.667%;
        flex-basis: 41.667%;
        max-width: 41.667%;
    }
    .col-l-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-l-7 {
        -ms-flex-preferred-size: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .col-l-8 {
        -ms-flex-preferred-size: 66.667%;
        flex-basis: 66.667%;
        max-width: 66.667%;
    }
    .col-l-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-l-10 {
        -ms-flex-preferred-size: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .col-l-11 {
        -ms-flex-preferred-size: 91.667%;
        flex-basis: 91.667%;
        max-width: 91.667%;
    }
    .col-l-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .col-l-offset-1 {
        margin-left: 8.333%;
    }
    .col-l-offset-2 {
        margin-left: 16.667%;
    }
    .col-l-offset-3 {
        margin-left: 25%;
    }
    .col-l-offset-4 {
        margin-left: 33.333%;
    }
    .col-l-offset-5 {
        margin-left: 41.667%;
    }
    .col-l-offset-6 {
        margin-left: 50%;
    }
    .col-l-offset-7 {
        margin-left: 58.333%;
    }
    .col-l-offset-8 {
        margin-left: 66.667%;
    }
    .col-l-offset-9 {
        margin-left: 75%;
    }
    .col-l-offset-10 {
        margin-left: 83.333%;
    }
    .col-l-offset-11 {
        margin-left: 91.667%;
    }
    .start-l {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }
    .center-l {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }
    .end-l {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }
    .top-l {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }
    .middle-l {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }
    .bottom-l {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }
    .around-l {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .between-l {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .first-l {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .last-l {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}
.self-start {
    align-self: flex-start;
}

.nowrap {
    flex-wrap: nowrap;
}

.col {
    display: flex;
    flex-direction: column;
}

.block {
    display: block;
}

.row-between,
.col-between {
    justify-content: space-between;
}

.between {
    justify-content: space-between;
}

.around {
    justify-content: space-around;
}

.center {
    justify-content: center;
}

.middle {
    align-items: center;
}

.top {
    align-items: flex-start;
}

.bottom {
    align-items: flex-end;
}

.flex1 {
    flex: 1;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.scroll {
    overflow: auto;
}

.scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
}
.scroll::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 6px;
}
.scroll::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);
}

.hide-scrollbar {
    scrollbar-arrow-color: transparent;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    position: absolute;
    height: 0;
}
.hide-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 0;
    width: 0px;
}
.hide-scrollbar::-webkit-scrollbar-thumb {
    position: relative;
    background: transparent;
    border-radius: 3px;
    width: 0px;
}

.no-scrollbar {
    scrollbar-arrow-color: transparent;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
.no-scrollbar::-webkit-scrollbar {
    width: 10px;
    position: absolute;
}
.no-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 16px 0;
}
.no-scrollbar::-webkit-scrollbar-thumb {
    position: relative;
    background: transparent;
    border-radius: 3px;
}

.checkbox {
    min-width: 24px;
    width: 24px;
    height: 24px;
}
.checkbox input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}
.checkbox input:not(:checked) {
    opacity: 0.5;
    border: 2px solid #ffffff;
}
.checkbox input:checked {
    background-color: #005bff;
    border-color: #005bff;
}
.checkbox svg {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.width-100 {
    width: 100%;
}
.width-max {
    width: max-content;
}

.dividor {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #342c3e;
}

button.opacity {
    transition: opacity 0.3s ease-in-out;
}
button.opacity:hover {
    opacity: 1;
}

.opacity-hover {
    transition: opacity 0.3s ease-in-out;
}
.opacity-hover:hover {
    opacity: 1;
}
.opacity-1 {
    opacity: 0.1;
}
.opacity-2 {
    opacity: 0.2;
}
.opacity-3 {
    opacity: 0.3;
}
.opacity-4 {
    opacity: 0.3;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-6 {
    opacity: 0.6;
}
.opacity-7 {
    opacity: 0.7;
}
.opacity-8 {
    opacity: 0.8;
}
.opacity-off {
    opacity: 1;
}

.height-100 {
    height: 100%;
}
.height-max-content {
    height: max-content !important;
    min-height: unset !important;
}

.bg-img {
    background-size: cover;
    background-position: center;
}

.border-box {
    box-sizing: border-box;
}

.radius-8 {
    border-radius: 8px;
}
.radius-12 {
    border-radius: 12px;
}
.radius-16 {
    border-radius: 16px;
}
.radius-24 {
    border-radius: 24px;
}
.radius-32 {
    border-radius: 32px;
}
.radius-48 {
    border-radius: 48px;
}
.radius-64 {
    border-radius: 64px;
}
.radius-80 {
    border-radius: 80px;
}

.top-films {
    height: 35.55vw;
    margin-bottom: 64px;
}
@media screen and (max-width: 1024px) {
    .top-films {
        margin-bottom: 32px;
        height: 50vw;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.scroll-hidden::-webkit-scrollbar {
    width: 0px;
    border-radius: 6px;
    background: transparent;
}
.scroll-hidden::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 0px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scroll-hidden::-webkit-scrollbar-thumb {
    width: 0px;
    border-radius: 6px;
    background: transparent;
}

.relative {
    position: relative;
}

.main-policy {
    min-height: 100vh;
}

main,
.main {
    margin-bottom: 80px;
}
main-series .film-list,
main-movies .film-list,
main-bloggers .film-list,
.main-series .film-list,
.main-movies .film-list,
.main-bloggers .film-list {
    margin-bottom: 0;
    min-height: 600px;
}
@media screen and (max-width: 1024px) {
    main-series .film-list,
    main-movies .film-list,
    main-bloggers .film-list,
    .main-series .film-list,
    .main-movies .film-list,
    .main-bloggers .film-list {
        min-height: 350px;
    }
}
main-shorts,
.main-shorts {
    margin-bottom: 0;
}
@media screen and (max-width: 768px) {
    main-shorts,
    .main-shorts {
        margin-top: 0;
    }
}
@media screen and (max-width: 1024px) {
    main,
    .main {
        margin-bottom: 48px;
    }
}
main-not-found,
.main-not-found {
    margin: 0;
}

.cover {
    object-fit: cover;
}

.skeleton {
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.skeleton:empty:before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-position-x: 180%;
    animation: shine 1.5s ease infinite;
    background: linear-gradient(100deg, rgba(52, 44, 62, 0) 40%, rgba(52, 44, 62, 0.5) 50%, rgba(52, 44, 62, 0) 60%)
        #1e1529;
    background-position-x: 180%;
    background-size: 200% 100%;
    border-radius: inherit;
}

.auth-reg-form .error {
    margin-bottom: 16px;
}

@keyframes shine {
    to {
        background-position-x: -20%;
    }
}
.ghost-button {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 40px;
}

.blue-button {
    background: #005bff;
    border-radius: 80px;
    color: #fff;
}
.blue-button:not(:disabled):hover {
    background: #004bd2;
}

.h-3 {
    font-weight: 600;
    font-size: 24px;
}
.h-5 {
    font-weight: 600;
    font-size: 18px;
}

.bg-no {
    background-color: transparent;
}
.bg--white-opacity {
    background: rgba(255, 255, 255, 0.2);
}
.bg-dark-grey {
    background-color: #212121;
}
.bg-red {
    background-color: #ed0a34;
}
.bg-blue {
    background-color: #005bff;
}
.bg-pink {
    background-color: #fd4aa7;
}
.bg-150 {
    background-color: #281f34;
}
.bg-100 {
    background-color: #342c3e;
}
.bg-100-hover {
    transition: background-color 0.3s ease-in-out;
}
.bg-100-hover:hover {
    background-color: #342c3e;
}
.bg-200 {
    background-color: #1e1529;
}

.inline-block {
    display: inline-block;
}

.round {
    border-radius: 50%;
}

.svg-red path {
    fill: #ed0a34;
}

.mh-auto {
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
}
.flex1 {
    flex: 1;
}
.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-end {
    justify-content: flex-end;
}
.justify-start {
    justify-content: flex-start;
}

.align-top {
    align-items: flex-start;
}
.align-bottom {
    align-items: flex-end;
}
.align-middle {
    align-items: center;
}
.align-stretch {
    align-items: stretch;
}
@media screen and (max-width: 1024px) {
    .align-m-top {
        align-items: flex-start;
    }
}

.rotate-90 {
    transform: rotate(90deg);
}
.rotate-180 {
    transform: rotate(180deg);
}

body {
    margin: 0;
    padding: 0;
    font-family: "Averta CY";
    background: #0c0216;
    color: #fff;
}
body a {
    text-decoration: none;
    color: #fff;
}
body .section-title {
    position: relative;
    font-size: 40px;
    margin-bottom: 34px;
    min-height: 49px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
body .section-title h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
body .section-title .filter-btn {
    display: none;
}
body .section-title svg {
    margin-left: 8px;
    margin-top: 7px;
}
@media screen and (max-width: 1024px) {
    body .section-title {
        font-size: 24px;
        margin-bottom: 24px;
        min-height: 29px;
        width: 100%;
    }
    body .section-title a {
        position: absolute;
        display: flex;
        height: 30px;
        align-items: center;
        right: 0;
        font-size: 0;
        bottom: 0;
    }
    body .section-title .filter-btn {
        display: flex;
    }
    body .section-title svg {
        margin-top: 0;
    }
}
body * {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
body .slider-control-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 80px;
    cursor: pointer;
    transition: all 100ms ease-out;
}
body .slider-control-arrow.swiper-button-disabled {
    visibility: hidden;
}
body .slider-control-arrow:hover {
    width: 72px;
    height: 72px;
    margin: -4px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow:hover {
        width: 42px;
        height: 42px;
        margin: 0px;
    }
}
body .slider-control-arrow.slider-prev {
    left: 40px;
}
body .slider-control-arrow.slider-next {
    right: 40px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow {
        width: 42px;
        height: 42px;
    }
    body .slider-control-arrow.slider-prev {
        left: 6px;
    }
    body .slider-control-arrow.slider-next {
        right: 6px;
    }
}
@media screen and (max-width: 768px) {
    body .slider-control-arrow svg {
        width: 14px;
        height: 14px;
    }
}
body .slider-control-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 80px;
    cursor: pointer;
    transition: all 100ms ease-out;
}
body .slider-control-arrow.swiper-button-disabled {
    visibility: hidden;
}
body .slider-control-arrow:hover {
    width: 72px;
    height: 72px;
    margin: -4px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow:hover {
        width: 42px;
        height: 42px;
        margin: 0px;
    }
}
body .slider-control-arrow.slider-prev {
    left: 40px;
}
body .slider-control-arrow.slider-next {
    right: 40px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow {
        width: 42px;
        height: 42px;
    }
    body .slider-control-arrow.slider-prev {
        left: 6px;
    }
    body .slider-control-arrow.slider-next {
        right: 6px;
    }
}
body button.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0 32px;
    font-size: 15px;
    outline: none;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
}
body button.button.ghost-button {
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: none;
    border-radius: 40px;
    color: #fff;
    height: 48px;
}
body button.button.ghost-button:hover {
    border: 2px solid rgb(255, 255, 255);
}
body .select-list {
    position: relative;
    display: block;
    height: 24px;
}
body .select-list .select-list-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    opacity: 0.5;
}
@media screen and (max-width: 1024px) {
    body .select-list .select-list-title {
        font-size: 13px;
    }
}
body .select-list .select-list-title:hover {
    opacity: 1;
}
body .select-list .select-list-title svg:first-child {
    margin-right: 8px;
}
body .select-list.active .select-list-items {
    display: block;
}
body .select-list.active .select-list-title {
    opacity: 1;
}
body .select-list.active .select-list-title .select-arrow {
    transform: rotate(180deg);
}
body .select-list .select-list-items {
    position: absolute;
    display: none;
    overflow: hidden;
    top: 30px;
    left: 0;
    z-index: 1;
    z-index: 10;
    padding: 8px 0;
    background: #1e1529;
    box-shadow: 0px 8px 24px rgba(12, 2, 22, 0.8);
    border-radius: 16px;
}
body .select-list .select-list-items.right-position {
    right: 0;
    left: auto;
}
@media screen and (max-width: 1024px) {
    body .select-list .select-list-items.right-position {
        right: auto;
        left: 0;
    }
}
body .select-list .select-list-items .select-list-item {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 40px;
    padding: 0 60px 0 16px;
    box-sizing: border-box;
    min-width: 255px;
    cursor: pointer;
}
body .select-list .select-list-items .select-list-item:hover {
    background: #342c3e;
}
body .select-list.input-list {
    height: 56px;
}
body .select-list.input-list-disabled {
    opacity: 0.3;
}
body .select-list.input-list-disabled .select-list-title {
    cursor: default;
}
body .select-list.input-list .select-list-title {
    height: 56px;
    width: 220px;
    padding: 0 16px;
    background: #1e1529;
    border-radius: 8px;
    opacity: 1;
}
body .select-list.input-list .select-list-title svg {
    position: absolute;
    right: 16px;
    top: 16px;
}
body .select-list.input-list .select-list-items {
    top: 60px;
    max-height: 336px;
    min-width: 100%;
}
body .select-list.input-list .select-list-items .select-list-items-scrolllist {
    margin-right: 6px;
    overflow-y: scroll;
    box-sizing: border-box;
    max-height: 336px;
}
body .select-list.input-list .select-list-items .select-list-items-scrolllist::-webkit-scrollbar {
    width: 4px;
    padding-right: 6px;
}
body .select-list.input-list .select-list-items .select-list-items-scrolllist::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 4px;
}
body .select-list.input-list .select-list-items .select-list-items-scrolllist::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.2);
}
body .select-list.input-list .select-list-items .select-list-items-scrolllist .select-list-item {
    min-width: 328px;
}
body .select-list.input-list.active .select-list-title {
    opacity: 1;
}
body .select-list.input-list.active .select-list-title svg {
    transform: rotate(180deg);
}
body .container {
    position: relative;
    margin: 0 72px;
}
@media screen and (max-width: 1024px) {
    body .container {
        margin: 0 16px;
    }
}
body .header--border {
    border-bottom: 1px solid #302839;
}
@media screen and (max-width: 1024px) {
    body .header--border {
        margin-bottom: 24px;
    }
}
body .header__user-avatar {
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
}
body .header__user-avatar-placeholder {
    opacity: 0.5;
}
body .header__user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (max-width: 1024px) {
    body .header__user-avatar {
        opacity: 1;
        background: unset;
    }
}
body header {
    position: relative;
    display: block;
    height: 72px;
    margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
    body header {
        height: 64px;
        border-bottom: 0;
    }
}
body header .container {
    position: relative;
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body header .container {
        height: 64px;
    }
}
body header .container .icon-btn {
    opacity: 0.5;
}
body header .container .icon-btn:hover {
    opacity: 1;
}
@media screen and (max-width: 1024px) {
    body header .container .icon-btn {
        opacity: 1;
    }
}
body header .container .header-right {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    font-size: 0;
}
body header .container .header-right-mobile {
    display: none;
}
@media screen and (max-width: 1024px) {
    body header .container .header-right-mobile {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
        font-size: 0;
    }
    body header .container .header-right-mobile #burger-btn img:nth-child(2) {
        display: none;
    }
}
body header .container .header-left {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    align-items: center;
    font-size: 0;
    flex: 1;
}
body header .container .header-left a#header-logo {
    margin-right: 28px;
    opacity: 1;
    font-size: 0;
}
@media screen and (max-width: 1024px) {
    body header .container .header-left a#header-logo img {
        width: 94px;
    }
}
body header .container .header-left a:hover {
    opacity: 1;
}
body header .container .header-left .header-links {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    height: 28px;
}
body header .container .header-left .header-links-left {
    display: flex;
    gap: 24px;
}
body header .container .header-left .header-links__item {
    position: relative;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    opacity: 0.5;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
body header .container .header-left .header-links__item:hover {
    opacity: 1;
}
body header .container .header-left .header-links__item--active {
    opacity: 1;
}
body header .container .header-left .header-links .menu-social-links {
    display: none;
}
@media screen and (max-width: 1024px) {
    body header .container .header-left .header-links {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        left: -100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;
        width: 100%;
        height: calc(100% - 64px);
        background: #0c0216;
        z-index: 10;
        top: 64px;
        padding: 32px 16px;
        box-sizing: border-box;
        transition: all 400ms ease-out;
    }
    body header .container .header-left .header-links__item {
        font-size: 20px;
        opacity: 1;
        cursor: pointer;
    }
    body header .container .header-left .header-links .menu-social-links {
        position: absolute;
        bottom: 32px;
        left: 16px;
        display: flex;
        gap: 8px;
        justify-content: space-between;
    }
    body header .container .header-left .header-links .menu-social-links a {
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 80px;
        display: flex;
        height: 48px;
        justify-content: center;
        opacity: 0.5;
        position: relative;
        width: 48px;
    }
}
body header #profile-header-menu {
    position: absolute;
    display: none;
    right: -62px;
    top: 10px;
    width: 280px;
    box-sizing: border-box;
    height: auto;
    background: #1e1529;
    border-radius: 24px;
    z-index: 10;
    padding: 16px;
}
body header #profile-header-menu.active {
    display: block;
}
@media screen and (max-width: 1024px) {
    body header #profile-header-menu {
        right: -10px;
    }
}
body header #profile-header-menu .avatar-username {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
    font-size: 15px;
    margin-bottom: 16px;
}
body header #profile-header-menu .avatar-username img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
body header #profile-header-menu .avatar-username p {
    max-width: 170px;
}
body header #profile-header-menu .avatar-username p span {
    display: block;
    word-break: break-all;
}
body header #profile-header-menu .profile-header-menu-items {
    position: relative;
    display: block;
}
body header #profile-header-menu .profile-header-menu-items a {
    width: 100%;
    position: relative;
    display: block;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    line-height: 40px;
}
body header #profile-header-menu .profile-header-menu-items a svg {
    position: absolute;
    display: block;
    right: 16px;
    top: calc(50% - 12px);
}
body header #profile-header-menu .profile-header-menu-items a:hover {
    background: #342c3e;
}
@media screen and (max-width: 1024px) {
    body.open-menu {
        padding-top: 104px;
        overflow: hidden !important;
    }
    body.open-menu header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        background: #0c0216;
    }
    body.open-menu header .container .header-links {
        left: 0;
    }
    body.open-menu header .container .header-links-left {
        gap: 12px;
        flex-direction: column;
    }
    body.open-menu header .container .header-right-mobile #burger-btn img:nth-child(1) {
        display: none;
    }
    body.open-menu header .container .header-right-mobile #burger-btn img:nth-child(2) {
        display: block;
    }
}
body section {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
    body section {
        overflow: hidden;
    }
}
body section#main-menu {
    height: 72px;
    background: #1e1529;
    margin-bottom: 40px;
    margin-top: -41px;
}
@media screen and (max-width: 1024px) {
    body section#main-menu {
        height: 56px;
        margin-top: -41px;
        margin-bottom: 24px;
        white-space: nowrap;
        width: 100%;
        overflow-x: scroll;
    }
    body section#main-menu::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
}
body section#main-menu .container {
    display: flex;
    align-items: center;
    height: 72px;
    gap: 24px;
}
@media screen and (max-width: 1024px) {
    body section#main-menu .container {
        height: 56px;
        width: auto;
        padding-right: 16px;
    }
    body section#main-menu .container a:last-child {
        padding-right: 16px;
    }
}
body section#main-menu .container a {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.5;
    line-height: 34px;
    display: flex;
    align-items: center;
}
body section#main-menu .container a svg {
    vertical-align: middle;
    margin-right: 4px;
}
body section#main-menu .container a.round-btn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    padding: 0 16px;
    opacity: 1;
}
body section#main-menu .container a:hover {
    opacity: 1;
}
body section.film-list {
    margin-bottom: 48px;
    padding: 0 72px;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section.film-list {
        padding: 0 16px;
        margin-bottom: 32px;
    }
    body section.film-list .slider-control-arrow {
        display: none;
    }
}
body section.film-list.profile-film-list {
    padding: 0;
    overflow: visible;
}
body section.film-list .film-list-filters {
    margin-bottom: 40px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
}
@media screen and (max-width: 1024px) {
    body section.film-list .film-list-filters {
        display: none;
    }
}
body section.film-list .swiper-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 40px 24px;
    flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
    body section.film-list .swiper-wrapper {
        gap: 24px 8px;
    }
}
body section.film-list .swiper-wrapper .film-list-element {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: 0;
    transition: opacity 300ms linear;
    margin-bottom: 16px;
}
body section.film-list .swiper-wrapper .film-list-element span {
    position: relative;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: calc((100% - 144px) / 4 - 18px);
}
@media screen and (max-width: 1024px) {
    body section.film-list .swiper-wrapper .film-list-element span {
        width: calc((100% - 32px) / 2 - 4px);
        font-size: 14px;
    }
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres a {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 13px;
    padding: 0 10px;
    line-height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres a:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}
body section.film-list .show-more-block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
@media screen and (max-width: 998px) {
    body section.film-list .show-more-block {
        margin-top: 32px;
    }
    body section.film-list .show-more-block button {
        width: 100%;
    }
}
body section.film-list.profile-film-list {
    padding: 0;
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider:after {
        display: none;
    }
    body section.film-list.with-slider:before {
        display: none;
    }
}
body section.film-list.with-slider.full-format:before {
    width: 28vw;
}
body section.film-list.with-slider .swiper-wrapper {
    display: block;
    white-space: nowrap;
    font-size: 0;
}
body section.film-list.with-slider.small-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card {
        width: 67.7vw;
    }
}
body section.film-list.with-slider.small-format .swiper-wrapper .film-card .img-wrap {
    height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card .img-wrap {
        height: 38vw;
    }
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card .film-list-element-genres {
        display: flex;
    }
    body
        section.film-list.with-slider.small-format
        .swiper-wrapper
        .film-card
        .film-list-element-genres
        a:nth-child(2) {
        margin-bottom: 0px;
    }
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card a:nth-child(2) {
        margin-bottom: 12px;
    }
}
body section.film-list.small-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card {
        width: calc(50vw - 20px);
    }
}
body section.film-list.small-format .swiper-wrapper .film-card .img-wrap {
    height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card .img-wrap {
        height: 25.39vw;
    }
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card a:nth-child(2) {
        margin-bottom: 0;
    }
    body section.film-list.small-format .swiper-wrapper .film-card .film-list-element-genres {
        display: none;
    }
}
body section.film-list.small-format .slider-control-arrow {
    top: calc(83px + 5.93vw - 32px);
    z-index: 1;
}
body section.film-list.middle-format {
    min-height: calc(31.66vw + 50px);
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format {
        min-height: calc(65.6vw + 30px);
    }
}
body section.film-list.middle-format .swiper-container {
    min-height: 31.66vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-container {
        min-height: 65.6vw;
    }
}
body section.film-list.middle-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-wrapper .film-card {
        width: calc(50vw - 20px);
    }
}
body section.film-list.middle-format .swiper-wrapper .film-card .img-wrap {
    height: 31.66vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-wrapper .film-card .img-wrap {
        height: 65.6vw;
    }
}
body section.film-list.middle-format .slider-control-arrow {
    top: calc(83px + 15.83vw - 32px);
    z-index: 1;
}
body section.film-list.big-format .swiper-wrapper .film-card {
    width: 44.16vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.big-format .swiper-wrapper .film-card {
        width: 86.7vw;
    }
}
body section.film-list.big-format .swiper-wrapper .film-card .img-wrap {
    width: 44.16vw;
    height: 25.55vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.big-format .swiper-wrapper .film-card .img-wrap {
        height: 48.67vw;
        width: 86.7vw;
    }
}
body section.film-list.big-format .slider-control-arrow {
    top: calc(83px + 12.775vw - 32px);
    z-index: 1;
}
body section.film-list.full-format .swiper-wrapper .film-card {
    width: 67vw;
    height: 37.98vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card {
        height: 52.9vw;
        width: 91vw;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card:hover img {
    transform: scale(1);
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-label {
    position: absolute;
    display: block;
    left: 16px;
    top: 16px;
    background: #cc5ef5;
    border-radius: 8px;
    padding: 0 12px;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 14px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-label {
        padding: 0 8px;
        font-size: 11px;
        line-height: 22px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info {
    position: absolute;
    display: block;
    left: 32px;
    bottom: 32px;
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info {
        left: 16px;
        bottom: 16px;
        font-size: 13px;
        line-height: 24px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info b {
        font-size: 24px;
        margin-bottom: 8px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info small {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info small {
        display: none;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    margin: 0 6px;
    top: -1px;
    opacity: 0.5;
    width: auto;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info span {
        font-size: 13px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info .top-film-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section.film-list.full-format .slider-control-arrow {
    top: calc(83px + 18.99vw - 32px);
}
body section.film-list.genres-format {
    min-height: 165px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format {
        min-height: 135px;
    }
}
body section.film-list.genres-format .swiper-container {
    height: 80px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .swiper-container {
        height: 80px;
    }
}
body section.film-list.genres-format .genres-card {
    width: 196px;
    height: 80px;
    background: #005bff;
    border-radius: 24px;
    line-height: 120px;
    text-align: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .genres-card {
        width: calc((100% - 32px) / 2 - 4px);
        line-height: 80px;
        border-radius: 12px;
    }
}
body section.film-list.genres-format .genres-card span {
    font-size: 18px;
    width: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .genres-card span {
        font-size: 15px;
    }
}
body section.film-list.genres-format .slider-control-arrow {
    top: 55%;
}
body section.film-list.whatch-and-by-slider .section-title {
    padding-top: 102px;
    text-align: center;
    justify-content: center;
    margin: 0 auto 16px auto;
    display: block;
}
body section.film-list.whatch-and-by-slider .section-title h2 {
    justify-content: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .section-title {
        padding-top: 63px;
    }
    body section.film-list.whatch-and-by-slider .section-title a {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-text {
    position: relative;
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #fff;
    margin-bottom: 66px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-text {
        font-size: 14px;
        margin: 0 24px;
        margin-bottom: 32px;
    }
    body section.film-list.whatch-and-by-slider .watching-and-buy-text br {
        position: relative;
        display: inline-block;
        content: "";
        width: 5px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer {
    position: absolute;
    background: linear-gradient(168deg, #005bff -17.4%, #0c0216 52%);
    height: 522px;
    border-radius: 24px;
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(1) {
    width: calc(100% - 246px);
    left: 123px;
    top: 0;
    background: linear-gradient(168deg, #0a1c5a -17.4%, #0c0216 52%);
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(1) {
        border-radius: 24px;
        left: 56px;
        width: calc(100% - 112px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(2) {
    width: calc(100% - 174px);
    left: 87px;
    top: 17px;
    background: linear-gradient(168deg, #06287c -17.4%, #0c0216 52%);
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(2) {
        border-radius: 24px;
        left: 40px;
        top: 13px;
        width: calc(100% - 80px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(3) {
    width: calc(100% - 96px);
    left: 48px;
    top: 42px;
    border-radius: 32px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(3) {
        border-radius: 24px;
        left: 16px;
        top: 26px;
        width: calc(100% - 32px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .slider-control-arrow {
    top: calc(256px + 6.11vw);
}
body section#top-films {
    position: relative;
    display: block;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section#top-films {
        margin-bottom: 32px;
    }
}
body section#top-films .swiper-wrapper,
body section#top-films .swiper-container {
    height: 35.55vw;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper,
    body section#top-films .swiper-container {
        height: 50vw;
    }
    body section#top-films .swiper-wrapper .slider-control-arrow,
    body section#top-films .swiper-container .slider-control-arrow {
        top: calc(50% - 21px);
    }
}
body section#top-films .swiper-wrapper {
    position: relative;
    display: block;
    white-space: nowrap;
    font-size: 0;
}
body section#top-films .swiper-wrapper .top-film-slide {
    background-color: #1e1529;
    position: relative;
    display: inline-block;
    display: inline-flex !important;
    top: 0;
    margin: 0 -1.04vw;
    width: 62.77vw;
    height: 35.55vw;
    border-radius: 24px;
    overflow: hidden;
    opacity: 0.3;
    transform: scale(0.8849);
}
body section#top-films .swiper-wrapper .top-film-slide:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 55.7%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide {
        width: calc(100% - 32px);
        margin: 0 -1.2vw;
        transform: scale(0.92);
        border-radius: 16px;
        height: 50vw;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-label {
    position: absolute;
    z-index: 3;
    display: block;
    opacity: 0;
    left: 16px;
    top: 16px;
    background: #fd4aa7;
    border-radius: 8px;
    padding: 0 12px;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 14px;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-label {
        padding: 0 8px;
        font-size: 11px;
        line-height: 22px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info {
    position: absolute;
    display: block;
    opacity: 0;
    left: 32px;
    bottom: 32px;
    font-size: 14px;
    line-height: 22px;
    z-index: 3;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info {
        left: 16px;
        bottom: 16px;
        font-size: 13px;
        line-height: 24px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
    white-space: break-spaces;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info b {
        font-size: 20px;
        margin-bottom: 0px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info span {
        font-size: 13px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info .top-film-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section#top-films .swiper-wrapper .top-film-slide.transition {
    transition: 300ms linear;
    transition-property: opacity, transform;
}
body section#top-films .swiper-wrapper .top-film-slide.transition .top-film-label {
    transition: 300ms linear;
}
body section#top-films .swiper-wrapper .top-film-slide.transition .top-film-info {
    transition: 300ms linear;
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active {
    opacity: 1;
    transform: scale(1);
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active .top-film-label {
    opacity: 1;
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active .top-film-info {
    opacity: 1;
}
body section#top-films .swiper-wrapper .top-film-slide .img-wrap {
    position: relative;
}
body section#top-films .swiper-wrapper .top-film-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body section#all-categories {
    margin-bottom: 48px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories {
        margin-bottom: 24px;
    }
}
body section#all-categories .categories-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 48px 24px;
    flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list {
        gap: 32px 8px;
        margin-bottom: 14px;
    }
}
body section#all-categories .categories-list .categories-list-item {
    position: relative;
    display: block;
    width: calc(25% - 18px);
    height: calc(13.4vw - 18px);
    border-radius: 16px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item {
        width: 44.44vw;
        height: 25.39vw;
        border-radius: 12px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1-img {
    position: absolute;
    display: block;
    top: 0;
    left: 10%;
    width: 80%;
    height: 12.22vw;
    border-radius: 8px;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1-img {
    width: 80%;
    height: 12.22vw;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
    position: absolute;
    display: block;
    top: 8px;
    left: 5%;
    width: 90%;
    height: 12.22vw;
    border-radius: 12px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-back2,
    body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
        top: 7px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
    width: 90%;
    height: 12.22vw;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2 {
    transition: top 0.3s ease-in-out;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    top: 16px;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-info {
        top: 14px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info span {
    position: absolute;
    display: block;
    top: 1.66vw;
    left: 1.66vw;
    font-size: 1.25vw;
    line-height: 1.625vw;
    max-width: 111px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-info span {
        left: 16px;
        top: 16px;
        font-size: 14px;
        line-height: 17px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    transition: all 300ms ease-out;
}
body
    section#all-categories
    .categories-list
    .categories-list-item
    .categories-list-item-info
    .categories-list-item-back3 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-info {
    top: 13px;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-info img {
    transform: scale(1.015);
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-back1 {
    top: -5px;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-back2 {
    top: 3px;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back3 {
    background: linear-gradient(95.42deg, #005bff 37.93%, rgba(0, 91, 255, 0) 97.39%);
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back1 {
    background: #aa0c5f;
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back2 {
    background: #cb1d78;
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back3 {
    background: linear-gradient(94.75deg, #e33b92 37.83%, rgba(227, 59, 146, 0) 97.52%);
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back1 {
    background: #28a1aa;
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back2 {
    background: #34b8c2;
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back3 {
    background: linear-gradient(96.41deg, #3ec2cc 38.08%, rgba(122, 216, 223, 0) 97.3%);
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back1 {
    background: #c55809;
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back2 {
    background: #e9741f;
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #ff9e00 38%, rgba(255, 158, 0, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back1 {
    background: #a90e2b;
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back2 {
    background: #ce1b3d;
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #eb2b4f 38%, rgba(235, 43, 79, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back1 {
    background: #b98500;
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back2 {
    background: #d9a00e;
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #e6b711 38%, rgba(229, 183, 17, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back1 {
    background: #3891cd;
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back2 {
    background: #4dabeb;
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back3 {
    background: linear-gradient(96.17deg, #60bfff 38.04%, rgba(96, 191, 255, 0) 97.31%);
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back1 {
    background: #6c138b;
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back2 {
    background: #8719ad;
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back3 {
    background: linear-gradient(96.87deg, #9e20ca 38.14%, rgba(158, 32, 202, 0) 115.87%);
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back1 {
    background: #4c0f90;
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back2 {
    background: #601bad;
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back3 {
    background: linear-gradient(95.81deg, #7027c2 37.99%, rgba(112, 39, 194, 0) 108.13%);
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back1 {
    background: #aa0c5f;
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back2 {
    background: #cb1d78;
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back3 {
    background: linear-gradient(96.68deg, #e33b92 38.12%, rgba(227, 59, 146, 0) 104.05%);
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back1 {
    background: #b98500;
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back2 {
    background: #d9a00e;
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back3 {
    background: linear-gradient(98.16deg, #e6b711 38.33%, rgba(229, 183, 17, 0) 103.08%);
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back1 {
    background: #087554;
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back2 {
    background: #09946a;
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #10bc88 38%, rgba(16, 188, 136, 0) 108.42%);
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back1 {
    background: #a90e2b;
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back2 {
    background: #ce1b3d;
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #eb2b4f 38%, rgba(235, 43, 79, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back1 {
    background: #6c138b;
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back2 {
    background: #8719ad;
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #9e20ca 38%, rgba(158, 32, 202, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back1 {
    background: #c55809;
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back2 {
    background: #e9741f;
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #ff9e00 38%, rgba(255, 158, 0, 0) 121.98%);
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list.swiper-wrapper {
        display: block;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    body section#all-categories .categories-list.swiper-wrapper .categories-list-item {
        display: inline-block;
    }
}
body section.film-top {
    height: 41.6vw;
    margin-top: -1px;
    margin-bottom: 64px;
}
body section.film-top .over-cover {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-size: cover;
    width: 72vw;
}
@media screen and (max-width: 1024px) {
    body section.film-top {
        margin-top: -41px;
        padding-top: 57.4vw;
        height: auto;
        background-size: 100% 57.4vw;
        margin-bottom: 48px;
    }
    body section.film-top .over-cover {
        height: 57.4vw;
        left: 0;
        width: 100%;
    }
}
body section.film-top .previous-page {
    display: none;
}
@media screen and (max-width: 1024px) {
    body section.film-top .previous-page {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        left: 16px;
        top: 16px;
        width: 42px;
        height: 42px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 80px;
    }
    body section.film-top .previous-page img {
        object-fit: contain;
        width: 24px;
        height: 24px;
    }
}
body section.film-top .container {
    height: 100%;
}
body section.film-top .film-bookmarks {
    position: absolute;
    display: flex;
    gap: 8px;
    top: 0;
    font-weight: 600;
    font-size: 13px;
    height: 22px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-bookmarks {
        display: none;
    }
}
body section.film-top .film-bookmarks a {
    color: #fff;
    opacity: 0.4;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
}
body section.film-top .film-bookmarks a:hover {
    opacity: 1;
}
body section.film-top .film-top-info {
    position: absolute;
    display: block;
    bottom: 48px;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    padding-bottom: 56px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info {
        position: relative;
        bottom: auto;
        margin-top: 8px;
        padding-bottom: 0;
    }
}
body section.film-top .film-top-info .film-title {
    position: relative;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-title {
        font-size: 28px;
        line-height: 32px;
    }
}
body section.film-top .film-top-info .blogger-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 3px;
    position: relative;
    width: max-content;
}
body section.film-top .film-top-info .blogger-name::after,
body section.film-top .film-top-info .blogger-name:before {
    position: absolute;
    top: 50%;
    right: -10px;
}
body section.film-top .film-top-info .blogger-name:after {
    content: "";
    background: #fd4aa7;
    border-radius: 80px;
    width: 24px;
    height: 24px;
    transform: translate(100%, -50%);
}

body section.film-top .film-top-info .film-rating-age-genre {
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-rating-age-genre {
        margin-bottom: 24px;
    }
}
body section.film-top .film-top-info .film-rating-age-genre b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
}
body section.film-top .film-top-info .film-rating-age-genre span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
}
body section.film-top .film-top-info .film-rating-age-genre .raing-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section.film-top .film-top-info .film-rating-age-genre .raing-age.green {
    border: 2px solid #0dc268;
}
body section.film-top .film-top-info .film-top-actors {
    position: relative;
    display: block;
    margin-top: 32px;
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-actors {
        margin-top: 24px;
        margin-bottom: 0;
    }
}
body section.film-top .film-top-info .film-top-actors .film-top-actor {
    position: relative;
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 8px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px 0 6px;
    background: #1e1529;
    border-radius: 80px;
    color: rgba(255, 255, 255, 0.8);
}
body section.film-top .film-top-info .film-top-actors .film-top-actor--no-img {
    padding-left: 16px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-actors .film-top-actor {
        font-size: 13px;
    }
}
body section.film-top .film-top-info .film-top-actors .film-top-actor__preview {
    border-radius: 80px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 2px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}
body section.film-top .film-top-info .film-top-actors .film-top-actor__preview img {
    object-fit: cover;
    width: 20px;
    height: 20px;
    border-radius: 80px;
    background-color: #1e1529;
}
body section.film-top .film-top-info .film-top-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 8px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-buttons {
        position: relative;
        bottom: auto;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
}
body section.film-top .film-top-info .film-top-buttons .flex {
    display: flex;
    gap: 8px;
}
body section.film-top-blogger {
    height: 43.334vw;
}
@media screen and (max-width: 1024px) {
    body section.film-top-blogger {
        height: auto;
    }
}
body section.film-top-blogger .film-top-info {
    width: 100%;
    padding-bottom: 0;
}
body section.film-top-blogger .film-top-info .flex {
    gap: 8px;
}
@media screen and (max-width: 1024px) {
    body section.film-top-blogger .film-top-info {
        padding-bottom: 0;
    }
}
body section.film-top-blogger .film-top-info__bloger-avatar {
    margin-right: 22px;
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}
body section.film-top-blogger .film-top-info__bloger-avatar img {
    object-fit: cover;
    border-radius: 16px;
    background-color: #1e1529;
}
body section.film-top-blogger .film-top-buttons {
    bottom: 0;
    right: 0;
    left: unset;
}
body section.seasons-slider .slider-control-arrow {
    top: 3.6vw !important;
}
body section#seasons-list {
    margin-bottom: 48px;
}
@media screen and (max-width: 1024px) {
    body section#seasons-list {
        margin-bottom: 24px;
        overflow-x: scroll;
    }
    body section#seasons-list::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
}
@media screen and (max-width: 1024px) {
    body section#seasons-list .container {
        white-space: nowrap;
    }
}
body section#seasons-list .container a {
    position: relative;
    display: inline-block;
    margin-right: 40px;
    opacity: 0.5;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}
@media screen and (max-width: 1024px) {
    body section#seasons-list .container a {
        margin-right: 24px;
        font-size: 18px;
        line-height: 24px;
    }
}
body section#seasons-list .container a.active {
    opacity: 1;
}
body section#seasons-list .container a:hover {
    opacity: 1;
}
body section#film-description {
    margin-top: 108px;
    margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
    body section#film-description {
        margin-top: 8px;
        margin-bottom: 0;
    }
}
body section#film-description .container {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container {
        flex-direction: column-reverse;
    }
}
body section#film-description .container .film-info {
    position: relative;
    display: block;
    width: calc(100% - 134px - 416px);
    max-width: 1000px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info {
        width: auto;
    }
}
body section#film-description .container .film-info .film-info-bookmarks {
    position: relative;
    display: flex;
    gap: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-info-bookmarks {
        white-space: nowrap;
        overflow-x: scroll;
        padding: 0 16px;
        margin: 0 -16px;
        border-bottom: 0;
    }
    body section#film-description .container .film-info .film-info-bookmarks::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
    body section#film-description .container .film-info .film-info-bookmarks:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.15);
        content: "";
        top: calc(100% - 1px);
    }
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark {
    position: relative;
    font-size: 18px;
    cursor: pointer;
    padding-bottom: 20px;
    opacity: 0.5;
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark:hover {
    opacity: 1;
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark.active {
    opacity: 1;
    border-bottom: 2px solid #005bff;
}
body section#film-description .container .film-info .film-info-pages {
    margin-top: 40px;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    margin-bottom: 58px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-info-pages {
        margin-bottom: 48px;
        margin-top: 24px;
    }
}
body section#film-description .container .film-info .film-info-pages div {
    white-space: break-spaces;
}
body section#film-description .container .film-info .film-crews {
    position: relative;
    display: flex;
    gap: 48px;
    flex-direction: column;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews {
        font-size: 14px;
        gap: 32px;
    }
}
body section#film-description .container .film-info .film-crews .film-crew {
    color: rgba(255, 255, 255, 0.8);
}
body section#film-description .container .film-info .film-crews .film-crew .film-crew-title {
    font-size: 18px;
    margin-bottom: 16px;
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews .film-crew .film-crew-title {
        margin-bottom: 8px;
    }
}
body section#film-description .container .film-info .film-crews .film-crew span {
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
    position: relative;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews .film-crew span {
        font-size: 13px;
        margin: 0;
    }
}
body section#film-description .container .film-info .film-crews .film-crew-actors {
    margin-bottom: 16px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 18px;
    padding: 8px;
    padding-right: 24px;
    background: #1e1529;
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.8);
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor--no-img {
    padding-left: 24px;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor__preview {
    margin-right: 8px;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 2px;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor__preview img {
    object-fit: cover;
    width: 44px;
    height: 44px;
    border-radius: 12px;
    background-color: #0c0216;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor p {
    opacity: 0.8;
}
body section#film-description .container .film-info .film-crews a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;
    height: 56px;
    box-sizing: border-box;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 80px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews a {
        width: auto;
        height: 48px;
        margin-bottom: 12px;
    }
}
body section#film-description .container .film-info .film-crews a:hover {
    border: 2px solid rgb(255, 255, 255);
}
body section.user-profile {
    z-index: 1;
    padding-top: 40px;
    margin-bottom: 108px;
    margin-top: -41px;
    background: #0c0216;
}
@media screen and (max-width: 1024px) {
    body section.user-profile {
        padding-top: 16px;
    }
}
body section.user-profile .film-list {
    margin-bottom: 0;
}
body section.user-profile .section-title {
    margin-bottom: 24px;
    line-height: 56px;
}
body section.user-profile .section-title .mobile-exit {
    display: none;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .section-title {
        line-height: 40px;
    }
    body section.user-profile .section-title .mobile-exit {
        display: block;
        height: 40px;
    }
}
body section.user-profile .profile-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 600px;
    column-gap: 11%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content {
        display: block;
    }
}
body section.user-profile .profile-content .profile-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
body section.user-profile .profile-content .profile-menu a {
    position: relative;
    display: block;
    white-space: nowrap;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
}
body section.user-profile .profile-content .profile-menu a span {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
    background: #342c3e;
    border-radius: 24px;
    margin-left: 8px;
}
body section.user-profile .profile-content .profile-menu a svg {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
}
body section.user-profile .profile-content .profile-menu a svg path {
    fill: rgba(255, 255, 255, 0.5);
}
body section.user-profile .profile-content .profile-menu a.active {
    color: #fff;
}
body section.user-profile .profile-content .profile-menu a.active svg path {
    fill: #fff;
}
body section.user-profile .profile-content .profile-menu a:hover {
    color: #fff;
}
body section.user-profile .profile-content .profile-menu a:hover svg path {
    fill: #fff;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-menu {
        background: #1e1529;
        height: 56px;
        flex-direction: row;
        align-items: center;
        padding: 0 16px;
        margin: 0 -16px;
        gap: 0;
        margin-bottom: 24px;
        overflow-x: scroll;
    }
    body section.user-profile .profile-content .profile-menu::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
    body section.user-profile .profile-content .profile-menu a {
        font-size: 14px;
        padding: 0 16px;
        line-height: 40px;
        border-radius: 8px;
    }
    body section.user-profile .profile-content .profile-menu a.active {
        background: #342c3e;
    }
    body section.user-profile .profile-content .profile-menu a.active span {
        background: rgba(255, 255, 255, 0.2);
    }
    body section.user-profile .profile-content .profile-menu a.exit-btn {
        display: none;
    }
    body section.user-profile .profile-content .profile-menu a svg {
        display: none;
    }
}
body section.user-profile .profile-content .profile-data {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    height: max-content;
}
body section.user-profile .profile-content .profile-data .film-card {
    min-height: calc((100vw - 362px - 222px) / 4 * 9 / 16);
    width: calc(25% - 18px) !important;
}
body section.user-profile .profile-content .profile-data .film-card .img-wrap {
    height: calc((100vw - 362px - 222px) / 4 * 9 / 16);
}
@media screen and (max-width: 1440px) {
    body section.user-profile .profile-content .profile-data .film-card {
        width: calc((100% - 48px) / 3) !important;
        min-height: calc((100vw - 471px) / 3 * 9 / 16);
    }
    body section.user-profile .profile-content .profile-data .film-card .img-wrap {
        height: calc((100vw - 471px) / 3 * 9 / 16);
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data {
        width: 100%;
        flex-direction: column;
    }
    body section.user-profile .profile-content .profile-data .film-card,
    body section.user-profile .profile-content .profile-data .blogger-channel-card {
        width: calc(50vw - 20px) !important;
        min-height: 25.2224vw;
    }
    body section.user-profile .profile-content .profile-data .film-card .img-wrap,
    body section.user-profile .profile-content .profile-data .film-card .preview,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .img-wrap,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .preview {
        height: 25.2224vw;
    }
}
body section.user-profile .profile-content .profile-data .show-more-block {
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .show-more-block .button {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .select-list {
    position: absolute;
    right: 0;
    top: 0;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .select-list {
        position: absolute;
        right: auto;
        left: 0;
    }
    body section.user-profile .profile-content .profile-data .select-list.list-sort {
        top: 32px;
    }
}
body section.user-profile .profile-content .profile-data .clear-all-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    right: 0;
    top: 0;
    opacity: 0.5;
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .clear-all-btn {
        font-size: 13px;
        right: 0;
    }
}
body section.user-profile .profile-content .profile-data .clear-all-btn:hover {
    opacity: 1;
}
body section.user-profile .profile-content .profile-data #upload-form {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1e1529;
    border-radius: 16px;
    width: 307px;
    height: 237px;
}
body section.user-profile .profile-content .profile-data #upload-form input {
    display: none;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin: 28px 0 16px 0;
    color: #fff;
    opacity: 0.5;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-size-hint {
    font-size: 13px;
    line-height: 21px;
    color: #fff;
    opacity: 0.5;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-preview,
body section.user-profile .profile-content .profile-data #upload-form .upload-preview img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
@media screen and (max-width: 1280px) {
    body section.user-profile .profile-content .profile-data #upload-form {
        width: 100%;
        height: 176px;
        order: -1;
        margin-bottom: 24px;
        padding: 24px 0;
    }
    body section.user-profile .profile-content .profile-data #upload-form svg#upload-profile-preview {
        width: 88px;
        height: 88px;
    }
    body section.user-profile .profile-content .profile-data #upload-form .upload-text {
        font-size: 14px;
        margin-top: 16px;
        opacity: 0.5;
    }
}
body section.user-profile .profile-content .profile-data .ghost-button {
    margin-bottom: 37px;
}
@media screen and (max-width: 768px) {
    body section.user-profile .profile-content .profile-data .ghost-button {
        margin-bottom: 0;
    }
}
body section.user-profile .profile-content .profile-data h3 {
    margin: 0;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    order: -2;
    width: 100%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data h3 {
        font-size: 18px;
        line-height: 23px;
    }
}
body section.user-profile .profile-content .profile-data__left {
    width: 55.5%;
}
body section.user-profile .profile-content .profile-data__left .error {
    margin-top: 16px;
}
@media screen and (max-width: 1280px) {
    body section.user-profile .profile-content .profile-data__left {
        width: auto;
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data__left {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .profile-form {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
}
@media screen and (max-width: 768px) {
    body section.user-profile .profile-content .profile-data .profile-form__save-btn {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 0;
        z-index: 11;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .form-empty-divider {
    height: 41px;
    width: 100%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .form-empty-divider {
        height: 0;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input {
    position: relative;
    width: calc(50% - 12px);
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-form-input {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.full-width {
    width: 100%;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.third-size {
    width: calc(33% - 12px);
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-form-input.third-size
    .select-list-item {
    min-width: 140px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.double-third-size {
    width: calc(66% - 12px);
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input .select-list {
    position: relative;
    width: 100%;
    right: auto;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-form-input
    .select-list
    .select-list-title {
    width: 100%;
    box-sizing: border-box;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input label {
    position: relative;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    opacity: 0.4;
    margin-bottom: 5px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input input {
    position: relative;
    display: block;
    width: 100%;
    background: #1e1529;
    border-radius: 8px;
    height: 56px;
    font-size: 15px;
    padding: 0 16px;
    outline: none;
    border: 0;
    box-sizing: border-box;
    color: #fff;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio input {
    display: none;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    vertical-align: middle;
    margin-right: 21px;
    margin-bottom: 0;
    color: #fff;
    opacity: 1;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label {
        font-size: 13px;
        margin-right: 13px;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label:before {
    cursor: pointer;
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-right: 15px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 80px;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label:before {
        margin-right: 8px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-input-radio
    input[type="radio"]:checked
    + label:before {
    background: #fff;
    border: #005bff solid 8px;
    box-sizing: border-box;
}
body section.user-profile .profile-content .profile-data .profile-tips-history-list {
    margin-bottom: 0;
    width: 100%;
}
body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: #1e1529;
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 24px 24px 24px 24px;
    box-sizing: border-box;
}
@media screen and (max-width: 1440px) {
    body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
        padding: 20px 16px 16px 16px;
        height: auto;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
        padding: 20px 16px 16px 16px;
        height: auto;
        flex-wrap: wrap;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product {
    width: 50%;
    flex: 1;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .product {
        width: 100%;
        margin-bottom: 16px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    white-space: break-spaces;
    word-break: break-all;
    margin-left: 16px;
    width: calc(100% - 80px - 16px);
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info
    div {
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info
    strong {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 3px;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film {
    max-width: 40%;
    display: block;
    right: 24px;
    top: 21px;
    font-size: 13px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
    display: block;
    flex-wrap: wrap;
    flex-direction: column;
    width: 43%;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film_date-mobile {
    display: none;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film {
        width: 100%;
        max-width: unset;
        padding-top: 16px;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film_date {
        display: none;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film_date-mobile {
        display: block;
        font-size: 13px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 0;
        width: 100%;
        min-height: 21px;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film:after {
        position: absolute;
        display: block;
        width: calc(100% + 32px);
        height: 1px;
        left: -16px;
        top: 0;
        background: #342c3e;
        content: "";
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        img {
        order: -1;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    img {
    display: block;
    right: 0;
    top: 0;
    width: 68px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        img {
        width: 80px;
        height: 48px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    p {
    height: max-content;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a {
    width: 100%;
    display: flex !important;
    align-items: flex-start;
    column-gap: 0;
    align-items: center;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    div {
    flex: 1;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    p {
    width: unset;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    .one-line,
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    .two-lines {
    display: -webkit-box !important;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film {
        right: auto;
        left: 16px;
        text-align: left;
        top: 14px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    a {
    display: block !important;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a {
    position: relative;
    display: block;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        a {
        margin-top: 0;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a:hover {
    color: #fff;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    b {
    position: relative;
    display: block;
    font-size: 14px;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    .one-line,
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    .two-lines {
    display: -webkit-box !important;
    padding-right: 24px;
    width: unset;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        div
        .one-line,
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        div
        .two-lines {
        padding-left: 16px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 12px;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .product-image {
        left: 16px;
        top: 48px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-image
    img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body footer {
    position: relative;
    margin-top: auto;
    border-top: 1px solid #302839;
    padding: 70px 72px 48px;
    height: 439px;
    box-sizing: border-box;
    background: url("/public/images/footer.png") no-repeat 70% 100%;
    background-size: 472px auto;
}
@media screen and (max-width: 1200px) {
    body footer {
        background: url("/public/images/footer.png") no-repeat 95% 100%;
        background-size: 472px auto;
    }
}
@media screen and (max-width: 1024px) {
    body footer {
        margin-top: 3px;
        overflow: hidden;
        height: 544px;
        background: url("/public/images/mobile_footer_bg.svg") no-repeat 100% 100%;
        padding: 30px 16px 24px;
    }
}
body footer .footer-columns {
    position: relative;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns {
        flex-wrap: wrap;
    }
}
body footer .footer-columns .footer-column {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column {
        width: 100%;
    }
}
@media screen and (max-width: 1024px) and (max-width: 1024px) {
    body footer .footer-columns .footer-column:nth-child(1) img {
        width: 94px;
    }
    body footer .footer-columns .footer-column:nth-child(1) a {
        margin-bottom: 24px;
    }
}
body footer .footer-columns .footer-column a {
    position: relative;
    display: block;
    margin-bottom: 14px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
}
body footer .footer-columns .footer-column a:hover {
    color: #fff;
}
body footer .footer-columns .footer-column a.write-us {
    height: 56px;
    margin-bottom: 32px;
    line-height: 56px;
    padding: 0 24px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 80px;
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column a.write-us {
        margin-bottom: 24px;
        height: 52px;
        line-height: 52px;
    }
}
body footer .footer-columns .footer-column a.write-us img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}
body footer .footer-columns .footer-column a.write-us:hover {
    border: 2px solid rgb(255, 255, 255);
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column a {
        margin-bottom: 8px;
        line-height: 24px;
    }
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column:nth-child(2) {
        width: 50%;
        margin-bottom: 40px;
    }
    body footer .footer-columns .footer-column:nth-child(3) {
        width: 50%;
    }
}
body footer .footer-columns .footer-column.contacts-column {
    width: 20%;
    min-width: 200px;
}
body footer .footer-columns .footer-column.contacts-column .footer-social-links {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
body footer .footer-columns .footer-column .contacts-column .footer-social-links a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0.5;
    border-radius: 80px;
}
body footer .footer-columns .footer-column.contacts-column .footer-social-links a:hover {
    opacity: 1;
}
body footer .footer-copyright {
    position: absolute;
    display: block;
    left: 72px;
    bottom: 48px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
}
body footer .footer-copyright a {
    position: relative;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin-top: 8px;
    margin-right: 24px;
}
body footer .footer-copyright a:hover {
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body footer .footer-copyright {
        left: 16px;
        bottom: 24px;
        line-height: 25px;
    }
    body footer .footer-copyright a {
        margin-top: 4px;
        width: calc(100% - 16px);
        margin-right: 0;
    }
}
body #modal {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background: rgba(12, 2, 22, 0.8);
}
body #modal.active {
    display: flex;
}
body #modal.with-scroll {
    align-items: flex-start;
    padding: 30px 0 40px 0;
    overflow-y: scroll;
}
@media screen and (max-width: 768px) {
    body #modal.with-scroll {
        padding: 0;
        align-items: stretch;
    }
}
@media screen and (max-width: 768px) {
    body #modal {
        overflow-y: scroll;
        align-items: stretch;
    }
}
body #modal .modal-content {
    position: relative;
    padding-bottom: 60px;
    max-width: 100%;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content {
        padding-bottom: 0;
    }
}
body #modal .modal-content .modal-block {
    position: relative;
    background: #1e1529;
    border-radius: 24px;
    box-sizing: border-box;
    margin: 0 auto;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block {
        border-radius: 0;
        padding: 30px;
        min-height: 100%;
    }
}
body #modal .modal-content .modal-block.forgot-form {
    font-weight: 400;
    font-size: 13px;
}
body #modal .modal-content .modal-block.forgot-form strong {
    position: relative;
    display: block;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: -8px;
    width: 100%;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block.forgot-form {
        height: 100%;
        padding-top: 50px;
    }
}
body #modal .modal-content .modal-block .modal-block-tumbler {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 328px;
    height: 56px;
    margin: 0 auto 40px;
    border: 2px solid #342c3e;
    border-radius: 12px;
    box-sizing: border-box;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-block-tumbler {
        width: 260px;
    }
}
body #modal .modal-content .modal-block .modal-block-tumbler .modal-tumbler-bg {
    position: absolute;
    display: block;
    width: calc(50% - 2px);
    left: 2px;
    top: 2px;
    background: #342c3e;
    border-radius: 8px;
    height: 48px;
    transition: all 300ms ease-out;
}
body #modal .modal-content .modal-block .modal-block-tumbler.register .modal-tumbler-bg {
    left: 50%;
}
body #modal .modal-content .modal-block .modal-block-tumbler span {
    cursor: pointer;
    position: relative;
    display: block;
    width: 50%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-block-tumbler span {
        font-size: 15px;
    }
}
body #modal .modal-content .modal-block .close-modal {
    position: absolute;
    display: block;
    right: 16px;
    top: 12px;
    cursor: pointer;
    opacity: 0.5;
}
body #modal .modal-content .modal-block .close-modal svg {
    width: 40px;
    height: 40px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .close-modal {
        right: 0;
        top: 0;
    }
}
body #modal .modal-content .modal-block .close-modal:hover {
    opacity: 1;
}
body #modal .modal-content .modal-block .modal-form form {
    position: relative;
    display: none;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    width: 400px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form form {
        width: auto;
    }
}
body #modal .modal-content .modal-block .modal-form form.active {
    display: flex;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 37px;
    color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form form .modal-form-button {
        padding: 0;
    }
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button button {
    width: 240px;
    text-align: center;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button--tink {
    font-size: 0;
    height: 56px;
    text-align: center;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button--tink img {
    width: 240px;
}
body #modal .modal-content .modal-block .modal-form form small {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 8px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}
body #modal .modal-content .modal-block .modal-form form small a {
    color: rgba(255, 255, 255, 0.8);
}
body #modal .modal-content .modal-block .modal-form form small a:hover {
    color: #fff;
}
body #modal .modal-content .modal-block .modal-form .form-empty-divider {
    height: 41px;
    width: 100%;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input {
    position: relative;
    width: 100%;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.third-size {
    width: calc(33% - 12px);
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.double-third-size {
    width: calc(66% - 12px);
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input input {
    display: none;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input label {
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    vertical-align: middle;
    margin-right: 21px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
    padding-left: 33px;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input label:before {
    content: "";
    display: block;
    position: absolute;
    vertical-align: middle;
    top: -2px;
    margin-right: 12px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid #342c3e;
}

body #mobile-filter {
    position: fixed;
    z-index: 4;
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #0c0216;
}
@media screen and (max-width: 1024px) {
    body #mobile-filter {
        display: block;
        left: -100%;
        transition: all 400ms ease-out;
    }
    body #mobile-filter.active {
        left: 0;
    }
}
body #mobile-filter #close-filter-btn {
    position: absolute;
    right: 16px;
    top: 16px;
}
body #mobile-filter h3 {
    position: relative;
    margin: 0;
}
body #mobile-filter h3 .reset-btn {
    position: absolute;
    display: block;
    right: 0;
    bottom: 2px;
    font-size: 13px;
    opacity: 0.5;
}
body #mobile-filter .input-list {
    margin-bottom: 24px;
}
body #mobile-filter .input-list .select-list-title {
    width: auto;
    font-size: 15px;
}
body #mobile-filter #set-filter {
    cursor: pointer;
    position: absolute;
    width: 344px;
    height: 56px;
    line-height: 56px;
    left: calc(50% - 172px);
    bottom: 24px;
    background: #005bff;
    border-radius: 80px;
    text-align: center;
}

.h100 {
    height: 100%;
}

.scroll {
    overflow: auto;
}

.scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
}
.scroll::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 6px;
}
.scroll::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);
}

.hide-scrollbar {
    scrollbar-arrow-color: transparent;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    position: absolute;
}
.hide-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 0;
    width: 0px;
}
.hide-scrollbar::-webkit-scrollbar-thumb {
    position: relative;
    background: transparent;
    border-radius: 3px;
    width: 0px;
}

.no-scrollbar {
    scrollbar-arrow-color: transparent;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
.no-scrollbar::-webkit-scrollbar {
    width: 10px;
    position: absolute;
}
.no-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 16px 0;
}
.no-scrollbar::-webkit-scrollbar-thumb {
    position: relative;
    background: transparent;
    border-radius: 3px;
}

.checkbox {
    min-width: 24px;
    width: 24px;
    height: 24px;
}
.checkbox input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}
.checkbox input:not(:checked) {
    opacity: 0.5;
    border: 2px solid #ffffff;
}
.checkbox input:checked {
    background-color: #005bff;
    border-color: #005bff;
}
.checkbox svg {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.width-100 {
    width: 100%;
}
.width-max {
    width: max-content;
}

.dividor {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #342c3e;
}

button.opacity {
    transition: opacity 0.3s ease-in-out;
}
button.opacity:hover {
    opacity: 1;
}

.opacity-hover {
    transition: opacity 0.3s ease-in-out;
}
.opacity-hover:hover {
    opacity: 1;
}
.opacity-2 {
    opacity: 0.2;
}
.opacity-3 {
    opacity: 0.3;
}
.opacity-4 {
    opacity: 0.3;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-6 {
    opacity: 0.6;
}
.opacity-7 {
    opacity: 0.7;
}
.opacity-8 {
    opacity: 0.8;
}
.opacity-off {
    opacity: 1;
}

.height-100 {
    height: 100%;
}
.height-max-content {
    height: max-content !important;
    min-height: unset !important;
}

.bg-img {
    background-size: cover;
    background-position: center;
}

.border-box {
    box-sizing: border-box;
}

.radius-8 {
    border-radius: 8px;
}
.radius-12 {
    border-radius: 12px;
}
.radius-16 {
    border-radius: 16px;
}
.radius-24 {
    border-radius: 24px;
}
.radius-32 {
    border-radius: 32px;
}
.radius-48 {
    border-radius: 48px;
}
.radius-64 {
    border-radius: 64px;
}
.radius-80 {
    border-radius: 80px;
}

.top-films {
    height: 35.55vw;
    margin-bottom: 64px;
}
@media screen and (max-width: 1024px) {
    .top-films {
        margin-bottom: 32px;
        height: 50vw;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.scroll-hidden::-webkit-scrollbar {
    width: 0px;
    border-radius: 6px;
    background: transparent;
}
.scroll-hidden::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 0px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scroll-hidden::-webkit-scrollbar-thumb {
    width: 0px;
    border-radius: 6px;
    background: transparent;
}

.relative {
    position: relative;
}

.main-policy {
    min-height: 100vh;
}

main,
.main {
    margin-bottom: 80px;
}
main-series .film-list,
main-movies .film-list,
main-bloggers .film-list,
.main-series .film-list,
.main-movies .film-list,
.main-bloggers .film-list {
    margin-bottom: 0;
    min-height: 600px;
}
@media screen and (max-width: 1024px) {
    main-series .film-list,
    main-movies .film-list,
    main-bloggers .film-list,
    .main-series .film-list,
    .main-movies .film-list,
    .main-bloggers .film-list {
        min-height: 350px;
    }
}
main-shorts,
.main-shorts {
    margin-bottom: 0;
}
@media screen and (max-width: 768px) {
    main-shorts,
    .main-shorts {
        margin-top: 0;
    }
}
@media screen and (max-width: 1024px) {
    main,
    .main {
        margin-bottom: 48px;
    }
}
main-not-found,
.main-not-found {
    margin: 0;
}

.cover {
    object-fit: cover;
}

.skeleton {
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.skeleton:empty:before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-position-x: 180%;
    animation: shine 1.5s ease infinite;
    background: linear-gradient(100deg, rgba(52, 44, 62, 0) 40%, rgba(52, 44, 62, 0.5) 50%, rgba(52, 44, 62, 0) 60%)
        #1e1529;
    background-position-x: 180%;
    background-size: 200% 100%;
    border-radius: inherit;
}

.auth-reg-form .error {
    margin-bottom: 16px;
}

@keyframes shine {
    to {
        background-position-x: -20%;
    }
}
.ghost-button {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 40px;
}

.blue-button {
    background: #005bff;
    border-radius: 80px;
    color: #fff;
}
.blue-button:not(:disabled):hover {
    background: #004bd2;
}

.h-3 {
    font-weight: 600;
    font-size: 24px;
}
.h-5 {
    font-weight: 600;
    font-size: 18px;
}

.bg-no {
    background-color: transparent;
}
.bg--white-opacity {
    background: rgba(255, 255, 255, 0.2);
}
.bg-dark-grey {
    background-color: #212121;
}
.bg-red {
    background-color: #ed0a34;
}
.bg-blue {
    background-color: #005bff;
}
.bg-pink {
    background-color: #fd4aa7;
}
.bg-150 {
    background-color: #281f34;
}
.bg-100 {
    background-color: #342c3e;
}
.bg-100-hover {
    transition: background-color 0.3s ease-in-out;
}
.bg-100-hover:hover {
    background-color: #342c3e;
}
.bg-200 {
    background-color: #1e1529;
}

.inline-block {
    display: inline-block;
}

.round {
    border-radius: 50%;
}

.svg-red path {
    fill: #ed0a34;
}

.mh-auto {
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
}
.flex1 {
    flex: 1;
}
.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-end {
    justify-content: flex-end;
}
.justify-start {
    justify-content: flex-start;
}

.align-top {
    align-items: flex-start;
}
.align-bottom {
    align-items: flex-end;
}
.align-middle {
    align-items: center;
}
.align-stretch {
    align-items: stretch;
}
@media screen and (max-width: 1024px) {
    .align-m-top {
        align-items: flex-start;
    }
}

.rotate-90 {
    transform: rotate(90deg);
}
.rotate-180 {
    transform: rotate(180deg);
}

body {
    margin: 0;
    padding: 0;
    font-family: "Averta CY";
    background: #0c0216;
    color: #fff;
}
body a {
    text-decoration: none;
    color: #fff;
}
body .section-title {
    position: relative;
    font-size: 40px;
    margin-bottom: 34px;
    min-height: 49px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
body .section-title h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
body .section-title .filter-btn {
    display: none;
}
body .section-title svg {
    margin-left: 8px;
    margin-top: 7px;
}
@media screen and (max-width: 1024px) {
    body .section-title {
        font-size: 24px;
        margin-bottom: 24px;
        min-height: 29px;
        width: 100%;
    }
    body .section-title svg {
        margin-left: 8px;
        display: block;
        margin-top: 0;
    }
    body .section-title a {
        position: absolute;
        display: flex;
        height: 30px;
        align-items: center;
        right: 0;
        font-size: 0;
        bottom: 0;
    }
    body .section-title .filter-btn {
        display: flex;
    }
}
body * {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
body .slider-control-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 80px;
    cursor: pointer;
    transition: all 100ms ease-out;
}
body .slider-control-arrow.swiper-button-disabled {
    visibility: hidden;
}
body .slider-control-arrow:hover {
    width: 72px;
    height: 72px;
    margin: -4px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow:hover {
        width: 42px;
        height: 42px;
        margin: 0px;
    }
}
body .slider-control-arrow.slider-prev {
    left: 40px;
}
body .slider-control-arrow.slider-next {
    right: 40px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow {
        width: 42px;
        height: 42px;
    }
    body .slider-control-arrow.slider-prev {
        left: 6px;
    }
    body .slider-control-arrow.slider-next {
        right: 6px;
    }
}
@media screen and (max-width: 768px) {
    body .slider-control-arrow svg {
        width: 14px;
        height: 14px;
    }
}
body .slider-control-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 80px;
    cursor: pointer;
    transition: all 100ms ease-out;
}
body .slider-control-arrow.swiper-button-disabled {
    visibility: hidden;
}
body .slider-control-arrow:hover {
    width: 72px;
    height: 72px;
    margin: -4px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow:hover {
        width: 42px;
        height: 42px;
        margin: 0px;
    }
}
body .slider-control-arrow.slider-prev {
    left: 40px;
}
body .slider-control-arrow.slider-next {
    right: 40px;
}
@media screen and (max-width: 1024px) {
    body .slider-control-arrow {
        width: 42px;
        height: 42px;
    }
    body .slider-control-arrow.slider-prev {
        left: 6px;
    }
    body .slider-control-arrow.slider-next {
        right: 6px;
    }
}
body button.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0 32px;
    font-size: 15px;
    outline: none;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
}
body button.button.ghost-button {
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: none;
    border-radius: 40px;
    color: #fff;
    height: 48px;
}
body button.button.ghost-button:hover {
    border: 2px solid rgb(255, 255, 255);
}
body .select-list {
    position: relative;
    display: block;
    height: 24px;
}
body .select-list .select-list-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    opacity: 0.5;
}
@media screen and (max-width: 1024px) {
    body .select-list .select-list-title {
        font-size: 13px;
    }
}
body .select-list .select-list-title:hover {
    opacity: 1;
}
body .select-list .select-list-title svg:first-child {
    margin-right: 8px;
}
body .select-list.active .select-list-items {
    display: block;
}
body .select-list.active .select-list-title {
    opacity: 1;
}
body .select-list.active .select-list-title .select-arrow {
    transform: rotate(180deg);
}
body .select-list .select-list-items {
    position: absolute;
    display: none;
    overflow: hidden;
    top: 30px;
    left: 0;
    z-index: 1;
    z-index: 10;
    padding: 8px 0;
    background: #1e1529;
    box-shadow: 0px 8px 24px rgba(12, 2, 22, 0.8);
    border-radius: 16px;
}
body .select-list .select-list-items.right-position {
    right: 0;
    left: auto;
}
@media screen and (max-width: 1024px) {
    body .select-list .select-list-items.right-position {
        right: auto;
        left: 0;
    }
}
body .select-list .select-list-items .select-list-item {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 40px;
    padding: 0 60px 0 16px;
    box-sizing: border-box;
    min-width: 255px;
    cursor: pointer;
}
body .select-list .select-list-items .select-list-item:hover {
    background: #342c3e;
}

body .container {
    position: relative;
    margin: 0 72px;
}
@media screen and (max-width: 1024px) {
    body .container {
        margin: 0 16px;
    }
}
body .header--border {
    border-bottom: 1px solid #302839;
}
@media screen and (max-width: 1024px) {
    body .header--border {
        margin-bottom: 24px;
    }
}
body .header__user-avatar {
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
}
body .header__user-avatar-placeholder {
    opacity: 0.5;
}
body .header__user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (max-width: 1024px) {
    body .header__user-avatar {
        opacity: 1;
        background: unset;
    }
}
body header {
    position: relative;
    display: block;
    height: 72px;
    margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
    body header {
        height: 64px;
        border-bottom: 0;
    }
}
body header .container {
    position: relative;
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body header .container {
        height: 64px;
    }
}
body header .container .icon-btn {
    opacity: 0.5;
}
body header .container .icon-btn:hover {
    opacity: 1;
}
@media screen and (max-width: 1024px) {
    body header .container .icon-btn {
        opacity: 1;
    }
}
body header .container .header-right {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    font-size: 0;
}
body header .container .header-right-mobile {
    display: none;
}
@media screen and (max-width: 1024px) {
    body header .container .header-right-mobile {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
        font-size: 0;
    }
    body header .container .header-right-mobile #burger-btn img:nth-child(2) {
        display: none;
    }
}
body header .container .header-left {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    align-items: center;
    font-size: 0;
    flex: 1;
}
body header .container .header-left a#header-logo {
    margin-right: 28px;
    opacity: 1;
    font-size: 0;
}
@media screen and (max-width: 1024px) {
    body header .container .header-left a#header-logo img {
        width: 94px;
    }
}
body header .container .header-left a:hover {
    opacity: 1;
}
body header .container .header-left .header-links {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    height: 28px;
}
body header .container .header-left .header-links-left {
    display: flex;
    gap: 24px;
}
body header .container .header-left .header-links__item {
    position: relative;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    opacity: 0.5;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
body header .container .header-left .header-links__item:hover {
    opacity: 1;
}
body header .container .header-left .header-links__item--active {
    opacity: 1;
}
body header .container .header-left .header-links .menu-social-links {
    display: none;
}
@media screen and (max-width: 1024px) {
    body header .container .header-left .header-links {
        position: fixed;
        overflow: hidden;
        right: 0;
        top: 0;
        bottom: 0;
        left: -100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;
        width: 100%;
        height: calc(100% - 64px);
        background: #0c0216;
        z-index: 10;
        top: 64px;
        padding: 32px 16px;
        box-sizing: border-box;
        transition: all 400ms ease-out;
    }
    body header .container .header-left .header-links__item {
        font-size: 20px;
        opacity: 1;
        cursor: pointer;
    }
    body header .container .header-left .header-links .menu-social-links {
        position: absolute;
        bottom: 32px;
        left: 16px;
        display: flex;
        gap: 8px;
        justify-content: space-between;
    }
    body header .container .header-left .header-links .menu-social-links a {
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 80px;
        display: flex;
        height: 48px;
        justify-content: center;
        opacity: 0.5;
        position: relative;
        width: 48px;
    }
}
body header #profile-header-menu {
    position: absolute;
    display: none;
    right: -62px;
    top: 10px;
    width: 280px;
    box-sizing: border-box;
    height: auto;
    background: #1e1529;
    border-radius: 24px;
    z-index: 10;
    padding: 16px;
}
body header #profile-header-menu.active {
    display: block;
}
@media screen and (max-width: 1024px) {
    body header #profile-header-menu {
        right: -10px;
    }
}
body header #profile-header-menu .avatar-username {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
    font-size: 15px;
    margin-bottom: 16px;
}
body header #profile-header-menu .avatar-username img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
body header #profile-header-menu .avatar-username p {
    max-width: 170px;
}
body header #profile-header-menu .avatar-username p span {
    display: block;
    word-break: break-all;
}
body header #profile-header-menu .profile-header-menu-items {
    position: relative;
    display: block;
}
body header #profile-header-menu .profile-header-menu-items a {
    width: 100%;
    position: relative;
    display: block;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    line-height: 40px;
}
body header #profile-header-menu .profile-header-menu-items a svg {
    position: absolute;
    display: block;
    right: 16px;
    top: calc(50% - 12px);
}
body header #profile-header-menu .profile-header-menu-items a:hover {
    background: #342c3e;
}
@media screen and (max-width: 1024px) {
    body.open-menu {
        padding-top: 104px;
        overflow: hidden !important;
    }
    body.open-menu header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        background: #0c0216;
    }
    body.open-menu header .container .header-links {
        left: 0;
    }
    body.open-menu header .container .header-links-left {
        gap: 12px;
        flex-direction: column;
    }
    body.open-menu header .container .header-right-mobile #burger-btn img:nth-child(1) {
        display: none;
    }
    body.open-menu header .container .header-right-mobile #burger-btn img:nth-child(2) {
        display: block;
    }
}
body section {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
    body section {
        overflow: hidden;
    }
}
body section#main-menu {
    height: 72px;
    background: #1e1529;
    margin-bottom: 40px;
    margin-top: -41px;
}
@media screen and (max-width: 1024px) {
    body section#main-menu {
        height: 56px;
        margin-top: -41px;
        margin-bottom: 24px;
        white-space: nowrap;
        width: 100%;
        overflow-x: scroll;
    }
    body section#main-menu::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
}
body section#main-menu .container {
    display: flex;
    align-items: center;
    height: 72px;
    gap: 24px;
}
@media screen and (max-width: 1024px) {
    body section#main-menu .container {
        height: 56px;
        width: auto;
        padding-right: 16px;
    }
    body section#main-menu .container a:last-child {
        padding-right: 16px;
    }
}
body section#main-menu .container a {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.5;
    line-height: 34px;
    display: flex;
    align-items: center;
}
body section#main-menu .container a svg {
    vertical-align: middle;
    margin-right: 4px;
}
body section#main-menu .container a.round-btn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    padding: 0 16px;
    opacity: 1;
}
body section#main-menu .container a:hover {
    opacity: 1;
}
body section.film-list {
    margin-bottom: 48px;
    padding: 0 72px;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section.film-list {
        padding: 0 16px;
        margin-bottom: 32px;
    }
    body section.film-list .slider-control-arrow {
        display: none;
    }
}
body section.film-list.profile-film-list {
    padding: 0;
    overflow: visible;
}
body section.film-list .film-list-filters {
    margin-bottom: 40px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
}
@media screen and (max-width: 1024px) {
    body section.film-list .film-list-filters {
        display: none;
    }
}
body section.film-list .swiper-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 40px 24px;
    flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
    body section.film-list .swiper-wrapper {
        gap: 24px 8px;
    }
}
body section.film-list .swiper-wrapper .film-list-element {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: 0;
    transition: opacity 300ms linear;
    margin-bottom: 16px;
}
body section.film-list .swiper-wrapper .film-list-element span {
    position: relative;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: calc((100% - 144px) / 4 - 18px);
}
@media screen and (max-width: 1024px) {
    body section.film-list .swiper-wrapper .film-list-element span {
        width: calc((100% - 32px) / 2 - 4px);
        font-size: 14px;
    }
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres a {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 13px;
    padding: 0 10px;
    line-height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}
body section.film-list .swiper-wrapper .film-list-element .film-list-element-genres a:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}
body section.film-list .show-more-block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
@media screen and (max-width: 998px) {
    body section.film-list .show-more-block {
        margin-top: 32px;
    }
    body section.film-list .show-more-block button {
        width: 100%;
    }
}
body section.film-list.profile-film-list {
    padding: 0;
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider:after {
        display: none;
    }
    body section.film-list.with-slider:before {
        display: none;
    }
}
body section.film-list.with-slider.full-format:before {
    width: 28vw;
}
body section.film-list.with-slider .swiper-wrapper {
    display: block;
    white-space: nowrap;
    font-size: 0;
}
body section.film-list.with-slider.small-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card {
        width: 67.7vw;
    }
}
body section.film-list.with-slider.small-format .swiper-wrapper .film-card .img-wrap {
    height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card .img-wrap {
        height: 38vw;
    }
}
@media screen and (max-width: 1024px) {
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card .film-list-element-genres {
        display: flex;
    }
    body
        section.film-list.with-slider.small-format
        .swiper-wrapper
        .film-card
        .film-list-element-genres
        a:nth-child(2) {
        margin-bottom: 0px;
    }
    body section.film-list.with-slider.small-format .swiper-wrapper .film-card a:nth-child(2) {
        margin-bottom: 12px;
    }
}
body section.film-list.small-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card {
        width: calc(50vw - 20px);
    }
}
body section.film-list.small-format .swiper-wrapper .film-card .img-wrap {
    height: 11.86vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card .img-wrap {
        height: 25.39vw;
    }
}
@media screen and (max-width: 1024px) {
    body section.film-list.small-format .swiper-wrapper .film-card a:nth-child(2) {
        margin-bottom: 0;
    }
    body section.film-list.small-format .swiper-wrapper .film-card .film-list-element-genres {
        display: none;
    }
}
body section.film-list.small-format .slider-control-arrow {
    top: calc(83px + 5.93vw - 32px);
    z-index: 1;
}
body section.film-list.middle-format {
    min-height: calc(31.66vw + 50px);
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format {
        min-height: calc(65.6vw + 30px);
    }
}
body section.film-list.middle-format .swiper-container {
    min-height: 31.66vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-container {
        min-height: 65.6vw;
    }
}
body section.film-list.middle-format .swiper-wrapper .film-card {
    width: calc((100% - 72px) / 4);
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-wrapper .film-card {
        width: calc(50vw - 20px);
    }
}
body section.film-list.middle-format .swiper-wrapper .film-card .img-wrap {
    height: 31.66vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.middle-format .swiper-wrapper .film-card .img-wrap {
        height: 65.6vw;
    }
}
body section.film-list.middle-format .slider-control-arrow {
    top: calc(83px + 15.83vw - 32px);
    z-index: 1;
}
body section.film-list.big-format .swiper-wrapper .film-card {
    width: 44.16vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.big-format .swiper-wrapper .film-card {
        width: 86.7vw;
    }
}
body section.film-list.big-format .swiper-wrapper .film-card .img-wrap {
    width: 44.16vw;
    height: 25.55vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.big-format .swiper-wrapper .film-card .img-wrap {
        height: 48.67vw;
        width: 86.7vw;
    }
}
body section.film-list.big-format .slider-control-arrow {
    top: calc(83px + 12.775vw - 32px);
    z-index: 1;
}
body section.film-list.full-format .swiper-wrapper .film-card {
    width: 67vw;
    height: 37.98vw;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card {
        height: 52.9vw;
        width: 91vw;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card:hover img {
    transform: scale(1);
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-label {
    position: absolute;
    display: block;
    left: 16px;
    top: 16px;
    background: #cc5ef5;
    border-radius: 8px;
    padding: 0 12px;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 14px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-label {
        padding: 0 8px;
        font-size: 11px;
        line-height: 22px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info {
    position: absolute;
    display: block;
    left: 32px;
    bottom: 32px;
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info {
        left: 16px;
        bottom: 16px;
        font-size: 13px;
        line-height: 24px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info b {
        font-size: 24px;
        margin-bottom: 8px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info small {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info small {
        display: none;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    margin: 0 6px;
    top: -1px;
    opacity: 0.5;
    width: auto;
}
@media screen and (max-width: 1024px) {
    body section.film-list.full-format .swiper-wrapper .film-card .full-size-info span {
        font-size: 13px;
    }
}
body section.film-list.full-format .swiper-wrapper .film-card .full-size-info .top-film-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section.film-list.full-format .slider-control-arrow {
    top: calc(83px + 18.99vw - 32px);
}
body section.film-list.genres-format {
    min-height: 165px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format {
        min-height: 135px;
    }
}
body section.film-list.genres-format .swiper-container {
    height: 80px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .swiper-container {
        height: 80px;
    }
}
body section.film-list.genres-format .genres-card {
    width: 196px;
    height: 80px;
    background: #005bff;
    border-radius: 24px;
    line-height: 120px;
    text-align: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .genres-card {
        width: calc((100% - 32px) / 2 - 4px);
        line-height: 80px;
        border-radius: 12px;
    }
}
body section.film-list.genres-format .genres-card span {
    font-size: 18px;
    width: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.genres-format .genres-card span {
        font-size: 15px;
    }
}
body section.film-list.genres-format .slider-control-arrow {
    top: 55%;
}
body section.film-list.whatch-and-by-slider .section-title {
    padding-top: 102px;
    text-align: center;
    justify-content: center;
    margin: 0 auto 16px auto;
    display: block;
}
body section.film-list.whatch-and-by-slider .section-title h2 {
    justify-content: center;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .section-title {
        padding-top: 63px;
    }
    body section.film-list.whatch-and-by-slider .section-title a {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-text {
    position: relative;
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #fff;
    margin-bottom: 66px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-text {
        font-size: 14px;
        margin: 0 24px;
        margin-bottom: 32px;
    }
    body section.film-list.whatch-and-by-slider .watching-and-buy-text__desktop-text {
        display: none;
    }
    body section.film-list.whatch-and-by-slider .watching-and-buy-text br {
        position: relative;
        display: inline-block;
        content: "";
        width: 5px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer {
    position: absolute;
    background: linear-gradient(168deg, #005bff -17.4%, #0c0216 52%);
    height: 522px;
    border-radius: 24px;
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(1) {
    width: calc(100% - 246px);
    left: 123px;
    top: 0;
    background: linear-gradient(168deg, #0a1c5a -17.4%, #0c0216 52%);
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(1) {
        border-radius: 24px;
        left: 56px;
        width: calc(100% - 112px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(2) {
    width: calc(100% - 174px);
    left: 87px;
    top: 17px;
    background: linear-gradient(168deg, #06287c -17.4%, #0c0216 52%);
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(2) {
        border-radius: 24px;
        left: 40px;
        top: 13px;
        width: calc(100% - 80px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(3) {
    width: calc(100% - 96px);
    left: 48px;
    top: 42px;
    border-radius: 32px;
}
@media screen and (max-width: 1024px) {
    body section.film-list.whatch-and-by-slider .watching-and-buy-back .watching-and-buy-back-layer:nth-child(3) {
        border-radius: 24px;
        left: 16px;
        top: 26px;
        width: calc(100% - 32px);
        height: 177px;
    }
}
body section.film-list.whatch-and-by-slider .slider-control-arrow {
    top: calc(256px + 6.11vw);
}
body section#top-films {
    position: relative;
    display: block;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section#top-films {
        margin-bottom: 32px;
    }
}
body section#top-films .swiper-wrapper,
body section#top-films .swiper-container {
    height: 35.55vw;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper,
    body section#top-films .swiper-container {
        height: 50vw;
    }
    body section#top-films .swiper-wrapper .slider-control-arrow,
    body section#top-films .swiper-container .slider-control-arrow {
        top: calc(50% - 21px);
    }
}
body section#top-films .swiper-wrapper {
    position: relative;
    display: block;
    white-space: nowrap;
    font-size: 0;
}
body section#top-films .swiper-wrapper .top-film-slide {
    background-color: #1e1529;
    position: relative;
    display: inline-block;
    display: inline-flex !important;
    top: 0;
    margin: 0 -1.04vw;
    width: 62.77vw;
    height: 35.55vw;
    border-radius: 24px;
    overflow: hidden;
    opacity: 0.3;
    transform: scale(0.8849);
}
body section#top-films .swiper-wrapper .top-film-slide:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 55.7%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide {
        width: calc(100% - 32px);
        margin: 0 -1.2vw;
        transform: scale(0.92);
        border-radius: 16px;
        height: 50vw;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-label {
    position: absolute;
    z-index: 3;
    display: block;
    opacity: 0;
    left: 16px;
    top: 16px;
    background: #fd4aa7;
    border-radius: 8px;
    padding: 0 12px;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 14px;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-label {
        padding: 0 8px;
        font-size: 11px;
        line-height: 22px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info {
    position: absolute;
    display: block;
    opacity: 0;
    left: 32px;
    bottom: 32px;
    font-size: 14px;
    line-height: 22px;
    z-index: 3;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info {
        left: 16px;
        bottom: 16px;
        font-size: 13px;
        line-height: 24px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
    white-space: break-spaces;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info b {
        font-size: 20px;
        margin-bottom: 0px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
}
@media screen and (max-width: 1024px) {
    body section#top-films .swiper-wrapper .top-film-slide .top-film-info span {
        font-size: 13px;
    }
}
body section#top-films .swiper-wrapper .top-film-slide .top-film-info .top-film-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section#top-films .swiper-wrapper .top-film-slide.transition {
    transition: 300ms linear;
    transition-property: opacity, transform;
}
body section#top-films .swiper-wrapper .top-film-slide.transition .top-film-label {
    transition: 300ms linear;
}
body section#top-films .swiper-wrapper .top-film-slide.transition .top-film-info {
    transition: 300ms linear;
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active {
    opacity: 1;
    transform: scale(1);
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active .top-film-label {
    opacity: 1;
}
body section#top-films .swiper-wrapper .top-film-slide.swiper-slide-active .top-film-info {
    opacity: 1;
}
body section#top-films .swiper-wrapper .top-film-slide .img-wrap {
    position: relative;
}
body section#top-films .swiper-wrapper .top-film-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body section#all-categories {
    margin-bottom: 48px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories {
        margin-bottom: 24px;
    }
}
body section#all-categories .categories-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 48px 24px;
    flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list {
        gap: 32px 8px;
        margin-bottom: 14px;
    }
}
body section#all-categories .categories-list .categories-list-item {
    position: relative;
    display: block;
    width: calc(25% - 18px);
    height: calc(13.4vw - 18px);
    border-radius: 16px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item {
        width: 44.44vw;
        height: 25.39vw;
        border-radius: 12px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1-img {
    position: absolute;
    display: block;
    top: 0;
    left: 10%;
    width: 80%;
    height: 12.22vw;
    border-radius: 8px;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1-img {
    width: 80%;
    height: 12.22vw;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
    position: absolute;
    display: block;
    top: 8px;
    left: 5%;
    width: 90%;
    height: 12.22vw;
    border-radius: 12px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-back2,
    body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
        top: 7px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2-img {
    width: 90%;
    height: 12.22vw;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back1,
body section#all-categories .categories-list .categories-list-item .categories-list-item-back2 {
    transition: top 0.3s ease-in-out;
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    top: 16px;
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-info {
        top: 14px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info span {
    position: absolute;
    display: block;
    top: 1.66vw;
    left: 1.66vw;
    font-size: 1.25vw;
    line-height: 1.625vw;
    max-width: 111px;
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list .categories-list-item .categories-list-item-info span {
        left: 16px;
        top: 16px;
        font-size: 14px;
        line-height: 17px;
    }
}
body section#all-categories .categories-list .categories-list-item .categories-list-item-info img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    transition: all 300ms ease-out;
}
body
    section#all-categories
    .categories-list
    .categories-list-item
    .categories-list-item-info
    .categories-list-item-back3 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-info {
    top: 13px;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-info img {
    transform: scale(1.015);
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-back1 {
    top: -5px;
}
body section#all-categories .categories-list .categories-list-item:hover .categories-list-item-back2 {
    top: 3px;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color1 .categories-list-item-back3 {
    background: linear-gradient(95.42deg, #005bff 37.93%, rgba(0, 91, 255, 0) 97.39%);
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back1 {
    background: #aa0c5f;
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back2 {
    background: #cb1d78;
}
body section#all-categories .categories-list .categories-list-item.color2 .categories-list-item-back3 {
    background: linear-gradient(94.75deg, #e33b92 37.83%, rgba(227, 59, 146, 0) 97.52%);
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back1 {
    background: #28a1aa;
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back2 {
    background: #34b8c2;
}
body section#all-categories .categories-list .categories-list-item.color3 .categories-list-item-back3 {
    background: linear-gradient(96.41deg, #3ec2cc 38.08%, rgba(122, 216, 223, 0) 97.3%);
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back1 {
    background: #c55809;
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back2 {
    background: #e9741f;
}
body section#all-categories .categories-list .categories-list-item.color4 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #ff9e00 38%, rgba(255, 158, 0, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back1 {
    background: #a90e2b;
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back2 {
    background: #ce1b3d;
}
body section#all-categories .categories-list .categories-list-item.color5 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #eb2b4f 38%, rgba(235, 43, 79, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back1 {
    background: #b98500;
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back2 {
    background: #d9a00e;
}
body section#all-categories .categories-list .categories-list-item.color6 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #e6b711 38%, rgba(229, 183, 17, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back1 {
    background: #3891cd;
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back2 {
    background: #4dabeb;
}
body section#all-categories .categories-list .categories-list-item.color7 .categories-list-item-back3 {
    background: linear-gradient(96.17deg, #60bfff 38.04%, rgba(96, 191, 255, 0) 97.31%);
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back1 {
    background: #6c138b;
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back2 {
    background: #8719ad;
}
body section#all-categories .categories-list .categories-list-item.color8 .categories-list-item-back3 {
    background: linear-gradient(96.87deg, #9e20ca 38.14%, rgba(158, 32, 202, 0) 115.87%);
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back1 {
    background: #4c0f90;
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back2 {
    background: #601bad;
}
body section#all-categories .categories-list .categories-list-item.color9 .categories-list-item-back3 {
    background: linear-gradient(95.81deg, #7027c2 37.99%, rgba(112, 39, 194, 0) 108.13%);
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color10 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back1 {
    background: #aa0c5f;
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back2 {
    background: #cb1d78;
}
body section#all-categories .categories-list .categories-list-item.color11 .categories-list-item-back3 {
    background: linear-gradient(96.68deg, #e33b92 38.12%, rgba(227, 59, 146, 0) 104.05%);
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back1 {
    background: #b98500;
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back2 {
    background: #d9a00e;
}
body section#all-categories .categories-list .categories-list-item.color12 .categories-list-item-back3 {
    background: linear-gradient(98.16deg, #e6b711 38.33%, rgba(229, 183, 17, 0) 103.08%);
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back1 {
    background: #087554;
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back2 {
    background: #09946a;
}
body section#all-categories .categories-list .categories-list-item.color13 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #10bc88 38%, rgba(16, 188, 136, 0) 108.42%);
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back1 {
    background: #a90e2b;
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back2 {
    background: #ce1b3d;
}
body section#all-categories .categories-list .categories-list-item.color14 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #eb2b4f 38%, rgba(235, 43, 79, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back1 {
    background: #6c138b;
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back2 {
    background: #8719ad;
}
body section#all-categories .categories-list .categories-list-item.color15 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #9e20ca 38%, rgba(158, 32, 202, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back1 {
    background: #003dac;
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back2 {
    background: #004dd7;
}
body section#all-categories .categories-list .categories-list-item.color16 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #005bff 38%, rgba(0, 91, 255, 0) 121.98%);
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back1 {
    background: #c55809;
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back2 {
    background: #e9741f;
}
body section#all-categories .categories-list .categories-list-item.color17 .categories-list-item-back3 {
    background: linear-gradient(95.92deg, #ff9e00 38%, rgba(255, 158, 0, 0) 121.98%);
}
@media screen and (max-width: 1024px) {
    body section#all-categories .categories-list.swiper-wrapper {
        display: block;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    body section#all-categories .categories-list.swiper-wrapper .categories-list-item {
        display: inline-block;
    }
}
body section.film-top {
    height: 41.6vw;
    margin-top: -1px;
    margin-bottom: 64px;
}
body section.film-top .over-cover {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-size: cover;
    width: 72vw;
}
@media screen and (max-width: 1024px) {
    body section.film-top {
        margin-top: -41px;
        padding-top: 57.4vw;
        height: auto;
        background-size: 100% 57.4vw;
        margin-bottom: 48px;
    }
    body section.film-top .over-cover {
        height: 57.4vw;
        left: 0;
        width: 100%;
    }
}
body section.film-top .previous-page {
    display: none;
}
@media screen and (max-width: 1024px) {
    body section.film-top .previous-page {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        left: 16px;
        top: 16px;
        width: 42px;
        height: 42px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 80px;
    }
    body section.film-top .previous-page img {
        object-fit: contain;
        width: 24px;
        height: 24px;
    }
}
body section.film-top .container {
    height: 100%;
}
body section.film-top .film-bookmarks {
    position: absolute;
    display: flex;
    gap: 8px;
    top: 0;
    font-weight: 600;
    font-size: 13px;
    height: 22px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-bookmarks {
        display: none;
    }
}
body section.film-top .film-bookmarks a {
    color: #fff;
    opacity: 0.4;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
}
body section.film-top .film-bookmarks a:hover {
    opacity: 1;
}
body section.film-top .film-top-info {
    position: absolute;
    display: block;
    bottom: 48px;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    padding-bottom: 56px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info {
        position: relative;
        bottom: auto;
        margin-top: 8px;
        padding-bottom: 0;
    }
}
body section.film-top .film-top-info .film-title {
    position: relative;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-title {
        font-size: 28px;
        line-height: 32px;
    }
}
body section.film-top .film-top-info .blogger-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 3px;
    position: relative;
    width: max-content;
}
body section.film-top .film-top-info .blogger-name::after,
body section.film-top .film-top-info .blogger-name:before {
    position: absolute;
    top: 50%;
    right: -10px;
}
body section.film-top .film-top-info .blogger-name:after {
    content: "";
    background: #fd4aa7;
    border-radius: 80px;
    width: 24px;
    height: 24px;
    transform: translate(100%, -50%);
}
body section.film-top .film-top-info .film-rating-age-genre {
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-rating-age-genre {
        margin-bottom: 24px;
    }
}
body section.film-top .film-top-info .film-rating-age-genre b {
    position: relative;
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
}
body section.film-top .film-top-info .film-rating-age-genre span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
}
body section.film-top .film-top-info .film-rating-age-genre .raing-age {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: 24px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 8px;
}
body section.film-top .film-top-info .film-rating-age-genre .raing-age.green {
    border: 2px solid #0dc268;
}
body section.film-top .film-top-info .film-top-actors {
    position: relative;
    display: block;
    margin-top: 32px;
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-actors {
        margin-top: 24px;
        margin-bottom: 0;
    }
}
body section.film-top .film-top-info .film-top-actors .film-top-actor {
    position: relative;
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 8px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px 0 6px;
    background: #1e1529;
    border-radius: 80px;
    color: rgba(255, 255, 255, 0.8);
}
body section.film-top .film-top-info .film-top-actors .film-top-actor--no-img {
    padding-left: 16px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-actors .film-top-actor {
        font-size: 13px;
    }
}
body section.film-top .film-top-info .film-top-actors .film-top-actor__preview {
    border-radius: 80px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 2px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}
body section.film-top .film-top-info .film-top-actors .film-top-actor__preview img {
    object-fit: cover;
    width: 20px;
    height: 20px;
    border-radius: 80px;
    background-color: #1e1529;
}
body section.film-top .film-top-info .film-top-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 8px;
}
@media screen and (max-width: 1024px) {
    body section.film-top .film-top-info .film-top-buttons {
        position: relative;
        bottom: auto;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
}
body section.film-top .film-top-info .film-top-buttons .flex {
    display: flex;
    gap: 8px;
}
body section.film-top-blogger {
    height: 43.334vw;
}
@media screen and (max-width: 1024px) {
    body section.film-top-blogger {
        height: auto;
    }
}
body section.film-top-blogger .film-top-info {
    width: 100%;
    padding-bottom: 0;
}
body section.film-top-blogger .film-top-info .flex {
    gap: 8px;
}
@media screen and (max-width: 1024px) {
    body section.film-top-blogger .film-top-info {
        padding-bottom: 0;
    }
}
body section.film-top-blogger .film-top-info__bloger-avatar {
    margin-right: 22px;
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}
body section.film-top-blogger .film-top-info__bloger-avatar img {
    object-fit: cover;
    border-radius: 16px;
    background-color: #1e1529;
}
body section.film-top-blogger .film-top-buttons {
    bottom: 0;
    right: 0;
    left: unset;
}
body section.seasons-slider .slider-control-arrow {
    top: 3.6vw !important;
}
body section#seasons-list {
    margin-bottom: 48px;
}
@media screen and (max-width: 1024px) {
    body section#seasons-list {
        margin-bottom: 24px;
        overflow-x: scroll;
    }
    body section#seasons-list::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
}
@media screen and (max-width: 1024px) {
    body section#seasons-list .container {
        white-space: nowrap;
    }
}
body section#seasons-list .container a {
    position: relative;
    display: inline-block;
    margin-right: 40px;
    opacity: 0.5;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}
@media screen and (max-width: 1024px) {
    body section#seasons-list .container a {
        margin-right: 24px;
        font-size: 18px;
        line-height: 24px;
    }
}
body section#seasons-list .container a.active {
    opacity: 1;
}
body section#seasons-list .container a:hover {
    opacity: 1;
}
body section#film-description {
    margin-top: 108px;
    margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
    body section#film-description {
        margin-top: 8px;
        margin-bottom: 0;
    }
}
body section#film-description .container {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container {
        flex-direction: column-reverse;
    }
}
body section#film-description .container .film-info {
    position: relative;
    display: block;
    width: calc(100% - 134px - 416px);
    max-width: 1000px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info {
        width: auto;
    }
}
body section#film-description .container .film-info .film-info-bookmarks {
    position: relative;
    display: flex;
    gap: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-info-bookmarks {
        white-space: nowrap;
        overflow-x: scroll;
        padding: 0 16px;
        margin: 0 -16px;
        border-bottom: 0;
    }
    body section#film-description .container .film-info .film-info-bookmarks::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
    body section#film-description .container .film-info .film-info-bookmarks:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.15);
        content: "";
        top: calc(100% - 1px);
    }
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark {
    position: relative;
    font-size: 18px;
    cursor: pointer;
    padding-bottom: 20px;
    opacity: 0.5;
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark:hover {
    opacity: 1;
}
body section#film-description .container .film-info .film-info-bookmarks .film-info-bookmark.active {
    opacity: 1;
    border-bottom: 2px solid #005bff;
}
body section#film-description .container .film-info .film-info-pages {
    margin-top: 40px;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    margin-bottom: 58px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-info-pages {
        margin-bottom: 48px;
        margin-top: 24px;
    }
}
body section#film-description .container .film-info .film-info-pages div {
    white-space: break-spaces;
}
body section#film-description .container .film-info .film-crews {
    position: relative;
    display: flex;
    gap: 48px;
    flex-direction: column;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews {
        font-size: 14px;
        gap: 32px;
    }
}
body section#film-description .container .film-info .film-crews .film-crew {
    color: rgba(255, 255, 255, 0.8);
}
body section#film-description .container .film-info .film-crews .film-crew .film-crew-title {
    font-size: 18px;
    margin-bottom: 16px;
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews .film-crew .film-crew-title {
        margin-bottom: 8px;
    }
}
body section#film-description .container .film-info .film-crews .film-crew span {
    display: inline-block;
    font-size: 15px;
    margin: 0 6px;
    opacity: 0.5;
    position: relative;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews .film-crew span {
        font-size: 13px;
        margin: 0;
    }
}
body section#film-description .container .film-info .film-crews .film-crew-actors {
    margin-bottom: 16px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 18px;
    padding: 8px;
    padding-right: 24px;
    background: #1e1529;
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.8);
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor--no-img {
    padding-left: 24px;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor__preview {
    margin-right: 8px;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(218.75deg, #5177ff 2.82%, #a04bff 56.98%, #ff349d 108.92%);
    padding: 2px;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor__preview img {
    object-fit: cover;
    width: 44px;
    height: 44px;
    border-radius: 12px;
    background-color: #0c0216;
}
body section#film-description .container .film-info .film-crews .film-crew-actors .film-crew-actor p {
    opacity: 0.8;
}
body section#film-description .container .film-info .film-crews a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;
    height: 56px;
    box-sizing: border-box;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 80px;
}
@media screen and (max-width: 1024px) {
    body section#film-description .container .film-info .film-crews a {
        width: auto;
        height: 48px;
        margin-bottom: 12px;
    }
}
body section#film-description .container .film-info .film-crews a:hover {
    border: 2px solid rgb(255, 255, 255);
}
body section.user-profile {
    z-index: 1;
    padding-top: 40px;
    margin-bottom: 108px;
    margin-top: -41px;
    background: #0c0216;
}
@media screen and (max-width: 1024px) {
    body section.user-profile {
        padding-top: 16px;
    }
}
@media screen and (max-width: 768px) {
    body section.user-profile {
        margin-bottom: 0;
    }
}
body section.user-profile .film-list {
    margin-bottom: 0;
}
body section.user-profile .section-title {
    margin-bottom: 24px;
    line-height: 56px;
}
body section.user-profile .section-title .mobile-exit {
    display: none;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .section-title {
        line-height: 40px;
    }
    body section.user-profile .section-title .mobile-exit {
        display: block;
        height: 40px;
    }
}
body section.user-profile .profile-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 600px;
    column-gap: 11%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content {
        display: block;
    }
}
body section.user-profile .profile-content .profile-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
body section.user-profile .profile-content .profile-menu a {
    position: relative;
    display: block;
    white-space: nowrap;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
}
body section.user-profile .profile-content .profile-menu a span {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
    background: #342c3e;
    border-radius: 24px;
    margin-left: 8px;
}
body section.user-profile .profile-content .profile-menu a svg {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
}
body section.user-profile .profile-content .profile-menu a svg path {
    fill: rgba(255, 255, 255, 0.5);
}
body section.user-profile .profile-content .profile-menu a.active {
    color: #fff;
}
body section.user-profile .profile-content .profile-menu a.active svg path {
    fill: #fff;
}
body section.user-profile .profile-content .profile-menu a:hover {
    color: #fff;
}
body section.user-profile .profile-content .profile-menu a:hover svg path {
    fill: #fff;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-menu {
        background: #1e1529;
        height: 56px;
        flex-direction: row;
        align-items: center;
        padding: 0 16px;
        margin: 0 -16px;
        gap: 0;
        margin-bottom: 24px;
        overflow-x: scroll;
    }
    body section.user-profile .profile-content .profile-menu::-webkit-scrollbar {
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;
        height: 0;
    }
    body section.user-profile .profile-content .profile-menu a {
        font-size: 14px;
        padding: 0 16px;
        line-height: 40px;
        border-radius: 8px;
    }
    body section.user-profile .profile-content .profile-menu a.active {
        background: #342c3e;
    }
    body section.user-profile .profile-content .profile-menu a.active span {
        background: rgba(255, 255, 255, 0.2);
    }
    body section.user-profile .profile-content .profile-menu a.exit-btn {
        display: none;
    }
    body section.user-profile .profile-content .profile-menu a svg {
        display: none;
    }
}
body section.user-profile .profile-content .profile-data {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    height: max-content;
}
body section.user-profile .profile-content .profile-data .film-card,
body section.user-profile .profile-content .profile-data .blogger-channel-card {
    min-height: calc((100vw - 362px - 222px) / 4 * 9 / 16);
    width: calc(25% - 18px) !important;
}
body section.user-profile .profile-content .profile-data .film-card .img-wrap,
body section.user-profile .profile-content .profile-data .film-card .preview,
body section.user-profile .profile-content .profile-data .blogger-channel-card .img-wrap,
body section.user-profile .profile-content .profile-data .blogger-channel-card .preview {
    height: calc((100vw - 362px - 222px) / 4 * 9 / 16);
}
@media screen and (max-width: 1640px) {
    body section.user-profile .profile-content .profile-data .film-card,
    body section.user-profile .profile-content .profile-data .blogger-channel-card {
        width: calc((100% - 48px) / 3) !important;
        min-height: calc((100vw - 471px) / 3 * 9 / 16);
    }
    body section.user-profile .profile-content .profile-data .film-card .img-wrap,
    body section.user-profile .profile-content .profile-data .film-card .preview,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .img-wrap,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .preview {
        height: calc((100vw - 471px) / 3 * 9 / 16);
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data {
        width: 100%;
        flex-direction: column;
    }
    body section.user-profile .profile-content .profile-data .film-card,
    body section.user-profile .profile-content .profile-data .blogger-channel-card {
        width: calc(50vw - 20px) !important;
        min-height: 25.2224vw;
    }
    body section.user-profile .profile-content .profile-data .film-card .img-wrap,
    body section.user-profile .profile-content .profile-data .film-card .preview,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .img-wrap,
    body section.user-profile .profile-content .profile-data .blogger-channel-card .preview {
        height: 25.2224vw;
    }
}
body section.user-profile .profile-content .profile-data .show-more-block {
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .show-more-block .button {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .select-list {
    position: absolute;
    right: 0;
    top: 0;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .select-list {
        position: absolute;
        right: auto;
        left: 0;
    }
    body section.user-profile .profile-content .profile-data .select-list.list-sort {
        top: 32px;
    }
}
body section.user-profile .profile-content .profile-data .clear-all-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    right: 0;
    top: 0;
    opacity: 0.5;
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .clear-all-btn {
        font-size: 13px;
        right: 0;
    }
}
body section.user-profile .profile-content .profile-data .clear-all-btn:hover {
    opacity: 1;
}
body section.user-profile .profile-content .profile-data #upload-form {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1e1529;
    border-radius: 16px;
    width: 307px;
    height: 237px;
}
body section.user-profile .profile-content .profile-data #upload-form input {
    display: none;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin: 28px 0 16px 0;
    color: #fff;
    opacity: 0.5;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-size-hint {
    font-size: 13px;
    line-height: 21px;
    color: #fff;
    opacity: 0.5;
}
body section.user-profile .profile-content .profile-data #upload-form .upload-preview,
body section.user-profile .profile-content .profile-data #upload-form .upload-preview img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
@media screen and (max-width: 1280px) {
    body section.user-profile .profile-content .profile-data #upload-form {
        width: 100%;
        height: 176px;
        padding: 24px 0;
    }
    body section.user-profile .profile-content .profile-data #upload-form svg#upload-profile-preview {
        width: 88px;
        height: 88px;
    }
    body section.user-profile .profile-content .profile-data #upload-form .upload-text {
        font-size: 14px;
        margin-top: 16px;
        opacity: 0.5;
    }
}
body section.user-profile .profile-content .profile-data .ghost-button {
    margin-bottom: 37px;
}
@media screen and (max-width: 768px) {
    body section.user-profile .profile-content .profile-data .ghost-button {
        margin-bottom: 0;
    }
}
body section.user-profile .profile-content .profile-data h3 {
    margin: 0;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    order: -2;
    width: 100%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data h3 {
        font-size: 18px;
        line-height: 23px;
    }
}
body section.user-profile .profile-content .profile-data__left {
    width: 55.5%;
}
body section.user-profile .profile-content .profile-data__left .error {
    margin-top: 16px;
}
@media screen and (max-width: 1280px) {
    body section.user-profile .profile-content .profile-data__left {
        width: auto;
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data__left {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .profile-form {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
}
@media screen and (max-width: 768px) {
    body section.user-profile .profile-content .profile-data .profile-form__save-btn {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 0;
        z-index: 11;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .form-empty-divider {
    height: 41px;
    width: 100%;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .form-empty-divider {
        height: 0;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input {
    position: relative;
    width: calc(50% - 12px);
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-form-input {
        width: 100%;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.full-width {
    width: 100%;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.third-size {
    width: calc(33% - 12px);
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-form-input.third-size
    .select-list-item {
    min-width: 140px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input.double-third-size {
    width: calc(66% - 12px);
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input .select-list {
    position: relative;
    width: 100%;
    right: auto;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-form-input
    .select-list
    .select-list-title {
    width: 100%;
    box-sizing: border-box;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input label {
    position: relative;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    opacity: 0.4;
    margin-bottom: 5px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-form-input input {
    position: relative;
    display: block;
    width: 100%;
    background: #1e1529;
    border-radius: 8px;
    height: 56px;
    font-size: 15px;
    padding: 0 16px;
    outline: none;
    border: 0;
    box-sizing: border-box;
    color: #fff;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio input {
    display: none;
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    vertical-align: middle;
    margin-right: 21px;
    margin-bottom: 0;
    color: #fff;
    opacity: 1;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label {
        font-size: 13px;
        margin-right: 13px;
    }
}
body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label:before {
    cursor: pointer;
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-right: 15px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 80px;
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-form .profile-input-radio label:before {
        margin-right: 8px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-form
    .profile-input-radio
    input[type="radio"]:checked
    + label:before {
    background: #fff;
    border: #005bff solid 8px;
    box-sizing: border-box;
}
body section.user-profile .profile-content .profile-data .profile-tips-history-list {
    margin-bottom: 0;
    width: 100%;
}
body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: #1e1529;
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 24px 24px 24px 24px;
    box-sizing: border-box;
}
@media screen and (max-width: 1440px) {
    body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
        padding: 20px 16px 16px 16px;
        height: auto;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 1024px) {
    body section.user-profile .profile-content .profile-data .profile-tips-history-list .profile-tips-history-item {
        padding: 20px 16px 16px 16px;
        height: auto;
        flex-wrap: wrap;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product {
    width: 50%;
    flex: 1;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .product {
        width: 100%;
        margin-bottom: 16px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    white-space: break-spaces;
    word-break: break-all;
    margin-left: 16px;
    width: calc(100% - 80px - 16px);
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info
    div {
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-info
    strong {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 3px;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film {
    max-width: 40%;
    display: block;
    right: 24px;
    top: 21px;
    font-size: 13px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
    display: block;
    flex-wrap: wrap;
    flex-direction: column;
    width: 43%;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film_date-mobile {
    display: none;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film {
        width: 100%;
        max-width: unset;
        padding-top: 16px;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film_date {
        display: none;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film_date-mobile {
        display: block;
        font-size: 13px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 0;
        width: 100%;
        min-height: 21px;
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film:after {
        position: absolute;
        display: block;
        width: calc(100% + 32px);
        height: 1px;
        left: -16px;
        top: 0;
        background: #342c3e;
        content: "";
    }
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        img {
        order: -1;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    img {
    display: block;
    right: 0;
    top: 0;
    width: 68px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        img {
        width: 80px;
        height: 48px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    p {
    height: max-content;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a {
    width: 100%;
    display: flex !important;
    align-items: flex-start;
    column-gap: 0;
    align-items: center;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    div {
    flex: 1;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    p {
    width: unset;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    .one-line,
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    .two-lines {
    display: -webkit-box !important;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film {
        right: auto;
        left: 16px;
        text-align: left;
        top: 14px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    a {
    display: block !important;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a {
    position: relative;
    display: block;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        a {
        margin-top: 0;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a:hover {
    color: #fff;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    a
    b {
    position: relative;
    display: block;
    font-size: 14px;
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    .one-line,
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .profile-tips-history-film
    div
    .two-lines {
    display: -webkit-box !important;
    padding-right: 24px;
    width: unset;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        div
        .one-line,
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .profile-tips-history-film
        div
        .two-lines {
        padding-left: 16px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 12px;
}
@media screen and (max-width: 1024px) {
    body
        section.user-profile
        .profile-content
        .profile-data
        .profile-tips-history-list
        .profile-tips-history-item
        .product-image {
        left: 16px;
        top: 48px;
    }
}
body
    section.user-profile
    .profile-content
    .profile-data
    .profile-tips-history-list
    .profile-tips-history-item
    .product-image
    img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body footer .footer-columns {
    position: relative;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns {
        flex-wrap: wrap;
    }
}
body footer .footer-columns .footer-column {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column {
        width: 100%;
    }
}
@media screen and (max-width: 1024px) and (max-width: 1024px) {
    body footer .footer-columns .footer-column:nth-child(1) img {
        width: 94px;
    }
    body footer .footer-columns .footer-column:nth-child(1) a {
        margin-bottom: 24px;
    }
}
body footer .footer-columns .footer-column a {
    position: relative;
    display: block;
    margin-bottom: 14px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
}
body footer .footer-columns .footer-column a:hover {
    color: #fff;
}
body footer .footer-columns .footer-column a.write-us {
    height: 56px;
    margin-bottom: 32px;
    line-height: 56px;
    padding: 0 24px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 80px;
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column a.write-us {
        margin-bottom: 24px;
        height: 52px;
        line-height: 52px;
    }
}
body footer .footer-columns .footer-column a.write-us img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}
body footer .footer-columns .footer-column a.write-us:hover {
    border: 2px solid rgb(255, 255, 255);
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column a {
        margin-bottom: 8px;
        line-height: 24px;
    }
}
@media screen and (max-width: 1024px) {
    body footer .footer-columns .footer-column:nth-child(2) {
        width: 50%;
        margin-bottom: 40px;
    }
    body footer .footer-columns .footer-column:nth-child(3) {
        width: 50%;
    }
}
body footer .footer-columns .footer-column.contacts-column {
    width: 20%;
    min-width: 200px;
}
body footer .footer-columns .footer-column.contacts-column .footer-social-links {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
body footer .footer-columns .footer-column.contacts-column .footer-social-links a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0.5;
    border-radius: 80px;
}
body footer .footer-columns .footer-column.contacts-column .footer-social-links a:hover {
    opacity: 1;
}
body footer .footer-copyright {
    position: absolute;
    display: block;
    left: 72px;
    bottom: 48px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
}
body footer .footer-copyright a {
    position: relative;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin-top: 8px;
    margin-right: 24px;
}
body footer .footer-copyright a:hover {
    color: #fff;
}
@media screen and (max-width: 1024px) {
    body footer .footer-copyright {
        left: 16px;
        bottom: 24px;
        line-height: 25px;
    }
    body footer .footer-copyright a {
        margin-top: 4px;
        width: calc(100% - 16px);
        margin-right: 0;
    }
}
body #modal {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background: rgba(12, 2, 22, 0.8);
}
body #modal.active {
    display: flex;
}
body #modal.with-scroll {
    align-items: flex-start;
    padding: 30px 0 40px 0;
    overflow-y: scroll;
}
@media screen and (max-width: 768px) {
    body #modal.with-scroll {
        padding: 0;
        align-items: stretch;
    }
}
@media screen and (max-width: 768px) {
    body #modal {
        overflow-y: scroll;
        align-items: stretch;
    }
}
body #modal .modal-content {
    position: relative;
    padding-bottom: 60px;
    max-width: 100%;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content {
        padding-bottom: 0;
    }
}
body #modal .modal-content .modal-block {
    position: relative;
    background: #1e1529;
    border-radius: 24px;
    box-sizing: border-box;
    margin: 0 auto;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block {
        border-radius: 0;
        padding: 30px;
        min-height: 100%;
    }
}
body #modal .modal-content .modal-block.forgot-form {
    font-weight: 400;
    font-size: 13px;
}
body #modal .modal-content .modal-block.forgot-form strong {
    position: relative;
    display: block;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: -8px;
    width: 100%;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block.forgot-form {
        height: 100%;
        padding-top: 50px;
    }
}
body #modal .modal-content .modal-block .modal-block-tumbler {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 328px;
    height: 56px;
    margin: 0 auto 40px;
    border: 2px solid #342c3e;
    border-radius: 12px;
    box-sizing: border-box;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-block-tumbler {
        width: 260px;
    }
}
body #modal .modal-content .modal-block .modal-block-tumbler .modal-tumbler-bg {
    position: absolute;
    display: block;
    width: calc(50% - 2px);
    left: 2px;
    top: 2px;
    background: #342c3e;
    border-radius: 8px;
    height: 48px;
    transition: all 300ms ease-out;
}
body #modal .modal-content .modal-block .modal-block-tumbler.register .modal-tumbler-bg {
    left: 50%;
}
body #modal .modal-content .modal-block .modal-block-tumbler span {
    cursor: pointer;
    position: relative;
    display: block;
    width: 50%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-block-tumbler span {
        font-size: 15px;
    }
}
body #modal .modal-content .modal-block .close-modal {
    position: absolute;
    display: block;
    right: 16px;
    top: 12px;
    cursor: pointer;
    opacity: 0.5;
}
body #modal .modal-content .modal-block .close-modal svg {
    width: 40px;
    height: 40px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .close-modal {
        right: 0;
        top: 0;
    }
}
body #modal .modal-content .modal-block .close-modal:hover {
    opacity: 1;
}
body #modal .modal-content .modal-block .modal-form form {
    position: relative;
    display: none;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    width: 400px;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form form {
        width: auto;
    }
}
body #modal .modal-content .modal-block .modal-form form.active {
    display: flex;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 37px;
    color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form form .modal-form-button {
        padding: 0;
    }
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button button {
    width: 240px;
    text-align: center;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button--tink {
    font-size: 0;
    height: 56px;
    text-align: center;
}
body #modal .modal-content .modal-block .modal-form form .modal-form-button--tink img {
    width: 240px;
}
body #modal .modal-content .modal-block .modal-form form small {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 8px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}
body #modal .modal-content .modal-block .modal-form form small a {
    color: rgba(255, 255, 255, 0.8);
}
body #modal .modal-content .modal-block .modal-form form small a:hover {
    color: #fff;
}
body #modal .modal-content .modal-block .modal-form .form-empty-divider {
    height: 41px;
    width: 100%;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input {
    position: relative;
    width: 100%;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.third-size {
    width: calc(33% - 12px);
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.double-third-size {
    width: calc(66% - 12px);
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input input {
    display: none;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input label {
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    vertical-align: middle;
    margin-right: 21px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
    padding-left: 33px;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input label:before {
    content: "";
    display: block;
    position: absolute;
    vertical-align: middle;
    top: -2px;
    margin-right: 12px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid #342c3e;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input.checkbox-input label a {
    position: relative;
    display: inline-block;
}
body
    #modal
    .modal-content
    .modal-block
    .modal-form
    .modal-form-input.checkbox-input
    input[type="checkbox"]:checked
    + label:before {
    // background: #005bff url("../public/static/img/check-mark.svg") no-repeat 50% 50%;
    border: #005bff solid 2px;
    box-sizing: border-box;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input a {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
}
body #modal .modal-content .modal-block .modal-form .modal-form-input a:hover {
    color: #fff;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input .select-list {
    position: relative;
    width: 100%;
    right: auto;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input .select-list .select-list-title {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #342c3e;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input label {
    position: relative;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    opacity: 0.4;
    margin-bottom: 5px;
}
body #modal .modal-content .modal-block .modal-form .modal-form-input input {
    position: relative;
    display: block;
    width: 100%;
    background: #1e1529;
    border-radius: 8px;
    height: 56px;
    font-size: 15px;
    padding: 0 16px;
    outline: none;
    border: 2px solid #342c3e;
    box-sizing: border-box;
    color: #fff;
}
body #modal .modal-content .modal-block .modal-form .modal-input-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
}
body #modal .modal-content .modal-block .modal-form .modal-input-radio input {
    display: none;
}
body #modal .modal-content .modal-block .modal-form .modal-input-radio label {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    vertical-align: middle;
    margin-right: 21px;
    margin-bottom: 0;
    color: #fff;
    opacity: 1;
    white-space: nowrap;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form .modal-input-radio label {
        font-size: 13px;
        margin-right: 13px;
    }
}
body #modal .modal-content .modal-block .modal-form .modal-input-radio label:before {
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-right: 12px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 80px;
    box-sizing: border-box;
    border: 2px solid #342c3e;
}
@media screen and (max-width: 768px) {
    body #modal .modal-content .modal-block .modal-form .modal-input-radio label:before {
        margin-right: 8px;
    }
}
body #modal .modal-content .modal-block .modal-form .modal-input-radio input[type="radio"]:checked + label:before {
    background: #fff;
    border: #005bff solid 8px;
    box-sizing: border-box;
}
body #mobile-filter {
    position: fixed;
    z-index: 4;
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #0c0216;
}
@media screen and (max-width: 1024px) {
    body #mobile-filter {
        display: block;
        left: -100%;
        transition: all 400ms ease-out;
    }
    body #mobile-filter.active {
        left: 0;
    }
}
body #mobile-filter #close-filter-btn {
    position: absolute;
    right: 16px;
    top: 16px;
}
body #mobile-filter h3 {
    position: relative;
    margin: 0;
}
body #mobile-filter h3 .reset-btn {
    position: absolute;
    display: block;
    right: 0;
    bottom: 2px;
    font-size: 13px;
    opacity: 0.5;
}
body #mobile-filter .input-list {
    margin-bottom: 24px;
}
body #mobile-filter .input-list .select-list-title {
    width: auto;
    font-size: 15px;
}
body #mobile-filter #set-filter {
    cursor: pointer;
    position: absolute;
    width: 344px;
    height: 56px;
    line-height: 56px;
    left: calc(50% - 172px);
    bottom: 24px;
    background: #005bff;
    border-radius: 80px;
    text-align: center;
}

.report-input-radio {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}
.report-input-radio input {
    display: none;
}
.report-input-radio label {
    font-style: normal;
    line-height: 160%;
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    margin-right: 21px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.4392156863);
    opacity: 1;
}
.report-input-radio label:before {
    opacity: 1;
    cursor: pointer;
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-right: 15px;
    left: 0;
    width: 24px;
    height: 24px;
    background: #1e1529;
    border-radius: 80px;
    border: 2px solid hsla(0deg, 0%, 100%, 0.5);
    box-sizing: border-box;
}
.report-input-radio input[type="radio"]:checked + label:before {
    background: #fff;
    border: #005bff solid 8px;
}

.region-select-field button.icon {
    cursor: pointer;
    padding: 0;
    right: 16px;
    top: calc(50% - 12px);
    position: absolute;
    width: 24px;
    height: 24px;
}
.region-select-field button.icon svg {
    height: 24px;
    width: 24px;
}
.region-select-field input {
    padding-right: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.region-select-field .loader {
    top: calc(50% - 12px);
    position: absolute;
    right: 8px;
}
.region-select-field .loader svg path {
    stroke: #fff;
}

.select-region-modal {
    width: 500px;
}
.select-region-modal .stack {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.select-region-modal .stack .input-list.select-list .select-list-title {
    width: auto;
    max-width: 100%;
}
.select-region-modal .stack .input-list.select-list .select-list-title input {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    background: transparent;
}
.select-region-modal .select-region__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .select-region-modal {
        width: 100%;
    }
}

// ---------
.about-page {
    .sellers-page__top-section {
        &-left {
            max-width: 525px;
        }
        &-right {
            width: 688px;
            height: 688px;
            @media screen and (max-width: 768px) {
                width: 100%;
                height: unset;
                top: 0;
            }
        }
        @media screen and (max-width: 768px) {
            padding-top: 390px;
        }
    }
    .creators-page__cabinet-section {
        &:before {
            // display: none;
            background-image: url("/public/images/about/bg.png");
            top: -294px;
        }
        h5 {
            max-width: 508px;
        }
        .point-2 {
            margin-top: -166px;
        }
        @media screen and (max-width: 968px) {
            li {
                width: 100%;
            }
            img {
                margin-bottom: 16px;
            }
            .point-2 {
                margin-top: unset;
            }
        }
    }
    &__video-section {
        display: flex;
        gap: 16px 24px;
        flex-wrap: nowrap;
        &__block {
            width: calc((100% - 24px) / 2);
            border-radius: 40px;
            height: 32.5vw;
            padding: 48px 0 0 48px;
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            &-subtitle {
                max-width: 420px;
            }
            img {
                z-index: 1;
            }
            &:first-child {
                background: radial-gradient(
                        185.53% 329.56% at 154.95% 160.91%,
                        #e336d9 16.5%,
                        #3d13b3 70.46%,
                        #0c0216 100%
                    ),
                    #1e1529;
                &:before {
                    position: absolute;
                    content: "";
                    background-image: url("/public/images/about/video-section-block-bg-1.png");
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 0;
                    background-size: cover;
                }
            }
            &:last-child {
                background-size: cover;
                background-position: center;
                background-image: url("/public/images/about/video-section-block-img-2.jpeg");
            }
        }
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            &__block {
                width: 100%;
                border-radius: 24px;
                padding: 32px 0 0 24px;
                &:nth-child(1) {
                    height: 98.667vw;
                }
                &:nth-child(2) {
                    height: 59.734vw;
                }
                &-subtitle {
                    font-size: 15px;
                    line-height: 21px;
                }
            }
        }
    }
    // ЧТО ПОЛУЧАЕТ ЗРИТЕЛЬ
    .sellers-page__how-work-section {
        li {
            width: calc((100% - 24px * 4) / 5);
            justify-content: flex-start;
            p {
                max-width: 231px;
            }
            img:nth-child(2) {
                bottom: 0 !important;
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }
    &__more-btn {
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 32px;
            button {
                width: 100%;
            }
        }
    }
    //  ЧТО ПОЛУЧАЕТ ПРОДАВЕЦ
    &__seller-section {
        .swiper-container {
            margin-left: 72px;
            .slider-control-arrow {
                top: calc(50% + 32px);
            }
            @media screen and (max-width: 1024px) {
                margin-left: 16px;
            }
            @media screen and (max-width: 768px) {
                .slider-control-arrow {
                    top: calc(50% - 32px);
                    transform: unset;
                    &:hover {
                        width: 42px;
                        height: 42px;
                    }
                }
            }
        }
        .swiper-wrapper {
            .swiper-slide {
                &:first-child {
                    background: linear-gradient(282.75deg, #e336d9 -44.86%, #3d13b3 135.77%), #1e1529;
                }
                &:nth-child(2) {
                    background: linear-gradient(287.52deg, #d11e9f -27.81%, #151efe 132.32%), #1e1529;
                }
                &:nth-child(3) {
                    background: linear-gradient(286.94deg, #ec30e1 -3%, #f3a345 110.78%), #1e1529;
                }
                &:nth-child(4) {
                    background: linear-gradient(292.7deg, #80d9b4 4.5%, #2454ff 118.27%), #1e1529;
                }
                &:nth-child(5) {
                    background: linear-gradient(294.1deg, #4e36e3 -43.6%, #ff4bed 115.4%), #1e1529;
                }
                &:nth-child(6) {
                    background: linear-gradient(285.95deg, #26c5f3 -45.29%, #8711c1 101.07%), #1e1529;
                }
                &:nth-child(7) {
                    background: linear-gradient(288.44deg, #f4e5f0 -30.66%, #e536ab 49.95%, #5203a8 137.22%), #1e1529;
                }
                &:nth-child(8) {
                    background: linear-gradient(282.75deg, #e336d9 -44.86%, #3d13b3 135.77%), #1e1529;
                }
                &:nth-child(9) {
                    background: linear-gradient(292.98deg, #ed5cc4 4.5%, #151efe 107.97%), #1e1529;
                }
            }
        }
        &__list-item {
            border-radius: 40px;
            height: 20.9799vw;
            width: calc((100% - 24px * 3 - 72px) / 4);
            height: 15.4vw;
            padding: 32px;
            box-sizing: border-box;
            display: inline-flex;
            vertical-align: top;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 1440px) {
                width: calc((100% - 24px * 2 - 72px) / 3);
                height: 20.9799vw;
            }
            img {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            @media screen and (max-width: 1024px) {
                width: calc((100% - 16px * 3 - 32px) / 3);
            }
            @media screen and (max-width: 998px) {
                width: calc((100% - 16px * 2 - 32px) / 2);
            }
            @media screen and (max-width: 768px) {
                width: calc((100% - 16px - 32px));
                height: 49.6vw;
            }
            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 21px;
                white-space: break-spaces;
            }
            @media screen and (max-width: 998px) {
                border-radius: 24px;
                padding: 24px;
            }
        }
    }
    &__seller-section,
    &__creators-section {
        .swiper-wrapper {
            display: block;
            white-space: nowrap;
            font-size: 0;
        }
        .swiper-container {
            padding-right: 16px;
        }
    }
    //  ЧТО ПОЛУЧАЕТ КРЕАТОР
    &__creators-section {
        &__list {
            box-sizing: border-box;
            gap: 24px;
        }
        &__list-item {
            height: 23.334vw; // 336px - 1440px
            border-radius: 40px;
            padding: 40px;
            box-sizing: border-box;
            background-size: cover;
            background-position: center;
            &:nth-child(1),
            &:nth-child(2) {
                width: calc((100% - 24px) / 2);
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                width: calc((100% - 24px * 2) / 3);
            }
            p {
                max-width: 270px;
            }
        }
        @media screen and (max-width: 1024px) {
            .swiper-container {
                margin-left: 16px;
            }
            .swiper-slide {
                display: inline-flex;
                width: calc(100% - 16px - 16px) !important;
                height: 59.734vw;
                vertical-align: top;
            }
            &__list-item {
                width: 100% !important;
                height: 100%;
                padding: 24px;
                border-radius: 24px;
                &:nth-child(1) {
                    background-position: 18% center;
                }
                p {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 21px;
                    white-space: break-spaces;
                }
            }
        }
    }
    .creators-page__capabilities-section {
        li {
            justify-content: flex-start;
            img {
                width: 180px;
                height: 180px;
            }
            p {
                margin-bottom: 0;
            }
            .figure-wrap {
                flex: 1;
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
            width: calc((100% - 24px * 2) / 3);
            background-image: url("/public/images/about/capabilities-bg.svg") !important;
            background-size: cover;
            @media screen and (max-width: 1024px) {
                width: 100%;
                max-height: 360px;
                min-height: 360px;
                // p {
                // 	margin-bottom: 50px;
                // }
            }
        }
    }
}
